import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { OrdersPackagingUsage } from "../CustomTypes";
import CompanyWidget from "../../common/CompanyWidget";
import { PaginationState } from "../../common/CustomTypes";
import Pagination, { paginate } from "../../common/Pagination";
import PriorityWidget from "../../common/PriorityWidget";
import { DataContext } from "../../../context/dataContext";
import orderUtils, { ARCHIVE, CONTRACT, DECLINED } from "../../../utils/orderUtils";
import { PackagingsDocument } from "../../../model/packagings.types";
import packagingUtils from "../../../utils/packagingUtils";
import baseUtils from "../../../utils/baseUtils";
import contractUtils from "../../../utils/contractUtils";

interface PackagingOrdersProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingOrdersState extends PaginationState {
  orders: Array<OrdersPackagingUsage>;
  manufacturer: string;
}

class PackagingOrders extends PureComponent<PackagingOrdersProps, PackagingOrdersState> {
  constructor(props: PackagingOrdersProps) {
    super(props);
    this.state = { manufacturer: "all", orders: [], currentPage: 1, pageSize: 5 };
  }

  componentDidMount() {
    this.setState({ orders: this.collectOrders() });
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingOrdersProps>,
    prevState: Readonly<PackagingOrdersState>,
    snapshot?: any
  ) {
    if (prevProps !== this.props || prevState.manufacturer !== this.state.manufacturer) {
      this.setState({ orders: this.collectOrders() });
    }
  }

  handleChangeManufacturer = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({ manufacturer: e.target.value });

  /**
   * Collect all orders where the current packaging is used and prepares the date for further usage.
   * @returns { OrdersPackagingUsage } Data about the usage of the current packaging
   */
  collectOrders = () => {
    const { packaging, context } = this.props;
    const { manufacturer } = this.state;
    const orders = context.orders
      .filter(
        o =>
          orderUtils.isOrder(o) &&
          !contractUtils.isContract(o) &&
          o.state !== DECLINED &&
          o.calculations[0].packagings.some(p => p._id.toString() === packaging._id.toString()) &&
          (manufacturer === "all" || o.settings.manufacturer.toString() === manufacturer)
      )
      .sort((o1, o2) => o2.createdOn.getTime() - o1.createdOn.getTime());
    const ordersPrepared = [];
    for (let i = 0; i < orders.length; i++) {
      const o = orders[i];
      const packagingPrice = o.calculations[0].packagings.find(p => p._id.toString() === packaging._id.toString());
      if (!packagingPrice) continue;
      const customer = context.companies.find(c => o.createdFor.toString() === c._id.toString());
      if (!customer) continue;
      const usage = packagingPrice.amount * (1 + packagingPrice.buffer / 100) * o.calculations[0].units;
      ordersPrepared.push({ order: o, customer, packagingPrice: packagingPrice, amount: usage });
    }
    return ordersPrepared;
  };

  render() {
    const { packaging, context } = this.props;
    const { currentPage, orders, pageSize, manufacturer } = this.state;
    const { manufacturers } = context;
    const ordersPaginated: Array<OrdersPackagingUsage> = paginate(orders, currentPage, pageSize);
    return (
      <>
        <>
          <div className="row justify-content-end">
            <div className="col-12 col-md-6 col-lg-3 ">
              <select className="form-control" value={manufacturer} onChange={this.handleChangeManufacturer}>
                <option value="all">All Manufacturers</option>
                {manufacturers.map(m => (
                  <option key={m._id.toString()} value={m._id.toString()}>
                    {m.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {orders && orders.length > 0 ? (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "35%" }}>Order</th>
                    <th style={{ width: "25%" }}>Company</th>
                    <th style={{ width: "10%" }}>Priority</th>
                    <th style={{ width: "10%" }}>Required</th>
                    <th style={{ width: "10%" }}>Ordered</th>
                    <th style={{ width: "10%" }}>Order Created</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersPaginated.map(o => {
                    const pack = o.packagingPrice;
                    return (
                      <tr
                        key={o.order._id.toString()}
                        style={{ backgroundColor: "#fafafa", opacity: o.order.state === ARCHIVE ? 0.5 : 1 }}
                      >
                        <td className="align-middle">
                          <Link to={"/order/" + o.order._id.toString()} className="h6 kt-link kt-font-dark">
                            AT-{o.order.identifier}
                          </Link>
                          <div className="text-muted">{o.order.title}</div>
                        </td>
                        <td className="align-middle">
                          <CompanyWidget company={o.customer} />
                        </td>
                        <td className="align-middle">
                          <PriorityWidget priority={o.order.priority} />
                        </td>
                        <td className="align-middle text-success kt-font-bold">{o.amount} pcs.</td>
                        <td className="align-middle kt-font-bold">
                          {pack.ordered ? baseUtils.formatDate(pack.ordered) : "-"}
                        </td>
                        <td className="align-middle">{baseUtils.formatDate(o.order.createdOn)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <Pagination
                  itemsCount={orders.length}
                  pageSize={pageSize}
                  onPageChange={currentPage => this.setState({ currentPage })}
                  currentPage={currentPage}
                  additionalNavClasses="justify-content-center flex-grow-1"
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-muted my-5">
              No orders with {packagingUtils.resolvePackagingProperties(packaging)}
            </div>
          )}
        </>
      </>
    );
  }
}
export default PackagingOrders;
