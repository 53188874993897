import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../../context/dataContext";
import SplashScreen from "../../common/SplashScreen";
import baseUtils from "../../../utils/baseUtils";
import orderUtils from "../../../utils/orderUtils";
import {
  T_COMMODITYDELIVERED,
  T_COMMODITYORDERED,
  T_COMMODITYORDEREDITED,
  T_COMMODITYREPLACED,
  T_DECLINED,
  T_FULFILLMENT,
  T_NOTE,
  T_OFFER,
  T_ORDERED,
  T_PACKAGINGORDERED,
  T_PACKAGINGDELIVERED,
  T_PRODUCTION,
  T_REQUESTAPPROVED,
  T_REQUESTPENDING,
  T_RETURNPRODUCTIONQUEUE,
  T_SHIPPINGLABEL,
  T_TARGETDATEUPDATED,
  T_UPDATE,
  T_WAITING,
  T_PACKAGINGREPLACED,
  T_COMMODITYREMOVED,
  T_PACKAGINGREMOVED,
  T_COMMODITYORDERUNLINKED,
  T_COMMODITYORDERCANCELED,
  T_INVOICECREATED,
  T_INVOICEREMINDER,
  T_INVOICEPARTLYPAID,
  T_INVOICEPAID,
  T_INVOICECANCELED,
  T_PACKAGINGORDERED2,
  T_PACKAGINGORDEREDITED,
  T_PACKAGINGORDERUNLINKED,
  T_PACKAGINGORDERCANCELED,
  T_FULFILLMENTREVOKED,
  T_COMMODITYATWAREHOUSE,
  T_TITLEUPDATED,
  T_CONVERTEDTOCONTRACT,
  T_CALCULATIONCHANGED,
  T_PRODUCTIONREPORTAPPROVED,
  T_ORDERSHIPPED,
  T_ORDERARCHIVED
} from "../../../utils/timelineUtils";
import dateUtils from "../../../utils/dateUtils";
import packagingUtils from "../../../utils/packagingUtils";
import { MARGIN } from "../../../utils/orderCalculationUtils";
import { ActivityCalculation, ActivityInvoice, RecentActivitiesDocument } from "../../../model/recentActivities.types";
import dbService, { RECENTACTIVITIES } from "../../../services/dbService";

interface RecentActivitiesProps {
  context: React.ContextType<typeof DataContext>;
}

interface RecentActivitiesState {
  recentActivities: Array<RecentActivitiesDocument>;
}

class RecentActivities extends PureComponent<RecentActivitiesProps, RecentActivitiesState> {
  pollingInterval: NodeJS.Timeout | null = null;
  constructor(props: RecentActivitiesProps) {
    super(props);
    this.state = {
      recentActivities: []
    };
  }

  async componentDidMount() {
    const recentActivities = await dbService.getCollection<RecentActivitiesDocument>(RECENTACTIVITIES);
    this.setState({ recentActivities });
    // Refresh data every 2 minutes
    this.pollingInterval = setInterval(async () => {
      const recentActivities = await dbService.getCollection<RecentActivitiesDocument>(RECENTACTIVITIES);
      this.setState({ recentActivities });
    }, 1000 * 60 * 2);
  }

  componentWillUnmount() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  render() {
    const { context } = this.props;
    const { recentActivities } = this.state;
    return (
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Recent Activities</h3>
          </div>
        </div>
        <div className="kt-portlet__body overflow-auto" style={{ height: "720px" }}>
          {recentActivities.length > 0 ? (
            <div className="kt-timeline-v2">
              <div className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30">
                {recentActivities.map(activity => (
                  <RecentActivity key={activity._id.toString()} activity={activity} context={context} />
                ))}
              </div>
            </div>
          ) : (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          )}
        </div>
      </div>
    );
  }
}
interface RecentActivityProps {
  activity: RecentActivitiesDocument;
  context: React.ContextType<typeof DataContext>;
}

class RecentActivity extends PureComponent<RecentActivityProps, {}> {
  getMarginText = (percentMargin: number) => {
    if (percentMargin < MARGIN.CRITICAL) return <span className="text-danger">Prohibited margin</span>;
    else if (percentMargin < MARGIN.BAD && percentMargin >= MARGIN.CRITICAL)
      return <span className="text-danger">Critical margin</span>;
    else if (percentMargin < MARGIN.GOOD && percentMargin >= MARGIN.BAD)
      return <span className="text-warning">Bad margin</span>;
    else if (percentMargin >= MARGIN.GOOD) return <span className="text-success">Good margin</span>;
    return "";
  };

  /**
   * Get content to render for an activity
   * @param activity the activity
   * @returns {JSX.Element} content for the activity
   */
  getActivityContent = (activity: RecentActivitiesDocument) => {
    const { userdata, companies, commodities, suppliers, packagings } = this.props.context;
    const user = baseUtils.getDocFromCollection(userdata, activity.timelineCopy.person);
    const userName = user ? `${user.prename} ${user.surname}` : "Unknown";
    let supplier;
    let commodity;
    let packaging;
    let invoice: ActivityInvoice | undefined;
    switch (activity.timelineCopy.type) {
      case T_OFFER:
      case T_UPDATE:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName + (activity.timelineCopy.type === T_OFFER ? " created a new offer" : " updated an offer")}
            </span>
            {activity.calculations &&
              activity.calculations.map((c: ActivityCalculation) => {
                return (
                  <div key={c.id.toString()}>
                    {c.units + " units à " + (c.unitPrice ? baseUtils.formatEuro(c.unitPrice) : "")}.{" "}
                    {c.percentMargin ? this.getMarginText(c.percentMargin) : ""}
                    {" of " +
                      c.percentMargin?.toFixed(2) +
                      "% | " +
                      (c.totalMargin ? baseUtils.formatEuro(c.totalMargin) : "")}
                  </div>
                );
              })}
          </>
        );
      case T_REQUESTPENDING:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} requested a commodity check </span>
            <br />
            {"Added " + activity.commodityAmount + " commodities to the request queue"}
          </>
        );
      case T_REQUESTAPPROVED:
        // activity.commodityChange possible 0
        if (activity.commodityChange !== undefined)
          return (
            <>
              <span className="kt-font-bold mr-2">
                {userName} updated {activity.commodityAmount} commodities{" "}
                {isNaN(activity.commodityChange) ? (
                  ""
                ) : activity.commodityChange <= 0 ? (
                  <span className="kt-user-card-v2__name mb-0" style={{ color: "#0abb87" }}>
                    <i className="fa fa-caret-down mx-2" style={{ color: "#0abb87" }} />
                    {activity.commodityChange}%
                  </span>
                ) : (
                  <span className="kt-user-card-v2__name mb-0" style={{ color: "#0abb87" }}>
                    <i className="fa fa-exclamation mx-2" style={{ color: "#fd397a" }} />
                    <span style={{ color: "#fd397a" }}>{activity.commodityChange}%</span>
                  </span>
                )}
              </span>
              <br />
              {!isNaN(activity.commodityChange) &&
                "The updated commodity prices deviate by " + activity.commodityChange + "%"}
            </>
          );
        return null;
      case T_ORDERED:
        const company = baseUtils.getDocFromCollection(companies, activity.createdFor);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {company ? company.name : "Unknown"} ordered {activity.orderUnits} units of {activity.orderTitle}
            </span>
            <div>
              {activity.orderUnits + " units à " + baseUtils.formatEuro(activity.timelineCopy.stats.unitprice)}.{" "}
              {this.getMarginText(activity.timelineCopy.stats.percentmargin)}
              {" of " +
                activity.timelineCopy.stats.percentmargin.toFixed(2) +
                "% | " +
                baseUtils.formatEuro(activity.timelineCopy.stats.totalmargin)}
            </div>
          </>
        );
      case T_CALCULATIONCHANGED:
        return (
          <>
            <span className="kt-font-bold mr-2">{`${userName} updated calculation`}</span>
            <div>{`Old cost per unit ${baseUtils.formatEuro(
              activity.timelineCopy.prices.oldPrice
            )}, new cost per unit ${baseUtils.formatEuro(activity.timelineCopy.prices.newPrice)}`}</div>
          </>
        );
      case T_COMMODITYORDERED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} ordered {activity.timelineCopy.amount + (commodity.type ? "tsd." : "kg")} of{" "}
              {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            Ordered at {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_COMMODITYORDEREDITED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} edited order of {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            Ordered at {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_COMMODITYORDERCANCELED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} canceled order of {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            {`Order of ${activity.timelineCopy.amount + (commodity.type ? "tsd." : "kg")} ${
              commodity.title.en
            } for ${baseUtils.formatEuro(activity.timelineCopy.price)} canceled`}
          </>
        );
      case T_COMMODITYORDERUNLINKED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} unlinked order of {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            Order was unlinked from commodity order of {commodity ? commodity.title.en : "unknown"}
          </>
        );
      case T_COMMODITYDELIVERED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} delivered {activity.timelineCopy.amount + "kg"} of {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            Delivered from {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_COMMODITYATWAREHOUSE:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        return (
          <span className="kt-font-bold mr-2">
            {userName} delivered {commodity ? commodity.title.en : "unknown"} to warehouse
          </span>
        );
      case T_COMMODITYREPLACED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodityPre);
        const commodityPost = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodityPost);
        const sameCom = commodity?.title.en === commodityPost?.title.en;
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName +
                (sameCom ? " changed source of " : " replaced ") +
                (commodity ? commodity.title.en : "unknown")}
            </span>
            <br />
            {sameCom
              ? "Source of " + (commodity ? commodity.title.en : "unknown") + " was changed "
              : (commodity ? commodity.title.en : "unknown") +
                " was replaced with " +
                (commodityPost ? commodityPost.title.en : "unknown")}
          </>
        );
      case T_COMMODITYREMOVED:
        commodity = baseUtils.getDocFromCollection(commodities, activity.timelineCopy.commodity);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} removed {commodity ? commodity.title.en : "unknown"}
            </span>
            <br />
            {commodity ? commodity.title.en : "unknown"} was removed
          </>
        );
      case T_PACKAGINGORDERED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} ordered {activity.timelineCopy.orderquantity} of{" "}
              {packagingUtils.getShortPackagingInfo(packaging)}
            </span>
            <br />
            Ordered at {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price
              ? baseUtils.formatEuro(
                  activity.timelineCopy.price /
                    (activity.timelineCopy.orderquantity ? activity.timelineCopy.orderquantity : 1)
                )
              : "unknown €"}{" "}
            per Item | {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_PACKAGINGORDERED2:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} ordered {activity.timelineCopy.amount} pcs. of{" "}
              {packaging ? packagingUtils.getShortPackagingInfo(packaging) : "unknown"}
            </span>
            <br />
            Ordered at {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_PACKAGINGORDEREDITED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} edited order of {packaging ? packagingUtils.getShortPackagingInfo(packaging) : "unknown"}
            </span>
            <br />
            Ordered at {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_PACKAGINGORDERCANCELED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} canceled order of {packaging ? packagingUtils.getShortPackagingInfo(packaging) : "unknown"}
            </span>
            <br />
            {`Order of ${activity.timelineCopy.amount} pcs. ${
              packaging ? packagingUtils.resolvePackagingProperties(packaging) : "unknown"
            } for ${baseUtils.formatEuro(activity.timelineCopy.price)} canceled`}
          </>
        );
      case T_PACKAGINGORDERUNLINKED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} unlinked order of {packaging ? packagingUtils.getShortPackagingInfo(packaging) : "unknown"}
            </span>
            <br />
            Order was unlinked from packaging order of{" "}
            {packaging ? packagingUtils.resolvePackagingProperties(packaging) : "unknown"}
          </>
        );
      case T_PACKAGINGDELIVERED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        supplier = baseUtils.getDocFromCollection(suppliers, activity.timelineCopy.supplier);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} delivered {activity.timelineCopy.orderquantity} of{" "}
              {packagingUtils.getShortPackagingInfo(packaging)}
            </span>
            <br />
            Delivered from {supplier ? supplier.name : "unknown"} for{" "}
            {activity.timelineCopy.price ? baseUtils.formatEuro(activity.timelineCopy.price) : "unknown €"}
          </>
        );
      case T_PACKAGINGREPLACED:
        packaging = packagingUtils.resolvePackagingProperties(
          baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packagingPre)
        );
        const packagingPost = packagingUtils.resolvePackagingProperties(
          baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packagingPost)
        );
        const samePack = packaging === packagingPost;
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName + (samePack ? " changed source of " : " replaced ") + packaging}
            </span>
            <br />
            {samePack ? "Source of " + packaging + " was changed" : packaging + " was replaced with " + packagingPost}
          </>
        );
      case T_PACKAGINGREMOVED:
        packaging = baseUtils.getDocFromCollection(packagings, activity.timelineCopy.packaging);
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} removed {packagingUtils.getShortPackagingInfo(packaging)}
            </span>
            <br />
            {packagingUtils.resolvePackagingProperties(packaging)} was removed
          </>
        );
      case T_WAITING:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} marked all commodities as "on stock"</span>
            <br />
            The order is now ready for production
          </>
        );
      case T_INVOICECREATED:
        invoice = activity.invoices?.find(
          (i: ActivityInvoice) => i.id.toString() === activity.timelineCopy.invoiceId.toString()
        );
        return (
          <>
            {invoice && (
              <>
                <span className="kt-font-bold mr-2">
                  {userName} created an invoice ({invoice.type})
                </span>
                <br />
                {(invoice.totalGross ? baseUtils.formatEuro(invoice.totalGross) : "") +
                  (invoice.dueIn !== undefined && invoice.invoiceDate
                    ? ` due on ${baseUtils.formatDate(orderUtils.getInvoiceDue(invoice.dueIn, invoice.invoiceDate))}`
                    : "")}
              </>
            )}
          </>
        );
      case T_INVOICEREMINDER:
        invoice = activity.invoices?.find(
          (i: ActivityInvoice) => i.id.toString() === activity.timelineCopy.invoiceId.toString()
        );
        const reminder = invoice?.reminder?.find(r => r.id.toString() === activity.timelineCopy.reminderId.toString());
        return (
          <>
            {invoice && reminder && (
              <>
                <span className="kt-font-bold mr-2">
                  {userName} send a reminder for invoice RE-{invoice.invoiceNumber}
                </span>
                {reminder.total
                  ? "with dunning fee and interest (" + baseUtils.formatEuro(reminder.total) + ")"
                  : "without interest"}{" "}
                <br />A reminder was created.
              </>
            )}
          </>
        );
      case T_INVOICEPARTLYPAID:
      case T_INVOICEPAID:
        invoice = activity.invoices?.find(
          (i: ActivityInvoice) => i.id.toString() === activity.timelineCopy.invoiceId.toString()
        );
        return (
          <>
            {invoice && (
              <>
                <span className="kt-font-bold mr-2">
                  {userName} marked invoice RE-{invoice.invoiceNumber} as{" "}
                  {activity.timelineCopy.type === T_INVOICEPARTLYPAID ? "partly" : ""} paid
                </span>
                <br />
                {invoice.totalGross ? baseUtils.formatEuro(invoice.totalGross) : ""} were paid
              </>
            )}
          </>
        );
      case T_INVOICECANCELED:
        invoice = activity.invoices?.find(
          (i: ActivityInvoice) => i.id.toString() === activity.timelineCopy.invoiceId.toString()
        );
        return (
          <>
            {invoice && (
              <>
                <span className="kt-font-bold mr-2">
                  {userName} canceled invoice RE-{invoice.invoiceNumber}
                </span>
                <br />
                {invoice.totalGross ? baseUtils.formatEuro(invoice.totalGross) : ""} were canceled
              </>
            )}
          </>
        );
      case T_DECLINED:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} declined an order</span>
            <br />
            Offer/Order AN/AT-{activity.identifier} has now been moved to declined orders
          </>
        );
      case T_SHIPPINGLABEL:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} uploaded a shipping label</span>
            <br />
            The order is now ready for dispatch
          </>
        );
      case T_NOTE:
        return (
          <>
            <span className="kt-font-bold mr-2">{userName} added a note</span>
            <br />
            <i>{activity.timelineCopy.text}</i>
          </>
        );
      case T_PRODUCTION:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span className="text-success">moved the order to "in production"</span>
            </span>
            <br />
          </>
        );
      case T_RETURNPRODUCTIONQUEUE:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span className="text-danger">had to abort the production</span>
            </span>
            <br />
            <i>{activity.timelineCopy.reason ? activity.timelineCopy.reason : "reason unknown"}</i>
          </>
        );
      case T_FULFILLMENT:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span className="text-success">finished production</span>
            </span>
            <br /> Order is now in fulfillment
          </>
        );
      case T_FULFILLMENTREVOKED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span className="text-danger">revoked the fulfillment</span>
            </span>
            <br /> Order is now in production again
          </>
        );
      case T_TARGETDATEUPDATED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>updated the target date</span>
            </span>
            <div>
              Target date was updated from{" "}
              <span className="text-danger ">
                {activity.timelineCopy.oldDate
                  ? `${baseUtils.formatDate(activity.timelineCopy.oldDate)} (CW ${dateUtils.getCW(
                      activity.timelineCopy.oldDate
                    )})`
                  : "-"}
              </span>{" "}
              to{" "}
              <span className="text-success ">{`${baseUtils.formatDate(
                activity.timelineCopy.newDate
              )} (CW ${dateUtils.getCW(activity.timelineCopy.newDate)})`}</span>
              {activity.timelineCopy.note && <div>Note: {activity.timelineCopy.note}</div>}
            </div>
          </>
        );
      case T_TITLEUPDATED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>updated the title</span>
            </span>
            {activity.timelineCopy.titlePre !== activity.timelineCopy.titlePost && (
              <div className="font-weight-normal text-muted">
                Title was updated from: "{activity.timelineCopy.titlePre}" to "{activity.timelineCopy.titlePost}"
              </div>
            )}
            {activity.timelineCopy.subtitlePre !== activity.timelineCopy.subtitlePost && (
              <div className="font-weight-normal text-muted">
                Subtitle was updated from: "{activity.timelineCopy.subtitlePre}" to "
                {activity.timelineCopy.subtitlePost}"
              </div>
            )}
          </>
        );
      case T_CONVERTEDTOCONTRACT:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>converted an order to contract</span>
            </span>
            <div>Order AT-{activity.identifier} was converted to contract</div>
          </>
        );
      case T_PRODUCTIONREPORTAPPROVED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>approved the production report</span>
            </span>
            <div>Order is now ready for shipment</div>
          </>
        );
      case T_ORDERSHIPPED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>shipped the order</span>
            </span>
            <div>Order is on its way to the customer</div>
          </>
        );
      case T_ORDERARCHIVED:
        return (
          <>
            <span className="kt-font-bold mr-2">
              {userName} <span>archived the order</span>
            </span>
            <div>Order is finished</div>
          </>
        );
    }
  };

  render() {
    const { activity } = this.props;
    const activityContent = this.getActivityContent(activity);

    return (
      <>
        {activityContent && (
          <div className="kt-timeline-v2__item">
            <span className="kt-timeline-v2__item-time" style={{ fontSize: "0.95rem", paddingTop: ".32rem" }}>
              {activity.timelineCopy.date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })}
            </span>
            <div className="kt-timeline-v2__item-cricle lightblue">
              <i className="fa fa-genderless" />
            </div>
            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
              <Link className="kt-link kt-font-bolder mr-1" to={"/order/" + activity.orderId}>
                {(+activity.identifier > 20000 ? "AT-" : "AN-") + activity.identifier}:
              </Link>
              {activityContent}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default RecentActivities;
