import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import validator from "validator";
import DunningFloatingButton from "./DunningFloatingButton";
import { DunningObject } from "./DunningListing";
import { CustomerData, CustomOrder, InvoicePosition } from "../../order/CustomTypes";
import { Invoice, Position } from "../../../model/orders.types";
import invoiceGeneration from "../../../utils/pdf/invoiceGeneration";
import invoiceUtils, { I_DUNNING_PERIOD, I_REMINDER } from "../../../utils/invoiceUtils";
import dateUtils from "../../../utils/dateUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import userService from "../../../services/userService";
import dbOrderService, { I_INTEREST } from "../../../services/dbServices/dbOrderService";
import notificationService, { R_INVOICEREMINDER } from "../../../services/notificationService";
import dbService, { COMPANIES, ORDERS } from "../../../services/dbService";
import config from "../../../config/config.json";
import baseUtils from "../../../utils/baseUtils";
import { DataContext } from "../../../context/dataContext";
import OrderHelper from "../../order/OrderHelper";
import orderUtils from "../../../utils/orderUtils";
import { Companies, CompaniesDocument } from "../../../model/companies.types";
import slackService from "../../../services/slackService";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";

interface DunningConfirmationModalProps {
  selectedDunningObjects: Array<DunningObject>;
  onFinish: () => void;
  context: React.ContextType<typeof DataContext>;
}

/**
 * Component to represent the confirmation modal for dunning processes.
 */
const DunningConfirmationModal: React.FunctionComponent<DunningConfirmationModalProps> = ({
  selectedDunningObjects,
  onFinish,
  context
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => {
    // check if all selected accounting mail values are valid
    const allSelectionValid =
      selectedDunningObjects.find(
        dunningObject =>
          !dunningObject.correspondingAccountingMail || !validator.isEmail(dunningObject.correspondingAccountingMail)
      ) === undefined;

    if (!allSelectionValid) {
      toast.error("Please make sure, that all selected dunning rows have a valid accounting mail");
    }

    setShow(allSelectionValid);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleFinish = () => {
    setShow(false);
    onFinish();
  };

  return (
    <>
      <DunningFloatingButton
        numberOfSelected={selectedDunningObjects.length}
        isDisabled={selectedDunningObjects.length === 0}
        onClick={handleShow}
      />
      <div className="col-xl-2 text-right">
        <button className="btn btn-success" disabled={selectedDunningObjects.length === 0} onClick={handleShow}>
          Remind Selected ({selectedDunningObjects.length})
        </button>
      </div>
      {show && (
        <ConfirmationProcessModal
          context={context}
          selectedDunningObjects={selectedDunningObjects}
          onFinish={handleFinish}
          onClose={handleClose}
        />
      )}
    </>
  );
};

/**
 * States of a dunning process.
 */
enum DunningProcessStates {
  READY,
  RUNNING,
  SUCCESS,
  FAILED
}

interface ConfirmationProcessModalProps {
  selectedDunningObjects: Array<DunningObject>;
  onClose: () => void;
  onFinish: () => void;
  context: React.ContextType<typeof DataContext>;
}

const ConfirmationProcessModal: React.FunctionComponent<ConfirmationProcessModalProps> = ({
  selectedDunningObjects,
  onClose,
  onFinish,
  context
}: ConfirmationProcessModalProps) => {
  const [isDunningProcessActive, setIsDunningProcessActive] = useState<boolean>(false);
  const [dunningProcessFinished, setDunningProcessFinished] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [progressStates, setProgressStates] = useState<Map<string, { state: DunningProcessStates }>>(
    new Map(
      selectedDunningObjects.map(dunningObject => [
        dunningObject.invoice._id.toString(),
        { state: DunningProcessStates.READY }
      ])
    )
  );

  useEffect(() => {
    const progressStateCopy = new Map(progressStates);
    const isRunning =
      Array.from(progressStateCopy.values()).find(stateObject => stateObject.state === DunningProcessStates.RUNNING) !==
      undefined;

    if (!isRunning && !firstRender) {
      setIsDunningProcessActive(false);
      setDunningProcessFinished(true);
    }

    if (firstRender) {
      setFirstRender(false);
    }
  }, [progressStates]);

  /**
   * Create a set of reminders corresponding to the selected dunning rows and notify the customer later on via mail.
   * @returns {Promise<void>}
   */
  const handleCreateReminder = async () => {
    // activate reminder process(es)
    const progressStatesCopy = new Map(progressStates);
    progressStatesCopy.forEach((value, key, map) => map.set(key, { state: DunningProcessStates.RUNNING }));
    setIsDunningProcessActive(true);
    setProgressStates(progressStatesCopy);

    // duplication protection for mail changes
    const mailUpdateStore: Array<string> = [];

    // .map for concurrent processing
    selectedDunningObjects.map(async correspondingDunningObject => {
      // check if there is a newly created accounting mail and the mail has not already been updated, if true update entry first
      if (
        correspondingDunningObject.hasAccountingMailChanged &&
        !mailUpdateStore.includes(correspondingDunningObject.order.createdFor.toString())
      ) {
        // receive the corresponding companies document
        const company: CompaniesDocument = baseUtils.getDocFromCollection(
          context.companies,
          correspondingDunningObject.order.createdFor
        );

        // create update object
        const toUpdate: Companies = {
          ...company,
          accountingEmail: correspondingDunningObject.correspondingAccountingMail
        };

        // update accounting mail
        await dbService.updateDocument(COMPANIES, company._id, toUpdate);
        mailUpdateStore.push(correspondingDunningObject.order.createdFor.toString()); // duplication prevention
      }

      // Extending necessary information
      const extendedOrder: CustomOrder = OrderHelper.getExtendedOrder(correspondingDunningObject.order, context);
      const extendedCustomer = OrderHelper.getCustomerData(extendedOrder);

      // Creating reminder object for reminder generation
      const reminderObject: { order: CustomOrder; invoice: Invoice } = {
        order: extendedOrder,
        invoice: correspondingDunningObject.invoice
      };

      // receive the corresponding positions of an invoice, map each entry to an InvoicePosition object
      const positionCollection = reminderObject.invoice.positions;

      // go through all position and map then to InvoicePosition objects
      const invoicePositionCollection: Array<InvoicePosition> = positionCollection.map(position => {
        const correspondingInvoicePosition: InvoicePosition = {
          id: new BSON.ObjectId(position._id),
          vat: position.vat.toString(),
          type: position.type,
          amount: position.amount.toString(),
          total: position.total.toString(),
          discount: position.discount.toString(),
          unit: position.unit.toString(),
          price: position.price.toString(),
          description: position.description
        };
        return correspondingInvoicePosition;
      });

      // receive reminder level and language
      const nextReminderLevel = correspondingDunningObject.reminderOption.value;
      const correspondingLanguage =
        correspondingDunningObject.pdfLanguage.value === "de" ? { lng: "de" } : { lng: "en" };

      if (correspondingDunningObject.correspondingAccountingMail) {
        // Create a reminder for the created reminder object
        const newReminderPosition = createInvoiceData(
          reminderObject.invoice,
          invoicePositionCollection,
          nextReminderLevel,
          correspondingLanguage
        );

        // create reminder pdf -> save if to backend and notify customer
        await uploadReminder(
          reminderObject.order,
          extendedCustomer,
          newReminderPosition.invoiceResult,
          newReminderPosition.positions,
          nextReminderLevel,
          correspondingLanguage,
          correspondingDunningObject.correspondingAccountingMail
        );
      } else {
        toast.error("Failed to receive corresponding destination!");
        const informationObject = {
          forCustomer: extendedCustomer,
          relatedDunningObject: correspondingDunningObject,
          relatedReminderObject: reminderObject
        };
        await slackService.sendMessage(
          "novacode-reviews",
          ":warning: Failed to receive a corresponding destination for automated reminders!\nRelated Information:\n" +
            JSON.stringify(informationObject)
        );
        setProgressStates(prevState => {
          const copy = new Map(prevState);
          copy.set(reminderObject.invoice._id.toString(), { state: DunningProcessStates.FAILED });
          return copy;
        });
      }
    });
  };

  /**
   * Create a reminder pdf.
   * @param order the corresponding order.
   * @param customer the corresponding customer.
   * @param invoice the corresponding invoice.
   * @param positions the corresponding invoice positions.
   * @param reverseCharge the corresponding reverseCharge indicator.
   * @param subTotal the newly calculated subTotal.
   * @param vatList the corresponding vat listing.
   * @param total the total value of the reminder object.
   * @param language the language to use.
   * @param draft indicates if object is a draft.
   * @return {string} html representation of the created reminder.
   */
  const doCreateReminderPDF = (
    order: CustomOrder,
    customer: CustomerData,
    invoice: Invoice,
    positions: Array<InvoicePosition>,
    reverseCharge: boolean,
    subTotal: number,
    vatList: Array<{ vat: string; value: number; total: number }>,
    total: number,
    language: { lng: string },
    draft?: boolean
  ): string => {
    return invoiceGeneration.createInvoice(
      order,
      customer,
      invoice,
      positions,
      reverseCharge,
      subTotal,
      vatList,
      total,
      I_REMINDER,
      order.fulfillment ? order.fulfillment.lot : undefined,
      draft,
      invoice.payments.length > 0
        ? invoice.payments.map(p => {
            return { date: p.date, note: p.note, value: p.amount };
          })
        : undefined,
      language
    );
  };

  /**
   * Create the order confirmation pdf.
   * @param total total amount with VAT.
   * @param reminderLevel the level of the dunning process.
   * @param pdfHTML the invoice reminder as html object.
   * @param invoice the invoice data.
   * @returns { Promise<{result: boolean, path?: string, message?: string}> } Result of pdf creation with path or error message.
   */
  const saveReminderPDF = async (total: number, reminderLevel: number, pdfHTML: string, invoice: Invoice) => {
    const data = JSON.stringify({
      html: pdfHTML,
      fileName:
        `${reminderLevel === 0 ? "Zahlungserinnerung" : "Mahnung"}-` +
        invoice.invoiceNumber +
        "_" +
        dateUtils.timeStampDate() +
        ".pdf"
    });
    let path;
    try {
      path = await pdfUtils.uploadAndReturnPath(data);
    } catch (e) {
      return { result: false, message: e.message };
    }
    return { result: true, path: path };
  };

  /**
   * Upload a created reminder.
   * @param order corresponding order.
   * @param customer corresponding customer.
   * @param invoice corresponding invoice.
   * @param positions corresponding invoice positions.
   * @param reminderLevel the level of the dunning process.
   * @param language the language to use.
   * @param receiver target email
   */
  const uploadReminder = async (
    order: CustomOrder,
    customer: CustomerData,
    invoice: Invoice,
    positions: Array<InvoicePosition>,
    reminderLevel: number,
    language: { lng: string },
    receiver: string
  ) => {
    const subTotal = invoiceUtils.getSubtotal(positions);
    const vatList = invoiceUtils.getVatAmount(positions, invoice.reverseCharge);
    const total = subTotal + vatList.reduce((a, b) => a + b.value, 0);
    let finishState: { state: DunningProcessStates } = { state: DunningProcessStates.FAILED }; // default failed

    // create with the collected data a corresponding html invoice pdf
    const reminderPDFHTML = doCreateReminderPDF(
      order,
      customer,
      invoice,
      positions,
      invoice.reverseCharge,
      subTotal,
      vatList,
      total,
      language
    );

    // save reminder into media hub
    try {
      const reminderPath = await saveReminderPDF(invoice.total, reminderLevel, reminderPDFHTML, invoice);

      // checking result of the creation
      if (reminderPath.result && reminderPath.path) {
        // receive index of newly created reminder object
        const newReminderIndex = invoice.reminder.length - 1;

        // extend reminder with pdf path
        let extendedNewReminderPosition = _.cloneDeep(invoice);
        extendedNewReminderPosition.reminder[newReminderIndex].path = reminderPath.path!;

        // create Timeline entry
        let timelineEntry: any;
        timelineEntry = {
          id: new BSON.ObjectId(),
          type: I_REMINDER,
          date: new Date(),
          person: new BSON.ObjectId(userService.getUserData()._id),
          invoiceNumber: extendedNewReminderPosition.invoiceNumber,
          invoiceId: extendedNewReminderPosition._id,
          path: extendedNewReminderPosition.reminder[newReminderIndex].path
        };

        let result;
        if (extendedNewReminderPosition.reminder.length > 0) {
          timelineEntry.reminderId =
            extendedNewReminderPosition.reminder[extendedNewReminderPosition.reminder.length - 1]._id;
          result = await dbOrderService.addInvoiceReminder(
            order._id,
            extendedNewReminderPosition._id,
            timelineEntry,
            extendedNewReminderPosition.reminder[extendedNewReminderPosition.reminder.length - 1]
          );
        }
        if (result) {
          notificationService.notify(R_INVOICEREMINDER, order._id);
          await context.updateDocumentInContext(ORDERS, order._id);

          // instantiate notifying variables
          const reminderURL = config.mediahubBase + extendedNewReminderPosition.reminder[newReminderIndex].path;
          const mailSubject = extendedNewReminderPosition.reminder[newReminderIndex].content.title;
          const mailContent = extendedNewReminderPosition.reminder[newReminderIndex].content.header;
          const reminderTitle = "Reminder for Invoice RE-" + extendedNewReminderPosition.invoiceNumber + ".pdf";
          const bccTargets: Array<string> = userService.getUserMail()
            ? [userService.getUserMail()!]
            : ["info@novacode.dev"];

          // receive the mail template from backend
          const mailTemplate = await dbService.callFunction("getReminderMailTemplate", [
            mailContent.split(",")[0].trim(), // receiving the overall greeting from the mail content (dear sir or madam,)
            mailContent // receiving the rest of the mail content, without greeting formula. The formula is seperated by comma
              .split(",")
              .slice(1)
              .join(",")
              .trim()
          ]);

          // notify customer or development destination
          const res = await dbService.callFunction("sendRawMail", [
            mailSubject,
            mailTemplate,
            mailContent,
            receiver,
            bccTargets,
            reminderURL,
            reminderTitle
          ]);

          if (res) {
            finishState = { state: DunningProcessStates.SUCCESS };
          } else {
            const informationObject = {
              subject: mailSubject,
              content: mailContent,
              sendTo: receiver,
              bccTargets: bccTargets,
              reminderURL: reminderURL,
              reminderTitle: reminderTitle
            };
            toast.error("Failed to send reminder to customer!");
            await slackService.sendMessage(
              "novacode-reviews",
              ":warning: Could not send an automated reminder!\n" + JSON.stringify(informationObject)
            );
          }
        } else {
          toast.error("Adding of timeline entry failed. " + reminderPath.path!);
        }
      } else {
        toast.error(
          "Could not create a reminder for Invoice: RE-" +
            invoice.invoiceNumber +
            " with Order: AT-" +
            order.identifier +
            "\n" +
            "Reason: " +
            reminderPath.message
        );
      }
    } catch (e) {
      toast.error(e);
    } finally {
      // concurrent state update
      setProgressStates(prevState => {
        const copy = new Map(prevState);
        copy.set(invoice._id.toString(), finishState);
        return copy;
      });
    }
  };

  /**
   * Initialize invoice data, e.g. from existing entries if required
   * @param invoice the referenced invoice.
   * @param positions the corresponding invoice positions.
   * @param reminderLevel level of the corresponding dunning process.
   * @param language the language to use.
   * @returns { { invoice: Invoice, positions: Array<InvoicePosition> } } Altered invoice and positions.
   */
  const createInvoiceData = (
    invoice: Invoice,
    positions: Array<InvoicePosition>,
    reminderLevel: number,
    language: { lng: string }
  ) => {
    const invoiceCopy = _.cloneDeep(invoice);

    // Set different title and text for reminder and cancellation
    const invoiceNumber = invoice.invoiceNumber.toString();
    const interest = reminderLevel >= 1 ? I_INTEREST : 0;
    const dunningDeadLine = new Date();
    dunningDeadLine.setDate(dunningDeadLine.getDate() + 7 * I_DUNNING_PERIOD);

    const reminderTemplate =
      reminderLevel >= 2 ? "template:invoiceTextReminderWithDebtCollectionWarning" : "template:invoiceTextReminder";
    // push a corresponding reminder object
    invoiceCopy.reminder.push({
      _id: new BSON.ObjectId(),
      date: new Date(),
      level: reminderLevel,
      interest: interest,
      content: {
        title: invoice.pdfData.content.title,
        paymentInfo: i18n.t("template:orderConfirmationPaymentNote", language),
        footer: i18n.t("template:orderConfirmationNote", language),
        header: i18n
          .t(reminderTemplate, language)
          .replace("{invoiceNumber}", invoiceNumber)
          .replace("{invoiceDate}", baseUtils.formatDate(invoice.invoiceDate))
          .replace("{today}", baseUtils.formatDate(new Date()))
          .replace("{dunningDeadline}", baseUtils.formatDate(dunningDeadLine))
      },
      path: "",
      person: userService.getUserId()
    });

    // generate corresponding invoice positions
    return generateReminderPosition(invoiceCopy, positions, language);
  };

  /**
   * Generate the reminder positions for the given invoice.
   * @param invoice Invoice that should be updated.
   * @param positions Positions that should be updated.
   * @param language the language to use.
   * @returns { { invoice: Invoice, positions: Array<InvoicePosition> } } Altered invoice and positions.
   */
  const generateReminderPosition = (invoice: Invoice, positions: Array<InvoicePosition>, language: { lng: string }) => {
    const invoiceResult = _.cloneDeep(invoice);
    if (invoice.reminder.length > 0) {
      const correspondingReminder = invoice.reminder[invoice.reminder.length - 1];
      if (correspondingReminder.position) {
        positions = positions.filter(p => p.id.toString() !== correspondingReminder.position!._id.toString());
      }
      // If the reminder is set to no interest, ensure that there is no position
      if (correspondingReminder.level === 0) {
        delete correspondingReminder.position;
      } else {
        // reminder has interests
        correspondingReminder.interest = I_INTEREST;
        const dueDate = new Date(invoice.invoiceDate);
        dueDate.setDate(dueDate.getDate() + invoice.dueIn);
        // Reminder interest is based upon the days the invoice is overdue
        const reminderInterest = +(
          ((invoice.totalGross * (I_INTEREST / 100)) / 365) *
          Math.ceil(dateUtils.getDaysBetween(dueDate, new Date()))
        ).toFixed(2);
        // checking for fees (add fees by remind value above 1)
        const hasExtendedFees = correspondingReminder.level === 2;
        const hasSimpleFees = !hasExtendedFees;

        correspondingReminder.position = {
          _id: new BSON.ObjectId(),
          total: reminderInterest + (!hasExtendedFees ? 0 : 40),
          price: reminderInterest + (!hasExtendedFees ? 0 : 40),
          description:
            (hasSimpleFees
              ? I_INTEREST + "% " + `${i18n.t("invoice:latePaymentInterest", language)}`
              : `${i18n.t("invoice:dunningFees", language)}` +
                "(40€ + " +
                I_INTEREST +
                "% " +
                `${i18n.t("invoice:latePaymentInterest", language)}`) +
            ` ${i18n.t("invoice:from", language)}` +
            " " +
            baseUtils.formatDate(dueDate) +
            " - " +
            baseUtils.formatDate(new Date())
        };
        const position = correspondingReminder.position!;
        const invoicePosition: InvoicePosition = {
          id: position._id,
          vat: "0",
          type: I_REMINDER,
          amount: "1",
          total: position.total.toString(),
          discount: "0",
          unit: "item",
          price: position.price.toString(),
          description: position.description
        };
        const pos: Position = {
          type: "reminder",
          amount: 0,
          vat: 0,
          discount: 0,
          unit: "item",
          ...correspondingReminder.position
        };
        positions.push(invoicePosition);
        invoiceResult.positions.push(pos);
      }
    }
    return { invoiceResult, positions };
  };

  /**
   * Receiving the company names corresponding to the relevant dunning objects.
   * @returns {Array<{id: string, name: string}>} collection of the corresponding companies.
   */
  const getRelevantDunningProcessCompanies = () => {
    const result: Array<{ id: string; name: string }> = [];
    selectedDunningObjects.map(dunningObject => {
      if (dunningObject.company && !_.find(result, dunningObject.company)) result.push(dunningObject.company);
    });
    return result;
  };

  /**
   * Receive the amount of reminders corresponding to the specific company and the selected dunning rows.
   * @param companyId id of the corresponding company.
   * @returns {number} number of corresponding reminders.
   */
  const getCorrespondingAmountOfReminder = (companyId: string) => {
    return selectedDunningObjects.filter(dunningObject => dunningObject.company?.id === companyId).length;
  };

  /**
   * Receive the state class corresponding to the processing states of a specific dunning object.
   * @param state the actual state of the corresponding dunning object.
   * @returns {string} representation of the new state class.
   */
  const getCorrespondingStateClass = (state: DunningProcessStates) => {
    let result: string = "";
    switch (state) {
      case DunningProcessStates.READY:
        result = "kt-badge--info";
        break;
      case DunningProcessStates.RUNNING:
        result = "kt-badge--warning text-white";
        break;
      case DunningProcessStates.FAILED:
        result = "kt-badge--danger";
        break;
      case DunningProcessStates.SUCCESS:
        result = "kt-badge--success";
        break;
    }
    return result;
  };

  /**
   * Receive a state description corresponding to the processing states of a specific dunning object.
   * @param state the actual state of the corresponding dunning object.
   * @returns {string} representation of the corresponding state text.
   */
  const getCorrespondingStateText = (state: DunningProcessStates) => {
    let result: string = "";
    switch (state) {
      case DunningProcessStates.READY:
        result = "Ready";
        break;
      case DunningProcessStates.RUNNING:
        result = "Sending";
        break;
      case DunningProcessStates.FAILED:
        result = "Failed";
        break;
      case DunningProcessStates.SUCCESS:
        result = "Success";
        break;
    }
    return result;
  };

  // receive related companies
  const relevantDunningCompanies: Array<{ id: string; name: string }> = getRelevantDunningProcessCompanies();
  return (
    <Modal show={true} centered onHide={!isDunningProcessActive ? onClose : undefined} size={"lg"} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="kt-font-brand fa fa-warning" />
          Dunning Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kt-portlet__body">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name">
                You are about to send {selectedDunningObjects.length} Reminders.{" "}
                {selectedDunningObjects.length > 1 ? "The recipients are:" : "The recipient is:"}
              </span>
            </div>
          </div>
          <div className="container mt-3">
            {relevantDunningCompanies.map(relevantCompany => {
              return (
                <div className="kt-portlet mb-3" key={relevantCompany.id}>
                  <div className="kt-portlet__head">
                    <div className="row mt-3 w-100">
                      <div className="col-8 font-weight-bold">{relevantCompany.name}</div>
                      <div className="col-4">{getCorrespondingAmountOfReminder(relevantCompany.id)} Reminder(s)</div>
                    </div>
                  </div>
                  <div className="kt-portlet__body">
                    {selectedDunningObjects
                      .filter(dunningObject => dunningObject.company!.id === relevantCompany.id)
                      .map(correspondingDunningObject => (
                        <div
                          className="row mt-2 font-weight-normal"
                          key={correspondingDunningObject.invoice._id.toString()}
                        >
                          <div className="col-2">RE-{correspondingDunningObject.invoice.invoiceNumber}</div>
                          <div className="col-2">AT-{correspondingDunningObject.order.identifier}</div>
                          <div className="col-3">Dunning Level: {correspondingDunningObject.reminderOption.value}</div>
                          <div className="col-2">
                            {baseUtils.formatDate(
                              orderUtils.getInvoiceDue(
                                correspondingDunningObject.invoice.dueIn,
                                correspondingDunningObject.invoice.invoiceDate
                              )
                            )}
                          </div>
                          <div className="col text-right">
                            <span
                              className={
                                " mr-5  kt-font-bold kt-badge kt-badge--inline kt-badge--pill " +
                                getCorrespondingStateClass(
                                  progressStates.get(correspondingDunningObject.invoice._id.toString())!.state
                                )
                              }
                              style={{ width: "fill-content" }}
                            >
                              <p className={"text-center"} style={{ fontSize: "13px" }}>
                                {getCorrespondingStateText(
                                  progressStates.get(correspondingDunningObject.invoice._id.toString())!.state
                                )}
                                {progressStates.get(correspondingDunningObject.invoice._id.toString())!.state ===
                                  DunningProcessStates.RUNNING && (
                                  <span className="button-splash-spinner d-inline">
                                    <svg className="button-splash-spinner ml-1 mr-0" viewBox="0 0 50 50">
                                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                                    </svg>
                                  </span>
                                )}
                              </p>
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={!dunningProcessFinished ? onClose : onFinish}
          disabled={isDunningProcessActive}
        >
          Close
        </button>
        {!dunningProcessFinished && (
          <ErrorOverlayButton
            errors={[]}
            className="btn btn-primary"
            saving={isDunningProcessActive}
            onClick={handleCreateReminder}
            buttonText={
              <>
                {isDunningProcessActive && (
                  <div className="button-splash-spinner d-inline pr-3 pl-0 mx-0">
                    <svg className="button-splash-spinner" viewBox="0 0 50 50">
                      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                    </svg>
                  </div>
                )}
                Confirm
              </>
            }
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DunningConfirmationModal;
