import { BSON } from "realm-web";
import {
  Batch,
  BatchComment,
  BatchFile,
  BatchLocation,
  BatchLocationInformation,
  BatchTimelineEntry,
  BatchTimelineSubType,
  BatchTimelineType,
  PackagingUnit
} from "../model/warehouse/batch.types";
import { Reservation, ReservationState } from "../model/warehouse/reservation.types";
import userService from "../services/userService";
import {
  FileType,
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition
} from "../model/configuration/warehouseConfiguration.types";
import { DEFAULTWEIGHTUNIT } from "./warehouseUtils";
import { NumValue } from "../model/common.types";

export enum BatchStatus {
  AVAILABLE,
  RESERVED,
  PARTIALLY_RESERVED
}

/**
 * Returns a new BatchLocationInformation object based on the given information
 * @param logicalWarehouse the WarehouseDefinition from which the warehouseSnapshot should be taken from
 * @param physicalWarehouse the PhysicalWarehouse from which the warehouseArea should be taken from
 * @param storageSpace optional, the StorageSpace from which the storageSpace should be taken from
 * @returns {BatchLocationInformation} the BatchLocationInformation including snapshots from the given information
 */
export const getBatchLocationInformationObject = (
  logicalWarehouse: WarehouseDefinition,
  physicalWarehouse: PhysicalWarehouse,
  storageSpace?: StorageSpace
): BatchLocationInformation => {
  const batchLocationInformationObject: BatchLocationInformation = {
    warehouseSnapshot: {
      _id: logicalWarehouse._id,
      shortName: logicalWarehouse.shortName,
      warehouseName: logicalWarehouse.warehouseName
    },
    warehouseArea: {
      _id: physicalWarehouse._id,
      type: physicalWarehouse.type,
      shortName: physicalWarehouse.shortName,
      warehouseName: physicalWarehouse.warehouseName
    }
  };
  if (storageSpace) {
    batchLocationInformationObject.storageSpace = {
      _id: storageSpace._id,
      storageSpaceNo: storageSpace.storageSpaceNo
    };
  }
  return batchLocationInformationObject;
};

/**
 * Returns a new BatchLocation object based on the given information
 * @param batchLocationInformation the location information which should be used for the new batch location
 * @param packagingUnitAssignments optional, if given, the new location will include the given quantity of those packagingUnits
 * @returns {BatchLocation} the BatchLocation object with prefilled information
 */
export const getBatchLocationObject = (
  batchLocationInformation: BatchLocationInformation,
  packagingUnitAssignments?: Array<{ packagingUnit: PackagingUnit; quantity: number | undefined }>
): BatchLocation => {
  const totalAmount = packagingUnitAssignments
    ? packagingUnitAssignments.reduce(
        (totalAmount, pUAssignment) =>
          totalAmount +
          (pUAssignment.quantity ?? pUAssignment.packagingUnit.quantity ?? 0) *
            pUAssignment.packagingUnit.amountPerPu.value,
        0
      )
    : 0;
  const newPackagingUnits = packagingUnitAssignments
    ? packagingUnitAssignments.map(pUAssignment => {
        const adjustedPU: PackagingUnit = {
          _id: new BSON.ObjectId(),
          amountPerPu: pUAssignment.packagingUnit.amountPerPu,
          puSnapshot: pUAssignment.packagingUnit.puSnapshot,
          quantity: pUAssignment.quantity ?? pUAssignment.packagingUnit.quantity,
          weight: pUAssignment.packagingUnit.weight
        };
        return adjustedPU;
      })
    : [];

  return {
    _id: new BSON.ObjectId(),
    location: batchLocationInformation,
    amountAtLocation: {
      value: totalAmount,
      unit: DEFAULTWEIGHTUNIT // might change in later versions, if we use something else than kg in PUs
    },
    packagingUnits: newPackagingUnits
  };
};

/**
 * Get the batch status
 * @param batch a batch
 * @param reservations list of reservations
 * @returns {BatchStatus} status if batch is available, reserved or partially reserved
 */
export const getBatchStatus = (batch: Batch, reservations: Array<Reservation>): BatchStatus => {
  const reservationList = reservations.filter(
    r => r.state === ReservationState.OPEN && r.batches.some(b => b.batch._id.toString() === batch?._id.toString())
  );

  if (reservationList.length === 0) return BatchStatus.AVAILABLE;

  const notReservedAmount = batch
    ? batch.totalAmount.value -
      reservationList.reduce((sum, reservation) => {
        const reservedBatches = reservation.batches.filter(b => b.batch._id.toString() === batch._id.toString());
        return sum + reservedBatches.reduce((sumBatches, b) => sumBatches + b.amountAtLocation.value, 0);
      }, 0)
    : -1;
  if (notReservedAmount === batch.totalAmount.value) return BatchStatus.AVAILABLE;
  else if (notReservedAmount === 0) return BatchStatus.RESERVED;
  else return BatchStatus.PARTIALLY_RESERVED;
};

/**
 * Get a batch status indication
 * @param batch a batch
 * @param reservations list of reservations
 * @returns {[color: string, statusKey: string]} tuple with text color and translation key for status
 */
export const getBatchStatusIndicationText = (
  batch: Batch,
  reservations: Array<Reservation>
): [color: string, statusKey: string] => {
  const status = getBatchStatus(batch, reservations);
  if (status === BatchStatus.RESERVED) return ["text-danger", "reserved"];
  if (status === BatchStatus.PARTIALLY_RESERVED) return ["text-warning", "partiallyReserved"];
  return ["text-success", "available"];
};

/**
 * Get the status for a specific location of batch
 * @param batchId the id of the batch related to the location
 * @param location a specific batch location
 * @param reservations list of reservations
 * @returns {BatchStatus} status if amount at batch location is available, reserved or partially reserved
 */
export const getBatchLocationStatus = (
  batchId: string,
  location: BatchLocation,
  reservations: Array<Reservation>
): BatchStatus => {
  const reservationList = reservations.filter(
    r =>
      r.state === ReservationState.OPEN &&
      r.batches.some(b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location))
  );

  if (reservationList.length === 0) return BatchStatus.AVAILABLE;

  const notReservedAmount =
    location.amountAtLocation.value -
    reservationList.reduce((sum, reservation) => {
      const reservedBatches = reservation.batches.filter(
        b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location)
      );
      return sum + reservedBatches.reduce((sumBatches, b) => sumBatches + b.amountAtLocation.value, 0);
    }, 0);
  if (notReservedAmount === location.amountAtLocation.value) return BatchStatus.AVAILABLE;
  else if (notReservedAmount === 0) return BatchStatus.RESERVED;
  else return BatchStatus.PARTIALLY_RESERVED;
};

/**
 * Get a status indication for a specific location of batch
 * @param batchId the id of the batch related to the location
 * @param location a specific batch location
 * @param reservations list of reservations
 * @returns {[status: BatchStatus, color: string, statusKey: string]} triple with status, text color and translation key for status
 */
export const getBatchLocationStatusIndicationText = (
  batchId: string,
  location: BatchLocation,
  reservations: Array<Reservation>
): [status: BatchStatus, color: string, statusKey: string] => {
  const status = getBatchLocationStatus(batchId, location, reservations);
  if (status === BatchStatus.RESERVED) return [BatchStatus.RESERVED, "text-danger", "reserved"];
  if (status === BatchStatus.PARTIALLY_RESERVED)
    return [BatchStatus.PARTIALLY_RESERVED, "text-warning", "partiallyReserved"];
  return [BatchStatus.AVAILABLE, "text-success", "available"];
};

/**
 * Get how much of the amount at a location is still available
 * @param batchId the id of the batch related to the location
 * @param location a specific batch location
 * @param reservations list of reservations
 * @returns {NumValue} the available amount
 */
export const getBatchLocationAvailableAmount = (
  batchId: string,
  location: BatchLocation,
  reservations: Array<Reservation>
): NumValue => {
  const reservationList = reservations.filter(r =>
    r.batches.some(b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location))
  );
  const notReservedAmount =
    location.amountAtLocation.value -
    reservationList.reduce((sum, reservation) => {
      const reservedBatches = reservation.batches.filter(
        b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location)
      );
      return sum + reservedBatches.reduce((sumBatches, b) => sumBatches + b.amountAtLocation.value, 0);
    }, 0);
  return { value: notReservedAmount, unit: DEFAULTWEIGHTUNIT };
};

/**
 * Get a complete batch comment document
 * @param comment the comments content
 * @returns {BatchComment} a batch comment document
 */
export const getBatchCommentDocument = (comment: string): BatchComment => {
  return {
    date: new Date(),
    person: userService.getUserSnapshot(),
    text: comment.trim(),
    _id: new BSON.ObjectId()
  };
};

/**
 * Compare two batch locations with each other
 * @param location1 information about the location
 * @param location2 information about the location to compare with
 * @param ignoreStorageSpace optional, flag to not check storage space
 * @returns {boolean} true if information matches, else false
 */
export const compareBatchLocations = (
  location1: BatchLocationInformation,
  location2: BatchLocationInformation,
  ignoreStorageSpace?: boolean
): boolean => {
  return (
    location1.warehouseSnapshot._id.toString() === location2.warehouseSnapshot._id.toString() &&
    location1.warehouseArea._id.toString() === location2.warehouseArea._id.toString() &&
    (!!ignoreStorageSpace || location1.storageSpace?._id.toString() === location2.storageSpace?._id.toString())
  );
};

/**
 * Get a batch file object
 * @param filePath the path of the file
 * @param file the file itself
 * @param type the type of file
 * @returns {BatchFile} the batch file object
 */
export const getBatchFileEntry = (filePath: string, file: File, type: FileType): BatchFile => {
  const user = userService.getUserSnapshot();
  return {
    _id: new BSON.ObjectId(),
    type,
    date: new Date(),
    person: { _id: user._id, prename: user.prename, surname: user.surname },
    path: filePath,
    title: file.name,
    fileExtension: file.type,
    fileSize: file.size
  };
};

/**
 * Get a timeline entry for a batch
 * @param type The type of batch timeline entry
 * @param subtype The subtype of the batch timeline entry
 * @param payload The payload of the timeline entry
 * @returns {BatchTimelineEntry} The batch timeline entry
 */
export const getBatchTimelineEntry = (
  type: BatchTimelineType,
  subtype: BatchTimelineSubType,
  payload: object
): BatchTimelineEntry => {
  const user = userService.getUserSnapshot();
  return {
    _id: new BSON.ObjectId(),
    type,
    subtype,
    date: new Date(),
    person: { _id: user._id, prename: user.prename, surname: user.surname },
    payload: payload ?? {},
    diff: { pre: {}, post: {} }
  };
};
