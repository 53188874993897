import countryList from "i18n-iso-countries";
import _ from "lodash";
import React, { PureComponent } from "react";
import Select from "react-select";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { BSON } from "realm-web";
import CreateCommodityReport from "./CreateCommodityReport";
import PlannedCommodityOrdersModal from "./PlannedCommodityOrdersModal";
import { PaginationState } from "../common/CustomTypes";
import Pagination, { paginate } from "../common/Pagination";
import { DataContext } from "../../context/dataContext";
import { CommoditiesDocument, CommodityOrder } from "../../model/commodities.types";
import { ColorsDocument } from "../../model/colors.types";
import { CommoditycategoriesDocument } from "../../model/commoditycategories.types";
import commodityUtils from "../../utils/commodityUtils";
import dateUtils from "../../utils/dateUtils";
import baseUtils, { getComponentState } from "../../utils/baseUtils";
import { ManufacturerFilter, SearchBar } from "../listings/common/Filters";
import manufacturerUtils from "../../utils/manufacturerUtils";
import accessUtils, { CREATELOCATIONS } from "../../utils/accessUtils";
import CommodityBaseImage from "./CommodityBaseImage";

interface CommoditiesBaseListingProps extends RouteComponentProps<{}, {}, {}> {
  commodities: Array<CommoditiesDocument>;
  context: React.ContextType<typeof DataContext>;
  type: "commodities" | "softgels" | "custom" | "services";
}

interface CommoditiesBaseListingState extends PaginationState {
  commodityCategory: "" | { value: string; label: string };
  composition: "" | { value: string; label: string };
  manufacturer: "" | { value: string; label: string };
  orderState: "" | { value: string; label: string };
  query: string;
  showPlannedOrdersModal: boolean;
  sortingField: { value: string; label: string };
  sortingOrder: { value: string; label: string };
  manufacturerLocked: boolean;
  extendedCommodities: Array<CommoditiesExtended>;
  filteredCommodities: Array<CommoditiesExtended>;
  commodityOrders: Array<{ _id: BSON.ObjectId; orders: Array<CommodityOrder> }>;
  plannedOrdersCount: number;
}

interface CommoditiesExtended {
  commodity: CommoditiesDocument;
  color: ColorsDocument | undefined;
  category: CommoditycategoriesDocument | undefined;
  lowestPrice: { price: number | undefined; moq: number | undefined };
  fastestPrice: { price: number | undefined; moq: number | undefined; deliveryTime: number | undefined };
  usage: { usage: number; required: { orders: number; amount: number }; ordered: { orders: number; amount: number } };
  emoReq: { orders: number; amount: number } | undefined;
  lastUpdate: Date | null;
  stock: number;
}

const CONSTRUCTORNAME = "CommoditiesBaseListing";

class CommoditiesBaseListing extends PureComponent<CommoditiesBaseListingProps, CommoditiesBaseListingState> {
  constructor(props: CommoditiesBaseListingProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, CONSTRUCTORNAME + this.props.type);
    if (state) this.setState({ ...state });
    else {
      const commodities = this.generateExtendedCommodityList();
      const { commodityOrders, plannedOrdersCount } = this.getCommodityOrdersAndPlannedAmount();
      this.setState({
        extendedCommodities: commodities,
        filteredCommodities: this.filterCommodities(commodities),
        commodityOrders,
        plannedOrdersCount
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<CommoditiesBaseListingProps>,
    prevState: Readonly<CommoditiesBaseListingState>
  ) {
    const {
      query,
      orderState,
      manufacturer,
      commodityCategory,
      composition,
      sortingField,
      sortingOrder,
      extendedCommodities
    } = this.state;
    if (!_.isEqual(prevProps.context.orders, this.props.context.orders)) {
      const commodities = this.generateExtendedCommodityList();
      const { commodityOrders, plannedOrdersCount } = this.getCommodityOrdersAndPlannedAmount();
      this.setState({
        extendedCommodities: commodities,
        filteredCommodities: this.filterCommodities(commodities),
        commodityOrders,
        plannedOrdersCount
      });
    }
    if (prevState.manufacturer !== manufacturer) {
      this.setState({ extendedCommodities: this.generateExtendedCommodityList() });
    }
    if (
      prevState.query !== query ||
      prevState.orderState !== orderState ||
      prevState.commodityCategory !== commodityCategory ||
      prevState.composition !== composition ||
      !_.isEqual(prevState.sortingField, sortingField) ||
      !_.isEqual(prevState.sortingOrder, sortingOrder)
    ) {
      this.setState({ filteredCommodities: this.filterCommodities(extendedCommodities) });
    }
    if (!_.isEqual(prevProps.commodities, this.props.commodities)) {
      const { commodityOrders, plannedOrdersCount } = this.getCommodityOrdersAndPlannedAmount();
      this.setState({ commodityOrders, plannedOrdersCount });
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(CONSTRUCTORNAME + this.props.type, this.state);
  }

  handleSelectChange = (name: string, entry: "" | { value: string; label: string }) =>
    // @ts-ignore
    this.setState({ [name]: entry ? entry : "" });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ query: e.target.value, currentPage: 1 });
  handleReset = () => this.setState(this.getDefaultState());
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
  };

  /**
   * Get the default state
   * @returns {CommoditiesBaseListingState} state for the component
   */
  getDefaultState = () => {
    const manufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(this.props.context.manufacturers);
    return {
      sortingField: { value: "title", label: "Title" },
      sortingOrder: { value: "descending", label: "Descending" },
      currentPage: 1,
      pageSize: 15,
      commodityCategory: "",
      composition: "",
      manufacturer,
      orderState: "",
      query: "",
      showPlannedOrdersModal: false,
      manufacturerLocked: manufacturer !== "",
      extendedCommodities: [],
      filteredCommodities: [],
      commodityOrders: [],
      plannedOrdersCount: 0
    } as CommoditiesBaseListingState;
  };

  /**
   * Returns all commodities with their orders and the total amount of all orders
   * @returns { {commodityOrders: Array<{ _id: BSON.ObjectId; orders: Array<CommodityOrder> }>, plannedOrdersCount: number } } Commodities with their orders and the total amount of orders
   */
  getCommodityOrdersAndPlannedAmount = () => {
    const { commodities } = this.props;
    let plannedOrdersCount = 0;
    const commodityOrders: Array<{ _id: BSON.ObjectId; orders: Array<CommodityOrder> }> = commodities
      .filter(c => c.orders && c.orders.length > 0 && c.orders.some(o => !o.ordered && !o.delivered))
      .map(c => {
        plannedOrdersCount += c.orders.length;
        return {
          _id: c._id,
          orders: c.orders
        };
      });
    return { commodityOrders, plannedOrdersCount };
  };

  /**
   * Filters the commodities by the selected filters.
   * @param commodities: List of commodities
   * @returns { Array<CommoditiesDocument> } Filtered commodities list, sorted by name
   */
  filterCommodities = (commodities: Array<CommoditiesExtended>) => {
    const { commodityCategory, composition, manufacturer, manufacturerLocked, orderState, query } = this.state;
    let commoditiesFiltered = commodities;
    const ql = query.trim();
    commoditiesFiltered = commoditiesFiltered.filter(cex => {
      const c = cex.commodity;
      let retVal = true;
      if (orderState) {
        retVal =
          (orderState.value === "orderRequired" &&
            cex.usage.required.amount - (cex.usage.ordered.amount + cex.stock) > 0) ||
          (orderState.value === "ordered" && cex.usage.ordered.amount > 0) ||
          (orderState.value === "stock" && cex.stock > 0);
      }
      if (retVal && manufacturer && !manufacturerLocked) {
        retVal =
          (c.orders && c.orders.some(co => co.destination && co.destination.toString() === manufacturer.value)) ||
          c.stock.some(s => s.location.toString() === manufacturer.value);
      }
      if (retVal && commodityCategory) retVal = commodityCategory.value === c.category.toString();
      if (retVal && composition) retVal = composition.value === c.form.toString();
      return retVal;
    });
    if (ql !== "") {
      commoditiesFiltered = baseUtils.doFuseSearch(commoditiesFiltered, ql, [
        "commodity.identifier",
        "commodity.title.en",
        "commodity.title.de",
        "category.name.en",
        "category.name.de",
        "commodity.subtitle.en",
        "commodity.subtitle.de",
        "commodity.cas_number",
        "commodity.hs_code",
        "commodity.internal_code",
        "commodity.organic_code"
      ]);
      return commoditiesFiltered;
    }
    return commoditiesFiltered.sort((c1, c2) => this.sortCommodities(c1, c2));
  };

  /**
   * Sort the given commodities by set field and order.
   * @param c1: Extended commodity
   * @param c2: Extended commodity
   * @returns { number } Represents the result of the sorting
   */
  sortCommodities = (c1: CommoditiesExtended, c2: CommoditiesExtended) => {
    const { sortingField, sortingOrder } = this.state;
    const order = sortingOrder.value === "descending" ? 1 : -1;
    switch (sortingField.value) {
      case "title":
        return order * c1.commodity.title.en.localeCompare(c2.commodity.title.en);
      case "category":
        return c1.category && c2.category ? order * c1.category.name.en.localeCompare(c2.category.name.en) : 0;
      case "id":
        return order * (+c2.commodity.identifier - +c1.commodity.identifier);
      case "usage":
        return order * (c2.usage.usage - c1.usage.usage);
      case "lastUpdate":
        return order * ((c2.lastUpdate ? c2.lastUpdate.getTime() : 0) - (c1.lastUpdate ? c1.lastUpdate.getTime() : 0));
      default:
        return 0;
    }
  };

  /**
   * Generate the extended commodities list that contains additional information that is shown inside the overview.
   * @returns { Array<CommoditiesExtended> } Commodities extended with additional information
   */
  generateExtendedCommodityList = () => {
    const { commodities, context } = this.props;
    const { manufacturer } = this.state;
    const { colors, commoditycategories, orders, externalManufacturerOrders } = context;
    const usageMap = commodityUtils.getUsageMap(orders, commodities, manufacturer);
    const requiredEMOMap = commodityUtils.getEMORequiredMap(externalManufacturerOrders);
    const extendedCommodities: Array<CommoditiesExtended> = [];
    for (let i = 0; i < commodities.length; i++) {
      const color = commodities[i].color
        ? colors.find(col => commodities[i].color && col._id.toString() === commodities[i].color!.toString())
        : undefined;
      const category = commoditycategories.find(cc => commodities[i].category.toString() === cc._id.toString());
      const lowestPrice = commodityUtils.getLowestPrice(commodities[i]);
      const fastestPrice = commodityUtils.getFastestPrice(commodities[i]);
      const lastUpdate = commodityUtils.findLatestPriceUpdate(commodities[i].suppliers);
      const stock = commodities[i].stock.reduce(
        (stock, batch) =>
          stock + (!manufacturer || manufacturer.value === batch.location.toString() ? batch.amount : 0),
        0
      );
      extendedCommodities.push({
        commodity: commodities[i],
        color,
        category,
        lowestPrice,
        fastestPrice,
        lastUpdate,
        usage: usageMap[commodities[i]._id.toString()]
          ? usageMap[commodities[i]._id.toString()]
          : { usage: 0, required: { orders: 0, amount: 0 }, ordered: { orders: 0, amount: 0 } },
        emoReq: requiredEMOMap[commodities[i]._id.toString()],
        stock
      });
    }
    return extendedCommodities;
  };

  /**
   * Resolves the correct unit for the given commodity type.
   * @returns { string } Unit matching the commodity type
   */
  resolveUnit = () => {
    const { type } = this.props;
    switch (type) {
      case "commodities":
        return "kg";
      case "softgels":
      case "custom":
        return "tsd";
      case "services":
        return "units";
    }
  };

  /**
   * Resolves the correct icon for the commodity type.
   * @returns { string } Classes for the icon
   */
  resolveIcon = () => {
    const { type } = this.props;
    switch (type) {
      case "commodities":
        return "flaticon2-supermarket";
      case "custom":
        return "fas fa-boxes";
      case "softgels":
        return "fas fa-capsules";
      case "services":
        return "fas fa-hands";
    }
  };

  /**
   * Resolves the correct title for the commodity type.
   * @returns { string } Title of the commodity type
   */
  resolveTitle = () => {
    const { type } = this.props;
    switch (type) {
      case "commodities":
        return "Commodities";
      case "custom":
        return "Custom Products";
      case "softgels":
        return "Softgels";
      case "services":
        return "Services";
    }
  };

  /**
   * Resolve the type naming that is shown in the table header.
   * @returns { string } Correct table header
   */
  resolveTypeNaming = () => {
    const { type } = this.props;
    switch (type) {
      case "commodities":
        return "Commodity";
      case "custom":
        return "Custom Product";
      case "softgels":
        return "Softgel";
      case "services":
        return "Service";
    }
  };

  render() {
    const { commodities, context, history, type } = this.props;
    const {
      commodityCategory,
      composition,
      currentPage,
      manufacturer,
      orderState,
      pageSize,
      query,
      sortingField,
      sortingOrder,
      manufacturerLocked,
      filteredCommodities,
      commodityOrders,
      plannedOrdersCount
    } = this.state;
    const { commoditycategories, compositions, manufacturers, suppliers } = context;

    const commodityList: Array<CommoditiesExtended> = paginate(filteredCommodities, currentPage, pageSize);

    const unit = this.resolveUnit();
    let newText = "New Commodity";
    let createPath = "/create-commodity";
    if (type === "softgels") {
      newText = "New Softgel";
      createPath = "/create-softgel";
    } else if (type === "custom") {
      newText = "New Custom Product";
      createPath = "/create-customproduct";
    } else if (type === "services") {
      newText = "New Service";
      createPath = "/create-service";
    }
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className={"kt-font-brand " + this.resolveIcon()} />
            </span>
            <h3 className="kt-portlet__head-title">{this.resolveTitle()}</h3>
            <button className="btn btn-sm btn-secondary px-1 py-0 ml-2 mt-1" onClick={this.handleReset}>
              Reset
            </button>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-wrapper">
              <button onClick={history.goBack} className="btn btn-clean kt-margin-r-10">
                <i className="la la-arrow-left" />
                <span className="kt-hidden-mobile">Back</span>
              </button>
              {accessUtils.canCreateData(CREATELOCATIONS.COMMODITY) && (
                <>
                  <CreateCommodityReport commodities={context.commodities} />
                  <button className="btn btn-secondary mr-2" onClick={() => history.push(createPath)}>
                    {newText}
                  </button>
                  <PlannedCommodityOrdersModal
                    commodities={commodities}
                    orders={commodityOrders}
                    plannedOrders={plannedOrdersCount}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-form kt-form--label-right  kt-margin-b-10">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row">
                  <SearchBar onSearch={this.handleSearch} search={query} additionalSizeClasses="col-md-2 mb-4" />
                  <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                    <Select
                      className="select-default"
                      isClearable={true}
                      options={[
                        { value: "orderRequired", label: "Order Required" },
                        { value: "ordered", label: "Ordered" },
                        { value: "stock", label: "In Stock" }
                      ]}
                      value={orderState ? orderState : { value: "", label: "All States" }}
                      onChange={(value: any) => this.handleSelectChange("orderState", value || "")}
                    />
                  </div>
                  <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                    <ManufacturerFilter
                      noLabel={true}
                      additionalSizeClasses={"select-default"}
                      manufacturer={manufacturer}
                      manufacturers={manufacturers}
                      manufacturerLocked={manufacturerLocked}
                      onFilterSelect={this.handleSelectChange}
                    />
                  </div>
                  {type === "commodities" && (
                    <>
                      <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={commoditycategories.map(category => {
                            return {
                              value: category._id.toString(),
                              label: category.name.en
                            };
                          })}
                          value={commodityCategory ? commodityCategory : { value: "", label: "All Categories" }}
                          onChange={(value: any) => this.handleSelectChange("commodityCategory", value || "")}
                        />
                      </div>
                      <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                        <Select
                          className="select-default"
                          isClearable={true}
                          options={compositions.map(composition => {
                            return {
                              value: composition._id.toString(),
                              label: composition.name.en
                            };
                          })}
                          value={composition ? composition : { value: "", label: "All Compositions" }}
                          onChange={(value: any) => this.handleSelectChange("composition", value || "")}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                    <Select
                      className="select-default"
                      isClearable={true}
                      options={[
                        { value: "title", label: "Title" },
                        {
                          value: "category",
                          label: "Category",
                          isDisabled: ["softgels", "custom", "services"].includes(type)
                        },
                        { value: "id", label: "ID" },
                        { value: "usage", label: "Usage" },
                        { value: "lastUpdate", label: "Last Update" }
                      ]}
                      value={sortingField}
                      onChange={(value: any) =>
                        this.handleSelectChange("sortingField", value || { value: "", label: "Title" })
                      }
                    />
                  </div>
                  <div className="col-md-2 kt-margin-b-20-tablet-and-mobile">
                    <Select
                      className="select-default"
                      options={[
                        { value: "descending", label: "Descending" },
                        { value: "ascending", label: "Ascending" }
                      ]}
                      value={sortingOrder ? sortingOrder : { value: "descending", label: "Descending" }}
                      onChange={(value: any) =>
                        this.handleSelectChange("sortingOrder", value || { value: "descending", label: "Descending" })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-4">
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: ["softgels", "custom"].includes(type) ? "34%" : type === "services" ? "42%" : "23%"
                    }}
                  >
                    {this.resolveTypeNaming()}
                  </th>
                  {!["softgels", "custom", "services"].includes(type) && <th style={{ width: "8%" }}>Category</th>}
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "7%" }}>Usage</th>
                  <th style={{ width: "5%" }}>Suppliers</th>
                  <th style={{ width: "5%" }}>Best Price</th>
                  <th style={{ width: "5%" }}>Fastest</th>
                  <th style={{ width: "5%" }}>Last Update</th>
                  <th style={{ width: "5%" }}>Order Req.</th>
                  <th style={{ width: "5%" }}>External Req.</th>
                  <th style={{ width: "5%" }}>Ordered</th>
                  {type !== "services" && <th style={{ width: "6%" }}>Stock</th>}
                  {type !== "services" && <th style={{ width: "5%" }}>Available</th>}
                </tr>
              </thead>
              <tbody>
                {commodityList
                  ? commodityList.map(c => {
                      const daysAgo = c.lastUpdate ? dateUtils.getDaysBetween(c.lastUpdate, new Date()) : null;
                      const available =
                        Math.round(
                          (c.stock + (c.usage.ordered ? c.usage.ordered.amount : 0) - c.usage.required.amount) * 100
                        ) / 100;
                      return (
                        <tr key={c.commodity._id.toString()} className="table-hover">
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__pic">
                                  <div className="kt-widget__media">
                                    <CommodityBaseImage commodity={c.commodity} context={context} />
                                  </div>
                                </div>
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    <Link
                                      to={"/commodity/" + c.commodity._id.toString()}
                                      className="kt-user-card-v2__name pr-2"
                                      style={{ display: "inline" }}
                                    >
                                      {c.commodity.title.en}
                                    </Link>
                                    {c.commodity.country && (
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 100, hide: 400 }}
                                        overlay={
                                          <Tooltip id="button-tooltip product-popover">
                                            {countryList.getName(c.commodity.country, "en")}
                                          </Tooltip>
                                        }
                                      >
                                        <img
                                          style={{
                                            width: 14,
                                            height: 14,
                                            borderRadius: 14,
                                            objectFit: "cover"
                                          }}
                                          alt={c.commodity.country}
                                          src={"media/icons/countries/" + c.commodity.country.toLowerCase() + ".png"}
                                          className="country-icon"
                                        />
                                      </OverlayTrigger>
                                    )}
                                  </span>
                                  <span className="kt-user-card-v2__email">{c.commodity.subtitle.en}</span>
                                </div>
                              </div>
                            </span>
                          </td>
                          {!["softgels", "custom", "services"].includes(type) && (
                            <td className="align-middle">
                              <span
                                className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded"
                                style={{
                                  backgroundColor: commodityUtils.getCategoryColor(
                                    c.category ? c.category.name.en : ""
                                  ),
                                  color: c.category && c.category.name.en === "probiotics" ? "black" : "white",
                                  display: "inline-block"
                                }}
                              >
                                {c.category ? c.category.name.en : "unknown"}
                              </span>
                            </td>
                          )}
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    {c.commodity.organic && <i className="fas fa-leaf text-success mr-1" />}
                                    {c.commodity.identifier ? c.commodity.identifier : "-"}
                                  </span>
                                  <OverlayTrigger
                                    placement="top"
                                    show={c.commodity.internal_code ? undefined : false}
                                    overlay={
                                      <Tooltip id="allocatedInfo">
                                        These are legacy identifiers (internal code and organic code) and shouldn't be
                                        used any more.
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      <span className="kt-user-card-v2__email">
                                        {c.commodity.internal_code ? c.commodity.internal_code : ""}
                                      </span>
                                      <span className="kt-user-card-v2__email ml-1">
                                        {c.commodity.organic_code && <>({c.commodity.organic_code})</>}
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    <span style={{ fontSize: 8 }}>
                                      {c.usage.usage === 0 ? (
                                        <i className="text-secondary fas fa-circle" />
                                      ) : c.usage.usage > 0 && c.usage.usage < 10 ? (
                                        <i className="fas fa-circle" style={{ color: "red" }} />
                                      ) : c.usage.usage >= 10 && c.usage.usage < 25 ? (
                                        <React.Fragment>
                                          <i className="fas fa-circle" style={{ color: "#fd7e14" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#fd7e14" }} />
                                        </React.Fragment>
                                      ) : c.usage.usage >= 25 && c.usage.usage < 100 ? (
                                        <React.Fragment>
                                          <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#ffc107" }} />{" "}
                                        </React.Fragment>
                                      ) : c.usage.usage >= 100 && c.usage.usage < 500 ? (
                                        <React.Fragment>
                                          <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                          <i className="fas fa-circle" style={{ color: "#3faf59" }} />{" "}
                                        </React.Fragment>
                                      ) : (
                                        c.usage.usage >= 500 && (
                                          <React.Fragment>
                                            <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                            <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                            <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                            <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                            <i className="fas fa-circle" style={{ color: "#009e27" }} />{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                    </span>
                                  </span>
                                  <span className="kt-user-card-v2__email">
                                    <b>
                                      {c.usage.usage.toFixed(0)}&nbsp;{unit}
                                    </b>{" "}
                                    last 12 months
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name pointer">
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 100, hide: 400 }}
                                      overlay={
                                        <Tooltip id="button-tooltip product-popover">
                                          {c.commodity.suppliers.map(supplier => {
                                            const s = suppliers.find(
                                              sup => sup._id.toString() === supplier._id.toString()
                                            );
                                            return (
                                              <React.Fragment key={supplier._id.toString()}>
                                                <span className="kt-font-dark kt-font-bold" style={{ float: "left" }}>
                                                  {s ? s.name : "error"}
                                                </span>
                                                <span className="ml-2">
                                                  {supplier.prices.length}
                                                  {supplier.prices.length === 1 ? " price" : " prices"}
                                                </span>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {c.commodity.suppliers.length}{" "}
                                        {c.commodity.suppliers.length === 1 ? "supplier" : "suppliers"}
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    {c.lowestPrice.price
                                      ? c.lowestPrice.price.toLocaleString("de-DE", {
                                          style: "currency",
                                          currency: "EUR"
                                        })
                                      : "-"}
                                  </span>
                                  <span className="kt-user-card-v2__email">
                                    {c.lowestPrice && c.lowestPrice.moq !== undefined && c.lowestPrice.moq + " " + unit}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    {c.fastestPrice.price
                                      ? c.fastestPrice.price.toLocaleString("de-DE", {
                                          style: "currency",
                                          currency: "EUR"
                                        })
                                      : "-"}
                                  </span>
                                  <span className="kt-user-card-v2__email">
                                    {c.commodity.suppliers.length > 0 && c.fastestPrice.deliveryTime + " days"}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span
                                    className={
                                      "kt-user-card-v2__email " +
                                      (c.lastUpdate &&
                                        daysAgo &&
                                        (daysAgo > 90 && daysAgo < 180
                                          ? "text-warning"
                                          : c.lastUpdate && daysAgo >= 180
                                          ? "text-danger"
                                          : null))
                                    }
                                  >
                                    {c.lastUpdate ? c.lastUpdate.toLocaleDateString() : "-"}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span
                                    className={
                                      "kt-user-card-v2__name " + (c.usage.required.amount ? "text-primary" : null)
                                    }
                                  >
                                    {c.usage.required.amount
                                      ? Math.round(c.usage.required.amount * 100) / 100 + " " + unit
                                      : "-"}
                                  </span>
                                  <span className="kt-user-card-v2__email">
                                    {c.usage.required.amount ? "for " + c.usage.required.orders + " order(s)" : null}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className={"kt-user-card-v2__name " + (c.emoReq ? "text-primary" : null)}>
                                    {c.emoReq ? c.emoReq.amount + " kg" : "-"}
                                  </span>
                                  <span className="kt-user-card-v2__email">
                                    {c.emoReq ? "for " + c.emoReq.orders + " external order(s)" : null}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td className="align-middle">
                            <span>
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__details">
                                  <span className="kt-user-card-v2__name">
                                    {c.usage.ordered.amount
                                      ? Math.round(c.usage.ordered.amount * 100) / 100 + " " + unit
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </td>
                          {type !== "services" && (
                            <td className="align-middle">
                              <span>
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span className={"kt-user-card-v2__name " + (c.stock ? "text-success" : null)}>
                                      {c.stock ? Math.round(c.stock * 100) / 100 + " " + unit : "-"}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </td>
                          )}
                          {type !== "services" && (
                            <td className="align-middle">
                              <span>
                                <div className="kt-user-card-v2">
                                  <div className="kt-user-card-v2__details">
                                    <span
                                      className={
                                        "kt-user-card-v2__name " + (available < 0 ? "text-danger" : "text-success")
                                      }
                                    >
                                      {available + " " + unit}
                                    </span>
                                  </div>
                                </div>
                              </span>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  : "NO DATA"}
              </tbody>
            </Table>
            <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
              <Pagination
                itemsCount={filteredCommodities.length}
                pageSize={pageSize}
                onPageChange={currentPage => this.setState({ currentPage })}
                currentPage={currentPage}
                onPageSizeChange={pageSize => this.setState({ pageSize })}
                baseSize={15}
                additionalPageSizeClasses={"ml-2"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CommoditiesBaseListing);
