import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { OrdersDocument } from "../../../../model/orders.types";
import {
  BatchLocationReservationInformation,
  CommodityInformation
} from "../../../../model/warehouse/customTypes.types";
import BaseListing from "../../../listings/BaseListing";
import { paginate } from "../../../common/Pagination";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";

interface OrderReservationOverviewProps {
  selectedOrder: OrdersDocument;
  commodityInformation: Map<string, CommodityInformation>;
  orderReservations: Array<BatchLocationReservationInformation>;
}

const OrderReservationOverview: React.FC<OrderReservationOverviewProps> = ({
  selectedOrder,
  commodityInformation,
  orderReservations
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const tableHeader = [
    { title: i18n.t("warehouse:commodity"), size: 35 },
    { title: i18n.t("warehouse:neededAmount"), size: 20 },
    { title: i18n.t("warehouse:reserved"), size: 25 },
    { title: i18n.t("warehouse:pending"), size: 20 }
  ];

  const usedCommodities = useMemo(() => Array.from(commodityInformation.values()), [commodityInformation]);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  return (
    <>
      <h4 className="text-black font-weight-bold mb-3 d-block">{i18n.t("common:summary")}</h4>
      <div className="p-2 py-4 mb-4 bg-light">
        <div className="row mx-3">
          <div className="col-12">
            <h5 className="font-weight-bold text-black">{`AT-${selectedOrder.identifier} ${selectedOrder.title}`}</h5>
            <div className="text-black mb-3">{selectedOrder.subtitle}</div>
            <div className="kt-portlet__body px-0 pt-0">
              <BaseListing
                headerDefinition={tableHeader}
                additionalTableClasses={"bg-light"}
                documents={usedCommodities}
                bodyContent={
                  <>
                    {paginate(usedCommodities, currentPage, pageSize).map(cI => (
                      <ReservationOverviewRow
                        key={cI.commodityId}
                        commodityInformation={cI}
                        orderReservations={orderReservations.filter(r => r.commodityId === cI.commodityId)}
                      />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                baseSize={5}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface ReservationOverviewRowProps {
  commodityInformation: CommodityInformation;
  orderReservations: Array<BatchLocationReservationInformation>;
}

const ReservationOverviewRow: React.FC<ReservationOverviewRowProps> = ({ commodityInformation, orderReservations }) => {
  const updatedReservedAmount = useMemo(() => {
    const newReservations =
      orderReservations.length > 0
        ? orderReservations.reduce(
            (commodityReservedAmount, reservation) => commodityReservedAmount + reservation.amount.value,
            0
          )
        : 0;
    return newReservations + commodityInformation.reservedAmount.value;
  }, [orderReservations]);

  const newPendingAmount = useMemo(() => {
    return updatedReservedAmount >= commodityInformation.neededAmount.value
      ? 0
      : commodityInformation.neededAmount.value - updatedReservedAmount;
  }, [commodityInformation, updatedReservedAmount]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">{commodityInformation.commodityName}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {calculationUtils.formatAmount(commodityInformation.neededAmount.value, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {commodityInformation.reservedAmount.value === 0
            ? `0${DEFAULTWEIGHTUNIT}`
            : calculationUtils.formatAmount(commodityInformation.reservedAmount.value, 2)}{" "}
          →{" "}
          {updatedReservedAmount === 0
            ? `0${DEFAULTWEIGHTUNIT}`
            : calculationUtils.formatAmount(updatedReservedAmount, 2)}
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">
          {newPendingAmount === 0 ? `0${DEFAULTWEIGHTUNIT}` : calculationUtils.formatAmount(newPendingAmount, 2)}
        </span>
      </td>
    </tr>
  );
};

export default OrderReservationOverview;
