import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { FileType } from "../../../model/configuration/warehouseConfiguration.types";
import { resolveTranslation } from "../../../utils/translationUtils";

interface UploadFileDragNDropProps {
  fileTypes: Array<FileType>;
  onUploadFile: (file: File, title: string, type: FileType) => void;
}

interface UploadFileDragNDropState {
  file?: File;
  type: { value: string; label: string; object: FileType };
  name: string;
}

const getFileTypeSelections = (ft: FileType) => {
  return {
    value: ft._id.toString(),
    label: resolveTranslation(ft.type) + " - " + ft.shortName,
    object: ft
  };
};

const UploadFileDragNDrop: React.FunctionComponent<UploadFileDragNDropProps> = ({ fileTypes, onUploadFile }) => {
  const uploadRef: React.RefObject<HTMLInputElement> = useMemo(() => React.createRef(), []);
  const [state, setState] = useState<UploadFileDragNDropState>({
    file: undefined,
    type: getFileTypeSelections(fileTypes[0]),
    name: ""
  });

  const handleSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement> | { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];
    const maxFileSize = 50 * 1000 * 1000;
    if (file && file.size <= maxFileSize) {
      setState(prevState => {
        return { ...prevState, file, name: file.name.split(".")[0] };
      });
    } else if (file && file.size > maxFileSize) toast.error(i18n.t("warehouse:fileTooBig"));
  }, []);

  const handleChangeType = useCallback(
    (e: any) => {
      const newType = e.value;
      const fileType = fileTypes.find(ft => ft._id.toString() === newType);
      if (fileType)
        setState(prevState => {
          return {
            ...prevState,
            type: getFileTypeSelections(fileType)
          };
        });
    },
    [fileTypes]
  );

  const handleUploadFile = useCallback(() => {
    if (state.file) {
      onUploadFile(state.file, state.name, state.type.object);
      setState({
        file: undefined,
        type: getFileTypeSelections(fileTypes[0]),
        name: ""
      });
    }
  }, [state, onUploadFile, fileTypes]);

  return (
    <>
      <div className="col-12 mt-3">
        <div
          className="warehouseFileDiv"
          onDragOver={e => e.preventDefault()}
          onDrop={e => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (files && files.length > 0 && uploadRef.current) {
              uploadRef.current.files = files;
              handleSelectFile({ target: uploadRef.current });
              e.dataTransfer.clearData();
            }
          }}
          onClick={() => uploadRef.current?.click()} // opens file dialogue
        >
          <i
            className="fa fa-images"
            style={{
              marginBottom: "10px",
              fontSize: 72,
              opacity: 0.2
            }}
          />
          {state.file ? (
            <p className="text-black m-0">{state.file.name}</p>
          ) : (
            <p style={{ fontSize: 16 }} className="font-weight-bolder m-0">
              {i18n.t("warehouse:dragNDropOrBrowse")}{" "}
            </p>
          )}
          <input
            type="file"
            ref={uploadRef}
            className="warehouseFileInput"
            accept="*"
            name="file"
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              handleSelectFile(e);
            }}
          />
        </div>
      </div>
      <div className="col-12 my-3 d-flex justify-content-end align-items-center" style={{ gap: "10px" }}>
        <div style={{ width: 250 }}>
          <Select
            className="select-warehouse"
            classNamePrefix="select-warehouse"
            onChange={handleChangeType}
            value={state.type}
            placeholder={i18n.t("common:filetype")}
            options={fileTypes.map(ft => {
              return getFileTypeSelections(ft);
            })}
          />
        </div>
        <button
          className={"btn btn-success" + (!state.file && state.name !== "" ? " disabled" : "")}
          onClick={handleUploadFile}
          disabled={!state.file && state.name !== ""}
        >
          {i18n.t("warehouse:uploadFile")}
        </button>
      </div>
    </>
  );
};

export default UploadFileDragNDrop;
