import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../translations/i18n";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { resolveTranslation } from "../../../utils/translationUtils";
import baseUtils, { formatNumValue } from "../../../utils/baseUtils";
import { useDataContext } from "../../../context/dataContext";
import { getBatchCommentDocument, getBatchStatusIndicationText } from "../../../utils/batchUtils";
import { BatchComment, BatchFile } from "../../../model/warehouse/batch.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import dbService, { BATCH, UpdateAction } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import dateUtils from "../../../utils/dateUtils";
import { getBatchPackagingUnitDescription } from "../../../utils/warehouseUtils";

interface WarehouseInformationPanelProps {}

const WarehouseInformationPanel: React.FC<WarehouseInformationPanelProps> = React.memo(() => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h4 className="kt-portlet__head-title kt-font-bolder">{i18n.t("warehouse:information")}</h4>
        </div>
      </div>
      <div className="kt-portlet__body py-1">
        <WarehouseBatchInformation />
        <hr className="w-100 my-0" />
        <WarehouseFinancialAnalysis />
        <hr className="w-100 my-0" />
        <WarehouseInformationDocuments />
        <hr className="w-100 my-0" />
        <WarehouseInformationComments />
      </div>
    </div>
  );
});

const WarehouseBatchInformation: React.FC = () => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { reservation } = dataContext;
  const { informationPanelBatch } = warehouseContext;

  const batchStatusIndication: [color: string, statusKey: string] = useMemo(
    () => (!informationPanelBatch ? ["", ""] : getBatchStatusIndicationText(informationPanelBatch, reservation)),
    [reservation, informationPanelBatch]
  );

  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionBatchInfo">
      <div className="card mb-0">
        <div className="card-header" id="headingBatchInfo">
          <div className="card-title" data-toggle="collapse" data-target="#collapseBatchInfo">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:batchInformation")}
            </span>
          </div>
        </div>
        <div id="collapseBatchInfo" className="collapse show" data-parent="#accordionBatchInfo">
          <div className="card-body">
            {informationPanelBatch ? (
              <>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productType")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {i18n.t(`warehouse:${informationPanelBatch.content.type}`)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productTitle")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {resolveTranslation(informationPanelBatch.content.details.title)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">
                    {i18n.t("warehouse:productInformation")}:
                  </div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {resolveTranslation(informationPanelBatch.content.details.subtitle)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:supplier")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {informationPanelBatch.sender.name}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:lot")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black">{informationPanelBatch.lot}</div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:stored")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {baseUtils.formatDate(informationPanelBatch.stocked)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:bestBefore")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {baseUtils.formatDate(informationPanelBatch.expiry)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:amount")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {formatNumValue(informationPanelBatch.totalAmount, 2)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:packaging")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {getBatchPackagingUnitDescription(informationPanelBatch.locations.flatMap(l => l.packagingUnits))}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:status")}:</div>
                  <div className={"col-8 col-form-label p-m-0 kt-font-bold " + batchStatusIndication[0]}>
                    {i18n.t(`warehouse:${batchStatusIndication[1]}`)}
                  </div>
                </div>
              </>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForInformation")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WarehouseFinancialAnalysis: React.FC = () => {
  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionFinancialAnalysis">
      <div className="card mb-0">
        <div className="card-header" id="headingFinancialAnalysis">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseFinancialAnalysis">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:financialAnalysis")}
            </span>
          </div>
        </div>
        <div id="collapseFinancialAnalysis" className="collapse " data-parent="#accordionFinancialAnalysis">
          <div className="card-body">
            <span className="text-black d-block mb-3">{i18n.t("warehouse:comingSoon")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const WarehouseInformationDocuments: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const { informationPanelBatch } = warehouseContext;

  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionDocuments">
      <div className="card mb-0">
        <div className="card-header" id="headingDocuments">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseDocuments">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:documents")}
            </span>
          </div>
        </div>
        <div id="collapseDocuments" className="collapse " data-parent="#accordionDocuments">
          <div className="card-body">
            {informationPanelBatch ? (
              <div className="kt-widget4">
                {informationPanelBatch.files.map(f => (
                  <WarehouseInformationDocument key={f._id.toString()} file={f} />
                ))}
                <div className="tab-pane mt-3" role="tabpanel">
                  <div className="kt-section">
                    <div className="kt-section__content " style={{ borderLeft: "none" }}>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Specification
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Flow Chart
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        MSDS
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Test Report
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Composition Statement
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Nutritional Values
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Natural Statement
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        Raw Material Origin Statement
                      </button>
                      <button type="button" className="btn btn-light btn-sm text-black m-1 disabled">
                        CoA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForDocuments")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface WarehouseInformationDocumentProps {
  file: BatchFile;
}

const WarehouseInformationDocument: React.FC<WarehouseInformationDocumentProps> = ({ file }) => {
  const [saving, setSaving] = useState(false);
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { informationPanelBatch } = warehouseContext;

  const handleRemoveFile = useCallback(async () => {
    if (!informationPanelBatch) return;
    setSaving(true);
    try {
      const action: UpdateAction = {
        collection: BATCH,
        filter: { _id: informationPanelBatch._id },
        pull: { files: file }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully removed file", "File could not be removed", () => {
        dataContext.updateDocumentInContext(BATCH, informationPanelBatch._id);
      });
    } finally {
      setSaving(false);
    }
  }, [informationPanelBatch, file]);

  return (
    <div className="kt-widget4__item">
      <div className="kt-widget4__pic kt-widget4__pic--icon">
        <img src="/media/icons/pdf_icon.png" alt="" />
      </div>
      <div className="kt-widget4__info">
        <div>
          <a href={file.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username mr-2">
            {file.title}
          </a>
        </div>
        <p className="kt-widget4__text">{dateUtils.getTimeAgo(file.date)}</p>
      </div>
      <ErrorOverlayButton
        buttonText={<i className="flaticon2-cross px-1 line-height-sm" />}
        className={"btn btn-danger btn-sm p-2"}
        errors={[]}
        onClick={handleRemoveFile}
        saving={saving}
      />
    </div>
  );
};

const WarehouseInformationComments: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [newComment, setNewComment] = useState<string>("");

  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { informationPanelBatch } = warehouseContext;

  const handleChangeComment = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setNewComment(e.target.value),
    []
  );

  const handleSaveComment = useCallback(async () => {
    if (!informationPanelBatch || !newComment.trim()) return;
    setSaving(true);

    try {
      const action: UpdateAction = {
        collection: BATCH,
        filter: { _id: informationPanelBatch._id },
        push: { comments: getBatchCommentDocument(newComment) }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully added comment", "Comment could not be updated", () => {
        setNewComment("");
        dataContext.updateDocumentInContext(BATCH, informationPanelBatch._id);
      });
    } finally {
      setSaving(false);
    }
  }, [informationPanelBatch, newComment]);

  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionComments">
      <div className="card mb-0">
        <div className="card-header" id="headingComments">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseComments">
            <span className="navi-text font-weight-medium font-size-lg text-black">{i18n.t("warehouse:comments")}</span>
            {informationPanelBatch && (
              <span
                className={
                  "label label-sm font-weight-bolder label-rounded ml-2 " +
                  (informationPanelBatch.comments.length > 0 ? "label-danger" : "")
                }
              >
                {informationPanelBatch.comments.length}
              </span>
            )}
          </div>
        </div>
        <div id="collapseComments" className="collapse " data-parent="#accordionComments">
          <div className="card-body">
            {informationPanelBatch ? (
              <>
                {informationPanelBatch.comments.length > 0 && (
                  <div className="timeline timeline-justified timeline-4">
                    <div className="timeline-bar" />
                    <div className="timeline-items">
                      {informationPanelBatch.comments.map(c => (
                        <WarehouseInformationComment key={c._id.toString()} comment={c} />
                      ))}
                    </div>
                  </div>
                )}
                <div className="kt-portlet__body kt-portlet__body--fit-top mt-3">
                  <div className="form-group form-group-last mb-3">
                    <textarea
                      className="form-control"
                      id="newCommentTextArea"
                      value={newComment}
                      rows={5}
                      onChange={handleChangeComment}
                      placeholder={i18n.t("warehouse:addCommentPlaceholder")}
                    />
                  </div>
                  <div className="kt-section kt-section--last">
                    <ErrorOverlayButton
                      buttonText={i18n.t("warehouse:saveComment")}
                      className={"btn btn-label-brand btn-sm btn-bold"}
                      errors={newComment.trim() ? [] : [i18n.t("warehouse:commentEmpty")]}
                      onClick={handleSaveComment}
                      saving={saving}
                    />
                  </div>
                </div>
              </>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForComments")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface WarehouseInformationCommentProps {
  comment: BatchComment;
}

const WarehouseInformationComment: React.FC<WarehouseInformationCommentProps> = ({ comment }) => {
  return (
    <div className="timeline-item">
      <div className="timeline-badge">
        <div className="bg-secondary"></div>
      </div>
      <div className="timeline-label pl-0">
        <span className="text-black ">
          {`${comment.person.prename} ${comment.person.surname}`}, {baseUtils.formatDate(comment.date)}
        </span>
      </div>
      <div className="timeline-content text-black">{comment.text}</div>
    </div>
  );
};

export default WarehouseInformationPanel;
