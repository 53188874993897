import React, { PureComponent } from "react";
import CommoditiesBaseListing from "./CommoditesBaseListing";
import { DataContext } from "../../context/dataContext";
import commodityUtils from "../../utils/commodityUtils";

interface ServicesProps {}
interface ServicesState {}

class Services extends PureComponent<ServicesProps, ServicesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    const { commodities } = this.context;
    const cFiltered = commodities.filter(c => !c.disabled && commodityUtils.hasCorrectType(c, "services"));
    return <CommoditiesBaseListing type={"services"} commodities={cFiltered} context={this.context} />;
  }
}

export default Services;
