import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { CustomOrder, InvoicePosition } from "../CustomTypes";
import config from "../../../config/config.json";
import { Invoice } from "../../../model/orders.types";
import dbOrderService from "../../../services/dbServices/dbOrderService";
import userService from "../../../services/userService";
import dateUtils from "../../../utils/dateUtils";
import invoiceGeneration from "../../../utils/pdf/invoiceGeneration";
import invoiceUtils, { I_FINALINVOICE, I_REMINDER } from "../../../utils/invoiceUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import { T_INVOICEPAID, T_INVOICEPARTLYPAID } from "../../../utils/timelineUtils";

interface PayInvoiceModalProps {
  order: CustomOrder;
  invoice?: Invoice;
  onHide: () => void;
  show: boolean;
}

interface PayInvoiceModalState {
  amountPaid: string;
  note: string;
  loading: boolean;
}

class PayInvoiceModal extends PureComponent<PayInvoiceModalProps, PayInvoiceModalState> {
  constructor(props: PayInvoiceModalProps) {
    super(props);
    this.state = { amountPaid: this.calculateAmountToPay(true, true), note: "", loading: false };
  }

  componentDidUpdate(prevProps: Readonly<PayInvoiceModalProps>) {
    if (prevProps.invoice?.totalGross !== this.props.invoice?.totalGross) {
      this.setState({ amountPaid: this.calculateAmountToPay(true, true) });
    }
    if (!prevProps.show && this.props.show) {
      this.setState({ loading: false });
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "amountPaid") {
      let val = e.target.value;
      val = val.replaceAll(/^0+/g, "0");
      if (!val.includes(".")) val = Number(val).toString();
      if ((!val && val !== "0") || Number(val) < 0) return;
      this.setState({ amountPaid: val });
    } else {
      this.setState({ note: e.target.value });
    }
  };

  /**
   * Handles paying the invoice and updating the order.
   */
  handleInvoicePaid = async () => {
    const { invoice, onHide, order } = this.props;
    const { amountPaid, note } = this.state;
    if (!invoice) return;
    this.setState({ loading: true });
    const fullyPaid = amountPaid === this.calculateAmountToPay(true, true);
    try {
      const paymentId = new BSON.ObjectId();
      // Build timeline entry
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        invoiceId: invoice._id,
        invoiceNumber: invoice.invoiceNumber,
        paymentId: paymentId,
        type: fullyPaid ? T_INVOICEPAID : T_INVOICEPARTLYPAID,
        date: new Date(),
        person: userService.getUserId(),
        amount: amountPaid,
        note
      };
      // Collect all existing positions including reminders (fees) and other payments
      const positions: Array<InvoicePosition> = invoice.positions.map(p => {
        return {
          id: BSON.ObjectId.isValid(p._id) ? new BSON.ObjectId(p._id) : new BSON.ObjectId(),
          type: p.type,
          description: p.description,
          amount: p.amount.toString(),
          unit: p.unit,
          price: p.price.toString(),
          total: p.total.toString(),
          vat: p.vat.toString(),
          discount: p.discount.toString()
        };
      });
      invoice.reminder
        .filter(r => !!r.position)
        .forEach(p =>
          positions.push({
            id: BSON.ObjectId.isValid(p.position!._id) ? new BSON.ObjectId(p.position!._id) : new BSON.ObjectId(),
            description: p.position!.description,
            total: p.position!.total.toString(),
            price: p.position!.price.toString(),
            vat: "0",
            type: I_REMINDER,
            amount: "1",
            discount: "0",
            unit: "item"
          })
        );
      const payments = invoice.payments.map(p => {
        return { note: p.note, date: p.date, value: p.amount };
      });
      payments.push({ date: new Date(), note, value: +amountPaid });
      const html = invoiceGeneration.createInvoice(
        order,
        { name: invoice.pdfData.customer, ...invoice.pdfData.address },
        invoice,
        positions,
        invoice.reverseCharge,
        +this.calculateAmountToPay(false, false),
        invoiceUtils.getVatAmount(positions, invoice.reverseCharge),
        +this.calculateAmountToPay(true, false),
        I_FINALINVOICE,
        order.fulfillment ? order.fulfillment.lot : undefined,
        false,
        payments
      );
      const data = JSON.stringify({
        html,
        fileName: `Rechnung-` + invoice.invoiceNumber + "_" + dateUtils.timeStampDate() + ".pdf"
      });
      const path = await pdfUtils.uploadAndReturnPath(data);
      const res = await dbOrderService.payInvoice(
        order._id,
        invoice!._id,
        paymentId,
        +amountPaid,
        fullyPaid,
        timelineEntry,
        note,
        path
      );
      if (res) {
        if (path) window.open(config.mediahubBase + path, "_blank");
        toast.success("Invoice paid");
        onHide();
      } else toast.error("Invoice could not be updated");
    } catch (e) {
      console.error(e.message);
      toast.error("An unexpected error occurred: " + e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * Calculate the amount that still has to be paid.
   * @param gross Indicated whether the gross value should be returned
   * @param withPayments If set payments are subtracted from the sum
   * @returns { string } Open amount as string
   */
  calculateAmountToPay = (gross: boolean, withPayments: boolean): string => {
    const { invoice } = this.props;
    if (!invoice) return "0";
    let amount = gross ? invoice.totalGross : invoice.total;
    if (invoice.reminder.length > 0) {
      amount += invoice.reminder.reduce((sum, r) => sum + (r.position ? r.position.total : 0), 0);
    }
    if (withPayments && invoice.payments.length > 0) {
      amount -= invoice.payments.reduce((sum, p) => sum + p.amount, 0);
    }
    return amount.toFixed(2);
  };

  /**
   * Check the entered amount for validity
   * @returns { boolean } Validity
   */
  checkAmountValid = (): boolean => {
    const { amountPaid } = this.state;
    const remaining = this.calculateAmountToPay(true, true);
    return (+amountPaid > 0 && +amountPaid <= +remaining) || +amountPaid === +remaining;
  };

  render() {
    const { invoice, onHide, show } = this.props;
    const { amountPaid, loading, note } = this.state;
    if (!invoice) return <></>;
    const amountValid = this.checkAmountValid();
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pay invoice RE-{invoice.invoiceNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Remaining Brutto</label>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="totalGross"
                  value={this.calculateAmountToPay(true, true)}
                  disabled
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Amount paid</label>
            <div className="col-8">
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  name="amountPaid"
                  value={amountPaid}
                  min={0}
                  onChange={this.handleChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">€</span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-4 col-form-label kt-font-dark kt-font-bold text-right">Note (optional)</label>
            <div className="col-8">
              <input className="form-control" type="text" name="note" value={note} onChange={this.handleChange} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={onHide}>
            Close
          </button>
          <button
            className={"btn btn-success" + (loading || !amountValid ? " disabled" : "")}
            disabled={loading || !amountValid}
            onClick={loading || !amountValid ? undefined : this.handleInvoicePaid}
          >
            Pay invoice
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PayInvoiceModal;
