import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { LayoutSplashScreen } from "../../../_metronic";
import dbService, {
  ACTIVESUBSTANCES,
  ADDITIVES,
  ALLERGENS,
  BATCH,
  CAPSULES,
  COLORS,
  COMMODITIES,
  COMMODITYCATEGORIES,
  COMMODITYPROPERTIES,
  COMPANIES,
  COMPOSITIONS,
  EMORDERS,
  FAQS,
  GENERAL,
  MANUFACTURERS,
  NEWS,
  ORDERFEEDBACK,
  ORDERS,
  PACKAGINGORDERS,
  PACKAGINGS,
  PACKAGINGSTOCK,
  POHODAHISTORY,
  PRODUCTIONPLAN,
  REQUESTS,
  RESERVATION,
  SAMPLERECIPES,
  SOLVENTS,
  SUPPLIERS,
  TABLETS,
  USERDATA
} from "../../../services/dbService";
import Configurator from "../../../components/configurator/Configurator";
import { DataContext } from "../../../context/dataContext";
import StockCheck from "../../../components/commodities/StockCheck";
import ClosedOrdersTable from "../../../components/listings/ClosedOrdersTable";
import News from "../../../components/news/News";
import CreateNews from "../../../components/news/CreateNews";
import VersionCheck from "../../../components/common/VersionCheck";
import Manufacturers from "../../../components/manufacturers/Manufacturers";
import ManufacturerDetailsPage from "../../../components/manufacturers/ManufacturerDetailsPage";
import Userdata from "../../../components/userdata/Userdata";
import CreateUser from "../../../components/userdata/CreateUser";
import Solvents from "../../../components/generalSettings/Solvents";
import ErrorPage from "../../../components/common/ErrorPage";
import Capsules from "../../../components/capsules/Capsules";
import Capsule from "../../../components/capsules/Capsule";
import ActiveSubstances from "../../../components/generalSettings/ActiveSubstances";
import Allergens from "../../../components/generalSettings/Allergens";
import CreateCapsule from "../../../components/capsules/CreateCapsule";
import CommodityCategories from "../../../components/generalSettings/commodityCategories/CommodityCategories";
import CommodityProperties from "../../../components/generalSettings/CommodityProperties";
import Colors from "../../../components/generalSettings/Colors";
import Compositions from "../../../components/generalSettings/Compositions";
import OrderWrapper from "../../../components/order/OrderWrapper";
import Dashboard from "../../../components/dashboard/Dashboard";
import SampleRecipes from "../../../components/samplerecipes/SampleRecipes";
import Companies from "../../../components/companies/Companies";
import Company from "../../../components/companies/Company";
import Commodities from "../../../components/commodities/Commodities";
import CreateCompany from "../../../components/companies/CreateCompany";
import PackagingOverview from "../../../components/packaging/PackagingOverview";
import CreatePackaging from "../../../components/packaging/CreatePackaging";
import Softgels from "../../../components/commodities/Softgels";
import CustomProducts from "../../../components/commodities/CustomProducts";
import Services from "../../../components/commodities/Services";
import Packaging from "../../../components/packaging/PackagingWrapper";
import CreateCommodity from "../../../components/commodities/CreateCommodity";
import CommodityWrapper from "../../../components/commodities/CommodityWrapper";
import SupplierOverview from "../../../components/supplier/SupplierOverview";
import CreateSupplier from "../../../components/supplier/CreateSupplier";
import SupplierWrapper from "../../../components/supplier/SupplierWrapper";
import Offers from "../../../components/listings/Offers";
import Requests from "../../../components/listings/Requests";
import Contracts from "../../../components/listings/Contracts";
import CheckCommodities from "../../../components/listings/CheckCommodities";
import Orders from "../../../components/listings/Orders";
import ProductionOrdersWrapper from "../../../components/productionPlan/ProductionOrdersWrapper";
import ProductionPlanWrapper from "../../../components/productionPlan/ProductionPlanWrapper";
import FinanceOverviewWrapper from "../../../components/finance/FinanceOverviewWrapper";
import FinanceDetailsWrapper from "../../../components/finance/FinanceDetailsWrapper";
import DeliveryCalendarWrapper from "../../../components/listings/deliveryCalendar/fullView/DeliveryCalendarWrapper";
import userService from "../../../services/userService";
import { ROLES } from "../../../utils/userdataUtils";
import { ExternalManufacturerContext } from "../../../context/externalManufacturerContext";
import ExternalManufacturerDashboard from "../../../components/externalManufacturers/dashboard/ExternalManufacturerDashboard";
import EMCommoditiesWrapper from "../../../components/externalManufacturers/commodities/EMCommoditiesWrapper";
import EMCommodityWrapper from "../../../components/externalManufacturers/commodities/EMCommodityWrapper";
import EMOrdersWrapper from "../../../components/externalManufacturers/orders/EMOrdersWrapper";
import EMOrderWrapper from "../../../components/externalManufacturers/orders/EMOrderWrapper";
import AllEMOrders from "../../../components/externalManufacturers/internal/AllEMOrders";
import EMOrderWrapperInternal from "../../../components/externalManufacturers/internal/EMOrderWrapperInternal";
import UserWrapper from "../../../components/userdata/UserWrapper";
import SupplyCentralUserCreation from "../../../components/administration/SupplyCentralUserCreation";
import OpenInvitations from "../../../components/administration/OpenInvitations";
import PackagingsWrapper from "../../../components/packaging/PackagingsWrapper";
import DunningWrapper from "../../../components/finance/dunning/DunningWrapper";
import accessUtils, { ACCESS_AREAS } from "../../../utils/accessUtils";
import CustomerNotesWrapper from "../../../components/customerNotes/CustomerNotesWrapper";
import StockData from "../../../components/tools/stockData/StockData";
import CommoditiesToOrder from "../../../components/tools/CommoditiesToOrder";
import authenticationService from "../../../services/authenticationService";
import ExpiredStock from "../../../components/tools/ExpiredStock";
import CommodityConsumption from "../../../components/tools/CommodityConsumption";
import CommodityOrderStatistics from "../../../components/tools/commodityStatistics/CommodityOrderStatistics";
import TopPerformers from "../../../components/tools/TopPerformers";
import CommodityImportExport from "../../../components/tools/CommodityImportExport";
import RestrictedInformation from "../../../components/common/RestrictedInformation";
import StockListingWrapper from "../../../components/stock/StockListingWrapper";
import { DeliveryCalendarUserContext } from "../../../context/deliveryCalendarUserContext";
import MinimumDeliveryCalendarWrapper from "../../../components/listings/deliveryCalendar/minimumView/MinimumDeliveryCalendarWrapper";
import { CURRENCIES } from "../../../utils/baseUtils";
import OrderStatistics from "../../../components/tools/OrderStatistics";
import CommodityWaste from "../../../components/tools/CommodityWaste";
import WarehouseManagementSystem from "../../../components/warehouse/WarehouseManagementSystem";
import WarehouseSettings from "../../../components/warehouse/WarehouseSettings";
import { loadContextConfigurations } from "../../../utils/configurationUtils";

class HomePage extends Component {
  refreshTimer;
  dataUpdateFunctions = {};

  constructor(props) {
    super(props);
    this.dataUpdateFunctions = {
      updateDocumentInContext: this.updateDocumentInContext,
      addDocuments: this.addDocuments
    };
    this.state = {
      externalManufacturerData: {
        commodities: [],
        externalManufacturerOrders: [],
        activesubstances: [],
        allergens: [],
        colors: [],
        commoditycategories: [],
        commodityproperties: [],
        compositions: [],
        solvents: [],
        userdata: []
      },
      deliveryCalendarUserData: {
        commodities: [],
        colors: [],
        manufacturer: {},
        packagings: [],
        packagingOrders: []
      },
      collections: {
        activesubstances: [],
        allergens: [],
        batch: [],
        capsules: [],
        colors: [],
        commodities: [],
        commoditycategories: [],
        commodityproperties: [],
        companies: [],
        compositions: [],
        configuration: [],
        externalManufacturerOrders: [],
        faqs: [],
        general: [],
        manufacturers: [],
        news: [],
        orderFeedback: [],
        orders: [],
        packagings: [],
        packagingOrders: [],
        packagingStock: [],
        pohodaHistory: [],
        requests: [],
        reservations: [],
        sampleRecipes: [],
        solvents: [],
        suppliers: [],
        tablets: [],
        userdata: [],
        productionPlan: []
      },
      savedState: {},
      rawbidsCommodities: { commodities: [], date: new Date(0) },
      progress: 0,
      loading: true,
      loadingOrders: true,
      error: "",
      trace: "",
      loadingMessage: "Please wait...",
      currencies: []
    };
  }

  async componentDidMount() {
    this.setState({ currencies: CURRENCIES });
    if (userService.hasExclusiveRole(ROLES.DELIVERYCALENDERONLY)) {
      const collection = await this.loadDeliveryCalendarUserCollections();
      this.setState(
        {
          loading: false,
          loadingOrders: false,
          deliveryCalendarUserData: { ...collection }
        },
        () => dbService.listenDeliveryCalendarUserCollections(this.updateDeliveryCalendarUserCollections)
      );
    } else if (userService.hasExclusiveRole(ROLES.EXTERNALMANUFACTURER)) {
      const commoditiesPromise = dbService.callFunction("getEMCommodities", []);
      const collectionsPromise = this.loadEMCollections();
      const commodities = await commoditiesPromise;
      const collections = await collectionsPromise;
      this.setState(
        {
          loading: false,
          loadingOrders: false,
          externalManufacturerData: { ...collections, commodities }
        },
        () => dbService.listenEMCollections(this.updateEMCollections)
      );
    } else {
      const collections = await this.loadCollections();
      this.setState({ collections }, () => {
        this.setState({ loading: false });
        this.hideElements();
      });
      collections.orders = await dbService.getFilteredCollection(ORDERS, { state: { $nin: ["declined"] } });
      this.setState({ collections, loadingOrders: false }, () => dbService.listener(this.updateDatabase));
    }
    this.refreshTokens();
  }

  componentDidCatch(error, errorInfo) {
    const { history } = this.props;
    const trace = errorInfo.componentStack;
    const e = error.toString();
    this.setState({ error: e, trace });
    console.error("ERROR: ", e, ", Trace: ", trace);
    // Replace "/" so that params of the malfunctioning page are not seen as additional params of the error page
    if (process.env.NODE_ENV === "production")
      history.push("/error/" + history.location.pathname.replaceAll("/", "\\"));
    try {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-1" />
          {e}
        </b>,
        { autoClose: false, progress: undefined }
      );
    } catch (ex) {
      toast.error(
        <b>
          <i className="fa fa-exclamation mr-1" />
          Unknown Error
        </b>,
        { autoClose: false, progress: undefined }
      );
      console.error("ERROR: ", e, ex);
    }
  }

  componentWillUnmount = () => {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  };

  refreshTokens = () => {
    const user = authenticationService.getUser();
    if (user) {
      user.refreshAccessToken();
      user.refreshCustomData();
      this.refreshTimer = setTimeout(() => this.refreshTokens(), 5 * 60 * 1000); // 5 minutes
    }
  };

  hideElements = () => {
    const { loadingOrders } = this.state;
    const elements1 = Array.prototype.slice.call(document.getElementsByTagName("button"), 0);
    const elements2 = Array.prototype.slice.call(document.getElementsByClassName("btn"), 0);
    const elements3 = Array.prototype.slice.call(document.getElementsByTagName("select"), 0);
    const elements4 = Array.prototype.slice.call(document.getElementsByClassName("select-default"), 0);
    const elements5 = Array.prototype.slice.call(document.getElementsByTagName("input"), 0);
    const mergedElements = Array.from(
      new Set(elements1.concat(elements2.concat(elements3.concat(elements4.concat(elements5)))))
    );
    for (let i = 0; i < mergedElements.length; i++) {
      const element = mergedElements[i];
      if (!loadingOrders) element.classList.remove("hide-element");
      else if (!element.classList.contains("hide-element")) element.classList.add("hide-element");
    }
    if (loadingOrders) setTimeout(this.hideElements, 100);
  };

  /**
   * Add the given documents to the context and the given collection. Only documents that were not already inside context are added.
   * @param collection Collection that should be updated
   * @param documents Documents that should be added to the context
   */
  addDocuments = (collection, documents) => {
    const { collections } = this.state;
    if (collections[collection]) {
      const coll = collections[collection];
      let updated = false;
      const newDocuments = [];
      for (let doc of documents) {
        if (coll.some(d => d._id.toString() === doc._id.toString())) {
          newDocuments.push(doc);
          updated = true;
        }
      }
      coll.concat(newDocuments);
      if (updated) this.setState({ collections });
    }
  };

  /**
   * Save a components state
   * @param key key, i.e. class name
   * @param state the state to save
   */
  saveComponentState = (key, state) => {
    const savedState = { ...this.state.savedState };
    savedState[key] = { state, date: new Date() };
    this.setState({ savedState });
  };

  /**
   * Set cached rawbids commodities
   * @param rawbidsCommodities list of commodities
   */
  setRawbidsCommodities = rawbidsCommodities => {
    if (rawbidsCommodities.length === 0) return;
    this.setState({ rawbidsCommodities: { commodities: rawbidsCommodities, date: new Date() } });
  };

  /**
   * Fetches the given document from the given collection and updates the state by replacing it.
   * @param collection Collection that should be updated
   * @param _id ID of the document
   */
  updateDocumentInContext = async (collection, _id) => {
    const { collections } = this.state;
    const document = await dbService.getDocumentFromCollection(collection, _id.toString());
    if (collections[collection]) {
      if (document) {
        const idx = collections[collection].findIndex(c => c._id.toString() === _id.toString());
        if (idx !== -1) {
          collections[collection].splice(idx, 1, document);
        } else {
          collections[collection].push(document);
        }
      } else {
        collections[collection] = collections[collection].filter(d => d._id.toString() !== _id.toString());
      }
      this.setState({ collections });
    }
  };

  /**
   * Load collections for external manufacturers
   * @returns {object} Object with relevant collections for external manufacturers
   */
  loadEMCollections = async () => {
    const activeSubstancesPromise = dbService.getCollection(ACTIVESUBSTANCES);
    const allergensPromise = dbService.getCollection(ALLERGENS);
    const colorsPromise = dbService.getCollection(COLORS);
    const commoditycategoriesPromise = dbService.getCollection(COMMODITYCATEGORIES);
    const commoditypropertiesPromise = dbService.getCollection(COMMODITYPROPERTIES);
    const compositionsPromise = dbService.getCollection(COMPOSITIONS);
    const solventsPromise = dbService.getCollection(SOLVENTS);
    const userdataPromise = dbService.getCollection(USERDATA);
    const emOrdersPromise = dbService.getCollection(EMORDERS);
    return {
      activesubstances: await activeSubstancesPromise,
      allergens: await allergensPromise,
      colors: await colorsPromise,
      commoditycategories: await commoditycategoriesPromise,
      commodityproperties: await commoditypropertiesPromise,
      compositions: await compositionsPromise,
      solvents: await solventsPromise,
      userdata: await userdataPromise,
      externalManufacturerOrders: await emOrdersPromise
    };
  };

  /**
   * Load collection for delivery Calendar user
   * @returns {object} Object with relevant collections for manufacturer related delivery Calendar user
   */
  loadDeliveryCalendarUserCollections = async () => {
    // receive the related manufacturer
    const manufacturer = await dbService.callFunction("getRelatedManufacturer", [userService.getUserId().toString()]);

    // receive all manufacturer related packaging orders
    const packagingObjectPromise = dbService.callFunction("getManufacturerRelatedPackagingObjects", [
      manufacturer._id.toString()
    ]);
    // receive all commodities with filtered commodityOrders related to the manufacturer
    const commoditiesPromise = dbService.callFunction("getManufacturerRelatedCommodities", [
      manufacturer._id.toString()
    ]);
    const colorsPromise = dbService.getCollection(COLORS);
    const packagingObject = await packagingObjectPromise;
    const colors = await colorsPromise;
    const commodities = await commoditiesPromise;

    return {
      commodities: commodities,
      colors: colors,
      manufacturer: manufacturer,
      packagings: packagingObject.packagings,
      packagingOrders: packagingObject.packagingOrders
    };
  };

  /**
   * Handle updates on external manufacturer collections
   * @param change change object from stream
   * @returns {Promise<void>} Promise
   */
  updateEMCollections = async change => {
    const externalManufacturerData = { ...this.state.externalManufacturerData };
    let collection = change.ns.coll;
    if (collection === COMMODITIES) {
      externalManufacturerData.commodities = await dbService.callFunction("getEMCommodities", []);
      this.setState({ externalManufacturerData });
      return;
    }
    let action = change.operationType;
    let documentKey = change.documentKey._id;
    if (action === "delete") {
      externalManufacturerData[collection] = externalManufacturerData[collection].filter(
        item => item && item._id.toString() !== documentKey.toString()
      );
      this.setState({ externalManufacturerData });
      return;
    }
    let indexNew = null;
    const newEmData = { ...externalManufacturerData };
    switch (action) {
      case "replace":
      case "update":
        const newCollection = newEmData[change.ns.coll].slice();
        indexNew = newCollection.findIndex(d => d._id.toString() === documentKey.toString());
        newCollection.splice(indexNew, 1, change.fullDocument);
        newEmData[change.ns.coll] = newCollection;
        this.setState({ externalManufacturerData: newEmData });
        break;
      case "delete":
        break;
      case "insert":
        const collCopy = newEmData[change.ns.coll].slice();
        collCopy.push(change.fullDocument);
        newEmData[change.ns.coll] = collCopy;
        this.setState({ externalManufacturerData: newEmData });
        break;
    }
  };

  /**
   * Handle updates on manufacturer related delivery calendar user collections
   * @param change change object from stream
   * @returns {Promise<void>} Promise
   */
  updateDeliveryCalendarUserCollections = async change => {
    const deliveryCalendarUserData = { ...this.state.deliveryCalendarUserData };
    let collection = change.ns.coll;
    if (collection === COMMODITIES) {
      deliveryCalendarUserData.commodities = await dbService.callFunction("getManufacturerRelatedCommodities", [
        deliveryCalendarUserData.manufacturer._id.toString()
      ]);
      this.setState({ deliveryCalendarUserData });
      return;
    }
    if (collection === PACKAGINGS || collection === PACKAGINGORDERS) {
      const packagingObject = await dbService.callFunction("getManufacturerRelatedPackagingObjects", [
        deliveryCalendarUserData.manufacturer._id.toString()
      ]);
      deliveryCalendarUserData.packagings = packagingObject.packagings;
      deliveryCalendarUserData.packagingsOrders = packagingObject.packagingOrders;
      this.setState({ deliveryCalendarUserData });
      return;
    }
    if (collection === COLORS) {
      const colors = await dbService.getCollection(COLORS);
      deliveryCalendarUserData.colors = colors;
      this.setState({ deliveryCalendarUserData });
      return;
    }
  };

  /**
   * For future use
   */
  loadCollections = async () => {
    const additivesPromise = dbService.getCollection(ADDITIVES);
    const activeSubstancesPromise = dbService.getCollection(ACTIVESUBSTANCES);
    const allergensPromise = dbService.getCollection(ALLERGENS);
    const batchPromise = dbService.getCollection(BATCH);
    const capsulesPromise = dbService.getCollection(CAPSULES);
    const colorsPromise = dbService.getCollection(COLORS);
    const commoditiesPromise = dbService.getFilteredCollection(COMMODITIES, {});
    const commoditycategoriesPromise = dbService.getCollection(COMMODITYCATEGORIES);
    const commoditypropertiesPromise = dbService.getCollection(COMMODITYPROPERTIES);
    const companiesPromise = dbService.getCollection(COMPANIES);
    const compositionsPromise = dbService.getCollection(COMPOSITIONS);
    const configurationPromise = loadContextConfigurations();
    const faqsPromise = dbService.getCollection(FAQS);
    const generalPromise = dbService.getCollection(GENERAL);
    const manufacturersPromise = dbService.getCollection(MANUFACTURERS);
    const newsPromise = dbService.getCollection(NEWS);
    const orderFeedbackPromise = dbService.getCollection(ORDERFEEDBACK);
    const ordersPromise = dbService.loadLatestOrders(30);
    const packagingsPromise = dbService.getCollection(PACKAGINGS);
    const requestsPromise = dbService.getCollection(REQUESTS);
    const reservationPromise = dbService.getCollection(RESERVATION);
    const sampleRecipesPromise = dbService.getCollection(SAMPLERECIPES);
    const solventsPromise = dbService.getCollection(SOLVENTS);
    const suppliersPromise = dbService.getCollection(SUPPLIERS);
    const tabletsPromise = dbService.getCollection(TABLETS);
    const userdataPromise = dbService.getCollection(USERDATA);
    const productionPlanPromise = dbService.getCollection(PRODUCTIONPLAN);
    const emOrdersPromise = dbService.getCollection(EMORDERS);
    const packagingOrdersPromise = dbService.getCollection(PACKAGINGORDERS);
    const packagingStockPromise = dbService.getCollection(PACKAGINGSTOCK);
    const pohodaHistoryPromise = dbService.getCollection(POHODAHISTORY);

    return {
      additives: await additivesPromise,
      activesubstances: await activeSubstancesPromise,
      allergens: await allergensPromise,
      batch: await batchPromise,
      capsules: await capsulesPromise,
      colors: await colorsPromise,
      commodities: await commoditiesPromise,
      commoditycategories: await commoditycategoriesPromise,
      commodityproperties: await commoditypropertiesPromise,
      companies: await companiesPromise,
      compositions: await compositionsPromise,
      configuration: await configurationPromise,
      externalManufacturerOrders: await emOrdersPromise,
      faqs: await faqsPromise,
      general: await generalPromise,
      manufacturers: await manufacturersPromise,
      news: await newsPromise,
      orderFeedback: await orderFeedbackPromise,
      orders: await ordersPromise,
      packagings: await packagingsPromise,
      requests: await requestsPromise,
      reservation: await reservationPromise,
      sampleRecipes: await sampleRecipesPromise,
      solvents: await solventsPromise,
      suppliers: await suppliersPromise,
      tablets: await tabletsPromise,
      userdata: await userdataPromise,
      productionPlan: await productionPlanPromise,
      packagingOrders: await packagingOrdersPromise,
      packagingStock: await packagingStockPromise,
      pohodaHistory: await pohodaHistoryPromise
    };
  };

  updateDatabase = change => {
    let collection = change.ns.coll;
    let action = change.operationType;
    let documentKey = change.documentKey._id;
    const { collections } = this.state;
    if (action === "delete") {
      collections[collection] = collections[collection].filter(
        item => item && item._id.toString() !== documentKey.toString()
      );
      this.setState({
        collections
      });
      return;
    }
    let indexNew = null;
    const collNew = { ...collections };
    switch (action) {
      case "replace":
      case "update":
        const newCollection = collNew[change.ns.coll].slice();
        indexNew = newCollection.findIndex(d => d._id.toString() === documentKey.toString());
        newCollection.splice(indexNew, 1, change.fullDocument);
        collNew[change.ns.coll] = newCollection;
        this.setState({ collections: collNew });
        break;
      case "delete":
        break;
      case "insert":
        const collCopy = collNew[change.ns.coll].slice();
        collCopy.push(change.fullDocument);
        collNew[change.ns.coll] = collCopy;
        this.setState({ collections: collNew });
        break;
    }
  };

  render() {
    const {
      collections,
      loading,
      loadingOrders,
      loadingMessage,
      currencies,
      error,
      trace,
      savedState,
      externalManufacturerData,
      deliveryCalendarUserData,
      rawbidsCommodities
    } = this.state;
    const isDeliveryNoteUser = userService.hasExclusiveRole(ROLES.DELIVERYCALENDERONLY);
    const isEM = userService.hasExclusiveRole(ROLES.EXTERNALMANUFACTURER);
    const isProd = userService.hasRole(ROLES.PRODUCTION, true);
    const isGroupMember = userService.hasOneOfRoles([ROLES.MIXMASTERS], true);
    const isAdmin = userService.isAdmin();
    const canAccessFinance = accessUtils.canAccessArea(ACCESS_AREAS.FINANCE);
    const canAccessProcurement = accessUtils.canAccessArea(ACCESS_AREAS.PROCUREMENT);
    const canAccessSales = accessUtils.canAccessArea(ACCESS_AREAS.SALES);
    const canAccessProcurementNotProduction = accessUtils.canAccessArea(ACCESS_AREAS.PROCUREMENT_NOT_PRODUCTION);
    const canAccessProduction = accessUtils.canAccessArea(ACCESS_AREAS.PRODUCTION);
    const canAccessWarehouse = accessUtils.canAccessArea(ACCESS_AREAS.WAREHOUSE);
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
        />
        {loading ? (
          <div
            className={"modal fade show d-block my-0 mx-auto"}
            role="dialog"
            style={{ zIndex: "9999", backgroundColor: "white" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" style={{ border: "0px solid white" }}>
                <div className="modal-body text-center">
                  <img src={process.env.PUBLIC_URL + "/media/logos/admincentral-sm.png"} style={{ height: "60px" }} />
                  <br />
                  <br />
                  <span style={{ fontSize: "0.9rem", fontWeight: "500", color: "#595d6e" }}>{loadingMessage}</span>
                </div>
              </div>
            </div>
          </div>
        ) : isDeliveryNoteUser ? (
          <DeliveryCalendarUserContext.Provider
            value={{ ...deliveryCalendarUserData, savedState, saveComponentState: this.saveComponentState }}
          >
            <Switch>
              <Redirect exact from="/" to="/deliveryCalendar" />
              <Route path="/error/:source" render={() => <ErrorPage error={error} trace={trace} />} />
              <Route exact path="/deliveryCalendar" component={MinimumDeliveryCalendarWrapper} />
            </Switch>
          </DeliveryCalendarUserContext.Provider>
        ) : isEM ? (
          <ExternalManufacturerContext.Provider
            value={{ ...externalManufacturerData, savedState, saveComponentState: this.saveComponentState }}
          >
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              <Route path="/error" render={() => <ErrorPage error={error} trace={trace} />} />
              <Route exact path="/dashboard" component={ExternalManufacturerDashboard} />
              <Route exact path="/commodity/:id" component={EMCommodityWrapper} />
              <Route exact path="/order/:id" component={EMOrderWrapper} />
              <Route exact path="/commodities" component={EMCommoditiesWrapper} />
              <Route exact path="/orders" component={EMOrdersWrapper} />
              <Redirect to="/error" />
            </Switch>
          </ExternalManufacturerContext.Provider>
        ) : (
          <>
            {loadingOrders && (
              <div className="alert alert-custom alert-white alert-shadow fade show gutter-b text-center">
                <div className="d-block mx-auto my-0">
                  <div className="alert-text">
                    <span className="kt-font-dark">
                      <CircularProgress className="kt-splash-screen__spinner mr-2" size={10} />
                      Loading commodities and orders. This may take a few seconds.
                    </span>
                  </div>
                </div>
              </div>
            )}
            <DataContext.Provider
              value={{
                ...collections,
                currencies: currencies,
                savedState,
                ...this.dataUpdateFunctions,
                saveComponentState: this.saveComponentState,
                rawbidsCommodities,
                setRawbidsCommodities: this.setRawbidsCommodities
              }}
            >
              <Switch>
                <Redirect exact from="/" to="/dashboard" />
                <Route path="/error/:source" render={() => <ErrorPage error={error} trace={trace} />} />
                <Route exact path="/user/:id" component={UserWrapper} />
                {userService.hasRole(ROLES.MIXMASTERS, true) ? (
                  <>
                    <Route exact path="/dashboard" component={Orders} />
                    <Route exact path="/calculation" component={Configurator} />
                    <Route exact path="/allOrders" component={Orders} />
                    <Route exact path="/inProgress/:view" component={Orders} />
                    <Route exact path="/deliveryCalendar" component={DeliveryCalendarWrapper} />
                    <Route exact path="/order/:id" component={OrderWrapper} />
                    <Route exact path="/capsule/:id" render={() => <Capsule currencies={currencies} />} />
                    <Route exact path="/packaging/:id" component={Packaging} />
                    <Route exact path="/packaging/:id/:order" component={Packaging} />
                    <Route exact path="/commodity/:id" component={CommodityWrapper} />
                    <Route exact path="/commodity/:id/:order" component={CommodityWrapper} />
                    <Route exact path="/commodities" component={Commodities} />
                    <Route exact path="/customproducts" component={CustomProducts} />
                    <Route exact path="/services" component={Services} />
                    <Route exact path="/softgels" component={Softgels} />
                    <Route exact path="/packagings" component={PackagingOverview} />
                    <Route exact path="/pStockOverview" component={PackagingsWrapper} />
                    <Route exact path="/capsules" component={Capsules} />
                    <Route exact path="/create-commodity" render={() => <CreateCommodity type={"commodity"} />} />
                    <Route exact path="/create-customproduct" render={() => <CreateCommodity type={"custom"} />} />
                    <Route exact path="/create-service" render={() => <CreateCommodity type={"service"} />} />
                    <Route exact path="/create-softgel" render={() => <CreateCommodity type={"softgel"} />} />
                    <Route exact path="/create-packaging" component={CreatePackaging} />
                    <Route exact path="/create-capsule" component={CreateCapsule} />
                    <Route exact path="/productionOrders" component={ProductionOrdersWrapper} />
                    <Route exact path="/manufacturer/:id" component={ManufacturerDetailsPage} />
                    <Route exact path="/company/:id" component={RestrictedInformation} />
                    <Route exact path="/supplier/:id" component={RestrictedInformation} />
                    <Route exact path="/archive" render={props => <ClosedOrdersTable {...props} type={"archive"} />} />
                    <Route
                      exact
                      path="/declined"
                      render={props => <ClosedOrdersTable {...props} type={"declined"} />}
                    />
                  </>
                ) : (
                  <>
                    {canAccessWarehouse && <Route exact path="/warehouse" component={WarehouseManagementSystem} />}
                    {canAccessWarehouse && <Route exact path="/warehouseSettings" component={WarehouseSettings} />}
                    <Route exact path="/pStockOverview" component={PackagingsWrapper} />
                    {canAccessSales && <Route exact path="/accountCreation" component={SupplyCentralUserCreation} />}
                    {canAccessSales && <Route exact path="/openInvitations" component={OpenInvitations} />}
                    <Route exact path="/dashboard" component={Dashboard} />
                    {(canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/externalOrders" component={AllEMOrders} />
                    )}
                    {(canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/externalOrder/:id" component={EMOrderWrapperInternal} />
                    )}
                    {(canAccessProcurement || canAccessSales || canAccessProduction) && (
                      <Route exact path="/deliveryCalendar" component={DeliveryCalendarWrapper} />
                    )}
                    {canAccessFinance && <Route exact path="/financeOverview" component={FinanceOverviewWrapper} />}
                    {canAccessFinance && <Route exact path="/financeDetails" component={FinanceDetailsWrapper} />}
                    {canAccessFinance && <Route exact path="/dunning" component={DunningWrapper} />}
                    {(canAccessProduction || canAccessProcurement || canAccessSales) && (
                      <Route exact path="/productionPlan" component={ProductionPlanWrapper} />
                    )}
                    <Route exact path="/allOrders" component={Orders} />
                    <Route exact path="/inProgress/:view" component={Orders} />
                    {(canAccessProduction || canAccessProcurement || canAccessSales) && (
                      <Route exact path="/productionOrders" component={ProductionOrdersWrapper} />
                    )}
                    {(canAccessSales || canAccessProcurement || canAccessFinance) && (
                      <Route exact path="/companies" component={Companies} />
                    )}
                    <Route exact path="/offers" component={Offers} />
                    {(canAccessSales || canAccessFinance || canAccessProcurementNotProduction) && (
                      <Route exact path="/requests" component={Requests} />
                    )}
                    <Route exact path="/contracts" component={Contracts} />
                    {canAccessSales && <Route exact path="/sample-recipes" component={SampleRecipes} />}
                    <Route exact path="/archive" render={props => <ClosedOrdersTable {...props} type={"archive"} />} />
                    <Route
                      exact
                      path="/declined"
                      render={props => <ClosedOrdersTable {...props} type={"declined"} />}
                    />
                    {(canAccessSales || canAccessProcurementNotProduction) && (
                      <Route exact path="/checkcommodities" component={CheckCommodities} />
                    )}
                    <Route exact path="/company/:id" component={Company} />
                    <Route exact path="/order/:id" component={OrderWrapper} />
                    <Route exact path="/capsule/:id" render={() => <Capsule currencies={currencies} />} />
                    {canAccessProcurementNotProduction && <Route exact path="/stockcheck" component={StockCheck} />}
                    <Route exact path="/packaging/:id" component={Packaging} />
                    <Route exact path="/packaging/:id/:order" component={Packaging} />
                    <Route exact path="/commodity/:id" component={CommodityWrapper} />
                    <Route exact path="/commodity/:id/:order" component={CommodityWrapper} />
                    <Route exact path="/supplier/:id" component={SupplierWrapper} />
                    <Route exact path="/suppliers" component={SupplierOverview} />
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/create-company" component={CreateCompany} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || isGroupMember) && (
                      <Route exact path="/calculation/:collection/:id" component={Configurator} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || isGroupMember) && (
                      <Route exact path="/calculation" component={Configurator} />
                    )}
                    <Route exact path="/create-supplier" component={CreateSupplier} />
                    <Route exact path="/user/:id" component={UserWrapper} />
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/userdata" render={() => <Userdata mode="all" />} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/customers" render={() => <Userdata mode="customer" />} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/employees" render={() => <Userdata mode="internal" />} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/create-user" component={CreateUser} />
                    )}
                    {!isProd && (
                      <Route
                        exact
                        path="/create-news"
                        render={props => <CreateNews {...props} news={collections.news} />}
                      />
                    )}
                    {!isProd && (
                      <Route exact path="/news" render={props => <News {...props} news={collections.news} />} />
                    )}
                    {(canAccessSales || canAccessProcurementNotProduction || canAccessFinance) && (
                      <Route exact path="/customerNotes" component={CustomerNotesWrapper} />
                    )}
                    <Route exact path="/commodities" component={Commodities} />
                    <Route exact path="/customproducts" component={CustomProducts} />
                    <Route exact path="/services" component={Services} />
                    <Route exact path="/softgels" component={Softgels} />
                    <Route exact path="/packagings" component={PackagingOverview} />
                    <Route exact path="/capsules" component={Capsules} />
                    <Route exact path="/create-commodity" render={() => <CreateCommodity type={"commodity"} />} />
                    <Route exact path="/create-customproduct" render={() => <CreateCommodity type={"custom"} />} />
                    <Route exact path="/create-service" render={() => <CreateCommodity type={"service"} />} />
                    <Route exact path="/create-softgel" render={() => <CreateCommodity type={"softgel"} />} />
                    <Route exact path="/create-packaging" component={CreatePackaging} />
                    <Route exact path="/create-capsule" component={CreateCapsule} />
                    <Route exact path="/commodity-categories" component={CommodityCategories} />
                    <Route exact path="/commodity-properties" component={CommodityProperties} />
                    <Route exact path="/compositions" component={Compositions} />
                    <Route exact path="/colors" component={Colors} />
                    <Route exact path="/allergens" component={Allergens} />
                    <Route exact path="/active-substances" component={ActiveSubstances} />
                    <Route exact path="/solvents" component={Solvents} />
                    <Route exact path="/manufacturers" component={Manufacturers} />
                    <Route exact path="/manufacturer/:id" component={ManufacturerDetailsPage} />
                    <Route exact path="/stock-overview" component={StockListingWrapper} />
                    {isAdmin && (
                      <Route
                        exact
                        path="/stockData"
                        render={() => (
                          <StockData
                            {...this.props}
                            commodities={collections.commodities}
                            manufacturers={collections.manufacturers}
                            suppliers={collections.suppliers}
                            orders={collections.orders}
                          />
                        )}
                      />
                    )}
                    {isAdmin && (
                      <Route
                        exact
                        path="/commoditiesToOrder"
                        render={() => (
                          <CommoditiesToOrder
                            {...this.props}
                            commodities={collections.commodities}
                            orders={collections.orders}
                          />
                        )}
                      />
                    )}
                    {isAdmin && (
                      <Route
                        exact
                        path="/commodityConsumption"
                        render={() => <CommodityConsumption {...this.props} commodities={collections.commodities} />}
                      />
                    )}
                    {isAdmin && <Route exact path="/topPerformers" render={() => <TopPerformers {...this.props} />} />}
                    {isAdmin && (
                      <Route
                        exact
                        path="/commodityImportExport"
                        render={() => <CommodityImportExport {...this.props} />}
                      />
                    )}
                    {isAdmin && <Route exact path="/commodityOrderStatistics" component={CommodityOrderStatistics} />}
                    {isAdmin && <Route exact path="/orderStatistics" component={OrderStatistics} />}
                    {isAdmin && <Route exact path="/commodityWaste" component={CommodityWaste} />}
                    {canAccessProcurementNotProduction && (
                      <Route
                        exact
                        path="/expiredStock"
                        render={() => (
                          <ExpiredStock
                            {...this.props}
                            commodities={collections.commodities}
                            manufacturers={collections.manufacturers}
                          />
                        )}
                      />
                    )}
                  </>
                )}
                <Redirect to="/error" />
              </Switch>
            </DataContext.Provider>
          </>
        )}
        <VersionCheck />
      </Suspense>
    );
  }
}

export default HomePage;
