import i18n from "../translations/i18n";
import {
  PackagingUnitDefinition,
  PhysicalWarehouse,
  WarehouseConfiguration,
  WarehouseDefinition,
  StorageSpace,
  PackagingUnitShape,
  WarehouseTypes
} from "../model/configuration/warehouseConfiguration.types";
import {
  BatchLocation,
  LocationWarehouseAreaSnapshot,
  LocationWarehouseSnapshot,
  PackagingUnit,
  StorageSpaceSnapshot
} from "../model/warehouse/batch.types";
import { formatNumValue } from "./baseUtils";
import { resolveTranslation } from "./translationUtils";
import { LocationType } from "../model/warehouse/common.types";
import { Reservation } from "../model/warehouse/reservation.types";
import { BookOutPackagingUnit, ExtendedPackagingUnit, ExtendedReservation } from "../model/warehouse/customTypes.types";
import { LengthUnit, SelectOption, WeightUnit } from "../model/common.types";

export enum SelectedBatchEntryType {
  BATCH = "batch",
  LOCATION = "location"
}

export enum SelectedCommodityEntryType {
  COMMODITY = "commodity",
  BATCH_LOCATION = "batchLocation"
}

export const DEFAULTLENGTHUNIT = LengthUnit.mm;
export const DEFAULTWEIGHTUNIT = WeightUnit.kg;

export const DEFAULTDIMENSIONS = {
  length: 0,
  height: 0,
  width: 0,
  shape: PackagingUnitShape.ANGULAR,
  unit: DEFAULTLENGTHUNIT
};

export const DEFAULTMAXWEIGHT = {
  value: 0,
  unit: DEFAULTWEIGHTUNIT
};

/**
 * Get a concatenated string with the pu sizes for a list of batch packaging unit entries
 * @param packagingUnits list of packaging units of a batch
 * @returns {string} string with all different packaging unit variants
 */
export function getBatchPackagingUnitDescription(
  packagingUnits: Array<PackagingUnit | ExtendedPackagingUnit | BookOutPackagingUnit>
): string {
  const uniqueSizes: Set<string> = new Set();
  packagingUnits.forEach(pU =>
    uniqueSizes.add(`${formatNumValue(pU.amountPerPu)} ${resolveTranslation(pU.puSnapshot.label)}`.trim())
  );
  return concatUniqueStrings(uniqueSizes);
}

/**
 * Get a concatenated string with the pu sizes for a list of packaging units
 * @param packagingUnits list of packaging unit definitions
 * @returns {string} string with all different packaging unit variants
 */
export function getPackagingUnitsDescription(packagingUnits: Array<PackagingUnitDefinition>): string {
  const uniqueSizes: Set<string> = new Set();
  packagingUnits.forEach(pU =>
    uniqueSizes.add(
      `${pU.maxFillingWeight ? formatNumValue(pU.maxFillingWeight) : ""} ${resolveTranslation(pU.label)}`.trim()
    )
  );
  return concatUniqueStrings(uniqueSizes);
}

/**
 * Get a concatenated string with warehouse names
 * @param locations list of batch locations
 * @param includeArea optional, flag indicating whether to include physical warehouse name or not
 * @returns {string} string with unique location names
 */
export const getExtendedLocationsDescription = (locations: Array<BatchLocation>, includeArea?: boolean): string => {
  const uniqueLocations: Set<string> = new Set();
  locations.forEach(l =>
    uniqueLocations.add(
      `${resolveTranslation(l.location.warehouseSnapshot.warehouseName)} ${
        includeArea ? resolveTranslation(l.location.warehouseArea.warehouseName) : ""
      }`.trim()
    )
  );
  return concatUniqueStrings(uniqueLocations);
};

/**
 * Concat a set of unique strings
 * @param uniqueStrings set of unique strings
 * @returns {string} concatenated string of unique strings
 */
function concatUniqueStrings(uniqueStrings: Set<string>): string {
  let description = "";
  const uniqueSizesArray = Array.from(uniqueStrings);
  uniqueSizesArray.forEach((s, idx) => {
    if (idx === uniqueSizesArray.length - 1) description += `${s}`;
    else if (idx === uniqueSizesArray.length - 2) description += `${s} ${i18n.t("warehouse:and")} `;
    else description += `${s}, `;
  });
  return description;
}

/**
 * Formats the packaging unit as string based on the given requirements
 * @param pu the packagingUnit from which the values should be taken from
 * @param includeQuantity optional, if quantity and the PU label should be included
 * @param includeAmount optional, if the amount per PU and its unit should be included
 * @param includeTotalAmount optional, if the total amount based on quantity and amountPerPu and its unit should be included
 * @returns {string} the formatted packaging unit string, empty if no include boolean was given
 */
export function getFormattedPackagingUnitAmount(
  pu: PackagingUnit,
  includeQuantity?: boolean,
  includeAmount?: boolean,
  includeTotalAmount?: boolean
): string {
  if (pu.quantity === null) return "Storage Space managed remotely";
  let puString = "";
  puString += includeQuantity ? `${pu.quantity} ${resolveTranslation(pu.puSnapshot.label)} ` : "";
  puString += includeAmount ? `${includeQuantity ? "à " : ""} ${pu.amountPerPu.value} ${pu.amountPerPu.unit} ` : "";
  puString += includeTotalAmount
    ? `${includeQuantity || includeAmount ? "(" : ""}${pu.quantity * pu.amountPerPu.value}${
        pu.amountPerPu.unit
      } ${i18n.t("invoice:totalAmount")}${includeQuantity || includeAmount ? ")" : ""}`
    : "";
  return puString;
}

/**
 * Retrieves the storage spaces from all physical warehouses listed under the given warehouse and returns them as select options
 * @returns {Array<SelectOption<LocationType>>} storage spaces as select options or empty array if no storage spaces were found
 */
export function getWarehouseSelectOptions(
  warehouses: Array<WarehouseDefinition> | undefined,
  currentWarehouseType: WarehouseTypes
): Array<SelectOption<LocationType>> {
  if (warehouses === undefined) return [];
  let relevantWarehouses: Array<{ logicalWarehouse: WarehouseDefinition; physicalWarehouse: PhysicalWarehouse }> = [];
  for (let i = 0; i < warehouses.length; i++) {
    const lw = warehouses[i];
    for (let j = 0; j < lw.physicalWarehouses.length; j++) {
      relevantWarehouses.push({ logicalWarehouse: lw, physicalWarehouse: lw.physicalWarehouses[j] });
    }
  }
  if (currentWarehouseType === WarehouseTypes.REMOTE)
    relevantWarehouses = relevantWarehouses.filter(rw => rw.physicalWarehouse.type === WarehouseTypes.REMOTE);

  return relevantWarehouses.map(rw => ({
    value: rw.physicalWarehouse._id.toString(),
    label: `${resolveTranslation(rw.logicalWarehouse.warehouseName)} - ${resolveTranslation(
      rw.physicalWarehouse.warehouseName
    )} `,
    data: LocationType.PHYSICAL_WAREHOUSE
  }));
}

/**
 * Get a label with warehouse name and shortname of logical and physical warehouses
 * @param warehouse The warehouse the label is for, either logical or physical
 * @returns {string} The warehouse label
 */
export function getWarehouseLabel(warehouse: WarehouseDefinition | PhysicalWarehouse): string {
  return resolveTranslation(warehouse.warehouseName) + " (" + warehouse.shortName + ")";
}

/**
 * Get a concatenated string with the order numbers for a list of reservations
 * @param reservations list of reservations for a batch
 * @returns {string} string with all different reservations
 */
export function getBatchReservationDescription(reservations: Array<Reservation | ExtendedReservation>): string {
  const uniqueSizes: Set<string> = new Set();
  reservations.forEach(r => uniqueSizes.add(`AT-${r.order.identifier}`.trim()));
  return concatUniqueStrings(uniqueSizes);
}

/**
 * Get a select option for a packaging unit
 * @param packagingUnit a packaging unit definition
 * @returns {SelectOption<PackagingUnitDefinition>} a select option for the packaging unit
 */
export function getPackagingUnitSelectOption(
  packagingUnit: PackagingUnitDefinition
): SelectOption<PackagingUnitDefinition> {
  return {
    value: packagingUnit._id.toString(),
    label: `${resolveTranslation(packagingUnit.label)} ${
      packagingUnit.maxFillingWeight ? `(${formatNumValue(packagingUnit.maxFillingWeight)})` : ""
    }`,
    data: packagingUnit
  };
}

/**
 * Get a select option for a warehouse area
 * @param storageSpace a storage space object or a snapshot of it or undefined/null
 * @returns {SelectOption<StorageSpace | StorageSpaceSnapshot> | null} a select option for the storage space or null if no storage space was provided
 */
export function getStorageSpaceSelectOption(
  storageSpace: StorageSpace | StorageSpaceSnapshot | null | undefined
): SelectOption<StorageSpace | StorageSpaceSnapshot> | null {
  if (!storageSpace) return null;
  return {
    value: storageSpace._id.toString(),
    label: storageSpace.storageSpaceNo,
    data: storageSpace
  };
}

/**
 * Get a select option for a warehouse area
 * @param warehouseArea a physical warehouse object or a snapshot of it
 * @param logicalWarehouse optional, a warehouse definition object or a snapshot of it
 * @returns {SelectOption<PhysicalWarehouse | LocationWarehouseAreaSnapshot>} a select option for the warehouse area
 */
export function getWarehouseAreaSelectOption(
  warehouseArea: PhysicalWarehouse | LocationWarehouseAreaSnapshot,
  logicalWarehouse?: WarehouseDefinition | LocationWarehouseSnapshot
): SelectOption<PhysicalWarehouse | LocationWarehouseAreaSnapshot> {
  return {
    value: warehouseArea._id.toString(),
    label: `${logicalWarehouse ? `${resolveTranslation(logicalWarehouse.warehouseName)} ` : ""}${resolveTranslation(
      warehouseArea.warehouseName
    )}`,
    data: warehouseArea
  };
}

/**
 * Get the total packaging unit amount as a formatted string including the unit
 * @param packagingUnit a packaging unit
 * @returns {string} formatted string with total amount
 */
export function getFormattedPackagingUnitTotalAmount(
  packagingUnit: PackagingUnit | ExtendedPackagingUnit | BookOutPackagingUnit
): string {
  return formatNumValue(
    { value: packagingUnit.amountPerPu.value * (packagingUnit.quantity || 0), unit: packagingUnit.amountPerPu.unit },
    2
  );
}

/**
 * Check if given warehouse is a remote warehouse
 * @param warehouseId warehouse id
 * @param warehouseAreaId physical warehouse id
 * @param configuration warehouse configuration with warehouse structure
 * @returns {boolean} true if all physical warehouses are remote warehouses, else false
 */
export function isRemoteWarehouse(
  warehouseId: string,
  warehouseAreaId: string | undefined,
  configuration: WarehouseConfiguration | null
) {
  if (!configuration) return false;
  const warehouse = configuration.values.warehouseStructure.find(w => w._id.toString() === warehouseId);
  if (warehouse) {
    if (warehouseAreaId)
      return warehouse.physicalWarehouses.some(
        pW => pW.type === WarehouseTypes.REMOTE && pW._id.toString() === warehouseAreaId
      );
    else return warehouse.physicalWarehouses.every(pW => pW.type === WarehouseTypes.REMOTE);
  }
  return false;
}

/**
 * Get a color indication for best before date
 * @param date best before date
 * @returns {string} text color class name
 */
export function getBBDColor(date: Date): string {
  const today = new Date();
  const sixMonths = new Date(new Date(today).setMonth(today.getMonth() + 6));
  const oneYear = new Date(new Date(today).setMonth(today.getMonth() + 12));
  if (date < sixMonths) return "text-danger";
  if (date < oneYear) return "text-warning";
  return "text-black";
}
