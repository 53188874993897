import { BSON } from "realm-web";
import dbService, { COMMODITIES } from "../dbService";
import {
  CommodityBatch,
  Commodities,
  CommoditiesDocument,
  CommodityOrder,
  CommoditySpecification,
  CommodityTimeline
} from "../../model/commodities.types";
import { TIMELINE_SLICE_AMOUNT } from "../../utils/materialUtils";

/**
 * Update the referenced commodity.
 * @param _id: ID of the commodity
 * @param update: Partial update that should be performed
 * @param timeline:  Optional, if passed element is pushed to timeline and the timelines size is reduced
 * @returns Result of the query
 */
async function updateCommodity(_id: BSON.ObjectId, update: object, timeline?: CommodityTimeline) {
  const updateObj: any = { $set: update };
  if (timeline) updateObj["$push"] = { timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } };
  return dbService.getDb()?.collection(COMMODITIES).updateOne({ _id }, updateObj);
}

/**
 * Adds the given specification to the referenced commodity.
 * @param _id: ID of the commodity
 * @param specification: Specification that should be added
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function addSpecification(_id: BSON.ObjectId, specification: CommoditySpecification) {
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne({ _id }, { $push: { specifications: specification } });
}

/**
 * Removes the given specification from the referenced commodity.
 * @param _id: ID of the commodity
 * @param specification: Specification that should be removed
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function removeSpecification(_id: BSON.ObjectId, specification: CommoditySpecification) {
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne({ _id }, { $pull: { specifications: specification } });
}

/**
 * Duplicates the given commodity.
 * @param commodity: Commodity that should be duplicated
 * @returns Result of the query
 */
async function duplicateCommodity(commodity: CommoditiesDocument) {
  commodity._id = new BSON.ObjectId();
  commodity.orders = [];
  commodity.timeline = [];
  return dbService.getDb()?.collection(COMMODITIES).insertOne(commodity);
}

/**
 * Pushes a new commodity order to the commodity.
 * @param _id: ID of the commodity
 * @param commodityOrder: Commodity order that should be pushed
 * @param timeline: Timeline object that should be added to the commodity
 * @param push: Determines whether the order should be pushed or set (set is needed in case the orders are null)
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function addCommodityOrder(
  _id: BSON.ObjectId,
  commodityOrder: CommodityOrder,
  timeline: CommodityTimeline,
  push: boolean
) {
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne(
      { _id },
      push
        ? { $push: { orders: commodityOrder, timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } } }
        : {
            $set: { orders: [commodityOrder] },
            $push: { timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } }
          }
    );
}

/**
 * Updates a commodity order.
 * @param _id: ID of the commodity
 * @param commodityOrder: Commodity order after update
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function updateCommodityOrder(_id: BSON.ObjectId, commodityOrder: CommodityOrder) {
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne({ _id, "orders._id": commodityOrder._id }, { $set: { "orders.$": commodityOrder } });
}

/**
 * Updates a commodity by delivering a commodity order. The density is updated too.
 * @param _id: ID of the commodity
 * @param commodityOrder: Commodity order
 * @param batch: New batch
 * @param timeline: Timeline object that should be added to the commodity
 * @param density: New density
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function deliverCommodityOrder(
  _id: BSON.ObjectId,
  commodityOrder: CommodityOrder,
  batch: CommodityBatch,
  timeline: CommodityTimeline,
  density: number
) {
  commodityOrder.delivered = new Date();
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne(
      { _id, "orders._id": commodityOrder._id },
      {
        $set: { "orders.$": commodityOrder, density },
        $push: { stock: batch, timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } }
      }
    );
}

/**
 * Cancels a commodity order by deleting it.
 * @param _id: ID of the commodity
 * @param commodityOrder: Commodity order that should be cancelled
 * @param timeline: Timeline object that should be added to the commodity
 * @returns { Realm.Services.MongoDB.UpdateResult<any> } Result of the query
 */
async function cancelCommodityOrder(_id: BSON.ObjectId, commodityOrder: CommodityOrder, timeline: CommodityTimeline) {
  return dbService
    .getDb()
    ?.collection(COMMODITIES)
    .updateOne(
      { _id, "orders._id": commodityOrder._id },
      { $pull: { orders: commodityOrder }, $push: { timeline: { $each: [timeline], $slice: TIMELINE_SLICE_AMOUNT } } }
    );
}

/**
 * Call createCommodity backend function
 * @param commodity a complete commodity document
 * @returns {Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} result of the function
 */
async function createCommodity(
  commodity: Commodities
): Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return dbService.callFunction("createCommodity", [commodity], true);
}

/**
 * Call getCommodityIdentifier backend function
 * @param category the category id or string
 * @param organic organic flag
 * @returns {Promise<false | string>} result of the function
 */
async function getCommodityIdentifier(category: string | BSON.ObjectId, organic: boolean): Promise<false | string> {
  return dbService.callFunction("getCommodityIdentifier", [category, organic], true);
}

export default {
  addCommodityOrder,
  addSpecification,
  cancelCommodityOrder,
  createCommodity,
  deliverCommodityOrder,
  duplicateCommodity,
  getCommodityIdentifier,
  removeSpecification,
  updateCommodity,
  updateCommodityOrder
};
