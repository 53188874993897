import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import { resolveTranslation } from "../../../utils/translationUtils";
import {
  SelectedBatchEntryType,
  SelectedCommodityEntryType
} from "../../../utils/warehouseUtils";
import {
  BaseActionModalProps,
  LocationType,
  PUStorageSpaceAssignment,
} from "../../../model/warehouse/common.types";
import { WarehouseTypes } from "../../../model/configuration/warehouseConfiguration.types";
import { Batch, BatchLocation } from "../../../model/warehouse/batch.types";
import {
  getStorageSpaceSelectOptions,
  hasDuplicateLocations,
  hasOneLocation
} from "../../../utils/warehouseStorageSpaceUtils";
import { BatchStatus, compareBatchLocations, getBatchLocationStatus } from "../../../utils/batchUtils";
import dbService from "../../../services/dbService";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import PackagingUnitEntry from "../common/PackagingUnitEntry";
import { SelectOption } from "../../../model/common.types";
import { getNumericValue } from "../../../utils/baseUtils";
import { ReservationState } from "../../../model/warehouse/reservation.types";
import { isSelectedBatchEntries, isSelectedCommodityEntries } from "../../../utils/warehouseActionUtils";

enum ViewAction {
  NEXT = "next",
  PREVIOUS = "previous"
}

interface AssignStorageSpaceModalState {
  view: number;
  saving: boolean;
  batchEntry?: Batch;
  relevantLocations: Array<BatchLocation>;
  currentLocation?: BatchLocation;
  pUStorageSpaceAssignment: PUStorageSpaceAssignment;
  initialStorageSpaceAssignment: PUStorageSpaceAssignment;
}

const getDefaultState = (): AssignStorageSpaceModalState => {
  return {
    view: 0,
    saving: false,
    batchEntry: undefined,
    relevantLocations: [],
    currentLocation: undefined,
    pUStorageSpaceAssignment: {},
    initialStorageSpaceAssignment: {}
  };
};

const getUpdatedStorageSpaceAssignment = (newLocation: BatchLocation | undefined) => {
  const updatedData: PUStorageSpaceAssignment = {};
  if (newLocation && newLocation.packagingUnits) {
    newLocation.packagingUnits.forEach(packagingUnit => {
      const key = packagingUnit._id.toString();
      updatedData[key] = [
        {
          quantity: packagingUnit.quantity ?? 0,
          location:
            newLocation.location.storageSpace?._id.toString() ?? newLocation.location.warehouseArea._id.toString(),
          locationType: newLocation.location.storageSpace ? LocationType.STORAGESPACE : LocationType.PHYSICAL_WAREHOUSE
        }
      ];
    });
  }
  return updatedData;
};

const AssignStorageSpaceModal: React.FC<BaseActionModalProps> = ({ show, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { batch, reservation } = dataContext;
  const { selectedEntries, configuration } = warehouseContext;

  const [state, setState] = useState(getDefaultState());

  useEffect(() => {
    if (show) {
      let relevantLocations: Array<BatchLocation> = [];
      let currentLocation: BatchLocation | undefined = undefined;
      let initialStorageSpaceAssignment: PUStorageSpaceAssignment = {};
      let newBatch: Batch | undefined;
      let selectedLocations: Array<string> = [];
      if (selectedEntries.length > 0) {
        if (isSelectedBatchEntries(selectedEntries)) {
          // parent batch from selected location
          newBatch = batch.find(b => b._id.toString() === selectedEntries[0].parentId);
          if (newBatch) {
            // all locations from selection
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedBatchEntryType.LOCATION)
              .map(sE => sE.childId!);
          }
        } else if (isSelectedCommodityEntries(selectedEntries)) {
          const batchLocationEntry = selectedEntries.find(e => e.type === SelectedCommodityEntryType.BATCH_LOCATION);
          newBatch = batchLocationEntry ? batch.find(b => b._id.toString() === batchLocationEntry.batchId) : undefined;
          if (newBatch) {
            selectedLocations = selectedEntries
              .filter(sE => sE.type === SelectedCommodityEntryType.BATCH_LOCATION)
              .map(sE => sE.locationId!);
          }
        }
        if (newBatch) {
          relevantLocations = newBatch.locations.filter(
            l =>
              l.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED &&
              selectedLocations.includes(l._id.toString())
          );
          if (relevantLocations.length > 0) {
            // start on first location
            currentLocation = relevantLocations[0];
            if (currentLocation) {
              initialStorageSpaceAssignment = getUpdatedStorageSpaceAssignment(currentLocation);
            }
          }
        }
      }
      setState(prevState => {
        return {
          ...prevState,
          batchEntry: newBatch,
          relevantLocations,
          currentLocation,
          initialStorageSpaceAssignment,
          pUStorageSpaceAssignment: initialStorageSpaceAssignment
        };
      });
    }
  }, [show, batch, selectedEntries]);

  const currentLocationReservations = useMemo(() => {
    const { batchEntry, currentLocation } = state;
    if (!batchEntry || !currentLocation) return undefined;
    return reservation.filter(
      r =>
        r.state === ReservationState.OPEN &&
        r.batches.some(
          b =>
            b.batch._id.toString() === batchEntry._id.toString() &&
            compareBatchLocations(b.location, currentLocation.location)
        )
    );
  }, [state.batchEntry, reservation, state.currentLocation]);

  const locationStatus = useMemo(() => {
    const { batchEntry, currentLocation } = state;
    if (!batchEntry || !currentLocation) return undefined;
    return getBatchLocationStatus(batchEntry._id.toString(), currentLocation, reservation);
  }, [state.batchEntry, reservation, state.currentLocation]);

  const warehouses = useMemo(
    () => (configuration ? configuration.values.warehouseStructure : undefined),
    [configuration]
  );

  const currentLogicalWarehouse = useMemo(() => {
    const { currentLocation } = state;
    return warehouses && currentLocation
      ? warehouses.find(
          logicalWarehouse =>
            logicalWarehouse._id.toString() === currentLocation.location.warehouseSnapshot._id.toString()
        )
      : undefined;
  }, [warehouses, state.currentLocation]);

  const lastPage = useMemo(() => {
    const { relevantLocations, view } = state;
    return relevantLocations === undefined || relevantLocations.length <= 1 || relevantLocations.length - 1 === view;
  }, [state.relevantLocations, state.view]);

  const savingDisabled = useMemo(
    () => locationStatus === undefined || locationStatus === BatchStatus.PARTIALLY_RESERVED || state.saving,
    [locationStatus, state.saving]
  );

  const storageSpaceSelection = useMemo(
    () => getStorageSpaceSelectOptions(currentLogicalWarehouse),
    [currentLogicalWarehouse]
  );

  const globalErrors = useMemo(() => {
    const { currentLocation, pUStorageSpaceAssignment } = state;
    const errors = new Set<string>();
    if (!currentLocation) errors.add(i18n.t("warehouse:storageSpaceAssignmentError"));
    if (currentLocation) {
      for (let i = 0; i < currentLocation.packagingUnits.length; i++) {
        const pUId = currentLocation.packagingUnits[i]._id.toString();
        const pUQuantity = currentLocation.packagingUnits[i].quantity;
        const storageSpaceAssignmentEntry = pUStorageSpaceAssignment[pUId];
        if (storageSpaceAssignmentEntry) {
          const totalAmount = storageSpaceAssignmentEntry.reduce((acc, currentEntry) => acc + currentEntry.quantity, 0);
          if (totalAmount !== pUQuantity) {
            errors.add(i18n.t("warehouse:storageSpaceAssignmentQuantityError"));
          }
          const duplicateLocations = hasDuplicateLocations(storageSpaceAssignmentEntry);
          if (duplicateLocations) errors.add(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
        }
      }
      const oneLocation = hasOneLocation(pUStorageSpaceAssignment);
      if (locationStatus === BatchStatus.RESERVED && !oneLocation)
        errors.add(i18n.t("warehouse:storageSpaceAssignmentDestinationError"));
    }
    return Array.from(errors);
  }, [state.currentLocation, state.pUStorageSpaceAssignment]);

  const handleClose = useCallback(() => {
    setState(getDefaultState());
    onHide();
  }, []);

  const handleLocationChange = useCallback(
    (direction: ViewAction) => {
      setState(prevState => {
        const newView = direction === ViewAction.NEXT ? prevState.view + 1 : prevState.view - 1;
        const newLocation = state.relevantLocations.length > 0 ? state.relevantLocations[newView] : undefined;
        const newAssignment = getUpdatedStorageSpaceAssignment(newLocation);
        return {
          ...prevState,
          view: newView,
          currentLocation: newLocation,
          pUStorageSpaceAssignment: newAssignment,
          initialStorageSpaceAssignment: newAssignment
        };
      });
    },
    [state.relevantLocations]
  );

  const handleAddRow = useCallback(
    (packagingUnitId: string) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      rows[packagingUnitId].push({ quantity: 0, location: undefined, locationType: LocationType.STORAGESPACE });
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handleDeleteRow = useCallback(
    (packagingUnitId: string, index: number) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      rows[packagingUnitId].splice(index, 1);
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handleChangeAmount = useCallback(
    (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      const value = getNumericValue(e);
      const entry = rows[packagingUnitId][index];
      entry.quantity = Number(value);
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handeChangeLocation = useCallback(
    (packagingUnitId: string, index: number, value?: SelectOption<LocationType>) => {
      const rows = _.cloneDeep(state.pUStorageSpaceAssignment);
      const entry = rows[packagingUnitId][index];
      entry.location = value ? value.value : undefined;
      entry.locationType = value ? value.data : LocationType.STORAGESPACE;
      setState(prevState => ({
        ...prevState,
        pUStorageSpaceAssignment: rows
      }));
    },
    [state.pUStorageSpaceAssignment]
  );

  const handleSave = useCallback(async () => {
    const { batchEntry, currentLocation, pUStorageSpaceAssignment, initialStorageSpaceAssignment } = state;
    if (!batchEntry || !currentLocation) return;
    setState(prevState => {
      return { ...prevState, saving: true };
    });
    try {
      const result = await dbService.callFunction(
        "assignStorageSpaces",
        [
          batchEntry._id,
          currentLocation._id,
          pUStorageSpaceAssignment,
          initialStorageSpaceAssignment,
          locationStatus,
          currentLocationReservations
        ],
        true
      );
      if (result) {
        toast.success(i18n.t("warehouse:storageSpaceAssignmentSuccess"));
      } else {
        toast.error(i18n.t("warehouse:storageSpaceAssignmentFailure"));
      }
    } finally {
      setState(prevState => {
        return { ...prevState, saving: false };
      });
      if (lastPage) {
        handleClose();
      }
    }
  }, [state, lastPage, locationStatus, currentLocationReservations]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title as={"h5"}>{i18n.t("warehouse:assignStorageSpace")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          {state.batchEntry && (
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-9 kt-font-bold kt-font-dark">
                  {resolveTranslation(state.batchEntry.content.details.title)}
                </div>
                {state.currentLocation && (
                  <div className="col-3 kt-font-dark">
                    <small className="float-right">{`${i18n.t("common:page")} ${state.view + 1}/${
                      state.relevantLocations?.length
                    }`}</small>
                  </div>
                )}
                <div className="col-12 kt-font-bold kt-font-dark">
                  <small className="kt-font-regular">
                    {resolveTranslation(state.batchEntry.content.details.subtitle)}
                  </small>
                </div>
                <div className="col-12 kt-font-dark">LOT: {state.batchEntry.lot}</div>
              </div>
              {locationStatus === undefined || locationStatus === BatchStatus.PARTIALLY_RESERVED ? (
                <div className="text-muted mt-3">{i18n.t("warehouse:storageSpaceAssignmentError")} </div>
              ) : state.relevantLocations && state.relevantLocations.length > 0 && state.currentLocation ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-2">
                        <li className="breadcrumb-item text-black">
                          {resolveTranslation(state.currentLocation.location.warehouseSnapshot.warehouseName)}
                        </li>
                        <li className="breadcrumb-item text-black">
                          {resolveTranslation(state.currentLocation.location.warehouseArea.warehouseName)}
                        </li>
                        <li className="breadcrumb-item text-danger font-weight-bolder arrow">
                          {state.currentLocation.location.storageSpace?.storageSpaceNo ??
                            i18n.t("warehouse:incomingTab")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {state.currentLocation.packagingUnits.map(pU => {
                    return (
                      <PackagingUnitEntry
                        key={pU._id.toString()}
                        pU={pU}
                        storageSpaceAssignment={state.pUStorageSpaceAssignment[pU._id.toString()]}
                        selectOptions={storageSpaceSelection}
                        onAddRow={handleAddRow}
                        onDeleteRow={handleDeleteRow}
                        onChangeAmount={handleChangeAmount}
                        onChangeLocation={handeChangeLocation}
                      />
                    );
                  })}
                </>
              ) : (
                <div className="text-muted mt-3">{i18n.t("warehouse:storageSpaceAssignmentWarehouseError")}</div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (state.saving ? "disabled" : "")}
          onClick={
            state.saving ? undefined : state.view === 0 ? handleClose : () => handleLocationChange(ViewAction.PREVIOUS)
          }
        >
          {i18n.t(`common:${state.view === 0 ? "close" : "back"}`)}
        </button>
        {!lastPage && (
          <button
            type="button"
            className={"btn btn-secondary " + (state.saving ? "disabled" : "")}
            onClick={state.saving ? undefined : () => handleLocationChange(ViewAction.NEXT)}
          >
            {i18n.t("common:next")}
          </button>
        )}
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className={"btn btn-success"}
          errors={globalErrors}
          disabled={savingDisabled}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AssignStorageSpaceModal;
