import _ from "lodash";
import React, { PureComponent } from "react";
import { Line } from "react-chartjs-2";
import { OrdersDocument } from "../../../model/orders.types";
import dateUtils from "../../../utils/dateUtils";
import orderUtils, { DECLINED } from "../../../utils/orderUtils";
import { T_CAPSULE, T_LIQUID, T_POWDER, T_TABLET } from "../../order/OrderHelper";
import { I_CANCELED } from "../../../utils/invoiceUtils";

interface Period {
  monday: Date;
  sunday: Date;
  objects: Array<{ type: string; value: number }>;
}

const CAPSULE_SERIES = {
  label: "Capsules",
  fill: true,
  backgroundColor: "rgba(0,140,255,0.05)",
  pointBackgroundColor: "#008cff",
  borderColor: "#008cff",
  pointHighlightStroke: "#008cff",
  borderCapStyle: "butt",
  data: [] as Array<number>
};
const POWDER_SERIES = {
  label: "Powder",
  fill: true,
  backgroundColor: "rgba(0,140,255,0.05)",
  pointBackgroundColor: "#008cff",
  borderColor: "#008cff",
  pointHighlightStroke: "#008cff",
  borderCapStyle: "butt",
  data: [] as Array<number>
};
const LIQUID_SERIES = {
  label: "Liquid",
  fill: true,
  backgroundColor: "rgba(0,140,255,0.05)",
  pointBackgroundColor: "#008cff",
  borderColor: "#008cff",
  pointHighlightStroke: "#008cff",
  borderCapStyle: "butt",
  data: [] as Array<number>
};
const TABLET_SERIES = {
  label: "Tablets",
  fill: true,
  backgroundColor: "rgba(0,140,255,0.05)",
  pointBackgroundColor: "#008cff",
  borderColor: "#008cff",
  pointHighlightStroke: "#008cff",
  borderCapStyle: "butt",
  data: [] as Array<number>
};

interface CapacityHistoryProps {
  orders: Array<OrdersDocument>;
  periodCount: number;
}

interface CapacityHistoryState {}

class CapacityHistory extends PureComponent<CapacityHistoryProps, CapacityHistoryState> {
  /**
   * Get initial periods
   * @returns {Array<Period>} list of periods
   */
  getInitialPeriods = () => {
    let periods: Array<Period> = [];
    for (let i = 0; i < this.props.periodCount; i++) {
      const d = new Date(Date.now() - i * 7 * 24 * 60 * 60 * 1000);
      const [monday, sunday] = dateUtils.getMondaySunday(d);
      periods.push({
        monday: monday,
        sunday: sunday,
        objects: []
      });
    }
    return periods;
  };

  /**
   * Prepare data
   * @return {object} object with series for the product types and labels for x axis
   */
  getData = () => {
    const { orders } = this.props;
    let periods: Array<Period> = this.getInitialPeriods();

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (![T_CAPSULE, T_TABLET, T_POWDER, T_TABLET].includes(order.settings.type)) continue;
      if (order.state !== DECLINED && orderUtils.isOrder(order) && order.invoices) {
        for (let j = 0; j < order.invoices.length; j++) {
          const invoice = order.invoices[j];
          const invoiceDate = invoice.invoiceDate.getTime();
          for (let p = 0; p < periods.length; p++) {
            const period = periods[p];
            const mondayTime = period.monday.getTime();
            const sundayTime = period.sunday.getTime();
            if (invoiceDate >= mondayTime && invoiceDate <= sundayTime) {
              let amount = 0;
              for (let k = 0; k < invoice.positions.length; k++) {
                const position = invoice.positions[k];
                if (position.type === "position" && Math.abs(+position.amount) > 0) {
                  if (invoice.state !== I_CANCELED) amount += +position.amount;
                }
              }
              period.objects.push({
                type: order.settings.type,
                value: amount
              });
            }
          }
        }
      }
    }

    periods = periods.reverse();
    const capsuleSeries = _.cloneDeep(CAPSULE_SERIES);
    const tabletSeries = _.cloneDeep(TABLET_SERIES);
    const powderSeries = _.cloneDeep(POWDER_SERIES);
    const liquidSeries = _.cloneDeep(LIQUID_SERIES);
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      let capsuleCount = 0;
      let tabletCount = 0;
      let powderCount = 0;
      let liquidCount = 0;
      for (let j = 0; j < period.objects.length; j++) {
        const obj = period.objects[j];
        if (obj.type === T_CAPSULE) capsuleCount += obj.value;
        else if (obj.type === T_TABLET) tabletCount += obj.value;
        else if (obj.type === T_LIQUID) liquidCount += obj.value;
        else if (obj.type === T_POWDER) powderCount += obj.value;
      }
      capsuleSeries.data.push(capsuleCount);
      tabletSeries.data.push(tabletCount);
      powderSeries.data.push(powderCount);
      liquidSeries.data.push(liquidCount);
    }

    const labels = periods.map(p => "KW" + dateUtils.getCW(p.monday));

    return { capsuleSeries, tabletSeries, powderSeries, liquidSeries, labels };
  };

  render() {
    const { capsuleSeries, tabletSeries, powderSeries, liquidSeries, labels } = this.getData();
    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title kt-font-bolder">Output History</h3>
          </div>
        </div>
        <div className="kt-portlet__body" style={{ paddingTop: "10px", margin: "100px!important" }}>
          <div className="kt_stats_widget_11_chart apexcharts-theme-light">
            {capsuleSeries && tabletSeries && powderSeries && liquidSeries && (
              <Line
                data={{
                  labels: labels,
                  datasets: [capsuleSeries, tabletSeries, powderSeries, liquidSeries]
                }}
                options={{
                  responsive: true,
                  scales: { yAxes: [{ stacked: true }] },
                  animation: { duration: 750 }
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CapacityHistory;
