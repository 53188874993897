import React, { PureComponent } from "react";
import { LocalPackagingStock } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import { PackagingsDocument } from "../../../model/packagings.types";
import PackagingLocalStock from "../PackagingLocalStock";
import manufacturerUtils from "../../../utils/manufacturerUtils";

interface PackagingStockProps {
  packaging: PackagingsDocument;
  context: React.ContextType<typeof DataContext>;
}

interface PackagingStockState {
  manufacturer: string;
  manufacturerLocked: boolean;
  stock: Array<LocalPackagingStock>;
  showDisabledStock: boolean;
}

class PackagingStock extends PureComponent<PackagingStockProps, PackagingStockState> {
  constructor(props: PackagingStockProps) {
    super(props);
    const userManufacturer = manufacturerUtils.checkCurrentUserManufacturerObject(this.props.context.manufacturers);
    const revi = props.context.manufacturers.find(m => m._id.toString() === "5ef4fd72a4cf4fbc3202f609");
    this.state = {
      manufacturer:
        userManufacturer !== ""
          ? userManufacturer.value
          : revi
          ? revi._id.toString()
          : props.context.manufacturers[0]._id.toString(),
      manufacturerLocked: userManufacturer !== "",
      stock: [],
      showDisabledStock: false
    };
  }

  componentDidMount() {
    this.calculateGlobalStock();
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingStockProps>,
    prevState: Readonly<PackagingStockState>,
    snapshot?: any
  ) {
    if (prevProps !== this.props) this.calculateGlobalStock();
  }

  /**
   * Handles the change of the selected manufacturer.
   * @param manufacturer: ID of the manufacturer as string
   */
  handleManufacturerChange = (manufacturer: string) => {
    this.setState({ manufacturer });
  };

  /**
   * Calculates the global stock values for all stocks and every single manufacturer.
   */
  calculateGlobalStock = () => {
    const { context, packaging } = this.props;
    const { manufacturers, packagingStock } = context;
    const stocks = [];
    const stockForPackaging = packagingStock.filter(ps => ps.packaging.toString() === packaging._id.toString());
    for (let i = 0; i < manufacturers.length; i++) {
      const m = manufacturers[i];
      const stock = [];
      for (let j = 0; j < stockForPackaging.length; j++) {
        const b = stockForPackaging[j];
        if (m._id.toString() === b.location.toString()) {
          stock.push(b);
        }
      }
      stocks.push({ manufacturer: m, stock: stock });
    }
    this.setState({ stock: stocks });
  };

  render() {
    const { context, packaging } = this.props;
    const { manufacturer, stock, showDisabledStock, manufacturerLocked } = this.state;
    const localStock = stock.find(s => s.manufacturer._id.toString() === manufacturer);
    return (
      <>
        <div className="my-4 px-2 pt-2 font-weight-bolder text-dark row">
          <div className="col-6 align-self-center">
            <div className="kt-widget__label">
              <div className="h4 text-dark" style={{ color: "rgb(72, 70, 91)" }}>
                {context.manufacturers.find(m => m._id.toString() === manufacturer)?.name}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-auto my-auto">
                <label className="kt-checkbox kt-font-dark m-0 pl-4">
                  <input
                    type="checkbox"
                    onChange={() => this.setState({ showDisabledStock: !showDisabledStock })}
                    checked={showDisabledStock}
                  />
                  <span />
                  <div className="pl-1 kt-font-dark">Show disabled stock</div>
                </label>
              </div>
              <div className="col">
                <select
                  value={manufacturer}
                  className="form-control"
                  disabled={manufacturerLocked}
                  onChange={manufacturerLocked ? undefined : e => this.handleManufacturerChange(e.target.value)}
                >
                  {context.manufacturers.map(m => (
                    <option key={m._id.toString()} value={m._id.toString()}>
                      {m.name +
                        " - " +
                        (stock
                          ?.find(s => s.manufacturer._id.toString() === m._id.toString())
                          ?.stock.reduce(
                            (sum, b) => sum + (b && "amount" in b && b.amount > 0 && !b.disabled ? b.amount : 0),
                            0
                          ) || 0) +
                        " pcs."}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {localStock && (
          <PackagingLocalStock
            localStock={localStock}
            packaging={packaging}
            context={context}
            showDisabledStock={showDisabledStock}
          />
        )}
      </>
    );
  }
}

export default PackagingStock;
