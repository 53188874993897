import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { useDataContext } from "../../../../context/dataContext";
import { ContentType } from "../../../../model/warehouse/common.types";
import { SenderType } from "../../../../model/warehouse/batch.types";
import { OrdersDocument } from "../../../../model/orders.types";
import {
  BatchLocationReservationInformation,
  CommodityInformation
} from "../../../../model/warehouse/customTypes.types";
import { BatchStatus, getBatchStatus } from "../../../../utils/batchUtils";
import BaseListing from "../../../listings/BaseListing";
import { paginate } from "../../../common/Pagination";
import ReservationRow from "./ReservationRow";

interface OrderReservationBatchSelectionProps {
  edit: boolean;
  selectedOrder: OrdersDocument;
  selectedCommodity?: CommodityInformation;
  orderReservations: Array<BatchLocationReservationInformation>;
  onChangeReservationAmount: (
    batchId: string,
    locationId: string,
    commodityId: string,
    amount: number,
    amountValid: boolean
  ) => void;
}

const OrderReservationBatchSelection: React.FC<OrderReservationBatchSelectionProps> = ({
  edit,
  selectedOrder,
  selectedCommodity,
  orderReservations,
  onChangeReservationAmount
}) => {
  const dataContext = useDataContext();
  const { batch, reservation } = dataContext;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  const tableHeader = [
    { title: i18n.t("warehouse:supplier") },
    { title: i18n.t("warehouse:batch") },
    { title: i18n.t("warehouse:bbd") },
    { title: i18n.t("warehouse:amount") },
    { title: i18n.t("warehouse:warehouse") }
  ];

  const filteredBatches = useMemo(() => {
    if (edit) {
      if (!selectedCommodity) return [];
      const orderCreatedFor = selectedOrder.createdFor.toString();

      return batch.filter(b => {
        const batchSender = b.sender.senderId;
        const batchSenderType = b.sender.type;
        const batchStatus = getBatchStatus(b, reservation);

        const batchMatchesCommodity =
          b.content.type === ContentType.COMMODITY &&
          b.content.details._id.toString() === selectedCommodity.commodityId;
        const batchHasCorrectSender =
          batchSenderType !== SenderType.CUSTOMER ||
          (batchSenderType === SenderType.CUSTOMER && batchSender === orderCreatedFor); // Exclude Kundenbeistellungen from other customers
        const batchHasReservableAmount = batchStatus !== BatchStatus.RESERVED;

        return batchMatchesCommodity && batchHasCorrectSender && batchHasReservableAmount;
      });
    } else {
      const relevantBatches = new Set(orderReservations.map(r => r.batchId));
      return batch.filter(b => relevantBatches.has(b._id.toString()));
    }
  }, [edit, batch, selectedOrder, selectedCommodity, reservation, orderReservations]);

  return (
    <>
      <h4 className="font-weight-bold text-black">{i18n.t(`warehouse:${edit ? "selectBatch" : "batchUpdates"}`)}</h4>
      {filteredBatches.length > 0 ? (
        <div className="kt-portlet__body px-0 pt-0">
          <BaseListing
            headerDefinition={tableHeader}
            documents={filteredBatches}
            bodyContent={
              <>
                {paginate(filteredBatches, currentPage, pageSize).map(batch => (
                  <ReservationRow
                    key={batch._id.toString()}
                    edit={edit}
                    batch={batch}
                    batchReservations={orderReservations.filter(r => r.batchId === batch._id.toString())}
                    onChangeReservationAmount={onChangeReservationAmount}
                  />
                ))}
              </>
            }
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
          />
        </div>
      ) : (
        <div className="text-black">{i18n.t("warehouse:reservationNoBatchesFoundError")}</div>
      )}
    </>
  );
};

export default OrderReservationBatchSelection;
