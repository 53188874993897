import _ from "lodash";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { CommodityBatch, CommoditiesDocument, CommodityPrice } from "../../../../model/commodities.types";
import { CustomOrder, ExtendedCommoditySupplier } from "../../CustomTypes";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import { calculation, pricingCommodities } from "../../../../model/orders.types";
import OrderHelper, { T_CUSTOM, T_SERVICE, T_SOFTGEL } from "../../OrderHelper";
import calculationUtils from "../../../../utils/calculationUtils";
import orderUtils from "../../../../utils/orderUtils";
import commodityUtils, { CUSTOMER } from "../../../../utils/commodityUtils";
import { INCOTERMS } from "../../../../utils/suppliersUtils";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";
import UpdatePricesModal from "../../modals/UpdatePricesModal";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import { DataContext } from "../../../../context/dataContext";

interface OrderCommoditiesCheckProps {
  order: CustomOrder;
  commodities: Array<CommoditiesDocument>;
  suppliers: Array<SuppliersDocument>;
  calculations: Array<calculation>;
  onFlagChange: (type: "requested" | "updated", commodityId: BSON.ObjectId | string) => void;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderCommoditiesCheckState {
  commodityToUpdate?: CommoditiesDocument;
}

class OrderCommoditiesCheck extends PureComponent<OrderCommoditiesCheckProps, OrderCommoditiesCheckState> {
  constructor(props: OrderCommoditiesCheckProps) {
    super(props);
    this.state = {};
  }

  handleUpdatePrices = (commodity: CommoditiesDocument) => this.setState({ commodityToUpdate: commodity });
  handleCloseUpdatePrices = () => this.setState({ commodityToUpdate: undefined });

  render() {
    const { context, order, commodities, suppliers, calculations, onFlagChange, onCalculationChange } = this.props;
    const { commodityToUpdate } = this.state;
    const canCheck = accessUtils.canPerformAction(ACTIONS.ORDERCOMMODITYCHECK);

    return (
      <>
        {commodityToUpdate && (
          <UpdatePricesModal type={"commodity"} document={commodityToUpdate} onClose={this.handleCloseUpdatePrices} />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
          <table className="kt-datatable__table d-table ">
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Amount</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "52%" }}>
                  <span>Commodity</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Requested</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Updated</span>
                </th>
                <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "18%" }}>
                  <span>Prices</span>
                </th>
              </tr>
            </thead>
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {order.calculations[0].prices.map(price => {
                const commodity = commodities.find(c => c._id.toString() === price._id.toString());
                if (!commodity) return null;
                return (
                  <OrderCommodityCheckItem
                    key={price._id.toString()}
                    order={order}
                    price={price}
                    commodity={commodity}
                    suppliers={suppliers}
                    calculations={calculations}
                    canCheck={canCheck}
                    onFlagChange={onFlagChange}
                    onCalculationChange={onCalculationChange}
                    onUpdatePrices={() => this.handleUpdatePrices(commodity)}
                    context={context}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

interface OrderCommodityCheckItemProps {
  order: CustomOrder;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  suppliers: Array<SuppliersDocument>;
  calculations: Array<calculation>;
  canCheck: boolean;
  onFlagChange: (type: "requested" | "updated", commodityId: BSON.ObjectId | string) => void;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  onUpdatePrices: () => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderCommodityCheckItemState {
  showDetails: boolean;
}

class OrderCommodityCheckItem extends React.Component<OrderCommodityCheckItemProps, OrderCommodityCheckItemState> {
  constructor(props: OrderCommodityCheckItemProps) {
    super(props);
    this.state = {
      showDetails: true
    };
  }

  shouldComponentUpdate = (
    nextProps: Readonly<OrderCommodityCheckItemProps>,
    nextState: Readonly<OrderCommodityCheckItemState>
  ): boolean => {
    const { order, price, commodity, suppliers, calculations, context } = this.props;
    const { showDetails } = this.state;

    return (
      !_.isEqual(showDetails, nextState.showDetails) ||
      !_.isEqual(order, nextProps.order) ||
      !_.isEqual(price, nextProps.price) ||
      !_.isEqual(commodity, nextProps.commodity) ||
      !_.isEqual(suppliers, nextProps.suppliers) ||
      calculations.some(c => {
        const nextCalc = nextProps.calculations.find(c2 => c2.id === c.id);
        if (!nextCalc) return true;
        return !_.isEqual(
          c.prices.find(p => p._id.toString() === commodity._id.toString()),
          nextCalc.prices.find(p => p._id.toString() === commodity._id.toString())
        );
      }) ||
      !_.isEqual(context.orders, nextProps.context.orders)
    );
  };

  handleToggleDetails = () => this.setState({ showDetails: !this.state.showDetails });

  render() {
    const {
      context,
      order,
      price,
      commodity,
      suppliers,
      calculations,
      canCheck,
      onFlagChange,
      onCalculationChange,
      onUpdatePrices
    } = this.props;
    const { showDetails } = this.state;
    const type = order.settings.type;
    const requested = calculations.some(c => {
      const price = c.prices.find(p => p._id.toString() === commodity._id.toString());
      return !!price && !!price.requested;
    });
    const updated = calculations.some(c => {
      const price = c.prices.find(p => p._id.toString() === commodity._id.toString());
      return !!price && !!price.updated;
    });
    const dateLimit = new Date(); // Set half year as limit
    dateLimit.setMonth(dateLimit.getMonth() + 6);
    const warehouse = context.general.find(g => g.data === "defaultWarehouse")?.value;
    const warehouseStock: Array<CommodityBatch> = [];
    const manufacturerStock: Array<CommodityBatch> = [];
    const expiredBatches: Array<string> = [];
    for (let i = 0; i < commodity.stock.length; i++) {
      const s = commodity.stock[i];
      if (s.disabled || s.amount === 0 || !s.location || !order.settings.manufacturer._id || s.supplier === CUSTOMER) {
        continue;
      }
      if (s.expiry < dateLimit && s.location.toString() === order.settings.manufacturer._id.toString()) {
        expiredBatches.push(s.lot.toString());
        continue;
      }

      if (s.location.toString() === order.settings.manufacturer._id.toString()) {
        manufacturerStock.push(s);
      } else if (s.location.toString() === warehouse && order.settings.manufacturer._id.toString() !== warehouse) {
        // do not add stock twice if manufacturer = warehouse
        warehouseStock.push(s);
      }
    }
    const usage = commodityUtils.getCommodityUsage(
      context.orders,
      commodity,
      order.settings.manufacturer._id,
      expiredBatches
    );
    const availableStock =
      manufacturerStock && usage
        ? manufacturerStock.reduce((a, b) => a + b.amount, 0) + usage.orderedAmount - usage.requiredAmount
        : 0;
    const availableWarehouseStock = warehouseStock.reduce((sum, s) => sum + s.amount, 0);

    return (
      <>
        <tr className="kt-datatable__row d-table-row material-row">
          <td className=" kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>
              {[T_CUSTOM, T_SERVICE, T_SOFTGEL].includes(type)
                ? price.amount + " pcs."
                : calculationUtils.formatAmount(price.amount, 2)}
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <Link className="kt-user-card-v2__name kt-link" to={"/commodity/" + commodity._id.toString()}>
                  {commodity.title.en}
                  {commodity.article_number && <span className="text-success"> {commodity.article_number}</span>}
                </Link>
                <span className="kt-user-card-v2__email">{commodity.subtitle.en}</span>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>
              <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
                <input
                  type="checkbox"
                  onChange={canCheck ? () => onFlagChange("requested", commodity._id) : undefined}
                  checked={requested}
                  disabled={!canCheck}
                />
                <span />
              </label>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>
              <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
                <input
                  type="checkbox"
                  onChange={canCheck ? () => onFlagChange("updated", commodity._id) : undefined}
                  checked={updated}
                  disabled={!canCheck}
                />
                <span />
              </label>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell text-right">
            {canCheck && (
              <span className="pointer text-hover-black" onClick={onUpdatePrices}>
                <u>Update Prices</u>
              </span>
            )}
          </td>
        </tr>
        <tr className={showDetails ? "kt-datatable__row d-table-row " : "d-none"}>
          <td colSpan={5}>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
              style={{ boxShadow: "none" }}
            >
              <table className="kt-datatable__table d-table">
                <tbody
                  className="kt-datatable__body "
                  style={{ backgroundColor: "#fafafa", display: "table-row-group" }}
                >
                  {calculations.map(c => (
                    <OrderCommodityCheckItemDetails
                      key={c.id.toString()}
                      calculation={c}
                      order={order}
                      price={price}
                      commodity={commodity}
                      suppliers={suppliers}
                      availableStock={availableStock}
                      availableWarehouseStock={availableWarehouseStock}
                      canCheck={canCheck}
                      onCalculationChange={onCalculationChange}
                      context={context}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

interface OrderCommodityCheckItemDetailsProps {
  calculation: calculation;
  order: CustomOrder;
  price: pricingCommodities;
  commodity: CommoditiesDocument;
  suppliers: Array<SuppliersDocument>;
  availableStock: number;
  availableWarehouseStock: number;
  canCheck: boolean;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderCommodityCheckItemDetailsState {
  calculation: calculation;
  extendedSuppliers: Array<ExtendedCommoditySupplier>;
}

class OrderCommodityCheckItemDetails extends React.Component<
  OrderCommodityCheckItemDetailsProps,
  OrderCommodityCheckItemDetailsState
> {
  constructor(props: OrderCommodityCheckItemDetailsProps) {
    super(props);
    this.state = {
      calculation: _.cloneDeep(props.calculation),
      extendedSuppliers: this.getExtendedSuppliers(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<OrderCommodityCheckItemDetailsProps>,
    prevState: Readonly<OrderCommodityCheckItemDetailsState>,
    snapshot?: any
  ) {
    if (prevProps.calculation !== this.props.calculation) {
      this.setState({ calculation: _.cloneDeep(this.props.calculation) });
    }
    if (
      !_.isEqual(prevProps.commodity, this.props.commodity) ||
      !_.isEqual(prevProps.suppliers, this.props.suppliers)
    ) {
      this.setState({ extendedSuppliers: this.getExtendedSuppliers(this.props) });
    }
  }

  shouldComponentUpdate = (
    nextProps: Readonly<OrderCommodityCheckItemDetailsProps>,
    nextState: Readonly<OrderCommodityCheckItemDetailsState>
  ): boolean => {
    const { order, price, commodity, suppliers, calculation, availableStock } = this.props;
    const { calculation: cState, extendedSuppliers } = this.state;
    return (
      availableStock !== nextProps.availableStock ||
      !_.isEqual(price, nextProps.price) ||
      !_.isEqual(cState, nextState.calculation) ||
      !_.isEqual(calculation, nextProps.calculation) ||
      !_.isEqual(order, nextProps.order) ||
      !_.isEqual(commodity, nextProps.commodity) ||
      !_.isEqual(suppliers, nextProps.suppliers) ||
      !_.isEqual(extendedSuppliers, nextState.extendedSuppliers)
    );
  };

  /**
   * Get extended suppliers
   * @param props the components properties
   * @returns {ExtendedCommoditySupplier} supplier document extended with price information for the commodity
   */
  getExtendedSuppliers = (props: OrderCommodityCheckItemDetailsProps) => {
    const { commodity, suppliers } = props;
    return commodity.suppliers.map(s => {
      const supplier = suppliers.find(s2 => s2._id.toString() === s._id.toString());
      return {
        _id: s._id,
        prices: s.prices,
        ...supplier
      } as ExtendedCommoditySupplier;
    });
  };

  handleCalculationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const tmpPrice = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const key = e.target.name as keyof pricingCommodities;
    let value = e.target.value;
    if (["orderquantity"].includes(key)) {
      // handle in-component state updates that are propagated only on blur, no recalculation
      value = value.replaceAll(/^0+/g, "0");
      if (!value.includes(".")) value = Number(value).toString();
      // @ts-ignore
      // Warning: Here we have to use string temporary, this HAS to be converted later in case of numbers
      tmpPrice[key] = value;
      this.setState({ calculation });
    } else {
      // this path is currently only for deliverytime in case of supplier === customer! no recalculation required here
      // @ts-ignore
      tmpPrice[key] = +e.target.value;
      onCalculationChange(calculation, price._id);
    }
  };

  handleSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, commodity, price, context, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const id = e.currentTarget.value;
    const supplierId = BSON.ObjectId.isValid(id) ? new BSON.ObjectId(id) : id;
    const warehouseId = context.general.find(g => g.data === "defaultWarehouse")?.value;
    const warehouse = context.manufacturers.find(m => m._id.toString() === warehouseId);
    const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
      commodity,
      order.settings.manufacturer,
      quantity,
      supplierId,
      undefined,
      undefined,
      warehouse
    )!;
    this.updatePriceObject(priceObject, commodityPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleIncotermChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, commodity, price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const targetIncoterm = e.currentTarget.value;
    const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
      commodity,
      order.settings.manufacturer,
      quantity,
      priceObject.supplier,
      targetIncoterm
    );
    if (commodityPrice.price.incoterm !== targetIncoterm) {
      toast.error("No matching price could be found for incoterm: " + targetIncoterm);
      return;
    }
    this.updatePriceObject(priceObject, commodityPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleDeliveryTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, commodity, price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const targetDeliveryTime = +e.currentTarget.value;
    const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
      commodity,
      order.settings.manufacturer,
      quantity,
      priceObject.supplier,
      priceObject.incoterm,
      targetDeliveryTime
    );
    if (commodityPrice.price.deliverytime !== targetDeliveryTime) {
      toast.error("No matching price could be found for the selected delivery time: " + targetDeliveryTime);
      return;
    }
    this.updatePriceObject(priceObject, commodityPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleOrderQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { order, commodity, price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    let value = e.target.value;
    value = value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    const valueNum = +value;
    const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
      commodity,
      order.settings.manufacturer,
      valueNum,
      price.supplier
    )!;
    this.updatePriceObject(priceObject, commodityPrice, valueNum);
    onCalculationChange(calculation, price._id);
  };

  /**
   * Handle selection of delivery time. The action depends on the selected supplier
   * @param e The react change event
   * @param priceObject the price object containing supplier information
   */
  handleDeliveryTimeSelect = (e: React.ChangeEvent<HTMLSelectElement>, priceObject: pricingCommodities) => {
    if (typeof priceObject.supplier === "string") {
      if (priceObject.supplier === "customer") this.handleCalculationChange(e);
    } else {
      this.handleDeliveryTimeChange(e);
    }
  };

  handleAutoSelect = () => {
    const { order, commodity, price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const commodityPrice = orderCalculationUtils.getMatchingCommodityPrice(
      commodity,
      order.settings.manufacturer,
      quantity
    )!;
    this.updatePriceObject(priceObject, commodityPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  /**
   * Updates the passed price object with new price data
   * @param priceObject object of a calculation price
   * @param price object with new commodity price
   * @param orderQuantity the total order quantity
   * @param auto optional auto flag
   */
  updatePriceObject = (
    priceObject: pricingCommodities,
    price: { _id: BSON.ObjectID | "customer" | "ownstock"; price: CommodityPrice },
    orderQuantity: number,
    auto?: boolean
  ) => {
    const newPrice = price.price;
    const newTotalPrice = orderQuantity * newPrice.price;
    priceObject.supplier = price._id;
    priceObject.price = newPrice.price;
    priceObject.totalprice = newTotalPrice;
    priceObject.orderquantity = orderQuantity;
    priceObject.incoterm = newPrice.incoterm;
    priceObject.delivery = newPrice.delivery;
    priceObject.deliverytime = newPrice.deliverytime;
    priceObject.purchasePrice = newPrice.purchasePrice;
    priceObject.purchaseCurrency = newPrice.purchaseCurrency;
    priceObject.auto = !!auto;
  };

  render() {
    const { order, price, commodity, suppliers, availableStock, canCheck, availableWarehouseStock } = this.props;
    const { calculation, extendedSuppliers } = this.state;
    const type = order.settings.type;
    const totalAmount = orderUtils.getTotalAmountWithBuffer(
      +order.settings.perUnit,
      +calculation.units,
      price.amount,
      price.buffer,
      type
    );

    const priceObject = calculation.prices.find(p => p._id.toString() === price._id.toString())!;
    const priceDifference = (+priceObject.price / +priceObject.estimatedprice - 1) * 100;
    const selectedSupplier = suppliers.find(s => s._id.toString() === priceObject.supplier.toString());
    let incotermList: Array<string> = [];
    let availableIncoterms = [...INCOTERMS];
    const deliveryTimesByIncoterm: any = {};
    if (selectedSupplier && commodity.suppliers) {
      const moq = orderCalculationUtils.getMatchingMOQ(commodity, selectedSupplier._id, +priceObject.orderquantity!);
      const selectedSupplierPrices = commodity.suppliers.find(
        s => s._id.toString() === selectedSupplier._id.toString()
      );
      if (selectedSupplierPrices) {
        selectedSupplierPrices.prices.forEach(p => {
          if (moq >= p.moq) {
            if (!incotermList.includes(p.incoterm)) {
              deliveryTimesByIncoterm[p.incoterm] = [p.deliverytime];
              if (p.incoterm.trim() !== "") incotermList.push(p.incoterm);
            } else if (!deliveryTimesByIncoterm[p.incoterm].includes(p.deliverytime)) {
              deliveryTimesByIncoterm[p.incoterm].push(p.deliverytime);
            }
          }
          availableIncoterms = incotermList;
        });
      }
    }

    const deliveryTimesForIncoterm =
      !_.isEmpty(deliveryTimesByIncoterm) && deliveryTimesByIncoterm[priceObject.incoterm]
        ? deliveryTimesByIncoterm[priceObject.incoterm]
        : [];
    const unit = OrderHelper.getQuantityUnitForType(type);
    const currentSupplierNotExists = extendedSuppliers.some(s => s._id.toString() === priceObject.supplier.toString());
    const invalidMOQ =
      selectedSupplier &&
      orderCalculationUtils.getMatchingMOQ(commodity, selectedSupplier._id, +priceObject.orderquantity!) >
        priceObject.orderquantity!;

    return (
      <tr className="kt-datatable__row d-table-row">
        <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
          <span>{calculation.units + " units"}</span>
        </td>
        <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
          <span className="kt-font-bold ">{OrderHelper.getFormattedAmount(totalAmount, type)}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "14%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <div className="form-group " style={{ marginBottom: "0" }}>
              <div className="input-group input-group-sm">
                <input
                  type="number"
                  className="form-control"
                  name="orderquantity"
                  min={1}
                  value={priceObject.orderquantity!.toString()}
                  disabled={!canCheck}
                  onChange={canCheck ? this.handleCalculationChange : undefined}
                  onBlur={canCheck ? this.handleOrderQuantityBlur : undefined}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{unit}</span>
                </div>
              </div>
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "22%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              className="form-control"
              style={invalidMOQ ? { border: "1px solid red" } : {}}
              value={priceObject.supplier.toString()}
              onChange={canCheck ? this.handleSupplierChange : undefined}
              disabled={!canCheck}
            >
              <option
                key="ownstock"
                value="ownstock"
                disabled={priceObject.orderquantity! > availableStock + availableWarehouseStock}
              >
                Stock {OrderHelper.formatAmount(availableStock, type)}
                {availableWarehouseStock > 0 &&
                  " (" + OrderHelper.formatAmount(availableWarehouseStock, type) + " in Warehouse)"}
              </option>
              <option key="customer" value="customer">
                Customer
              </option>
              {!currentSupplierNotExists && selectedSupplier && (
                <option key={selectedSupplier._id.toString()} value={selectedSupplier._id.toString()}>
                  {selectedSupplier.name}
                </option>
              )}
              {extendedSuppliers.map(supp => {
                const matchingMOQ = orderCalculationUtils.getMatchingMOQ(
                  commodity,
                  supp._id,
                  +priceObject.orderquantity!
                );
                return (
                  <option
                    key={supp._id.toString()}
                    value={supp._id.toString()}
                    //disabled if we dont have a matching moq
                    disabled={priceObject.orderquantity! < matchingMOQ}
                  >
                    {supp.name + ` MOQ ${OrderHelper.formatAmount(matchingMOQ, type)}`}
                  </option>
                );
              })}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              disabled={
                availableIncoterms.length === 0 ||
                (typeof priceObject.supplier === "string" && ["ownstock", "customer"].includes(priceObject.supplier)) ||
                !currentSupplierNotExists ||
                invalidMOQ ||
                !canCheck
              }
              className="form-control"
              value={priceObject.incoterm}
              onChange={canCheck ? this.handleIncotermChange : undefined}
            >
              <option disabled key={""} value={""}>
                not set
              </option>
              {availableIncoterms.map(incoterm => (
                <option key={incoterm} value={incoterm}>
                  {incoterm}
                </option>
              ))}
              {!availableIncoterms.includes(priceObject.incoterm) &&
                priceObject.incoterm.trim() !== "" &&
                priceObject.supplier !== "customer" &&
                priceObject.supplier !== "ownstock" && (
                  // Add current incoterm if it isnt included anymore
                  <option key={priceObject.incoterm} value={priceObject.incoterm}>
                    {priceObject.incoterm}
                  </option>
                )}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              className="form-control input-number-arrows-low-pr"
              disabled={
                (deliveryTimesForIncoterm.length < 2 && priceObject.supplier !== "customer") ||
                !currentSupplierNotExists ||
                invalidMOQ ||
                !canCheck
              }
              value={priceObject.deliverytime}
              name={"deliverytime"}
              onChange={e => (canCheck ? this.handleDeliveryTimeSelect(e, priceObject) : undefined)}
            >
              {priceObject.supplier === "ownstock" && (
                <option key={"stock"} value={"stock"}>
                  On Stock
                </option>
              )}
              {priceObject.supplier === "customer" && (
                <>
                  <option value={"0"}>Immediately</option>
                  <option value={"7"}>In 7 days</option>
                  <option value={"10"}>In 10 days</option>
                  <option value={"14"}>In 14 days</option>
                  <option value={"28"}>In 28 days</option>
                  <option value={"30"}>In 30 days</option>
                  <option value={"60"}>In 60 days</option>
                </>
              )}
              {deliveryTimesForIncoterm.map((d: string) => (
                <option key={d} value={d}>
                  {d} days
                </option>
              ))}
              {!deliveryTimesForIncoterm.includes(priceObject.deliverytime) &&
                !["customer", "ownstock"].includes(priceObject.supplier.toString()) && (
                  // Add current deliverytime if it isnt included anymore
                  <option key={priceObject.deliverytime} value={priceObject.deliverytime}>
                    {priceObject.deliverytime} days
                  </option>
                )}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            {priceObject.purchasePrice && priceObject.purchasePrice > 0 ? (
              <span className="mr-1">
                {priceObject.purchasePrice.toFixed(2) + " " + priceObject.purchaseCurrency + `/${unit}`}
              </span>
            ) : (
              <span className="mr-1">not set</span>
            )}
            <Tooltip title="Purchase price and currency, excluding transport, custom, fees." placement="top">
              <i className="la la-question-circle" />
            </Tooltip>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <span className="mr-1">
              {priceObject.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) + `/${unit}`}
            </span>
            <Tooltip
              title="Estimated total price including transport, custom, fees on an average basis. This price is used for a rough indication while calculating."
              placement="top"
            >
              <i className="la la-question-circle" />
            </Tooltip>
            {canCheck && (
              <span className="kt-portlet__head-icon ml-3 pointer " onClick={this.handleAutoSelect}>
                <i className="kt-font-brand fa fa-magic" />
              </span>
            )}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
          <span className={priceDifference <= 0 ? "text-success" : priceDifference > 0 ? "text-danger" : ""}>
            <b>{priceObject.supplier === "customer" ? "-" : priceDifference.toFixed(2) + "%"}</b>
          </span>
        </td>
      </tr>
    );
  }
}

export default OrderCommoditiesCheck;
