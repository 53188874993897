import _ from "lodash";
import React, { PureComponent, useCallback, useState } from "react";
import { Table } from "react-bootstrap";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { RawbidsSupplierSelected, SupplierSelectedPrices } from "./CustomTypes";
import PersonWidget from "../PersonWidget";
import RatingInfo from "../RatingInfo";
import { DataContext } from "../../../context/dataContext";
import dateUtils from "../../../utils/dateUtils";
import { INCOTERMS } from "../../../utils/suppliersUtils";
import baseUtils from "../../../utils/baseUtils";
import { Currency } from "../../../model/customTypes.types";
import RawbidsRequestModal from "./modals/RawbidsRequestModal";
import { RawbidsPricesResult } from "../../../utils/rawbidsUtils";

interface RawbidsPricesEntryProps {
  onAddNewPriceClick: (_id: BSON.ObjectId) => void;
  onRemovePriceClick: (supplier: BSON.ObjectId, price: BSON.ObjectId) => void;
  onNumberChange: (supplier: BSON.ObjectId, price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) => void;
  onStringChange: (
    supplier: BSON.ObjectId,
    price: BSON.ObjectId,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  onUpdateRawbidsSupplier: (prices: RawbidsPricesResult) => void;
  supplierSelected: RawbidsSupplierSelected;
  type: "Capsule" | "Commodity" | "Packaging" | "Custom" | "Service" | "Softgel";
  activeKey: string;
  onChangeActiveKey: (activeKey: string) => void;
  disabled?: boolean;
  allowManufacturer: boolean;
}

class RawbidsPricesEntry extends PureComponent<RawbidsPricesEntryProps> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  handleChangeActiveKey = () =>
    this.props.onChangeActiveKey(
      this.props.activeKey === this.props.supplierSelected._id.toString()
        ? ""
        : this.props.supplierSelected._id.toString()
    );

  handleAddNewPrice = () => this.props.onAddNewPriceClick(this.props.supplierSelected._id);

  handleRemovePrice = (price: BSON.ObjectId) => this.props.onRemovePriceClick(this.props.supplierSelected._id, price);

  handleNumberChange = (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onNumberChange(this.props.supplierSelected._id, price, e);

  handleStringChange = (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    this.props.onStringChange(this.props.supplierSelected._id, price, e);

  stopPropagation = (e: React.MouseEvent<HTMLTableDataCellElement>) => e.stopPropagation();

  render() {
    const { supplierSelected, type, activeKey, allowManufacturer, disabled, onUpdateRawbidsSupplier } = this.props;
    const { currencies, suppliers, userdata } = this.context;
    const prices = supplierSelected.prices;

    const supplier = suppliers.find(su => su._id.toString() === supplierSelected._id.toString());
    if (!supplier) return null;
    const contact =
      supplier.person.length > 0 ? userdata.find(u => u._id.toString() === supplier.person[0].toString()) : null;
    const bestPrice = supplierSelected.prices.reduce(
      (bp, p) => (+p.totalPrice < bp ? +p.totalPrice : bp),
      Number.POSITIVE_INFINITY
    );
    const fastestDelivery = supplierSelected.prices.reduce(
      (fd, p) => (+p.deliveryTime < fd ? +p.deliveryTime : fd),
      Number.POSITIVE_INFINITY
    );
    const lastUpdate = supplierSelected.prices.reduce(
      (lu, p) => (p.age && p.age.getTime() > lu.getTime() ? p.age : lu),
      new Date(0)
    );
    const isCom = !["Capsule", "Packaging"].includes(type);

    return (
      <>
        <tr className="row-hover" onClick={this.handleChangeActiveKey} style={{ backgroundColor: "#fafafa" }}>
          <td className="align-middle">
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__pic d-none d-xl-block">
                <div className="kt-badge kt-badge--xl kt-badge--primary bg-rawbids">
                  <img
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: 0,
                      objectFit: "cover"
                    }}
                    alt="RawbidsIcon"
                    src={"https://app.rawbids.com/favicon.ico"}
                    className="country-icon"
                  />
                </div>
              </div>
              <div className="kt-user-card-v2__details">
                <Link
                  to={"/supplier/" + supplier._id.toString()}
                  className="kt-user-card-v2__name kt-link"
                  onClick={e => e.stopPropagation()}
                >
                  {supplier.name.trim() !== "" ? supplier.name : "<Empty Name - Please fill in>"}
                </Link>
                <span className="kt-user-card-v2__email">
                  {supplier.address.length > 0 ? `${supplier.address[0].city}, ${supplier.address[0].country}` : "-"}
                </span>
              </div>
            </div>
          </td>
          {allowManufacturer && <td className="align-middle">Supplier</td>}
          <td className="align-middle">{contact && <PersonWidget person={contact} />}</td>
          <td className="align-middle">
            <RatingInfo rating="A" />
          </td>
          <td className="align-middle">{bestPrice === Infinity ? "-" : baseUtils.formatEuro(+bestPrice)}</td>
          <td className="align-middle">{fastestDelivery === Infinity ? "-" : `${fastestDelivery} Days`}</td>
          <td className="align-middle">{lastUpdate.getTime() !== 0 ? dateUtils.getTimeAgo(lastUpdate) : "-"}</td>
          <td className="align-middle text-right" onClick={this.stopPropagation}>
            <RawbidsRequestModal
              commodity={supplierSelected.material}
              supplier={supplierSelected}
              onUpdateRawbidsSupplier={onUpdateRawbidsSupplier}
            />
          </td>
        </tr>
        <tr>
          <td
            colSpan={6}
            className={"m-0 p-0 " + (activeKey === supplierSelected._id.toString() ? "active show" : "d-none")}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="align-middle" style={{ width: isCom ? "15%" : "31%", minWidth: "125px" }}>
                    Total Price
                  </th>
                  {isCom && (
                    <>
                      <th className="align-middle" style={{ width: "16%", minWidth: "150px" }}>
                        Purchase Price
                      </th>
                      <th className="align-middle" style={{ width: "10%", minWidth: "100px" }}>
                        Incoterm
                      </th>
                    </>
                  )}
                  <th className="align-middle" style={{ width: isCom ? "13%" : "18%", minWidth: "125px" }}>
                    MOQ
                  </th>
                  <th className="align-middle" style={{ width: isCom ? "11%" : "16%", minWidth: "110px" }}>
                    Del. Time
                  </th>
                  <th className="align-middle" style={{ width: "25%", minWidth: "125px" }}>
                    Note
                  </th>
                  <th className="align-middle" style={{ width: "10%", minWidth: "75px" }}>
                    Age
                  </th>
                  {!disabled && (
                    <th className="text-right" style={{ width: "10%" }}>
                      <button className="btn btn-sm btn-success" onClick={this.handleAddNewPrice}>
                        <i className="fa fa-plus pr-0" />
                      </button>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {prices.map(p => {
                  return (
                    <RawbidsPricesTableRow
                      key={p._id.toString()}
                      price={p}
                      currencies={currencies}
                      disabled={!!disabled}
                      type={type}
                      isRemovable={prices.length > 1}
                      onNumberChange={this.handleNumberChange}
                      onStringChange={this.handleStringChange}
                      onRemovePrice={this.handleRemovePrice}
                    />
                  );
                })}
              </tbody>
            </Table>
          </td>
        </tr>
      </>
    );
  }
}

interface RawbidsPricesTableRowProps {
  price: SupplierSelectedPrices;
  currencies: Array<Currency>;
  disabled: boolean;
  type: "Capsule" | "Commodity" | "Packaging" | "Custom" | "Service" | "Softgel";
  isRemovable: boolean;
  onRemovePrice: (price: BSON.ObjectId) => void;
  onNumberChange: (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement>) => void;
  onStringChange: (price: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const RawbidsPricesTableRow: React.FunctionComponent<RawbidsPricesTableRowProps> = ({
  price: p,
  disabled,
  currencies,
  type,
  isRemovable,
  onRemovePrice,
  onNumberChange,
  onStringChange
}) => {
  const [price, setPrice] = useState<SupplierSelectedPrices>(_.cloneDeep(p));

  const handleNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      let val = e.target.value;
      if (+val < 0) return;
      const name = e.target.name;
      setPrice(prevState => {
        if (!val.includes(".")) val = Number(val).toString();
        switch (name) {
          case "totalPrice":
            return { ...prevState, totalPrice: val, age: new Date() };
          case "purchasePrice":
            return { ...prevState, purchasePrice: val, age: new Date() };
          case "moq":
            return { ...prevState, moq: val, age: new Date() };
          case "deliveryTime":
            return { ...prevState, deliveryTime: val, age: new Date() };
        }
        return prevState;
      });
    },
    [disabled]
  );

  const handleStringChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (disabled) return;
      const val = e.target.value;
      const name = e.target.name;
      setPrice(prevState => {
        switch (name) {
          case "incoterm":
            return { ...prevState, incoterm: val, age: new Date() };
          case "note":
            return { ...prevState, note: val, age: new Date() };
          case "purchaseCurrency":
            return { ...prevState, purchaseCurrency: val, age: new Date() };
        }
        return prevState;
      });
    },
    [disabled]
  );

  const handleNumberBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => (disabled ? undefined : onNumberChange(p._id, e)),
    [p, disabled]
  );

  const handleStringBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => (disabled ? undefined : onStringChange(p._id, e)),
    [p, disabled]
  );

  const handleRemovePrice = useCallback(() => (disabled ? undefined : onRemovePrice(p._id)), [p, disabled]);

  return (
    <tr>
      <td className="align-middle">
        <div className="input-group">
          <input
            className={"form-control input-number-arrows-low-pr" + (+price.totalPrice === 0 ? " is-invalid" : "")}
            type="number"
            value={price.totalPrice}
            min={0}
            onChange={handleNumberChange}
            disabled={disabled}
            onBlur={handleNumberBlur}
            name="totalPrice"
          />
          <div className="input-group-append">
            <span className="input-group-text">€</span>
          </div>
        </div>
      </td>
      {!["Capsule", "Packaging"].includes(type) && (
        <>
          <td className="align-middle">
            <div className="input-group">
              <input
                className={
                  "form-control input-number-arrows-low-pr" +
                  (["Capsule", "Packaging"].includes(type)
                    ? " disabled"
                    : +price.purchasePrice === 0
                    ? " is-invalid"
                    : "")
                }
                disabled={["Capsule", "Packaging"].includes(type) || disabled}
                type="number"
                value={price.purchasePrice}
                min={0}
                onChange={handleNumberChange}
                onBlur={handleNumberBlur}
                name="purchasePrice"
              />
              <div className="input-group-append">
                <select
                  className={"form-control pl-1 pr-0" + (price.purchasePriceCurrency === "" ? " is-invalid" : "")}
                  value={price.purchasePriceCurrency}
                  onChange={handleStringChange}
                  disabled={disabled}
                  onBlur={handleStringBlur}
                  name="purchaseCurrency"
                  style={{ maxWidth: "60px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  <option disabled hidden value="">
                    Not set
                  </option>
                  {currencies &&
                    currencies.map(c => (
                      <option key={c.code} value={c.code}>
                        {c.code}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </td>
          <td className="align-middle">
            <select
              className={"form-control" + (price.incoterm === "" ? " is-invalid" : "")}
              value={price.incoterm}
              onChange={handleStringChange}
              disabled={disabled}
              onBlur={handleStringBlur}
              name="incoterm"
            >
              <option value="" disabled hidden>
                Not set
              </option>
              {INCOTERMS.map((i, key) => {
                return (
                  <option key={key} value={i}>
                    {i}
                  </option>
                );
              })}
            </select>
          </td>
        </>
      )}
      <td className="align-middle">
        <div className="input-group">
          <input
            className="form-control input-number-arrows-low-pr"
            type="number"
            value={price.moq}
            min={0}
            onChange={handleNumberChange}
            disabled={disabled}
            onBlur={handleNumberBlur}
            name="moq"
          />
          <div className="input-group-append">
            <span className="input-group-text">
              {type === "Commodity" ? "KG" : ["Packaging", "Service"].includes(type) ? "PCS" : "TSD"}
            </span>
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="input-group">
          <input
            className="form-control input-number-arrows-low-pr"
            type="number"
            value={price.deliveryTime}
            min={0}
            onChange={handleNumberChange}
            disabled={disabled}
            onBlur={handleNumberBlur}
            name="deliveryTime"
          />
          <div className="input-group-append">
            <span className="input-group-text">D</span>
          </div>
        </div>
      </td>
      <td className="align-middle">
        <input
          className="form-control"
          type="text"
          value={price.note}
          onChange={handleStringChange}
          disabled={disabled}
          onBlur={handleStringBlur}
          name="note"
        />
      </td>
      <td className="align-middle">{price.age ? dateUtils.getTimeAgo(price.age) : "-"}</td>
      {!disabled && (
        <td className="align-middle text-right">
          <button
            className={"btn btn-sm btn-danger" + (!isRemovable ? " disabled" : "")}
            onClick={handleRemovePrice}
            disabled={!isRemovable}
          >
            <i className="fa fa-trash pr-0" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default RawbidsPricesEntry;
