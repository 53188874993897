import { OrdersDocument } from "../model/orders.types";
import { I_PAID } from "./invoiceUtils";

export const T_OFFER = "offer";
export const T_REPORTPDF = "reportPDF";
export const T_OFFERPDF = "offerPDF";
export const T_NONBINDINGOFFERPDF = "nonBindingOfferPDF";
export const T_PRODUCTSPECIFICATION = "productSpecification";
export const T_ORDERED = "ordered";
export const T_CONVERTEDTOCONTRACT = "convertedToContract";
export const T_NOTE = "note";
export const T_CALCULATIONCHANGED = "calculationChanged";
export const T_MANUFACTURERCHANGED = "manufacturerChanged";
export const T_FILLERCHANGED = "fillerChanged";
export const T_OWNERCHANGED = "ownerChanged";
export const T_COMMODITYORDERED = "commodity_ordered";
export const T_COMMODITYORDEREDITED = "commodityOrderEdited";
export const T_COMMODITYORDERCANCELED = "commodityOrderCanceled";
export const T_COMMODITYORDERUNLINKED = "commodityOrderUnlinked";
export const T_COMMODITYATWAREHOUSE = "commodityAtWarehouse";
export const T_COMMODITYDELIVERED = "commodityDelivered";
export const T_COMMODITYREPLACED = "commodityReplaced";
export const T_COMMODITYREMOVED = "commodityRemoved";
export const T_COMMODITYFROMSTOCK = "commodityFromStock";
export const T_COMMODITYFROMCUSTOMER = "commodityFromCustomer";
export const T_COMMODITYFROMCUSTOMERDELIVERED = "commodityFromCustomerDelivered";
export const T_PACKAGINGORDERED = "packagingOrdered";
export const T_PACKAGINGORDEREDITED = "packagingOrderEdited";
// New packaging ordered entry, packagingOrdered has to stay for very old orders
export const T_PACKAGINGORDERED2 = "packagingOrdered2";
export const T_PACKAGINGORDERCANCELED = "packagingOrderCanceled";
export const T_PACKAGINGORDERUNLINKED = "packagingOrderUnlinked";
export const T_PACKAGINGDELIVERED = "packagingDelivered";
export const T_PACKAGINGREPLACED = "packagingReplaced";
export const T_PACKAGINGREMOVED = "packagingRemoved";
export const T_PACKAGINGFROMSTOCK = "packagingFromStock";
export const T_PACKAGINGFROMCUSTOMER = "packagingFromCustomer";
export const T_PACKAGINGFROMCUSTOMERDELIVERED = "packagingFromCustomerDelivered";
export const T_FILE = "file";
export const T_PRODUCTION = "production";
export const T_FULFILLMENT = "fulfillment";
export const T_FULFILLMENTREVOKED = "fulfillmentRevoked";
export const T_RESERVECOMMODITY = "reserveCommodity";
export const T_RESERVEPACKAGING = "reservePackaging";
export const T_DELIVERYINFORMATION = "deliveryinformation";
export const T_INVOICECREATED = "invoiceCreated";
export const T_INVOICECANCELED = "invoiceCanceled";
export const T_FEEDBACK = "feedback";
export const T_REQUESTPENDING = "request_pending";
export const T_REQUESTAPPROVED = "request_approved";
export const T_WAITING = "waiting";
export const T_REORDER = "reorder";
export const T_UPDATE = "update";
export const T_DATASHEET = "datasheet";
export const T_REQUESTREVOKED = "request_revoked";
export const T_OFFERCONFIRMATION = "offerconfirmation";
export const T_MANUFACTURINGPDF = "manufacturingPDF";
export const T_RETURNPRODUCTIONQUEUE = "return_productionqueue";
export const T_RESCHEDULE = "reschedule";
export const T_REQUESTED = "requested";
export const T_REQUESTUPDATED = "requestUpdated";
export const T_REMINDER = "reminder";
export const T_INVOICEREMINDER = "invoiceReminder";
export const T_INVOICEPARTLYPAID = "invoicePartlyPaid";
export const T_INVOICEPAID = "invoicePaid";
export const T_DECLINED = "declined";
export const T_SHIPPINGLABEL = "shippingLabel";
export const T_CALLCREATED = "callCreated";
export const T_CALLED = "called";
export const T_TARGETDATEUPDATED = "targetDateUpdated";
export const T_TITLEUPDATED = "titleUpdated";
export const T_PRODUCTIONREPORTAPPROVED = "productionReportApproved";
export const T_ORDERSHIPPED = "orderShipped";
export const T_ORDERARCHIVED = "orderArchived";
export const T_SHELFLIFESET = "shelfLifeSet";
export const T_QRCODECREATED = "qrCodeCreated";

/**
 * Get the amount of all and paid invoices
 * @param order an order document
 * @returns {[all: number, paid: number]} tuple with all and paid invoices
 */
function getInvoiceStats(order: OrdersDocument) {
  let all = 0;
  let paid = 0;
  if (order.invoices) {
    for (let i = 0; i < order.invoices.length; i++) {
      const invoice = order.invoices[i];
      all += 1;
      if (invoice.state === I_PAID) paid += 1;
    }
  }
  return [all, paid];
}

export default { getInvoiceStats };
