import _ from "lodash";
import React from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import i18n from "i18next";
import { getWeek } from "date-fns";
import {
  calculation,
  calculationInfo,
  CustomerNote,
  Invoice,
  OrderFile,
  OrderFileTypes,
  OrdersDocument,
  pricing,
  pricingCommodities
} from "../model/orders.types";
import {
  CustomOrder,
  ExtendedBatch,
  ExtendedPackagingBatch,
  PackagingStockUpdate,
  StockUpdate
} from "../components/order/CustomTypes";
import { DataContext, DataContextType } from "../context/dataContext";
import {
  T_CAPSULE,
  T_CUSTOM,
  T_LIQUID,
  T_POWDER,
  T_SERVICE,
  T_SOFTGEL,
  T_TABLET
} from "../components/order/OrderHelper";
import baseUtils from "./baseUtils";
import { PackagingsDocument } from "../model/packagings.types";
import { SuppliersDocument } from "../model/suppliers.types";
import calculationUtils from "./calculationUtils";
import { CompaniesDocument } from "../model/companies.types";
import { UserdataDocument } from "../model/userdata.types";
import { RequestsDocument } from "../model/requests.types";
import { ALLTORSO, PackagingTypes } from "../components/configurator/configuratorConstants";
import { I_CANCELED, I_PAID } from "./invoiceUtils";
import { CommoditiesDocument } from "../model/commodities.types";
import { MARGIN } from "./orderCalculationUtils";
import commodityUtils, { CUSTOMER } from "./commodityUtils";
import { PackagingStockDocument } from "../model/packagingStock.types";
import fileUtils, { DEFAULT_SEPARATOR, purgeStringForCSV } from "./fileUtils";
import userService from "../services/userService";
import contractUtils from "./contractUtils";
import { T_BAG, T_BLISTER, T_BOTTLE, T_LIQUIDBOTTLE } from "./packagingUtils";
import { StickerForms } from "../components/packaging/PackagingHelper";
import { T_OFFER } from "./timelineUtils";
import slackService from "../services/slackService";
import { NotificationUser } from "../model/configuration/notificationConfiguration.types";
import { OrderSnapshot } from "../model/warehouse/common.types";
import config from "../config/config.json";

export const ARCHIVE = "archive";
export const CACHED_REQUEST = "cached_request";
export const CREATEINVOICE = "create_invoice";
export const CONTRACT = "contract";
export const DECLINED = "declined";
export const FULFILLMENT = "fulfillment";
export const FEEDBACK = "feedback";
export const NOTE = "note";
export const OFFER = "offer";
export const OFFERCONFIRMATION = "offerconfirmation";
export const ORDERED = "ordered";
export const ORDERORDERCOMMODITIES = "order_order-commodities";
export const PRODUCTION = "production";
export const PRODUCTIONQUEUE = "productionqueue";
export const REORDER = "reorder";
export const REQUEST = "request";
export const REQUESTAPPROVED = "request_approved";
export const REQUESTED = "requested";
export const REQUESTPENDING = "request_pending";
export const REQUESTUPDATED = "requestUpdated";
export const UPDATE = "update";
export const WAITING = "waiting";

export const AN = "AN";
export const AT = "AT";

export interface ETAChangeNotificationInformation {
  materialName: string;
  materialId: string;
  materialAmountDescription: string;
  orderIdentifier: string;
  orderId: string;
  orderTargetDate: Date;
  materialOrderTargetDate: Date;
}

/**
 * Retrieve the earliest possible delivery date in relation to the given delivery times and an optional buffer.
 * @param items: Array of elements that contain deliverytimes (commodity prices or packaging)
 * @param buffer: Optional buffer in days
 * @returns {date} Earliest possible date
 */
function getEarliestDeliveryDate(items: Array<{ deliverytime: number }>, buffer?: number) {
  let earliestDate = new Date();
  for (let item of items) {
    const deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + item.deliverytime + (buffer ? buffer : 0));
    if (deliveryDate.getTime() > earliestDate.getTime()) {
      earliestDate = deliveryDate;
    }
  }
  return earliestDate;
}

/**
 * Resolve whether the given order is offer or order
 * @param order: Order document
 * @returns { boolean } True if order, False if offer
 */
function isOrder(order: OrdersDocument | CustomOrder) {
  return isOrderState(order.state);
}

/**
 * Resolves whether the given order is active or closed.
 * @param order: Order document
 * @returns { boolean } True if order is active, False if not
 */
function isActive(order: OrdersDocument) {
  return ![DECLINED, ARCHIVE].includes(order.state);
}

/**
 * Resolve whether the given state is an offer or order state
 * @param state the state of the order
 * @returns {boolean} True if order, False if offer
 */
function isOrderState(state: string) {
  return ![OFFER, REQUESTAPPROVED, REQUESTPENDING].includes(state);
}

/**
 * Resolve order description offer/order and at/an
 * @param order any order document
 * @returns {tuple} tuple with order/offer and at/an
 */
function resolveOrderDescription(order: CustomOrder | OrdersDocument): [string, string] {
  const isOrder = isOrderState(order.state);
  return [isOrder ? (contractUtils.isContract(order) ? "Contract" : "Order") : "Offer", isOrder ? "AT" : "AN"];
}

/**
 * Matches the state of the given order to the correct percentage.
 * @param state the state of an order
 * @returns {string} string with percentage indicating the progress
 */
function getOrderProgress(state: string): string {
  switch (state) {
    case REQUEST:
      return "0%";
    case OFFER:
      return "10%";
    case REQUESTPENDING:
      return "20%";
    case REQUESTAPPROVED:
      return "30%";
    case ORDERORDERCOMMODITIES:
      return "40%";
    case WAITING:
      return "50%";
    case PRODUCTIONQUEUE:
      return "60%";
    case PRODUCTION:
      return "70%";
    case FULFILLMENT:
      return "80%";
    case CREATEINVOICE:
      return "90%";
    case ARCHIVE:
    case DECLINED:
      return "100%";
    default:
      return "0%";
  }
}

/**
 * Get color indicator for margin
 * @param percentMargin the margin percentage
 * @returns {string} text class, e.g. text-warning
 */
const getMarginProperties = (percentMargin: number) => {
  if (percentMargin < MARGIN.BAD) {
    return "text-danger";
  } else if (percentMargin < MARGIN.GOOD && percentMargin >= MARGIN.BAD) {
    return "text-warning";
  } else {
    return "text-success";
  }
};

/**
 * Get values from the calculation info object
 * @param order an order
 * @param property key of the calculation info object to get the data for
 * @returns {[string, number, any]} triple of prefix e.g. average sign, the (average) value,
 *          and list with tuples for units and their respective non average value
 */
const getAverageValue = (
  order: OrdersDocument | CustomOrder,
  property: keyof Omit<calculationInfo, "marginBuffer" | "customCalculation" | "standardCalculation">
): [string, number, any] => {
  if (order.calculations.length > 1) {
    return [
      "∅",
      order.calculations.reduce((a, b) => a + +b.info[property], 0) / order.calculations.length,
      order.calculations.map(calc => {
        return [calc.units, calc.info[property]];
      })
    ];
  } else {
    return ["", order.calculations[0].info[property], null];
  }
};

/**
 * Calculate the earliest possible delivery date in relation to the delivery times of all commodities and packagings.
 * @param order Order document
 * @return {Date} Earliest possible delivery date
 */
const calculateEarliestDeliveryDate = (order: CustomOrder | OrdersDocument) => {
  const { calculations } = order;
  let earliestDeliveryDate = new Date();
  for (let calculation of calculations) {
    const { prices, packagings } = calculation;
    for (let price of prices.concat(packagings as Array<pricingCommodities>)) {
      const { ordered, deliverytime } = price;
      let deliveryTime = new Date();
      if (ordered) {
        deliveryTime = _.clone(ordered);
      }
      deliveryTime.setDate(deliveryTime.getDate() + deliverytime + (ordered ? 14 : 21));
      // Material that is already delivered should not extend the earliest delivery date
      if (!price.delivered && deliveryTime.getTime() > earliestDeliveryDate.getTime()) {
        earliestDeliveryDate = deliveryTime;
      }
    }
  }
  return earliestDeliveryDate;
};

/**
 * Count timeline entries
 * @param order the order
 * @param query the type to count
 * @returns {number} amount of the found entries matching the given query
 */
function countTimelineEntries(order: CustomOrder | OrdersDocument, query: string) {
  let count = 1;
  if (order && order.timeline && order.timeline.length > 0) {
    for (let i = 0; i < order.timeline.length; i++) {
      if (order.timeline[i].type === query) {
        count += 1;
      }
    }
  }
  return count;
}

/**
 * Calculate the total amount of an invoice timeline entry
 * @param invoiceData the invoiceData timeline object
 * @returns {number} the total amount
 */
function getInvoiceTotal(invoiceData: any) {
  let subtotal = 0;
  for (let i = 0; i < invoiceData.positions.length; i++) {
    if (invoiceData.positions[i].type === "position" || invoiceData.positions[i].type === "freeposition") {
      if (invoiceData.reverseCharge) {
        subtotal +=
          invoiceData.positions[i].total - (invoiceData.positions[i].discount / 100) * invoiceData.positions[i].total;
      } else {
        subtotal +=
          (invoiceData.positions[i].total -
            (invoiceData.positions[i].discount / 100) * invoiceData.positions[i].total) *
          (1 + invoiceData.positions[i].vat / 100);
      }
    }
  }
  return Math.round(subtotal * 100) / 100;
}

/**
 * Get the invoice units for an invoice
 * @param invoiceData the invoice data
 * @returns {number} the amount of invoice units
 */
function getInvoicedUnits(invoiceData: Invoice) {
  for (let i = 0; i < invoiceData.positions.length; i++) {
    const position = invoiceData.positions[i];
    if (position.type === "position") return +position.amount;
  }
  return 0;
}

/**
 * Get total invoice units
 * @param invoices list of invoices
 * @returns {number} total invoiced units
 */
function totalInvoicedUnits(invoices: Array<Invoice>) {
  return invoices.reduce((a: number, i: Invoice) => {
    const invoicedUnits = getInvoicedUnits(i);
    if (i.state === I_CANCELED) return 0;
    return a + invoicedUnits;
  }, 0);
}

/**
 * Get the total invoiced turnover
 * @param invoices list of invoices
 * @param paidOnly optional flag if only paid invoices should be checked
 * @returns {number} total invoiced turnover
 */
function totalInvoiceTurnover(invoices: Array<Invoice>, paidOnly?: boolean) {
  return invoices.reduce((a: number, b: Invoice) => {
    if (!paidOnly || (paidOnly && b.state === I_PAID)) return a + +b.totalGross;
    return a;
  }, 0);
}

/**
 * Get the due of the invoice as Date object.
 * @param due: Due of the invoice
 * @param invoiceDate: Date of the invoice
 * @returns { Date } Due date of the invoice
 */
function getInvoiceDue(due: number, invoiceDate: Date) {
  if (due === -1) return invoiceDate;
  const dueDate = new Date(invoiceDate);
  dueDate.setDate(dueDate.getDate() + due);
  return dueDate;
}

/**
 * Calculate the active substance amount contained in a commodity
 * @param activeSubstanceAmount the active substance percentage
 * @param commodityAmount the amount of the commodity
 * @returns {number} the total amount of the substance amount
 */
function getActiveSubstanceAmount(activeSubstanceAmount: number, commodityAmount: number) {
  return (activeSubstanceAmount / 100) * commodityAmount;
}

/**
 * Get the next version of a pdf
 * @param order the order
 * @param type the document/timeline type
 * @returns the next version
 */
function getPDFVersion(order: any, type: string) {
  const timeline = order.timeline;
  let version = 1;
  for (let i = 0; i < timeline.length; i++) {
    const entry = timeline[i];
    if (entry.type === type) {
      version += 1;
    }
  }
  return version;
}

/**
 * Get a product description for an order
 * @param order an orders document
 * @param context data context
 * @param specificLanguage the language to use
 * @returns {string} a description for the order/product
 */
function getProductDescription(
  order: OrdersDocument | CustomOrder,
  context: DataContextType,
  specificLanguage?: string
) {
  const { capsules, packagings } = context;
  let language = specificLanguage ? specificLanguage : i18n.language;
  language = language === "en" ? "en" : "de";
  const languageObject = { lng: language };
  let string = "";
  const usedPackagings = order.calculations[0].packagings.map(x => baseUtils.getDocFromCollection(packagings, x._id));
  const bottle = usedPackagings.find(x => x.packaging_type === PackagingTypes.BOTTLE);
  const bag = usedPackagings.find(x => x.packaging_type === PackagingTypes.BAG);
  const blister = usedPackagings.find(x => x.packaging_type === PackagingTypes.BLISTER);

  let torsoString = "";
  if (bottle)
    torsoString = ` | ${i18n.t("packaging:bottle", languageObject)} (${bottle.packaging_volume}ml), ${i18n.t(
      "packaging:" + bottle.packaging_color,
      languageObject
    )}, ${i18n.t("packaging:" + bottle.packaging_material, languageObject)}, ${bottle.packaging_neck}`;
  else if (bag) torsoString = ` | ${i18n.t("packaging:bag", languageObject)} (${bag.bag_volume}ml)`;
  else if (blister)
    torsoString = ` | Blister ${blister.blister_capsules} ${i18n.t("packaging:capsulesperblister", languageObject)}`;

  if (order.settings.type === T_CAPSULE) {
    const capsule = baseUtils.getDocFromCollection(capsules, order.settings.id);
    string +=
      order.settings.perUnit +
      ` ${i18n.t("pdf:capsules", languageObject)}, ` +
      capsule.capsule_material[language] +
      " " +
      capsule.capsule_color[language];
    string += ", " + order.title + ` ${i18n.t("pdf:accordingToSpecification", languageObject)}`;
  } else if (order.settings.type === T_TABLET) {
    string += order.settings.perUnit + ` ${i18n.t("pdf:tablets", languageObject)}`;
    string += ", " + order.title + ` ${i18n.t("pdf:accordingToSpecification", languageObject)}`;
  } else if ([T_CUSTOM, T_SOFTGEL, T_SERVICE].includes(order.settings.type)) {
    string += order.settings.perUnit + ` ${i18n.t("pdf:pieces", languageObject)}`;
    string += `, ${i18n.t("pdf:accordingToSpecification", languageObject)}`;
  } else if (order.settings.type === "powder") {
    string +=
      `${i18n.t("pdf:powder", languageObject)}, ` +
      order.title +
      ` ${i18n.t("pdf:accordingToSpecification", languageObject)}`;
  } else if (order.settings.type === "liquid") {
    string +=
      `${i18n.t("packaging:liquid", languageObject)}, ` +
      order.title +
      ` ${i18n.t("pdf:accordingToSpecification", languageObject)}`;
  }
  string += torsoString;
  // Additional packaging
  const lid = usedPackagings.find(x => x.packaging_type === PackagingTypes.LID);
  if (lid)
    string += ` | ${i18n.t("packaging:lid", languageObject)} (${i18n.t(
      "packaging:" + lid.lid_material,
      languageObject
    )}), ${lid.lid_size}`;
  const label = usedPackagings.find(x => x.packaging_type === PackagingTypes.LABEL);
  const multilayerLabel = usedPackagings.find(x => x.packaging_type === PackagingTypes.MULTILAYER_LABEL);
  if (label || multilayerLabel) {
    const pack = label ? label : multilayerLabel;
    if (label) string += " | Label";
    else string += "| Multilayer Label";
    string += ` (${pack.label_height}mm x ${pack.label_width}mm), ${i18n.t(
      "packaging:" + pack.label_quality,
      languageObject
    )}`;
  }
  const sleeve = usedPackagings.find(x => x.packaging_type === PackagingTypes.SLEEVE);
  if (sleeve) string += ` | Sleeve (${sleeve.sleeve_size}mm)`;
  const box = usedPackagings.find(x => x.packaging_type === PackagingTypes.BOX);
  if (box) string += ` | Box (${box.box_height}mm x ${box.box_width}mm x ${box.box_depth}mm)`;
  const sticker = usedPackagings.find(x => x.packaging_type === PackagingTypes.STICKER);
  if (sticker)
    string += ` | Sticker ${i18n.t("packaging:" + sticker.form, languageObject)}, ${i18n.t(
      "packaging:" + sticker.quality,
      languageObject
    )} (${
      sticker.form === StickerForms.ROUND
        ? `⌀ ${sticker.diameter} mm`
        : `${sticker.packaging_height}mm x ${sticker.packaging_width}mm`
    })`;
  const spoon = usedPackagings.find(x => x.packaging_type === PackagingTypes.SPOON);
  if (spoon) string += ` | Spoon, ${i18n.t("packaging:" + spoon.packaging_color, languageObject)} (${spoon.capacity}g)`;
  const silicaGelBag = usedPackagings.find(x => x.packaging_type === PackagingTypes.SILICAGELBAG);
  if (silicaGelBag) string += ` | Silica Gel Bag (${silicaGelBag.weight}g)`;
  const packageInsert = usedPackagings.find(x => x.packaging_type === PackagingTypes.PACKAGEINSERT);
  if (packageInsert) string += ` | Package Insert `;
  return string;
}

/**
 * Get the tracking id for an order
 * @param order an order document
 * @returns {string} the concatenated tracking id
 */
function getTrackingId(order: CustomOrder | OrdersDocument) {
  return (
    order.identifier +
    "-" +
    order._id
      .toString()
      .slice(order._id.toString().length - 6)
      .toUpperCase()
  );
}

/**
 * Get changes in commodity prices
 * @param calculation a calculation
 * @returns {number} the percentage change in commodity prices
 */
function getCommodityPriceChanges(calculation: calculation) {
  let previousTotal = 0;
  let newTotal = 0;
  for (let i = 0; i < calculation.prices.length; i++) {
    previousTotal += +calculation.prices[i].estimatedprice;
    newTotal += +calculation.prices[i].price;
  }
  return (newTotal / previousTotal) * 100 - 100;
}

/**
 * Get total change in commodity prices over all calculations
 * @param order an order document
 * @returns {number} the percentage change in commodity prices
 */
function getTotalCommodityPriceChanges(order: OrdersDocument) {
  const totalChanges = order.calculations.reduce((a, b) => a + getCommodityPriceChanges(b), 0);
  return totalChanges / order.calculations.length;
}

/**
 * Get printing file requirements for an order, i.e. check how many printing files are required and how many are uploaded
 * @param order an order document
 * @param packaging list of packaging
 * @returns { required: number, uploaded: number } a tuple consisting of the required and already uploaded labels
 */
function getPrintingFileReq(order: CustomOrder | OrdersDocument, packaging: Array<PackagingsDocument>) {
  let required = 0;
  let uploaded = 0;
  const calculation = order.calculations[0];
  for (let i = 0; i < calculation.packagings.length; i++) {
    const p = calculation.packagings[i];
    const fullPackaging = packaging.find(pack => pack._id.toString() === p._id.toString());
    if (
      fullPackaging &&
      [PackagingTypes.LABEL, PackagingTypes.MULTILAYER_LABEL, PackagingTypes.BOX].includes(
        fullPackaging.packaging_type
      ) &&
      p.supplier !== "customer"
    )
      required += 1;
  }
  for (let i = 0; i < order.timeline.length; i++) {
    const entry = order.timeline[i];
    if (entry.type === "file" && (entry.category === "printing_file" || entry.category === "label")) uploaded += 1;
  }
  return {
    required,
    uploaded
  };
}

/**
 * Get the name of a supplier
 * @param supplier a supplier document, customer or stock
 * @returns {string} the name of the supplier or description for stock, customer etc.
 */
function getSupplierName(supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock") {
  if (typeof supplier === "string") {
    switch (supplier) {
      case "ownstock":
        return "Stock";
      case "accumulatedstock":
        return "Accumulated Stock";
      case "customer":
        return "Delivered by customer";
      default:
        return "Unknown";
    }
  } else {
    return supplier.name;
  }
}
/**
 * Get the name of a supplier
 * @param supplier as string (id or specific tag)
 * @param suppliers suppliers collection
 * @returns {string} the name of the supplier or description for stock, customer etc.
 */
function getSupplierNameById(supplier: string, suppliers: Array<SuppliersDocument>) {
  switch (supplier) {
    case "ownstock":
      return "Stock";
    case "accumulatedstock":
      return "Accumulated Stock";
    case "customer":
      return "Delivered by customer";
    case "custom":
      return "Custom";
    default:
      const supplierObject: SuppliersDocument | undefined = baseUtils.getDocFromCollection(suppliers, supplier);
      if (!supplierObject) return "Unknown";
      return supplierObject.name;
  }
}

/**
 * Calculate the total amount of a commodity including buffer
 * @param perUnit the amount per unit
 * @param units the total units of a calculation
 * @param amount the commodity amount
 * @param buffer the commodity buffer
 * @param type the order type
 * @returns {number} the total amount with buffer
 */
function getTotalAmountWithBuffer(perUnit: number, units: number, amount: number, buffer: number, type: string) {
  const productType = calculationUtils.getTabForType(type);
  return calculationUtils.getTotalAmountWithBuffer(perUnit, units, amount, productType!, buffer);
}

/**
 * Check if order state needs to be updated after calculation price change
 * @param order an order document
 * @param newPrice the new price object
 * @returns {string | null} the new state or null if no change is required
 */
function checkOrderStateAfterPriceUpdate(order: OrdersDocument | CustomOrder, newPrice?: pricingCommodities | pricing) {
  const calculation = order.calculations[0];
  let commodityOrdered = true;
  let packagingOrdered = true;
  let commodityDelivered = true;
  let packagingDelivered = true;
  for (let i = 0; i < calculation.prices.length; i++) {
    const price = calculation.prices[i];
    if (newPrice && price._id.toString() === newPrice._id.toString()) {
      commodityOrdered = commodityOrdered && !!newPrice.ordered;
      commodityDelivered = commodityDelivered && !!newPrice.delivered;
    } else {
      commodityOrdered = commodityOrdered && !!price.ordered;
      commodityDelivered = commodityDelivered && !!price.delivered;
    }
    if (!commodityOrdered && !commodityDelivered) break;
  }
  for (let i = 0; i < calculation.packagings.length; i++) {
    const pPrice = calculation.packagings[i];
    if (newPrice && pPrice._id.toString() === newPrice._id.toString()) {
      packagingOrdered = packagingOrdered && !!newPrice.ordered;
      packagingDelivered = packagingDelivered && !!newPrice.delivered;
    } else {
      packagingOrdered = packagingOrdered && !!pPrice.ordered;
      packagingDelivered = packagingDelivered && !!pPrice.delivered;
    }
    if (!packagingOrdered && !packagingDelivered) break;
  }

  if (commodityDelivered && packagingDelivered && [ORDERORDERCOMMODITIES, WAITING].includes(order.state))
    return PRODUCTIONQUEUE;
  if (commodityOrdered && packagingOrdered && order.state !== WAITING && [ORDERORDERCOMMODITIES].includes(order.state))
    return WAITING;
  if (
    (!commodityOrdered || !commodityDelivered || !packagingOrdered || !packagingDelivered) &&
    [WAITING, PRODUCTIONQUEUE].includes(order.state)
  )
    return ORDERORDERCOMMODITIES;
  return null;
}

/**
 * Check if an order has fulfillment price info and is in the correct state
 * @param order an order document
 * @returns {boolean} true if price info exists, else false
 */
function hasFulfillmentPriceInfo(order: OrdersDocument | CustomOrder) {
  return (
    [FULFILLMENT, CREATEINVOICE, ARCHIVE, DECLINED].includes(order.state) &&
    !!order.fulfillment &&
    !!order.fulfillment.priceInfo
  );
}

/**
 * Resolve the description of the given state.
 * @param state: Order state
 * @returns { string } Description of the state
 */
function getStateDescription(state: string) {
  switch (state) {
    case OFFER:
      return "Offer";
    case REQUESTPENDING:
      return "Approval requested";
    case REQUESTAPPROVED:
      return "Approved offer";
    case ORDERORDERCOMMODITIES:
      return "Order Commodities";
    case CONTRACT:
      return "Contract";
    case WAITING:
      return "On Hold";
    case PRODUCTIONQUEUE:
      return "Ready For Production";
    case PRODUCTION:
      return "In Production";
    case FULFILLMENT:
      return "QM / Fulfillment";
    case CREATEINVOICE:
      return "Finished";
    case ARCHIVE:
      return "Archive";
    case DECLINED:
      return "Declined";
  }
}

/**
 * Filter orders or requests by a search query
 * @param orders list of orders or requests
 * @param companies list of companies
 * @param userdata list of userdata
 * @param search the query to search for
 * @param includeInvoices optional, if set invoices are also searched
 * @returns {Array<OrdersDocument|RequestsDocument>} list of requests or orders matching the search query
 */
function filterBySearch(
  orders: Array<OrdersDocument | RequestsDocument>,
  companies: Array<CompaniesDocument>,
  userdata: Array<UserdataDocument>,
  search: string,
  includeInvoices?: boolean
) {
  let ordersFuse: Array<{
    order: OrdersDocument | RequestsDocument;
    company: CompaniesDocument;
    fullName: string;
  }> = orders.map(o => {
    const owner = o.createdFrom ? baseUtils.getDocFromCollection(userdata, o.createdFrom) : null;
    return {
      order: o,
      company: baseUtils.getDocFromCollection(companies, o.createdFor),
      fullName: owner.prename + "" + owner.surname
    };
  });
  let query = search.toLowerCase().trim();
  const isOffId = search.match(/[0-9]{3}\.[0-9]{3}\.[0-9]{2}/g);

  return baseUtils
    .doFuseSearch(
      ordersFuse,
      query,
      [
        "order.title",
        "order.identifier",
        "order.subtitle",
        "fullName",
        "order.invoices.state",
        "order.settings.type",
        "order.priority",
        "company.name",
        "order.invoices.invoiceNumber"
      ],
      { threshold: isOffId ? 0 : 0.1 }
    )
    .map(o => o.order);
}

/**
 * Checks whether the given document is an OrderDocument or not.
 * @param document Document that should be checked
 * @returns True if the document is an OrderDocument. False if not
 */
function isOrderDocument(document: OrdersDocument | RequestsDocument): document is OrdersDocument {
  return ![REQUEST, CACHED_REQUEST].includes(document.state);
}

/**
 * Filter orders by the given filters
 * @param orders list of orders
 * @param state state to filter for
 * @param owner owner to filter for
 * @param priority priority to filter for
 * @param margin margin to filter for
 * @param manufacturer manufacturer to filter for
 * @param type the order type to filter for
 * @returns {Array<OrdersDocument>} list of orders matching the filters
 */
function filterOrders(
  orders: Array<OrdersDocument>,
  state?: string,
  owner?: string,
  priority?: string,
  margin?: string,
  manufacturer?: string,
  type?: string
) {
  let tmpOrders = orders.slice();
  if (state) {
    tmpOrders = tmpOrders.filter(o => o.state === state);
  }
  if (type) {
    tmpOrders = tmpOrders.filter(o => o.settings.type === type);
  }
  if (owner) {
    tmpOrders = tmpOrders.filter(o => o.createdFrom.toString() === owner);
  }
  if (priority) {
    tmpOrders = tmpOrders.filter(o => o.priority === priority);
  }
  if (margin) {
    tmpOrders = tmpOrders.filter(o => {
      const hasFfInfo = hasFulfillmentPriceInfo(o);
      const percentMargin = hasFfInfo
        ? o.fulfillment?.priceInfo?.percentMargin!
        : getAverageValue(o, "percentmargin")[1];
      return (
        (margin === "high" && percentMargin >= MARGIN.GOOD) ||
        (margin === "medium" && percentMargin >= MARGIN.BAD && percentMargin < MARGIN.GOOD) ||
        (margin === "low" && percentMargin >= MARGIN.CRITICAL && percentMargin < MARGIN.BAD) ||
        (margin === "forbidden" && percentMargin < MARGIN.CRITICAL)
      );
    });
  }
  if (manufacturer) {
    tmpOrders = tmpOrders.filter(o => o.settings.manufacturer.toString() === manufacturer);
  }
  return tmpOrders;
}

/**
 * Filter orders by a given period
 * @param orders list of orders to filter
 * @param period the period orders should be filtered for
 * @param filterDate indicator whether to check invoice or creation/ordered date
 * @returns {Array<OrdersDocument>} list of filtered orders
 */
function filterByPeriod(orders: Array<OrdersDocument>, period: { start: Date; end: Date }, filterDate: string) {
  let tmpOrders = orders.slice();
  tmpOrders = tmpOrders.filter(o => {
    let invoices = filterDate === "invoice" && o.invoices ? o.invoices : null;
    let comparisonTime =
      filterDate === "delivery"
        ? [o.delivery || o.targetDate]
        : invoices
        ? invoices.map(i => i.invoiceDate.getTime())
        : [o.createdOn.getTime()];
    return (
      (filterDate !== "invoice" || invoices) &&
      comparisonTime.some(t1 => t1 && period.start.getTime() <= t1) &&
      comparisonTime.some(t2 => t2 && t2 <= period.end.getTime())
    );
  });
  return tmpOrders;
}

/**
 * Filter orders by order volume
 * @param orders list of orders to filter
 * @param minVolume the minimum volume to filter for
 * @param maxVolume the maximum volume to filter for
 * @returns {Array<OrdersDocument>} list of filtered orders
 */
function filterByVolume(orders: Array<OrdersDocument>, minVolume: string, maxVolume: string) {
  return orders.filter(o => {
    const volume = getAverageValue(o, "totalprice")[1];
    let condition = true;
    if (minVolume) condition = condition && +minVolume <= volume;
    if (maxVolume) condition = condition && volume <= +maxVolume;
    return condition;
  });
}

/**
 * Filter orders by invoice filter
 * @param orders list of orders to filter
 * @param invoiceFilter the invoice filter
 * @returns {Array<OrdersDocument>} list of filtered orders
 */
function filterByInvoice(orders: Array<OrdersDocument>, invoiceFilter: string) {
  if (!invoiceFilter) return orders;
  if (invoiceFilter === "notInvoiced") {
    return orders.filter(o => {
      const invoices = o.invoices || [];
      const hasFfInfo = hasFulfillmentPriceInfo(o);
      const totalUnits = hasFfInfo ? o.fulfillment?.totalUnits! : o.calculations[0].units;
      const totalInvoiced = totalInvoicedUnits(invoices);
      return totalInvoiced < totalUnits;
    });
  } else if (invoiceFilter === "notPaid") {
    return orders.filter(o => {
      const invoices = o.invoices || [];
      const totalInvoicedTurnover = totalInvoiceTurnover(invoices);
      const totalPaidTurnover = totalInvoiceTurnover(invoices, true);
      return totalPaidTurnover < totalInvoicedTurnover;
    });
  }
  return orders;
}

/**
 * Sort orders
 * @param orders list of orders to sort
 * @param sortValue the value to sort for
 * @param sortOrder the sorting order
 * @returns {Array<OrdersDocument>} list of sorted orders
 */
function sortOrders(orders: Array<OrdersDocument>, sortValue: string, sortOrder: "asc" | "desc") {
  switch (sortValue) {
    case "AT":
      return _.orderBy(orders, o => o.identifier.toString(), [sortOrder]);
    case "DATE":
      return _.orderBy(orders, o => o.createdOn, [sortOrder]);
    case "VOLUME":
      return _.orderBy(orders, o => getAverageValue(o, "totalprice")[1], [sortOrder]);
    case "TITLE":
      return _.orderBy(orders, o => o.title, [sortOrder]);
    case "CUSTOMER":
      return _.orderBy(orders, o => o.createdFor.toString(), [sortOrder]);
    case "DELIVERY":
      return _.orderBy(orders, o => o.delivery ?? o.targetDate, [sortOrder]);
    default:
      return orders;
  }
}

/**
 * Check commodity prices if an action, e.g. request or update was already done
 * @param prices list of prices
 * @param action action, i.e. price request or price update
 * @param packaging optional packaging to include in check
 * @returns {string} percentage of commodities where the action was already done
 */
function getCommodityPercentageDone(
  prices: Array<pricingCommodities>,
  action: "requested" | "updated" | "ordered",
  packaging?: Array<pricing>
) {
  let all = 0;
  let done = 0;
  let pricesToCheck: Array<pricing | pricingCommodities> = prices.slice();
  if (packaging) pricesToCheck = pricesToCheck.concat(packaging);
  for (let i = 0; i < pricesToCheck.length; i++) {
    const price = pricesToCheck[i];
    all += 1;
    if (action in price && !!price[action]) {
      done += 1;
    }
  }
  return ((done / all) * 100).toString() + "%";
}

/**
 * Get the total amount of a product
 * @param order an order document
 * @returns {string} description for the total amount of a product, e.g. 120 tsd. Capsules
 */
function getTotalProductAmount(order: OrdersDocument) {
  const units = +order.calculations[0].units;
  const perUnit = order.settings.perUnit ? +order.settings.perUnit : 1;
  const totalAmount = units * perUnit;
  const formatAmount = () => {
    if (totalAmount > 1000) return `${totalAmount / 1000} tsd.`;
    return totalAmount;
  };
  switch (order.settings.type) {
    case T_CAPSULE:
      return `${formatAmount()} Capsules`;
    case T_TABLET:
      return `${formatAmount()} Tablets`;
    case T_POWDER:
      return `${calculationUtils.formatAmount(totalAmount, 2)} Powder`;
    case T_LIQUID:
      return `${calculationUtils.formatAmount(totalAmount, 2)} Liquid`;
    case T_SOFTGEL:
      return `${formatAmount()} Softgels`;
    case T_CUSTOM:
      return `${formatAmount()} Products`;
  }
}

/**
 * Check if an order is ready for production
 * @param order an order document
 * @param packagings list of packagings
 * @returns {boolean} true if ready else false
 */
function isReadyForProduction(order: OrdersDocument | CustomOrder, packagings: Array<PackagingsDocument>) {
  const commodities = order.calculations[0].prices;
  const packaging = order.calculations[0].packagings;
  const pfReq = getPrintingFileReq(order, packagings);
  return (
    pfReq.uploaded >= pfReq.required &&
    commodities.every(c => !!c.delivered) &&
    packaging.every(p => !!p.delivered) &&
    order.state === PRODUCTIONQUEUE
  );
}

/**
 * Check if an order is ready for production and returns errors if any exist
 * @param order an order document
 * @param packagings list of packaging
 * @returns {Array<string>} list of error messages
 */
function validateOrderDataForProduction(order: OrdersDocument | CustomOrder, packagings: Array<PackagingsDocument>) {
  const errors = [];
  const commodities = order.calculations[0].prices;
  const packaging = order.calculations[0].packagings;
  const pfReq = getPrintingFileReq(order, packagings);
  if (pfReq.uploaded < pfReq.required) errors.push("Printing file is missing");
  if (!commodities.every(c => !!c.delivered)) errors.push("Not all commodities were delivered yet");
  if (!packaging.every(p => !!p.delivered)) errors.push("Not all packaging was delivered yet");
  if (order.state !== PRODUCTIONQUEUE) errors.push("Order is not in production queue");
  return errors;
}

/**
 * Generate the stock list for the commodities that are used in the given order
 * @param order: Order whose commodities should be listed
 * @param commodityDocuments: All commodities
 * @returns { Array<StockUpdate> } Commodities with their stocks
 */
function generateCommoditiesStockList(order: CustomOrder, commodityDocuments: Array<CommoditiesDocument>) {
  // Collect all used commodities from recipe
  const usedCommodities = order.recipe.map(c => c.id.toString());
  // Get commodities
  const filteredCommodities = commodityDocuments.filter(c => usedCommodities.includes(c._id.toString()));
  const commodities = [];
  for (let i = 0; i < order.calculations[0].prices.length; i++) {
    const price = order.calculations[0].prices[i];
    const fullCommodity = filteredCommodities.find(c => c._id.toString() === price._id.toString());
    if (fullCommodity) {
      // Gather data and set used value if it was already set before
      commodities.push({
        id: fullCommodity._id,
        commodity: fullCommodity,
        stock: fullCommodity.stock
          .filter(
            stock =>
              stock.amount > 0 &&
              (stock.supplier !== CUSTOMER ||
                (stock.orders && stock.orders.some(o => o.toString() === order._id.toString())))
          )
          .map(s => {
            return {
              id: s._id.toString(),
              stock: s,
              used: order.usedBatches?.find(ub => ub.id === s._id.toString())?.used.toString() || "0"
            };
          })
      });
    }
  }
  return commodities;
}

/**
 * Generate the stock list for the packaging that are used in the given order
 * @param order: Order whose packaging should be listed
 * @param packagingDocuments: All packaging
 * @param packagingStock: List of packaging stock documents
 * @returns { Array<PackagingStockUpdate> } Packaging with their stocks
 */
function generatePackagingStockList(
  order: CustomOrder,
  packagingDocuments: Array<PackagingsDocument>,
  packagingStock: Array<PackagingStockDocument>
) {
  // Collect all used commodities from recipe
  const usedPackaging = order.calculations[0].packagings.map(p => p._id.toString());
  // Get commodities
  const filteredPackaging = packagingDocuments.filter(p => usedPackaging.includes(p._id.toString()));
  const packaging = [];
  for (let i = 0; i < order.calculations[0].packagings.length; i++) {
    const price = order.calculations[0].packagings[i];
    const fullPackaging = filteredPackaging.find(c => c._id.toString() === price._id.toString());
    if (fullPackaging) {
      const stockForPackaging = packagingStock.filter(
        pS =>
          pS.packaging.toString() === fullPackaging._id.toString() &&
          !pS.disabled &&
          pS.amount > 0 &&
          (pS.supplier !== CUSTOMER ||
            (pS.relatedOrders && pS.relatedOrders.some(o => o.toString() === order._id.toString())))
      );
      // Gather data and set used value if it was already set before
      packaging.push({
        id: fullPackaging._id,
        packaging: fullPackaging,
        stock: stockForPackaging.map(s => {
          return {
            id: s._id,
            stock: s,
            used: order.usedPackagingBatches?.find(ub => ub.id.toString() === s._id.toString())?.used.toString() || "0"
          };
        })
      });
    }
  }
  return packaging;
}

/**
 * Compare stock lists to check if any major errors occur that would require an update
 * @param oldStockList list of old stock usage
 * @param newStockList list of new stock usage that is populated with values from old stock list
 * @returns {Array<StockUpdate | PackagingStockUpdate>} new stock list populated with used amounts from old usage list
 */
const mergeStockLists = (
  oldStockList: Array<StockUpdate | PackagingStockUpdate>,
  newStockList: Array<StockUpdate | PackagingStockUpdate>
) => {
  for (let i = 0; i < oldStockList.length; i++) {
    const stock = oldStockList[i];
    const matchingStock = newStockList.find(s => s.id.toString() === stock.id.toString());
    if (!matchingStock) continue;
    for (let j = 0; j < stock.stock.length; j++) {
      const batch = stock.stock[j] as ExtendedBatch | ExtendedPackagingBatch;
      // @ts-ignore
      const matchingBatch = matchingStock.stock.find(
        (s: ExtendedBatch | ExtendedPackagingBatch) => s.id.toString() === batch.id.toString()
      );
      if (matchingBatch) matchingBatch.used = batch.used;
      else if (+batch.used > 0)
        toast.error(
          `Batch '${batch.stock.lot}' was used (${batch.used}) but disabled in the background. Please validate your input.`,
          {
            autoClose: false
          }
        );
    }
  }
  return newStockList;
};

/**
 * Retrieve all unread customer notes of the given order.
 * @param order: Order whose unread customer notes should be resolved
 * @param userdata: Userdata collection
 * @param companies: Companies collection
 * @returns { Array<CustomerNote> } Unread customer notes
 */
function getUnreadCustomerNotes(
  order: OrdersDocument | CustomOrder,
  userdata: Array<UserdataDocument>,
  companies: Array<CompaniesDocument>
) {
  const notes = order.customerNotes;
  const unreadNotes: Array<CustomerNote> = [];
  if (!notes) return unreadNotes;
  for (let i = 0; i < notes.length; i++) {
    const n = notes[i];

    const person: UserdataDocument | undefined = baseUtils.getDocFromCollection(userdata, n.person);
    if (person) {
      const company =
        person.company_id === "internal"
          ? person.company_id
          : baseUtils.getDocFromCollection(companies, person.company_id);

      // Show only unread customer notes - notes should be answers from sales
      if (!n.readDate && company !== "internal") unreadNotes.push(n);
    }
  }
  return unreadNotes;
}

/**
 * Upload a file and get an order file object
 * @param file the file to upload
 * @param type type of the file
 * @param customType optional, custom type e.g. for type "other"
 * @param fileName optional, file name for mediahub
 * @returns {OrderFile|null} OrderFile object if upload was successful else null
 */
function getOrderFile(file: File, type: OrderFileTypes, customType?: string, fileName?: string): OrderFile | null {
  const filePath = fileUtils.uploadFile(file, fileName);
  if (!filePath) return null;
  const orderFile: OrderFile = {
    _id: new BSON.ObjectId(),
    name: file.name,
    path: filePath,
    person: userService.getUserData()._id.toString(),
    date: new Date(),
    type,
    size: file.size
  };
  if (customType) orderFile.customType = customType;
  return orderFile;
}

/**
 * Upload a file and get an order file object
 * @param relativePath relative path from mediahub
 * @param type type of the file
 * @param customType optional, custom type e.g. for type "other"
 * @returns {OrderFile} OrderFile object if upload was successful
 */
function getOrderPDFFile(relativePath: string, type: OrderFileTypes, customType?: string): OrderFile {
  const fileName = relativePath.split("/")[1];
  const orderFile: OrderFile = {
    _id: new BSON.ObjectId(),
    name: fileName,
    path: config.mediahubBase + relativePath,
    person: userService.getUserData()._id.toString(),
    date: new Date(),
    type
  };
  if (customType) orderFile.customType = customType;
  return orderFile;
}

/**
 * Get a file name for an uploaded file
 * @param fileType any order file type
 * @param file the file to be uploaded
 * @param order the order the file is uploaded to
 * @returns {string} complete file name
 */
function getOrderFileName(fileType: OrderFileTypes, file: File, order: CustomOrder | OrdersDocument): string {
  let isoDate = new Date().toISOString();
  isoDate = isoDate.split(":").join("-");
  isoDate = isoDate.split(".").join("-");
  const fileExtension = fileUtils.getFileExtension(file!.name);

  return `${fileType}_AT-${baseUtils.encodeString(order.identifier.toString())}_${isoDate}.${fileExtension}`;
}

/**
 * Check if an order can be shipped
 * @param order an order document
 * @returns {Array<string>} list of error messages
 */
function checkOrderShippingPreconditions(order: CustomOrder): Array<string> {
  if (!order.fulfillment?.productionReportApproval?.required) return [];
  else if (!order.fulfillment?.productionReportApproval?.approvedBy)
    return ["Production report needs to be approved before continuing."];
  return [];
}

/**
 * Get a recipe string for an order
 * @param order an order document
 * @param context data context
 * @returns {string} a recipe string
 */
export function getRecipeString(order: OrdersDocument, context: React.ContextType<typeof DataContext>): string {
  const { capsules, tablets, commodities } = context;
  let recipeString = "";
  switch (order.settings.type) {
    case T_POWDER:
      recipeString = "Powder";
      break;
    case T_LIQUID:
      recipeString = "Liquid";
      break;
    case T_CAPSULE:
      const capsule = baseUtils.getDocFromCollection(capsules, order.settings.id)!;
      recipeString = `Capsule Size ${capsule.capsule_size}`;
      break;
    case T_TABLET:
      const tablet = baseUtils.getDocFromCollection(tablets, order.settings.id)!;
      recipeString = `Tablet ${tablet.volume}ml, ${tablet.shape}`;
      break;
    case T_CUSTOM:
      recipeString = "Custom";
      break;
    case T_SOFTGEL:
      recipeString = "Softgel";
      break;
    case T_SERVICE:
      recipeString = "Service";
      break;
    default:
      recipeString = "Special";
  }
  const calculation = order.calculations[0];
  if (![T_CUSTOM, T_SERVICE].includes(order.settings.type) && calculation.packagings.length === 0) {
    recipeString += ", Bulk";
  }

  calculation.prices.forEach((com, idx) => {
    const commodity: CommoditiesDocument = baseUtils.getDocFromCollection(commodities, com._id);
    if (!commodity) {
      console.error("Commodity not found for id", com._id.toString());
      return;
    }
    const formattedAmount =
      order.settings.type === T_SERVICE
        ? ""
        : [T_SOFTGEL, T_CUSTOM].includes(order.settings.type)
        ? com.amount === 1
          ? ""
          : `${com.amount} pcs.`
        : calculationUtils.formatAmount(com.amount, undefined, true);
    recipeString += `${idx === 0 ? " - " : ", "}${formattedAmount} ${purgeStringForCSV(
      commodity.title.en.trim(),
      DEFAULT_SEPARATOR
    )}`;
  });
  return recipeString;
}

/**
 * Get an order units string, e.g. 1000 Bottles á 120 Kapseln
 * @param order an order document
 * @param context the data context
 * @returns {string} a string containing a description of the order units
 */
export function getOrderUnitsString(order: OrdersDocument, context: React.ContextType<typeof DataContext>): string {
  const calculation = order.calculations[0];
  const units = order.fulfillment?.totalUnits || calculation.units;

  const packaging: Array<PackagingsDocument> = calculation.packagings
    .map(p => baseUtils.getDocFromCollection(context.packagings, p._id))
    .filter(p => !!p);
  const torso = packaging.find(p => ALLTORSO.includes(p.packaging_type));
  const torsoPrice = torso ? calculation.packagings.find(p => p._id.toString() === torso._id.toString()) : undefined;
  const bulk = (calculation.packagings.length !== 0 || !torso) && order.settings.type !== T_SERVICE;
  const customProduct: CommoditiesDocument | undefined =
    order.settings.type === T_CUSTOM
      ? baseUtils.getDocFromCollection(context.commodities, calculation.prices[0]?._id || "")
      : undefined;

  let unitsString = "";

  if (torso) {
    switch (torso.packaging_type) {
      case T_BOTTLE:
        unitsString = `${units} Dosen`;
        break;
      case T_BAG:
        unitsString = `${units} Beutel`;
        break;
      case T_LIQUIDBOTTLE:
        unitsString = `${units} Flaschen`;
        break;
      case T_BLISTER:
        unitsString = `${units} x ${torsoPrice?.amount || 1} Blister`;
        break;
    }
  } else if (bulk) {
    unitsString = `Bulk, ${units} Einheiten`;
  }

  switch (order.settings.type) {
    case T_POWDER:
      unitsString += ` á ${calculationUtils.formatAmount(order.settings.perUnit, undefined, true)} Pulver`;
      break;
    case T_LIQUID:
      unitsString += ` á ${calculationUtils.formatAmount(order.settings.perUnit, undefined, true)} Flüssigkeit`;
      break;
    case T_CAPSULE:
      unitsString += ` á ${
        torso && torsoPrice && torso.packaging_type === T_BLISTER
          ? order.settings.perUnit / torsoPrice.amount
          : order.settings.perUnit
      } Kapseln`;
      break;
    case T_TABLET:
      unitsString += ` á ${
        torso && torsoPrice && torso.packaging_type === T_BLISTER
          ? order.settings.perUnit / torsoPrice.amount
          : order.settings.perUnit
      } Tabletten`;
      break;
    case T_CUSTOM:
      unitsString += ` á ${order.settings.perUnit} Stück ${
        customProduct ? customProduct.title.en : "zugekauftes Produkt"
      }`;
      break;
    case T_SOFTGEL:
      unitsString += ` á ${order.settings.perUnit} Softgels`;
      break;
    case T_SERVICE:
      unitsString += "Service";
      break;
  }

  return purgeStringForCSV(unitsString, DEFAULT_SEPARATOR);
}

/**
 * Get the amount of a commodity that is required for a list of orders
 * @param commodity a commodity document
 * @param orders list of order documents
 * @returns {number} required amount
 */
const getRequiredAmount = (commodity: CommoditiesDocument, orders: Array<OrdersDocument>): number => {
  return orders.reduce(
    (sum, o) =>
      sum +
      commodityUtils.calculateUsage(
        o,
        o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!,
        commodity.type,
        false
      ).raw,
    0
  );
};

/**
 * Get the amount of a commodity that was booked out for a list of orders
 * @param commodity a commodity document
 * @param orders list of order documents
 * @returns {number} required amount
 */
const getBookedOutAmount = (commodity: CommoditiesDocument, orders: Array<OrdersDocument>): number => {
  return orders.reduce((sum, o) => {
    if (!o.usedBatches) {
      return sum;
    }
    const amount =
      o.usedBatches.find(
        uB =>
          uB.commodityId
            ? uB.commodityId.toString() === commodity._id.toString()
            : commodity.stock.some(s => s.lot === uB.lot) // try fallback for old orders to find matching lot
      )?.used || 0;
    return sum + amount;
  }, 0);
};

/**
 * Get the amount of a commodity that is not booked out yet for a list of orders
 * @param commodity a commodity document
 * @param orders list of order documents
 * @returns {number} required amount
 */
const getNotBookedOutAmount = (commodity: CommoditiesDocument, orders: Array<OrdersDocument>): number => {
  return orders.reduce((sum, o) => {
    if (
      !o.usedBatches ||
      !o.usedBatches.some(uB =>
        uB.commodityId
          ? uB.commodityId.toString() === commodity._id.toString()
          : commodity.stock.some(s => s.lot === uB.lot)
      )
    ) {
      return (
        sum +
        commodityUtils.calculateUsage(
          o,
          o.calculations[0].prices.find(p => p._id.toString() === commodity._id.toString())!,
          commodity.type,
          false
        ).raw
      );
    }
    return sum;
  }, 0);
};

/**
 * Get the offer identifier for an order document
 * @param order any order document
 * @returns {string | undefined} an offer identifier or undefined if not existing
 */
const getOfferIdentifier = (order: CustomOrder | OrdersDocument): string | undefined => {
  if (order.offerIdentifier) return order.offerIdentifier;
  const offerTimelineEntry = order.timeline
    .slice()
    .reverse()
    .find(t => t.type === T_OFFER);
  return offerTimelineEntry?.offernumber;
};

/**
 * Sends messages to a Slack channel or user about ETA changes for orders.
 * @param notificationInformation Array containing notification info
 * @param isCommodity Flag indicating material is commodity
 * @param messageTargets The targets to send the Slack message for
 */
const sendETAChangeMessages = (
  notificationInformation: Array<ETAChangeNotificationInformation>,
  isCommodity: boolean,
  messageTargets: Array<NotificationUser> | null
) => {
  const url = process.env.REACT_APP_BASE_URL;
  for (let i = 0; i < notificationInformation.length; i++) {
    const notificationInfo = notificationInformation[i];
    const infoMessage = `:warning: Target week CW${getWeek(notificationInfo.materialOrderTargetDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4
    })} of ${isCommodity ? "commodity" : "packaging"} order <${url}${isCommodity ? "commodity" : "packaging"}/${
      notificationInfo.materialId
    }|*${notificationInfo.materialAmountDescription} ${
      notificationInfo.materialName
    }*> conflicts with target week CW${getWeek(notificationInfo.orderTargetDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4
    })} of <${url}order/${notificationInfo.orderId}|*AT-${notificationInfo.orderIdentifier}*>.`;
    if (messageTargets)
      messageTargets.forEach(messageTarget => {
        if (messageTarget.slack) slackService.sendMessage(messageTarget.userId, infoMessage);
      });
    else
      slackService.sendMessage(
        "#interne-fehlermeldungen",
        "Message target not found!\nCould not forward following message:\n" + infoMessage
      );
  }
};

/**
 * Get all orders where the given customer is expected to supply the given commodity
 * @param orders list of order documents which should be searched
 * @param customerId the id of the customer who should provide
 * @param commodityId the id of the commodity which should be provided
 * @param customerOrders optional, list of specific orders the customer provided materials for
 * @returns {Array<OrdersDocument>} the orders, if any were found, otherwise empty array
 */
const getOrdersProvidedByCustomer = (
  orders: Array<OrdersDocument>,
  customerId: string,
  commodityId: string,
  customerOrders?: Array<OrderSnapshot>
): Array<OrdersDocument> => {
  let filteredOrders = orders.slice();
  const ids = customerOrders ? customerOrders.map(cO => cO._id.toString()) : null;
  return filteredOrders.filter(
    order =>
      (!ids || ids.includes(order._id.toString())) &&
      order.createdFor.toString() === customerId &&
      order.calculations[0].prices.some(price => price._id.toString() === commodityId && price.supplier === "customer")
  );
};

export default {
  checkOrderStateAfterPriceUpdate,
  isActive,
  isOrder,
  isOrderState,
  isReadyForProduction,
  resolveOrderDescription,
  generateCommoditiesStockList,
  generatePackagingStockList,
  getOrderProgress,
  getMarginProperties,
  getAverageValue,
  calculateEarliestDeliveryDate,
  countTimelineEntries,
  getInvoiceTotal,
  getInvoiceDue,
  getInvoicedUnits,
  getPDFVersion,
  getProductDescription,
  getTrackingId,
  getCommodityPriceChanges,
  getTotalCommodityPriceChanges,
  getPrintingFileReq,
  getSupplierName,
  getSupplierNameById,
  getTotalAmountWithBuffer,
  getStateDescription,
  getCommodityPercentageDone,
  getTotalProductAmount,
  hasFulfillmentPriceInfo,
  filterBySearch,
  filterOrders,
  filterByPeriod,
  filterByVolume,
  filterByInvoice,
  sortOrders,
  totalInvoicedUnits,
  totalInvoiceTurnover,
  mergeStockLists,
  getUnreadCustomerNotes,
  validateOrderDataForProduction,
  getOrderFile,
  getOrderFileName,
  checkOrderShippingPreconditions,
  getRecipeString,
  getOrderUnitsString,
  getRequiredAmount,
  getBookedOutAmount,
  getNotBookedOutAmount,
  getOfferIdentifier,
  sendETAChangeMessages,
  getOrdersProvidedByCustomer,
  getOrderPDFFile
};
