import React, { PureComponent } from "react";
import CommoditiesBaseListing from "./CommoditesBaseListing";
import { DataContext } from "../../context/dataContext";
import commodityUtils from "../../utils/commodityUtils";

interface SoftgelsProps {}
interface SoftgelsState {}

class Softgels extends PureComponent<SoftgelsProps, SoftgelsState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    const { commodities } = this.context;
    const cFiltered = commodities.filter(c => !c.disabled && commodityUtils.hasCorrectType(c, "softgels"));
    return <CommoditiesBaseListing type={"softgels"} commodities={cFiltered} context={this.context} />;
  }
}

export default Softgels;
