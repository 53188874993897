import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CompaniesDocument } from "../../model/companies.types";
import { SuppliersDocument } from "../../model/suppliers.types";
import suppliersUtils from "../../utils/suppliersUtils";

interface CompanyWidgetProps {
  company: SuppliersDocument | CompaniesDocument | "internal" | undefined;
  type?: "supplier" | "company";
  additionalClasses?: string;
  isRawbidsSupplier?: boolean;
}

class CompanyWidget extends PureComponent<CompanyWidgetProps> {
  render() {
    const { company, additionalClasses, type, isRawbidsSupplier } = this.props;
    const nameSplit = !company ? "N/A" : company === "internal" ? "P" : company.name.split(/[ -]+/);
    const supplierDisabled = suppliersUtils.isSupplier(company) ? company.disabled : null;
    return (
      <div className={"kt-user-card-v2 " + (additionalClasses ? additionalClasses : "")}>
        <div className="kt-user-card-v2__pic d-none d-xl-block">
          {isRawbidsSupplier ? (
            <div className="kt-badge kt-badge--xl kt-badge--primary bg-rawbids">
              <img
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: 0,
                  objectFit: "cover"
                }}
                alt="RawbidsIcon"
                src={"https://app.rawbids.com/favicon.ico"}
                className="country-icon"
              />
            </div>
          ) : (
            <div className="kt-badge kt-badge--xl kt-badge--primary">
              {!company
                ? "N/A"
                : company === "internal"
                ? nameSplit
                : (nameSplit.length > 1 ? nameSplit[0][0] + nameSplit[1][0] : company.name.slice(0, 2)).toUpperCase()}
            </div>
          )}
        </div>
        <div className="kt-user-card-v2__details">
          <Link
            to={
              !company
                ? type === "supplier"
                  ? "/suppliers/"
                  : "/companies/"
                : company === "internal"
                ? "/"
                : (type === "supplier" ? "/supplier/" : "/company/") + company._id.toString()
            }
            className={`kt-user-card-v2__name kt-link ${supplierDisabled ? "text-muted" : ""}`}
            onClick={e => e.stopPropagation()}
          >
            {!company
              ? "N/A"
              : company === "internal"
              ? "Private Label Factory"
              : company.name.trim() !== ""
              ? company.name
              : "<Empty Name - Please fill in>"}
            {supplierDisabled && " [DISABLED]"}
          </Link>
          <span className="kt-user-card-v2__email">
            {!company
              ? "N/A"
              : company === "internal"
              ? "Germany"
              : `${company.address[0].city}, ${company.address[0].country}`}
          </span>
        </div>
      </div>
    );
  }
}

export default CompanyWidget;
