import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import { Batch, BatchLocation } from "../../../model/warehouse/batch.types";
import { Reservation, ReservationState } from "../../../model/warehouse/reservation.types";
import { DEFAULTWEIGHTUNIT, SelectedBatchEntryType, SelectedCommodityEntryType } from "../../../utils/warehouseUtils";
import { BatchStatus, compareBatchLocations, getBatchLocationStatus } from "../../../utils/batchUtils";
import baseUtils, { formatNumValue } from "../../../utils/baseUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import dbService from "../../../services/dbService";
import {
  getBatchIdFromSelectedEntries,
  isSelectedBatchEntries,
  isSelectedCommodityEntries,
  WarehouseActionNumber
} from "../../../utils/warehouseActionUtils";

enum CancelMode {
  CANCEL,
  REASSIGN
}

interface CancelReservationModalState {
  saving: boolean;
  batchEntry?: Batch;
  selectedReservedLocations: Array<BatchLocation>;
  relevantReservations: Array<Reservation>;
  selectedReservations: Array<string>;
}

const getDefaultState = (): CancelReservationModalState => {
  return {
    saving: false,
    batchEntry: undefined,
    selectedReservedLocations: [],
    relevantReservations: [],
    selectedReservations: []
  };
};

const CancelReservationModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const dispatch = useWarehouseDispatch();
  const { batch, reservation } = dataContext;
  const { selectedEntries } = warehouseContext;

  const [state, setState] = useState(getDefaultState());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    let selectedReservedLocations: Array<BatchLocation> = [];
    let relevantReservations: Array<Reservation> = [];
    let selectedReservations: Array<string> = [];
    if (!show || !(selectedEntries.length > 0 || actionTrigger?.batchId)) return;
    const newBatch = batch.find(
      b => b._id.toString() === (actionTrigger?.batchId ?? getBatchIdFromSelectedEntries(selectedEntries))
    );
    if (newBatch) {
      // All locations from selection
      const selectedLocationsSet = new Set(
        actionTrigger?.locationId
          ? [actionTrigger?.locationId]
          : isSelectedBatchEntries(selectedEntries)
          ? selectedEntries.filter(sE => sE.type === SelectedBatchEntryType.LOCATION).map(l => l.childId)
          : isSelectedCommodityEntries(selectedEntries)
          ? selectedEntries.filter(sE => sE.type === SelectedCommodityEntryType.BATCH_LOCATION).map(l => l.locationId)
          : []
      );
      // All selected locations from the current batch which are reserved or partially reserved
      selectedReservedLocations = newBatch.locations.filter(l => {
        const locationStatus = getBatchLocationStatus(newBatch._id.toString(), l, reservation);
        const isReserved = locationStatus === BatchStatus.RESERVED || locationStatus === BatchStatus.PARTIALLY_RESERVED;
        return isReserved && selectedLocationsSet.has(l._id.toString());
      });
      if (selectedReservedLocations.length > 0) {
        const openReservations = reservation.filter(r => r.state === ReservationState.OPEN);
        const allLocationReservations = selectedReservedLocations.flatMap(loc =>
          openReservations.filter(r =>
            r.batches.some(
              b => b.batch._id.toString() === newBatch._id.toString() && compareBatchLocations(b.location, loc.location)
            )
          )
        );
        const uniqueReservations = new Set(allLocationReservations);
        // All reservations including the selected locations
        relevantReservations = Array.from(uniqueReservations);
        // Pre-Select all found reservations by default
        selectedReservations = relevantReservations.map(r => r._id.toString());
      }
    }
    setState(prevState => {
      return {
        ...prevState,
        batchEntry: newBatch,
        selectedReservedLocations,
        relevantReservations,
        selectedReservations
      };
    });
  }, [show, batch, reservation, selectedEntries, actionTrigger]);

  const errors = useMemo(() => {
    const errors = new Set<string>();
    if (state.selectedReservedLocations.length === 0) {
      errors.add(i18n.t("warehouse:noReservationForSelectionFound"));
    }
    if (state.selectedReservations.length === 0) {
      errors.add(i18n.t("warehouse:reservationNoOrderSelectedError"));
    }
    return Array.from(errors);
  }, [state.selectedReservedLocations, state.selectedReservations]);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  const handleCheckReservation = useCallback(
    (reservationId: string) => {
      let selectedReservations = _.cloneDeep(state.selectedReservations);
      const existingSelection = selectedReservations.indexOf(reservationId);
      if (existingSelection !== -1) {
        selectedReservations = selectedReservations.filter(r => r !== reservationId);
      } else {
        selectedReservations.push(reservationId);
      }
      setState(prevState => {
        return {
          ...prevState,
          selectedReservations
        };
      });
    },
    [state.selectedReservations]
  );

  const handleCancelReservation = useCallback(
    async (mode: CancelMode) => {
      const { batchEntry, selectedReservedLocations, selectedReservations } = state;
      if (!batchEntry || selectedReservedLocations.length === 0 || selectedReservations.length === 0) return;
      setState(prevState => {
        return { ...prevState, saving: true };
      });
      try {
        const result = await dbService.callFunction(
          "cancelReservations",
          [selectedReservations, batchEntry._id.toString(), selectedReservedLocations],
          true
        );
        if (result) {
          toast.success(i18n.t("warehouse:reservationCancellationSuccess"));
          onHide();
          if (mode === CancelMode.REASSIGN) {
            // Open reservation modal with same selection or call with same location after successful cancellation
            const payload = actionTrigger
              ? {
                  actionNumber: WarehouseActionNumber.CREATE_RESERVATION,
                  batchId: actionTrigger.batchId,
                  locationId: actionTrigger.locationId
                }
              : { actionNumber: WarehouseActionNumber.CREATE_RESERVATION };
            dispatch({
              type: WarehouseActionType.TRIGGER_ACTION,
              payload: payload
            });
          }
        } else {
          toast.error(i18n.t("warehouse:reservationCancellationFailure"));
        }
      } finally {
        setState(prevState => {
          return { ...prevState, saving: false };
        });
      }
    },
    [state.batchEntry, state.selectedReservations, state.selectedReservedLocations, actionTrigger]
  );

  const tableHeader = [{ title: "#", size: 1 }, { title: i18n.t("common:order") }, { title: i18n.t("common:amount") }];

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton onClick={onHide}>
        <Modal.Title as={"h5"}>{i18n.t("warehouse:deleteReservation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-3 font-weight-bold text-black">{i18n.t("warehouse:cancelReservationConfirmation")}</h4>
          <div className="alert alert-warning" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning"></i>
            </div>
            <div className="alert-text">{i18n.t("warehouse:cancelReservationOrderWarning")}</div>
          </div>
          {state.batchEntry && state.selectedReservedLocations.length > 0 ? (
            <>
              <hr className="w-100" />
              <BaseListing
                headerDefinition={tableHeader}
                documents={state.relevantReservations}
                bodyContent={
                  <>
                    {paginate(state.relevantReservations, currentPage, pageSize).map(
                      reservation =>
                        state.batchEntry && (
                          <CancelReservationRow
                            key={reservation._id.toString()}
                            batch={state.batchEntry}
                            selectedReservedLocations={state.selectedReservedLocations}
                            reservation={reservation}
                            isSelected={state.selectedReservations.includes(reservation._id.toString())}
                            onCheckReservation={handleCheckReservation}
                          />
                        )
                    )}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
              />
            </>
          ) : (
            <div className="text-muted">{i18n.t("warehouse:noReservationForSelectionFound")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          saving={state.saving}
          onClick={onHide}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:reassign")}
          className="btn btn-success"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.REASSIGN)}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:cancelReservation")}
          className="btn btn-danger"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.CANCEL)}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface CancelReservationRowProps {
  batch: Batch;
  selectedReservedLocations: Array<BatchLocation>;
  reservation: Reservation;
  isSelected: boolean;
  onCheckReservation: (reservationId: string) => void;
}

const CancelReservationRow: React.FC<CancelReservationRowProps> = ({
  batch,
  reservation,
  selectedReservedLocations,
  isSelected,
  onCheckReservation
}) => {
  const reservationBatches = useMemo(() => {
    const reservedBatches = reservation.batches.filter(b => b.batch._id.toString() === batch._id.toString());
    // Only regard reserved batches from the selected location
    return selectedReservedLocations.flatMap(loc =>
      reservedBatches.filter(b => compareBatchLocations(b.location, loc.location))
    );
  }, [reservation, batch, selectedReservedLocations]);

  const reservedAmount = useMemo(() => {
    // Currently assumes every batch amount has the same unit (kg)
    const totalAmount = reservationBatches.reduce(
      (totalAmount, batch) => totalAmount + batch.amountAtLocation.value,
      0
    );
    return { value: totalAmount, unit: DEFAULTWEIGHTUNIT };
  }, [reservationBatches]);

  return (
    <tr className={"kt-datatable__row d-table-row  "}>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onCheckReservation(reservation._id.toString())}
              className="ml-0 kt-checkbox--solid"
            />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {`AT-${reservation.order.identifier} ${baseUtils.truncateString(reservation.order.title, 25)}`}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">{formatNumValue(reservedAmount)}</div>
        </div>
      </td>
    </tr>
  );
};

export default CancelReservationModal;
