import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import OrderProgressbar from "./OrderProgressbar";
import { CustomOrder, PackagingOrderOptionInformation } from "../../CustomTypes";
import { DataContext } from "../../../../context/dataContext";
import { pricing } from "../../../../model/orders.types";
import { PackagingsDocument } from "../../../../model/packagings.types";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import baseUtils from "../../../../utils/baseUtils";
import dateUtils from "../../../../utils/dateUtils";
import orderUtils from "../../../../utils/orderUtils";
import packagingUtils from "../../../../utils/packagingUtils";
import PackagingOrderOptionsModal from "../../modals/PackagingOrderOptionsModal";
import DeliverPackagingOrderModal from "../../../packaging/modals/DeliverPackagingOrderModal";
import { T_PACKAGINGFROMCUSTOMERDELIVERED, T_PACKAGINGFROMSTOCK } from "../../../../utils/timelineUtils";
import userService from "../../../../services/userService";
import dbService, { ORDERS, UpdateAction } from "../../../../services/dbService";

interface OrderPackagingOrderProps {
  order: CustomOrder;
  packaging: Array<PackagingsDocument>;
  suppliers: Array<SuppliersDocument>;
  readOnly?: boolean;
}

interface OrderPackagingOrderState {
  selectedPackaging: PackagingOrderOptionInformation | null;
}

class OrderPackagingOrder extends PureComponent<OrderPackagingOrderProps, OrderPackagingOrderState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: OrderPackagingOrderProps) {
    super(props);
    this.state = {
      selectedPackaging: null
    };
  }

  /**
   * Handle selection of an order to show order options modal
   * @param price the packaging price entry that will be updated
   * @param packaging the packaging document related to the price
   * @param supplier the supplier related to the price
   */
  handleShowOrderOptions = (
    price: pricing,
    packaging: PackagingsDocument,
    supplier: SuppliersDocument | "customer" | "ownstock" | "accumulatedstock" | "custom"
  ) => {
    this.setState({ selectedPackaging: { price, packaging, supplier } });
  };

  handleClose = () => this.setState({ selectedPackaging: null });

  handleBookStockPrice = async (price: pricing) => {
    const { order } = this.props;
    if (price.supplier !== "ownstock" && price.supplier !== "customer") return;
    const pricePost = _.cloneDeep(price);
    const userId = userService.getUserId();
    const currentDate = new Date();
    pricePost.ordered = currentDate;
    pricePost.userOrdered = userId;
    pricePost.delivered = currentDate;
    pricePost.userDelivered = userId;
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
    try {
      const timelineEntry = {
        id: new BSON.ObjectId(),
        type: price.supplier === "customer" ? T_PACKAGINGFROMCUSTOMERDELIVERED : T_PACKAGINGFROMSTOCK,
        date: new Date(),
        person: userService.getUserId(),
        packaging: price._id
      };
      const action: UpdateAction = {
        collection: ORDERS,
        filter: { _id: order._id },
        update: {
          "calculations.0.packagings.$[p].ordered": new Date(),
          "calculations.0.packagings.$[p].userOrdered": userService.getUserId(),
          "calculations.0.packagings.$[p].delivered": new Date(),
          "calculations.0.packagings.$[p].userDelivered": userService.getUserId()
        },
        push: {
          timeline: timelineEntry
        },
        arrayFilters: [{ "p._id": price._id }]
      };
      if (newState && newState !== order.state) _.set(action, "update.state", newState);
      const result = await dbService.updatesAsTransaction([action]);
      if (result) {
        toast.success("Packaging successfully booked from");
      } else toast.error("Booking failed");
    } catch (e) {
      toast.error("Booking failed. Please try again.");
      console.error("Booking failed:", e);
    }
  };

  render() {
    const { order, packaging, suppliers, readOnly } = this.props;
    const { selectedPackaging } = this.state;
    return (
      <>
        {selectedPackaging && (
          <PackagingOrderOptionsModal
            order={order}
            selectedPackaging={selectedPackaging}
            context={this.context}
            onClose={this.handleClose}
          />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
          <table className="kt-datatable__table d-table ">
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Amount</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "23%" }}>
                  <span>Packaging</span>
                </th>
                {!readOnly && (
                  <th className="kt-datatable__cell d-table-cell" style={{ width: "23%" }}>
                    <span>Supplier</span>
                  </th>
                )}
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Quantity</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>€</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Ordered</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
                  <span>Delivered</span>
                </th>
                {!readOnly && (
                  <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "8%" }}>
                    <span>Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {order.calculations[0].packagings.map(price => {
                const units = order.calculations[0].units;
                const pack = packaging.find(p => p._id.toString() === price._id.toString());
                if (!pack) return null;
                let supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock" =
                  typeof price.supplier === "string"
                    ? price.supplier
                    : suppliers.find(s => s._id.toString() === price.supplier.toString())!;
                if (!supplier) supplier = baseUtils.getDocFromCollection(this.context.suppliers, price.supplier)!;
                return (
                  <OrderPackagingOrderEntry
                    key={price._id.toString()}
                    units={units}
                    price={price}
                    readOnly={readOnly}
                    packaging={pack}
                    supplier={supplier}
                    onShowOrderOptions={() => this.handleShowOrderOptions(price, pack, supplier)}
                    order={order}
                    onBookStockPrice={this.handleBookStockPrice}
                    context={this.context}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

interface OrderPackagingOrderEntryProps {
  units: number;
  price: pricing;
  readOnly?: boolean;
  packaging: PackagingsDocument;
  supplier: SuppliersDocument | "accumulatedstock" | "custom" | "customer" | "ownstock";
  onShowOrderOptions: () => void;
  onBookStockPrice: (price: pricing) => void;
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

const OrderPackagingOrderEntry: React.FunctionComponent<OrderPackagingOrderEntryProps> = ({
  units,
  price,
  readOnly,
  packaging,
  supplier,
  onShowOrderOptions,
  onBookStockPrice,
  order,
  context
}) => {
  const totalQuantity = units * price.amount;
  if (!packaging) return null;
  const canOrder = accessUtils.canPerformAction(ACTIONS.ORDERPACKAGINGORDER);
  const po = context.packagingOrders.find(
    pOrder =>
      pOrder.relatedOrders.some(o => o.toString() === order._id.toString()) &&
      pOrder.packaging.toString() === packaging._id.toString()
  );
  return (
    <tr className="kt-datatable__row d-table-row">
      <td className=" kt-datatable__cell d-table-cell">
        <span>
          <span>{price.amount + (price.amount === 1 ? " unit" : " units")}</span>
        </span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <Link className="kt-user-card-v2__name kt-link" to={"/packaging/" + packaging._id.toString()}>
              {packagingUtils.getPackagingType(packaging.packaging_type)}
              {packaging.article_number && <span className="text-success"> {packaging.article_number}</span>}
            </Link>
            <span className="kt-user-card-v2__email">{packagingUtils.resolvePackagingProperties(packaging)}</span>
          </div>
        </div>
        <OrderProgressbar price={price} />
      </td>
      {!readOnly && (
        <td className="kt-datatable__cell d-table-cell">
          <span>{orderUtils.getSupplierName(supplier)}</span>
        </td>
      )}
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <span className="kt-user-card-v2__email">
                {price.orderquantity ? price.orderquantity : totalQuantity + " req."}
              </span>
            ) : (
              <>
                <span className="kt-user-card-v2__name">{price.orderquantity}</span>
                <span className="kt-user-card-v2__email">{totalQuantity + " req."}</span>
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <>
                <span className="kt-user-card-v2__email">
                  {price.price ? baseUtils.formatEuro(price.price) + (price.amount > 1 ? "/pcs." : "/unit") : "-"}
                </span>
                <br />
                {price.amount > 1 && (
                  <span className="kt-user-card-v2__email">
                    {baseUtils.formatEuro(price.price * price.amount)}
                    /unit
                  </span>
                )}
              </>
            ) : (
              <>
                <span className="kt-user-card-v2__name">
                  {price.orderquantity ? baseUtils.formatEuro(price.price * price.orderquantity) : "-"}
                </span>
                <span className="kt-user-card-v2__email">
                  {baseUtils.formatEuro(price.price)}/{price.amount > 1 ? "pcs." : "unit"}
                </span>
                <br />
                {price.amount > 1 && (
                  <span className="kt-user-card-v2__email">
                    {baseUtils.formatEuro(price.price * price.amount)}
                    /unit
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <span className="kt-user-card-v2__email">-</span>
            ) : (
              <>
                <span className="kt-user-card-v2__name">{dateUtils.getTimeAgo(price.ordered)}</span>
                <span className="kt-user-card-v2__email">
                  {price.ordered.toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            {!price.ordered ? (
              <span className="kt-user-card-v2__email">-</span>
            ) : !!price.delivered ? (
              <>
                <span className="kt-user-card-v2__name text-success">
                  {supplier === "ownstock" ? "Stock" : "Delivered"}
                </span>
                <span className="kt-user-card-v2__email">
                  {price.delivered.toLocaleDateString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </span>
              </>
            ) : (
              <>
                <span className="kt-user-card-v2__name text-warning">Pending</span>
                <span className="kt-user-card-v2__email">
                  {price.eta
                    ? price.eta.toLocaleDateString("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })
                    : "unknown"}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      {!readOnly && (
        <td className="kt-datatable__cell d-table-cell text-right">
          <div className="btn-group">
            {!price.ordered ? (
              <div className="btn-group">
                {price.supplier === "ownstock" || price.supplier === "customer" ? (
                  <button className="btn btn-sm btn-primary kt-font-bold" onClick={() => onBookStockPrice(price)}>
                    Book
                  </button>
                ) : canOrder ? (
                  <Link
                    to={"/packaging/" + packaging._id.toString() + "/" + order._id.toString()}
                    className="btn btn-sm btn-primary kt-font-bold"
                  >
                    Order
                  </Link>
                ) : (
                  <button className="btn btn-sm btn-primary kt-font-bold disabled" disabled>
                    Order
                  </button>
                )}
                <button className="btn btn-sm btn-primary px-2" onClick={onShowOrderOptions}>
                  <i className="fa fa-cog px-1" />
                </button>
              </div>
            ) : po && !po.delivered ? (
              <DeliverPackagingOrderModal
                packaging={packaging}
                packagingOrder={po}
                context={context}
                buttonCSSClasses="btn btn-sm btn-secondary px-2"
              />
            ) : (
              <button className="btn btn-sm btn-secondary px-2" onClick={onShowOrderOptions}>
                <i className="fa fa-cog px-1" />
              </button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default OrderPackagingOrder;
