import { BSON } from "realm-web";
import React, { PureComponent, useEffect, useState } from "react";
import {
  CustomOrder,
  ExtendedBatch,
  ExtendedPackagingBatch,
  PackagingStockUpdate,
  StockUpdate
} from "../../CustomTypes";
import baseUtils from "../../../../utils/baseUtils";
import { T_CAPSULE, T_LIQUID, T_POWDER, T_TABLET } from "../../OrderHelper";
import { CommoditiesDocument } from "../../../../model/commodities.types";
import { PackagingsDocument } from "../../../../model/packagings.types";
import packagingUtils from "../../../../utils/packagingUtils";
import { DataContext } from "../../../../context/dataContext";
import commodityUtils from "../../../../utils/commodityUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface StockUpdateItemProps {
  location: BSON.ObjectId;
  stockUpdate: StockUpdate | PackagingStockUpdate;
  onUpdateStock: (materialID: string, batchID: string) => (e: any) => void;
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
  showEditedBatches: boolean;
  disabled?: boolean;
}

interface StockUpdateItemState {
  hide: boolean;
}

class StockUpdateItem extends PureComponent<StockUpdateItemProps, StockUpdateItemState> {
  constructor(props: StockUpdateItemProps) {
    super(props);
    this.state = { hide: false };
  }

  /**
   * Get the amount of edited batches.
   * @returns Amount of edited batches
   */
  editedBatches() {
    const { stockUpdate } = this.props;
    let batches = 0;
    for (let stock of stockUpdate.stock) {
      if (+stock.used > 0) {
        batches += 1;
      }
    }
    return batches;
  }

  render() {
    const { disabled, showEditedBatches, stockUpdate, onUpdateStock, order, context, location } = this.props;
    const { hide } = this.state;
    const type = "commodity" in stockUpdate ? "commodity" : "packaging";
    let material: CommoditiesDocument | PackagingsDocument | null;
    if ("commodity" in stockUpdate) {
      material = stockUpdate.commodity;
    } else if ("packaging" in stockUpdate) {
      material = stockUpdate.packaging;
    } else {
      material = null;
    }
    if (!material) return;
    // Only use batches stored at the manufacturer
    // @ts-ignore
    const filteredStock = stockUpdate.stock.filter(
      (b: ExtendedBatch | ExtendedPackagingBatch) => b.stock.location.toString() === location.toString()
    );
    if (disabled && !filteredStock.some((fs: ExtendedBatch | ExtendedPackagingBatch) => +fs.used > 0)) return <></>;
    const editedBatches = this.editedBatches();
    return (
      <>
        <hr className="mb-0" />
        <div className="form-group row my-0">
          <span className={"col-11 col-form-label kt-font-bold kt-font-dark " + (hide && "pb-0")}>
            {"title" in material
              ? material.title.en
              : packagingUtils.getShortPackagingInfo(material as PackagingsDocument)}
            <span className="col-form-label kt-font-regular text-muted mx-2">
              {"subtitle" in material
                ? material.subtitle.en
                : packagingUtils.resolvePackagingProperties(material as PackagingsDocument)}
            </span>
            {showEditedBatches && (
              <span className="text-warning">
                <i className="fa fa-info-circle mr-1" />
                {editedBatches} {editedBatches === 1 ? "batch has" : "batches have"} been edited.
              </span>
            )}
          </span>
          <span className={"col-1 col-form-label " + (hide && "pb-0")}>
            <span className="kt-link p-0 float-right" onClick={() => this.setState({ hide: !hide })}>
              {hide ? "Show" : "Hide"}
            </span>
          </span>
        </div>
        <div className={hide ? "d-none" : "d-block"}>
          {filteredStock.length > 0 ? (
            filteredStock.map((b: ExtendedBatch | ExtendedPackagingBatch) => (
              <BatchRow
                key={b.id.toString()}
                batch={b}
                context={context}
                order={order}
                onUpdateStock={onUpdateStock(material!._id.toString(), b.id.toString())}
                type={type}
                material={material!}
                disabled={!!disabled}
              />
            ))
          ) : (
            <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
              <div className="col-12">
                <span className="form-text text-danger kt-font-bolder">
                  <i className="fa fa-info-circle mr-2" />
                  {type === "commodity" ? "Commodity" : "Packaging"} not on stock. Nothing to update.
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

interface BatchRowProps {
  batch: ExtendedBatch | ExtendedPackagingBatch;
  order: CustomOrder;
  material: PackagingsDocument | CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
  onUpdateStock: (e: any) => void;
  type: "packaging" | "commodity";
  disabled: boolean;
}

const BatchRow: React.FunctionComponent<BatchRowProps> = ({
  batch,
  context,
  order,
  material,
  onUpdateStock,
  type,
  disabled
}) => {
  const { suppliers } = context;
  const [usedAmount, setUsedAmount] = useState(batch.used);
  const allocatedAmount =
    type === "commodity"
      ? commodityUtils.calculateTotalAllocatedStock(material._id, batch.stock._id, context.orders, order._id)
      : packagingUtils.calculateTotalAllocatedStock(material._id, batch.stock._id, context.orders, order._id);

  useEffect(() => {
    setUsedAmount(batch.used);
  }, [batch]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    if (+value >= 0) setUsedAmount(value);
  };

  /**
   * Get the name of a supplier from a stock object
   * @returns {string} name of the supplier
   */
  const getSupplierName = () => {
    if (BSON.ObjectId.isValid(batch.stock.supplier)) {
      const document = baseUtils.getDocFromCollection(suppliers, batch.stock.supplier);
      if (document) return document.name;
    }
    switch (batch.stock.supplier) {
      case "ownstock":
        return "Stock";
      case "accumulatedstock":
        return "Accumulated Stock";
      case "customer":
        return "Delivered by customer";
      default:
        return "Unknown stock";
    }
  };

  /**
   * Get the used amount of the given commodity.
   * @returns {number} The amount that was used or the amount of the batch when no amount is entered yet
   */
  const getRemainingAmount = () => {
    return Number((batch.stock.amount - +batch.used - allocatedAmount).toFixed(2));
  };

  const unit =
    type === "commodity"
      ? [T_CAPSULE, T_TABLET, T_POWDER, T_LIQUID].includes(order.settings.type)
        ? "kg"
        : "tsd"
      : "pcs.";

  if (disabled && usedAmount === "0") return <></>;
  const nothingLeft =
    batch.stock.amount - allocatedAmount <= 0 || Number(usedAmount) >= batch.stock.amount - allocatedAmount;

  return (
    <div className="form-group row" style={{ marginBottom: "0.5rem" }}>
      <div className="col-4">
        <input
          className="form-control"
          type="text"
          disabled={true}
          value={`${batch.stock.lot} (${getSupplierName()})`}
        />
      </div>
      <div className="col-3">
        <div className="input-group">
          <input
            className="form-control"
            type="number"
            min={0}
            value={usedAmount}
            onChange={disabled ? undefined : handleAmountChange}
            onBlur={disabled ? undefined : onUpdateStock}
            disabled={disabled}
          />
          <div className="input-group-append">
            <span className="input-group-text">{unit}</span>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="input-group">
          <div className="input-group-prepend">
            <OverlayTrigger
              overlay={
                <Tooltip id="takeEverything">
                  <span>{nothingLeft ? "Nothing left to use in the batch" : "Use the whole batch"}</span>
                </Tooltip>
              }
            >
              <button
                className={"btn btn-secondary" + (nothingLeft ? " disabled" : "")}
                onClick={
                  disabled || nothingLeft
                    ? undefined
                    : () => {
                        const newValue = (batch.stock.amount - allocatedAmount).toString();
                        setUsedAmount(newValue);
                        onUpdateStock({ target: { value: newValue } });
                      }
                }
              >
                <i className="fas fa-angle-double-left pr-0" />
              </button>
            </OverlayTrigger>
          </div>
          <input className="form-control" disabled={true} value={getRemainingAmount()} />
          <div className="input-group-append">
            <span className="input-group-text">{unit}</span>
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="input-group">
          <input className="form-control" disabled={true} value={allocatedAmount} />
          <div className="input-group-append">
            <span className="input-group-text">{unit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockUpdateItem;
