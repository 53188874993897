import React, { useCallback, useEffect, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { OrdersDocument } from "../../../../model/orders.types";
import {
  CommodityInformation,
  BatchLocationReservationInformation,
  BatchLocationError
} from "../../../../model/warehouse/customTypes.types";
import baseUtils from "../../../../utils/baseUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { DEFAULTWEIGHTUNIT } from "../../../../utils/warehouseUtils";
import Pagination, { paginate } from "../../../common/Pagination";
import { useDataContext } from "../../../../context/dataContext";

interface OrderReservationInformationProps {
  selectedOrder: OrdersDocument;
  selectedCommodityId?: string;
  commodityInformation: Map<string, CommodityInformation>;
  orderReservations: Array<BatchLocationReservationInformation>;
  batchLocationErrors: Array<BatchLocationError>;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderReservationInformation: React.FC<OrderReservationInformationProps> = ({
  selectedOrder,
  selectedCommodityId,
  commodityInformation,
  orderReservations,
  batchLocationErrors,
  onSelectCommodity
}) => {
  const readyForProduction = useMemo(() => {
    const allCommodities = Array.from(commodityInformation.values());
    const reservedCommodities = allCommodities.filter(
      commodity => commodity.reservedAmount.value >= commodity.neededAmount.value
    );
    return allCommodities.length === reservedCommodities.length;
  }, [commodityInformation]);

  return (
    <>
      <h4 className="font-weight-bold text-black">{`AT-${selectedOrder.identifier} ${selectedOrder.title}`}</h4>
      <div className="text-black mb-3">{selectedOrder.subtitle}</div>
      <div className="p-2 py-4 mb-4 bg-light">
        <div className="row mx-3 justify-content-end">
          <OrderCommodityInfo
            selectedOrder={selectedOrder}
            orderReservations={orderReservations}
            selectedCommodity={selectedCommodityId ? commodityInformation.get(selectedCommodityId) : undefined}
            readyForProduction={readyForProduction}
          />
          <OrderRecipeInfo
            selectedOrder={selectedOrder}
            orderReservations={orderReservations}
            commodityInformation={commodityInformation}
            selectedCommodityId={selectedCommodityId}
            batchLocationErrors={batchLocationErrors}
            onSelectCommodity={onSelectCommodity}
          />
        </div>
      </div>
    </>
  );
};

interface OrderCommodityInfoProps {
  selectedOrder: OrdersDocument;
  selectedCommodity?: CommodityInformation;
  orderReservations: Array<BatchLocationReservationInformation>;
  readyForProduction: boolean;
}

const OrderCommodityInfo: React.FC<OrderCommodityInfoProps> = ({
  selectedOrder,
  selectedCommodity,
  orderReservations,
  readyForProduction
}) => {
  const dataContext = useDataContext();
  const { manufacturers } = dataContext;

  const manufacturer = useMemo(
    () => manufacturers.find(m => m._id.toString() === selectedOrder.settings.manufacturer.toString()),
    [selectedOrder, manufacturers]
  );

  const pendingAmount = useMemo(() => {
    if (!selectedCommodity) return 0;
    const { reservedAmount, neededAmount } = selectedCommodity;
    return reservedAmount.value >= neededAmount.value ? 0 : neededAmount.value - reservedAmount.value;
  }, [selectedCommodity]);

  const selectedReservationAmount = useMemo(() => {
    return selectedCommodity && orderReservations.length > 0
      ? orderReservations.reduce((commodityReservedAmount, reservation) => {
          if (reservation.commodityId === selectedCommodity.commodityId) {
            commodityReservedAmount += reservation.amount.value;
          }
          return commodityReservedAmount;
        }, 0)
      : 0;
  }, [orderReservations, selectedCommodity]);

  return (
    <div className="col-6">
      <h5 className="text-black font-weight-bold mb-3 d-block">{i18n.t("common:informationPlural")}</h5>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-3 text-black kt-font-bold">{i18n.t("warehouse:production")}</div>
        <div className="col-9 text-black text-right">{manufacturer ? manufacturer.name : "-"}</div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:targetDate")}</div>
        <div className="col-6 text-warning text-right">
          {selectedOrder.targetDate ? baseUtils.formatDate(selectedOrder.targetDate) : "-"}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100">
        <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:readyForProduction")}</div>
        <div className={"col-6 text-right " + (readyForProduction ? "text-success" : "text-danger")}>
          {readyForProduction ? i18n.t("packaging:yes") : i18n.t("packaging:no")}
        </div>
      </div>
      <div className="form-group form-group-xs d-flex row w-100 mt-3">
        <div className="col-3 text-black kt-font-bold">{i18n.t("warehouse:commodity")}</div>
        <div className="col-9 text-black text-right">
          {selectedCommodity?.commodityName ?? i18n.t("warehouse:reservationNoCommoditySelectedError")}
        </div>
      </div>
      {selectedCommodity && (
        <>
          <div className="form-group form-group-xs d-flex row w-100">
            <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:neededAmount")}</div>
            <div className="col-6 text-black text-right">
              {calculationUtils.formatAmount(selectedCommodity.neededAmount.value, 2)}
            </div>
          </div>
          <div className="form-group form-group-xs d-flex row w-100">
            <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:alreadyReserved")}</div>
            <div className="col-6 text-black text-right">
              {selectedCommodity.reservedAmount.value === 0
                ? `0${DEFAULTWEIGHTUNIT}`
                : calculationUtils.formatAmount(selectedCommodity.reservedAmount.value, 2)}
              {selectedCommodity.reservedAmount.value > selectedCommodity.neededAmount.value && (
                <span className="text-success">{` (+${calculationUtils.formatAmount(
                  selectedCommodity.reservedAmount.value - selectedCommodity.neededAmount.value,
                  2
                )})`}</span>
              )}
            </div>
          </div>
          <div className="form-group form-group-xs d-flex row w-100">
            <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:pending")}</div>
            <div className="col-6 text-black text-right">
              {pendingAmount === 0 ? `0${DEFAULTWEIGHTUNIT}` : calculationUtils.formatAmount(pendingAmount, 2)}
            </div>
          </div>
          <div className="form-group form-group-xs d-flex row w-100">
            <div className="col-6 text-black kt-font-bold">{i18n.t("warehouse:selection")}</div>
            <div className="col-6 text-black text-right">
              {selectedReservationAmount === 0
                ? `0${DEFAULTWEIGHTUNIT}`
                : calculationUtils.formatAmount(selectedReservationAmount, 2)}
              {selectedReservationAmount > pendingAmount && (
                <span className="text-success">{` (+${calculationUtils.formatAmount(
                  selectedReservationAmount - pendingAmount,
                  2
                )})`}</span>
              )}
              {selectedReservationAmount < pendingAmount && (
                <span className="text-danger">{` (-${calculationUtils.formatAmount(
                  pendingAmount - selectedReservationAmount,
                  2
                )})`}</span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface OrderRecipeInfoProps {
  selectedOrder: OrdersDocument;
  orderReservations: Array<BatchLocationReservationInformation>;
  commodityInformation: Map<string, CommodityInformation>;
  selectedCommodityId?: string;
  batchLocationErrors: Array<BatchLocationError>;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderRecipeInfo: React.FC<OrderRecipeInfoProps> = ({
  selectedOrder,
  orderReservations,
  commodityInformation,
  selectedCommodityId,
  batchLocationErrors,
  onSelectCommodity
}) => {
  const DEFAULT_RECIPE_PAGESIZE = 5;

  const [currentPage, setCurrentPage] = useState<number>(1);

  // Reset current page if selected order changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedOrder]);

  const usedCommodities = useMemo(() => Array.from(commodityInformation.values()), [commodityInformation]);

  const totalReady = useMemo(() => {
    let count = 0;
    for (let i = 0; i < usedCommodities.length; i++) {
      const currentCommodity = usedCommodities[i];
      const { reservedAmount, neededAmount } = currentCommodity;
      const pending = neededAmount.value - reservedAmount.value;
      if (pending <= 0) {
        count++;
      } else {
        const newReservedAmount = orderReservations.reduce((totalAmount, reservation) => {
          if (reservation.commodityId === currentCommodity.commodityId) {
            return totalAmount + reservation.amount.value;
          } else {
            return totalAmount;
          }
        }, 0);
        if (newReservedAmount > pending) count++;
      }
    }
    return count;
  }, [usedCommodities, orderReservations]);

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);

  return (
    <div className="col-6 kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive px-3">
      <h5 className="text-black font-weight-bold mb-3 d-block">
        {i18n.t("pdf:recipe")} ({totalReady} / {usedCommodities.length} {i18n.t("warehouse:ready")})
      </h5>
      <table className="kt-datatable__table d-table ">
        <tbody className="bg-light">
          {paginate(usedCommodities, currentPage, DEFAULT_RECIPE_PAGESIZE).map(cI => (
            <OrderRecipeInfoRow
              key={cI.commodityId}
              commodityInformation={cI}
              orderReservations={orderReservations.filter(r => r.commodityId === cI.commodityId)}
              selectedCommodityId={selectedCommodityId}
              batchLocationErrors={batchLocationErrors}
              onSelectCommodity={onSelectCommodity}
            />
          ))}
        </tbody>
      </table>
      <div className="kt-datatable__pager kt-datatable--paging-loaded py-3 px-0">
        <Pagination
          itemsCount={usedCommodities.length}
          pageSize={DEFAULT_RECIPE_PAGESIZE}
          onPageChange={handleChangePage}
          currentPage={currentPage}
          additionalPageSizeClasses={"text-right"}
          compact={true}
        />
      </div>
    </div>
  );
};

interface OrderRecipeInfoRowProps {
  commodityInformation: CommodityInformation;
  orderReservations: Array<BatchLocationReservationInformation>;
  selectedCommodityId?: string;
  batchLocationErrors: Array<BatchLocationError>;
  onSelectCommodity: (commodityId: string) => void;
}

const OrderRecipeInfoRow: React.FC<OrderRecipeInfoRowProps> = ({
  commodityInformation,
  orderReservations,
  selectedCommodityId,
  batchLocationErrors,
  onSelectCommodity
}) => {
  const isReserved = commodityInformation.reservedAmount.value >= commodityInformation.neededAmount.value;
  const hasErrors = batchLocationErrors.filter(e => e.commodityId === commodityInformation.commodityId).length > 0;
  const pendingAmount = isReserved
    ? 0
    : commodityInformation.neededAmount.value - commodityInformation.reservedAmount.value;
  const selectionReservedAmount = useMemo(() => {
    return orderReservations.reduce(
      (commodityReservedAmount, reservation) => commodityReservedAmount + reservation.amount.value,
      0
    );
  }, [orderReservations]);

  return (
    <tr
      key={commodityInformation.commodityId.toString()}
      className={
        "kt-datatable__row d-table-row table-hover " +
        (selectedCommodityId === commodityInformation.commodityId.toString() ? "table-active-select" : "")
      }
      onClick={() => onSelectCommodity(commodityInformation.commodityId.toString())}
    >
      <td className="kt-datatable__cell d-table-cell" style={{ width: "75%" }}>
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">{commodityInformation.commodityName}</span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell text-right" style={{ width: "25%" }}>
        <div
          className={
            `kt-font-bold text-right ` +
            (hasErrors
              ? "text-danger"
              : isReserved || selectionReservedAmount >= pendingAmount
              ? "text-success"
              : "text-warning")
          }
        >
          {hasErrors
            ? i18n.t("common:inputError")
            : isReserved
            ? i18n.t("warehouse:reserved")
            : selectionReservedAmount >= pendingAmount
            ? i18n.t("warehouse:ready")
            : i18n.t("warehouse:pending")}
        </div>
      </td>
    </tr>
  );
};

export default OrderReservationInformation;
