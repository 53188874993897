import _ from "lodash";
import countryList from "i18n-iso-countries";
import React, { PureComponent } from "react";
import { DataContext } from "../../../context/dataContext";
import MaterialTimeline, { MaterialTimelineContent } from "../../common/MaterialTimeline";
import {
  CommoditiesDocument,
  CommodityTimeline as Timeline,
  StockTransferOrder
} from "../../../model/commodities.types";
import baseUtils from "../../../utils/baseUtils";
import { ROOTLISTKEYS, ROOTKEYS, TITLEKEYS } from "../../../utils/commodityUtils";
import materialUtils from "../../../utils/materialUtils";

interface CommodityTimelineProps {
  commodity: CommoditiesDocument;
  context: React.ContextType<typeof DataContext>;
}

interface CommodityTimelineState {}

interface TimelineSTO {
  type: "At warehouse" | "In transit" | "Delivered";
  amount: number;
  created?: string;
  delivered?: string;
}

class CommodityTimeline extends PureComponent<CommodityTimelineProps, CommodityTimelineState> {
  /**
   * Resolves the description of changes to a commodity.
   * @param t Timeline entry of a commodity
   * @returns {Array<{ key: string, pre?: Array<string>, post?: Array<string> }>} Contains all changed keys with their
   *  pre and post values
   */
  resolveChangeDescription = (t: Timeline): Array<{ key: string; pre?: Array<string>; post?: Array<string> }> => {
    let changes: Array<{ key: string; pre?: Array<string>; post?: Array<string> }> = [];
    const entriesPre = Object.entries(t.pre);
    const entriesPost = Object.entries(t.post);
    entriesPre.map(([key, value]) => {
      try {
        changes = changes.concat(this.processChanges(key, value, t));
      } catch (e) {
        console.error("ERROR parsing timeline:", e);
      }
    });
    entriesPost.map(([key, value]) => {
      try {
        if (!(key in t.pre)) {
          const changesPost = this.processChanges(key, value, t);
          changesPost.forEach(c => changes.push({ key: c.key, pre: c.pre ? undefined : c.post, post: c.pre }));
        }
      } catch (e) {
        console.error("ERROR parsing timeline:", e);
      }
    });
    return changes;
  };

  /**
   * Resolves a change by matching the key with the correct field of a commodity.
   * @param key Key that was changed
   * @param value Values that was set
   * @param t Timeline entry of a commodity
   * @returns {Array<{ key: string, pre?: Array<string>, post?: Array<string> }>} Contains all changed keys with their
   *  pre and post values
   */
  processChanges = (
    key: string,
    value: any,
    t: Timeline
  ): Array<{ key: string; pre?: Array<string>; post?: Array<string> }> => {
    const { context } = this.props;
    const changes: Array<{ key: string; pre?: Array<string>; post?: Array<string> }> = [];
    // @ts-ignore
    if (TITLEKEYS.includes(key)) {
      changes.push({
        key: _.upperFirst(key),
        pre: [value.en, value.de],
        // @ts-ignore
        post: [t.post[key]!.en!, t.post[key]!.de!]
      });
      // @ts-ignore
    } else if (ROOTKEYS.includes(key)) {
      switch (key) {
        case "form":
          const compPre = baseUtils.getDocFromCollection(context.compositions, value);
          const compPost = baseUtils.getDocFromCollection(context.compositions, t.post.form!);
          changes.push({ key: "Form", pre: [compPre.name.en], post: [compPost.name.en] });
          break;
        case "category":
          const coCaPre = baseUtils.getDocFromCollection(context.commoditycategories, value);
          const coCaPost = baseUtils.getDocFromCollection(context.commoditycategories, t.post.category!);
          changes.push({ key: "Category", pre: [coCaPre.name.en], post: [coCaPost.name.en] });
          break;
        case "density":
          changes.push({ key: "Density", pre: [value], post: [t.post.density!.toString()] });
          break;
        case "article_number":
          changes.push({
            key: "Article Number",
            pre: [value],
            post: t.post.article_number ? [t.post.article_number] : undefined
          });
          break;
        case "color":
          const colPre = baseUtils.getDocFromCollection(context.colors, value ?? "");
          const colPost = t.post.color ? baseUtils.getDocFromCollection(context.colors, t.post.color!) : null;
          changes.push({ key: "Color", pre: [colPre?.name.en], post: [colPost?.name.en] });
          break;
        case "hs_code":
          changes.push({ key: "HS Code", pre: [value], post: t.post.hs_code ? [t.post.hs_code] : undefined });
          break;
        case "internal_code":
          changes.push({ key: "Internal Code", pre: [value], post: [t.post.internal_code!] });
          break;
        case "identifier":
          changes.push({ key: "Identifier", pre: [value], post: [t.post.identifier!] });
          break;
        case "organic":
          changes.push({
            key: "Organic",
            pre: [value ? "Organic" : "Not organic"],
            post: [t.post.organic ? "Organic" : "Not organic"]
          });
          break;
        case "organic_code":
          changes.push({
            key: "Organic Code",
            pre: [value],
            post: t.post.organic_code ? [t.post.organic_code] : undefined
          });
          break;
        case "note":
          changes.push({ key: "Note", pre: [value], post: t.post.note ? [t.post.note] : undefined });
          break;
        case "cas_number":
          changes.push({
            key: "CAS Number",
            pre: [value],
            post: t.post.cas_number ? [t.post.cas_number] : undefined
          });
          break;
        case "solvent":
          const solPre = baseUtils.getDocFromCollection(context.solvents, value);
          const solPost = t.post.solvent ? baseUtils.getDocFromCollection(context.solvents, t.post.solvent) : null;
          changes.push({ key: "Solvent", pre: [solPre.name.en], post: solPost ? [solPost?.name.en] : undefined });
          break;
        case "toxic_amount":
          changes.push({
            key: "Toxic Amount",
            pre: [value],
            post: t.post.toxic_amount ? [t.post.toxic_amount.toString()] : undefined
          });
          break;
        case "country":
          changes.push({
            key: "Country",
            pre: [countryList.getName(value, "en")],
            post: t.post.country ? [countryList.getName(t.post.country, "en")] : undefined
          });
          break;
      }
      // @ts-ignore
    } else if (ROOTLISTKEYS.includes(key)) {
      switch (key) {
        case "properties":
          const proPre: Array<string> = [];
          value.forEach((v: any) =>
            proPre.push('"' + baseUtils.getDocFromCollection(context.commodityproperties, v).name.en + '"')
          );
          const proPost: Array<string> | null = t.post.properties ? [] : null;
          t.post.properties &&
            t.post.properties.forEach(p =>
              proPost!.push('"' + baseUtils.getDocFromCollection(context.commodityproperties, p).name.en + '"')
            );
          changes.push({ key: "Property", pre: proPre, post: proPost ? proPost : undefined });
          break;
        case "allergens":
          const algPre: Array<string> = [];
          value.forEach((v: any) =>
            algPre.push('"' + baseUtils.getDocFromCollection(context.allergens, v).name.en + '"')
          );
          const algPost: Array<string> | null = t.post.allergens ? [] : null;
          t.post.allergens &&
            t.post.allergens.forEach(p =>
              algPost!.push('"' + baseUtils.getDocFromCollection(context.allergens, p).name.en + '"')
            );
          changes.push({ key: "Allergen", pre: algPre, post: algPost ? algPost : undefined });
          break;
      }
    } else if (key === "prices") {
      const contentPre: Array<string> = [];
      const contentPost: Array<string> = [];
      const ignoredKeys = ["supplier", "id"];
      value.forEach((v: any) => {
        const supplier = baseUtils.getDocFromCollection(context.suppliers, v.supplier).name;
        const keysPre = Object.entries(v);
        const postPrice = t.post.prices?.find(price => price.id.toString() === v.id.toString());
        const keysPost = postPrice ? Object.entries(postPrice) : [];
        keysPre.length > 0 && contentPre.push("Supplier: " + supplier);
        keysPre.forEach(
          ([keyPre, valuePre]) =>
            !ignoredKeys.includes(keyPre) &&
            contentPre.push(_.upperFirst(keyPre) + ": " + valuePre + (keyPre === "price" ? "€" : ""))
        );
        keysPost.length > 0 && contentPost.push("Supplier: " + supplier);
        keysPost.forEach(
          ([keyPost, valuePost]) =>
            !ignoredKeys.includes(keyPost) &&
            contentPost.push(_.upperFirst(keyPost) + ": " + valuePost + (keyPost === "price" ? "€" : ""))
        );
      });
      changes.push({
        key: "Price",
        pre: contentPre.length > 0 ? contentPre : undefined,
        post: contentPost.length > 0 ? contentPost : undefined
      });
    } else if (key === "stock") {
      const contentPre: Array<string> = [];
      const contentPost: Array<string> = [];
      const ignoredKeys = ["lot", "_id"];
      value.forEach((v: any) => {
        const keysPre = Object.entries(v);
        const keysPost = t.post.stock
          ? Object.entries(t.post.stock.find(batch => batch._id.toString() === v._id.toString())!)
          : [];
        keysPre.length > 0 && contentPre.push("LOT: " + v.lot);
        keysPre.forEach(([keyPre, valuePre]) => {
          if (ignoredKeys.includes(keyPre)) return;
          let val: any = valuePre;
          if (["expiry", "stocked"].includes(keyPre)) val = baseUtils.formatDate(val);
          if (keyPre === "supplier") val = baseUtils.getDocFromCollection(context.suppliers, val).name;
          if (keyPre === "location") val = baseUtils.getDocFromCollection(context.manufacturers, val).name;
          contentPre.push(_.upperFirst(keyPre) + ": " + val);
        });
        keysPost.length > 0 && contentPost.push("LOT: " + v.lot);
        keysPost.forEach(([keyPost, valuePost]) => {
          if (ignoredKeys.includes(keyPost)) return;
          let val: any = valuePost;
          if (["expiry", "stocked"].includes(keyPost)) val = baseUtils.formatDate(val);
          if (keyPost === "supplier") val = baseUtils.getDocFromCollection(context.suppliers, val).name;
          if (keyPost === "location") val = baseUtils.getDocFromCollection(context.manufacturers, val).name;
          contentPost.push(_.upperFirst(keyPost) + ": " + val);
        });
      });
      changes.push({
        key: "Stock",
        pre: contentPre.length > 0 ? contentPre : undefined,
        post: contentPost.length > 0 ? contentPost : undefined
      });
    } else if (key === "orders") {
      const contentPre: Array<string> = [];
      const contentPost: Array<string> = [];
      value.forEach((v: any) => {
        const keysPre = Object.entries(v);
        const keysPost =
          t.post.orders && t.post.orders.length > 0
            ? Object.entries(t.post.orders.find(o => o._id.toString() === v._id.toString())!)
            : [];
        keysPre.forEach(([keyPre, valuePre]) => {
          let val: any = valuePre;
          let key = _.upperFirst(keyPre);
          if (["currency", "_id", "stockTransferOrder"].includes(keyPre)) return;
          else if (keyPre === "_id") return;
          else if (keyPre === "destination")
            val && (val = baseUtils.getDocFromCollection(context.manufacturers, val).name);
          else if (keyPre === "supplier") val && (val = baseUtils.getDocFromCollection(context.suppliers, val).name);
          else if (keyPre === "totalPrice") {
            key = "Total price";
            val = val + "€";
          } else if (keyPre === "purchasePrice") {
            key = "Purchase price";
            val = val + " " + keysPre.find(([k, v]) => k === "currency")![1];
          } else if (["ordered", "delivered"].includes(keyPre)) val = baseUtils.formatDate(val);
          else if (keyPre === "warehouseDestination") {
            if (!val) return;
            key = "Warehouse";
            val = baseUtils.getDocFromCollection(context.manufacturers, val)?.name;
          } else if (keyPre === "arrivedAtWarehouse") {
            if (!val) return;
            key = "Arrived at Warehouse";
            val = baseUtils.formatDate(val);
          }
          contentPre.push(key + ": " + val);
        });
        keysPost.forEach(([keyPost, valuePost]) => {
          let val: any = valuePost;
          let key = _.upperFirst(keyPost);
          if (["currency", "_id"].includes(keyPost)) return;
          else if (keyPost === "destination")
            val && (val = baseUtils.getDocFromCollection(context.manufacturers, val).name);
          else if (keyPost === "supplier") val && (val = baseUtils.getDocFromCollection(context.suppliers, val).name);
          else if (keyPost === "totalPrice") {
            key = "Total price";
            val = val + " €";
          } else if (keyPost === "purchasePrice") {
            key = "Purchase price";
            val = val + " " + keysPost.find(([k, v]) => k === "currency")![1];
          } else if (["ordered", "delivered"].includes(keyPost)) val = baseUtils.formatDate(val);
          else if (keyPost === "stockTransferOrder") {
            if (val && val.length > 0) {
              const relatedSTO = val[0] as StockTransferOrder;
              let timelineSTO: TimelineSTO;
              if (relatedSTO.delivered && relatedSTO.created) {
                timelineSTO = {
                  type: "Delivered",
                  amount: relatedSTO.amount,
                  created: relatedSTO.created.toString(),
                  delivered: relatedSTO.delivered.toISOString()
                };
              } else if (relatedSTO.created) {
                timelineSTO = {
                  type: "In transit",
                  amount: relatedSTO.amount,
                  created: relatedSTO.created.toString()
                };
              } else {
                timelineSTO = {
                  type: "At warehouse",
                  amount: relatedSTO.amount
                };
              }
              key = "Stock Transfer Order";
              val = JSON.stringify(timelineSTO);
            }
          } else if (keyPost === "warehouseDestination") {
            if (!val) return;
            key = "Warehouse";
            val = baseUtils.getDocFromCollection(context.manufacturers, val)?.name;
          } else if (keyPost === "arrivedAtWarehouse") {
            if (!val) return;
            key = "Arrived at Warehouse";
            val = baseUtils.formatDate(val);
          }
          contentPost.push(key + ": " + val);
        });
      });
      changes.push({
        key: "Order",
        pre: contentPre.length > 0 ? contentPre : undefined,
        post: contentPost.length > 0 ? contentPost : undefined
      });
    }
    return changes;
  };

  /**
   * Generates a list of all changes that are noted inside the timeline of the commodity.
   * @returns { Array<MaterialTimelineContent> } Changelist
   */
  generateChangeList = () => {
    const { commodity, context } = this.props;

    const changeList: Array<MaterialTimelineContent> = [];
    if (!commodity.timeline || commodity.timeline.length === 0) return changeList;
    const timeline = commodity.timeline.slice().reverse();
    const unit = commodity.type ? " tsd." : " kg";
    for (let i = 0; i < timeline.length; i++) {
      const t = timeline[i];
      const person = baseUtils.getDocFromCollection(context.userdata, t.person);
      const changes = this.resolveChangeDescription(t);
      let multiplePush = false;

      if (changes.length === 0 || !person) continue;
      for (let j = 0; j < changes.length; j++) {
        const c = changes[j];
        let icon = "flaticon2-edit";
        let changeDescription = " changed";
        if (c.pre && !c.post) {
          icon = "flaticon2-rubbish-bin-delete-button text-danger";
          changeDescription = " deleted";
        } else if (!c.pre && c.post) {
          icon = "flaticon2-plus text-success";
          changeDescription = " added";
        }
        let additionalInformation;
        if (c.key === "Order") {
          if (!c.pre && c.post) {
            changeDescription = " created";
            additionalInformation = c.post!.find(p => p.includes("Orderquantity"))!.split("Orderquantity: ")[1] + unit;
            const destination = c.post!.find(p => p.includes("Destination"))!.split("Destination: ")[1];
            const totalPrice = c.post!.find(p => p.includes("Total price"))!.split("Total price: ")[1];
            additionalInformation += " to " + destination + " for " + totalPrice;
          } else if (c.pre && !c.post) {
            changeDescription = " canceled";
            additionalInformation = c.pre!.find(p => p.includes("Orderquantity"))!.split("Orderquantity: ")[1] + unit;
            const destination = c.pre!.find(p => p.includes("Destination"))!.split("Destination: ")[1];
            additionalInformation += " for " + destination + " canceled";
            icon = "flaticon2-cross text-danger";
          } else if (
            // CASE STO
            c.post?.some(p => p.includes("Stock Transfer Order"))
          ) {
            const relatedSTO = JSON.parse(
              c.post.find(p => p.includes("Stock Transfer Order"))!.split("Stock Transfer Order: ")[1]
            ) as TimelineSTO;
            const stoIndex = c.post.findIndex(p => p.includes("Stock Transfer Order"));
            if (relatedSTO && stoIndex !== -1) {
              const timelinePostCopy = _.cloneDeep(c);
              const warehouse = c.post.find(p => p.includes("Warehouse"))?.split("Warehouse: ")[1];
              const destination = c.post.find(p => p.includes("Destination"))?.split("Destination: ")[1];
              switch (relatedSTO.type) {
                case "At warehouse":
                  const arrivedAtWarehouse = c.post
                    .find(p => p.includes("Arrived at Warehouse"))
                    ?.split("Arrived at Warehouse: ")[1];
                  icon = "fa fa-warehouse";
                  changeDescription = " at warehouse " + (arrivedAtWarehouse ? "(" + arrivedAtWarehouse + ")" : "");
                  additionalInformation =
                    relatedSTO.amount +
                    " " +
                    unit +
                    " has been arrived at warehouse for later production transport. (Warehouse: " +
                    warehouse +
                    "; Destination: " +
                    destination +
                    ")";
                  break;
                case "In transit":
                  const creationDate = relatedSTO.created
                    ? baseUtils.formatDate(new Date(relatedSTO.created))
                    : "unknown";
                  icon = "fa fa-truck text-primary";
                  changeDescription = " in transit to production (" + creationDate + ")";
                  additionalInformation =
                    relatedSTO.amount +
                    " " +
                    unit +
                    " are on the way to production. (Destination: " +
                    destination +
                    ")";
                  break;
                case "Delivered":
                  const deliveryDate = relatedSTO.delivered
                    ? baseUtils.formatDate(new Date(relatedSTO.delivered))
                    : "unknown";
                  icon = "fa fa-check text-success";
                  changeDescription = " delivered to production (" + deliveryDate + ")";
                  additionalInformation =
                    relatedSTO.amount +
                    " " +
                    unit +
                    " has been delivered to production center (Destination: " +
                    destination +
                    ")";
                  break;
              }
              // beautify sto for detailed popover information
              timelinePostCopy.post![stoIndex] =
                "Stock Transfer Order: " + "\n" + relatedSTO.amount + " " + unit + " -> " + destination;
              changeList.push({
                image: person.img_url,
                icon,
                date: t.date,
                user: person.prename + " " + person.surname,
                change: timelinePostCopy.key + changeDescription,
                additionalInformation,
                details: timelinePostCopy
              });
              multiplePush = true;
            }
          } else {
            // CASE direct delivery
            if (
              c.post?.some(p => p.includes("Delivered")) &&
              c.post?.find(p => p.includes("Orderquantity")) &&
              c.post?.find(p => p.includes("Destination"))
            ) {
              const changeDescription = " delivered";
              additionalInformation =
                c.post!.find(p => p.includes("Orderquantity"))!.split("Orderquantity: ")[1] + unit;
              const destination = c.post!.find(p => p.includes("Destination"))!.split("Destination: ")[1];
              additionalInformation += " to " + destination;
              icon = "fa fa-check text-success";
              multiplePush = true;
              changeList.push({
                image: person.img_url,
                icon,
                date: t.date,
                user: person.prename + " " + person.surname,
                change: c.key + changeDescription,
                additionalInformation,
                details: c
              });
            }
          }
        } else if (c.key === "Stock") {
          if (c.pre && !c.post) {
            const amounts = c.pre!.filter(p => p.includes("Amount")).map(a => Number(a.split("Amount: ")[1]));
            const location = c.pre!.find(p => p.includes("Location"))!.split("Location: ")[1];
            additionalInformation =
              amounts.reduce((sum, amount) => sum + amount, 0) + unit + " eliminated at " + location;
          }
        } else if (c.key === "Price") {
          additionalInformation = materialUtils.resolvePriceChanges(c.pre, c.post);
        }
        if (!multiplePush) {
          changeList.push({
            image: person.img_url,
            icon,
            date: t.date,
            user: person.prename + " " + person.surname,
            change: c.key + changeDescription,
            additionalInformation,
            details: c
          });
        }
      }
    }
    return changeList;
  };

  render() {
    return (
      <div className="kt-portlet__body">
        <MaterialTimeline content={this.generateChangeList()} />
      </div>
    );
  }
}

export default CommodityTimeline;
