import _ from "lodash";
import countryList from "i18n-iso-countries";
import React, { PureComponent } from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../_metronic";
import { CustomOrder } from "../CustomTypes";
import OrderHelper, { T_CUSTOM, T_LIQUID, T_POWDER, T_SERVICE, T_SOFTGEL } from "../OrderHelper";
import { PaginationState } from "../../common/CustomTypes";
import Pagination, { paginate } from "../../common/Pagination";
import { DataContext } from "../../../context/dataContext";
import { CommoditiesDocument, CommodityPrice } from "../../../model/commodities.types";
import { SuppliersDocument } from "../../../model/suppliers.types";
import dbService, { ORDERS, UpdateAction } from "../../../services/dbService";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import userService from "../../../services/userService";
import baseUtils from "../../../utils/baseUtils";
import calculationUtils from "../../../utils/calculationUtils";
import commodityUtils, { CUSTOMER } from "../../../utils/commodityUtils";
import orderCalculationUtils from "../../../utils/orderCalculationUtils";
import orderUtils, { CONTRACT, ORDERORDERCOMMODITIES, WAITING } from "../../../utils/orderUtils";
import { T_COMMODITYREPLACED, T_PACKAGINGREPLACED } from "../../../utils/timelineUtils";
import toastUtils from "../../../utils/toastUtils";
import { PackagingPrice, PackagingsDocument } from "../../../model/packagings.types";
import packagingUtils, {
  T_LABEL,
  T_LID,
  T_MULTILAYERLABEL,
  T_PIPETTE,
  T_SPRAYPUMP
} from "../../../utils/packagingUtils";
import { PackagingStockDocument } from "../../../model/packagingStock.types";
import slackService from "../../../services/slackService";

interface ReplaceMaterialModalProps {
  material?: CommoditiesDocument | PackagingsDocument;
  order: CustomOrder;
  type: "commodity" | "packaging";
  buttonCSSClasses?: string;
  externalHandling?: boolean; // indicates that the material may be optional and the selection is handled in an outside component, therefore show becomes irrelevant
  onHide?: () => void; // function to be called on hide, e.g. for external handling
  callback?: () => void;
}

interface ReplaceMaterialModalState extends PaginationState {
  materialWithStock: Array<MaterialWithStock>;
  query: string;
  onlyStocked: boolean;
  show: boolean;
  stage: "list" | "config" | "confirm";
  materialSelected?: MaterialWithStock;
  newAmount: string;
  newOrderQuantity: string;
  newSupplier: string;
  newPrice?: CommodityPrice | PackagingPrice;
  amountUnit: string;
  saving: boolean;
}

interface MaterialWithStock {
  material: CommoditiesDocument | PackagingsDocument;
  bestPrice: number;
  stockAmount: number;
}

class ReplaceMaterialModal extends PureComponent<ReplaceMaterialModalProps, ReplaceMaterialModalState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  constructor(props: ReplaceMaterialModalProps) {
    super(props);
    this.state = {
      materialWithStock: [],
      query: "",
      onlyStocked: true,
      show: false,
      stage: "list",
      currentPage: 1,
      pageSize: 10,
      newAmount: "",
      newOrderQuantity: "",
      newSupplier: "",
      amountUnit: "",
      saving: false
    };
  }

  componentDidMount() {
    this.prepareMaterial();
  }

  componentDidUpdate(prevProps: Readonly<ReplaceMaterialModalProps>) {
    const { externalHandling, material } = this.props;
    if (externalHandling && material && !_.isEqual(material, prevProps.material)) {
      this.prepareMaterial();
    }
  }

  handleShow = () => this.prepareMaterial(true);
  handleHide = () => {
    this.setState({
      show: false,
      query: "",
      onlyStocked: true,
      stage: "list",
      materialSelected: undefined,
      currentPage: 1
    });
    if (this.props.onHide) this.props.onHide();
  };

  handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: e.target.value, currentPage: 1 });
  };

  /**
   * Handles selection a commodity to replace the current one.
   * @param material: Commodity that was selected
   */
  handleClickSelect = (material: MaterialWithStock) => {
    const { order, type, material: materialProps } = this.props;
    if (!materialProps) return;
    const mId = materialProps._id.toString();
    let amountUnit, amountRaw, orderQuantity;
    if (type === "commodity") {
      const price = order.calculations[0].prices.find(p => p._id.toString() === mId)!;
      const amount = order.recipe.find(r => r.id.toString() === mId)!.amount;
      amountUnit = calculationUtils.formatAmount(amount).split(/^\d*\.?\d*/g)[1];
      amountRaw = calculationUtils.formatAmount(amount, 2).split(amountUnit)[0];
      if (amountUnit === "\u00b5g") amountUnit = "ug";
      orderQuantity = price.orderquantity;
    } else {
      const price = order.calculations[0].packagings.find(p => p._id.toString() === mId)!;
      amountUnit = price.amount === 1 ? " unit" : " units";
      amountRaw = "" + price.amount;
      orderQuantity = price.orderquantity;
    }
    const { needed } = this.resolveNeededAndStockedAmounts(amountRaw);
    let newSupplier, newPrice;
    // Case commodity
    if (type === "commodity") {
      const commodity = material.material as CommoditiesDocument;
      const { _id, price } = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        needed,
        order.state === ORDERORDERCOMMODITIES ? undefined : "ownstock"
      );
      newSupplier = _id;
      newPrice = price;
      // Case packaging
    } else {
      const packaging = material.material as PackagingsDocument;
      const { _id, price } = orderCalculationUtils.getMatchingPackagingPrice(
        packaging,
        order.settings.manufacturer,
        needed,
        this.context,
        order.state === ORDERORDERCOMMODITIES ? undefined : "ownstock"
      );
      newSupplier = _id;
      newPrice = price;
    }
    this.setState({
      stage: "config",
      materialSelected: material,
      newAmount: amountRaw,
      newOrderQuantity: orderQuantity ? orderQuantity.toString() : "0",
      amountUnit,
      newSupplier: newSupplier.toString(),
      newPrice
    });
  };

  handleClickNext = () => {
    this.setState({ stage: "confirm" });
  };

  /**
   * Function to generate the corresponding replacement message
   * @returns {string} the generated message
   */
  generateReplacementMessage = () => {
    const { material, order, type } = this.props;
    const { materialSelected } = this.state;

    const destination =
      process.env.NODE_ENV === "production"
        ? "https://www.admincentral.private-label-factory.com/"
        : "http://localhost:3000/";
    const currUser = userService.getUserData();

    let message: string;
    message = ":warning:";
    if (type === "commodity") {
      const preCommodity = material as CommoditiesDocument;
      const postCommodity = materialSelected?.material as CommoditiesDocument;
      message += ` Commodity for Order: <${destination}order/${order._id.toString()}|*${
        "AT-" + order.identifier
      }*> has been replaced by ${currUser.prename} ${
        currUser.surname
      }! From <${destination}commodity/${preCommodity._id.toString()}|*${
        preCommodity.title.en + " - " + preCommodity.subtitle.en
      }*> to <${destination}commodity/${postCommodity._id.toString()}|*${
        postCommodity.title.en + " - " + postCommodity.subtitle.en
      }*>.`;
    } else {
      // type packaging
      const prePackaging = material as PackagingsDocument;
      const postPackaging = materialSelected?.material as PackagingsDocument;
      message += ` Packaging for Order: <${destination}order/${order._id.toString()}|*${
        "AT-" + order.identifier
      }*> has been replaced by ${currUser.prename} ${
        currUser.surname
      }! From <${destination}packaging/${prePackaging._id.toString()}|*${
        packagingUtils.getShortPackagingInfo(prePackaging) +
        " - " +
        packagingUtils.resolvePackagingProperties(prePackaging)
      }*> to <${destination}packaging/${postPackaging._id.toString()}|*${
        packagingUtils.getShortPackagingInfo(postPackaging) +
        " - " +
        packagingUtils.resolvePackagingProperties(postPackaging)
      }*>.`;
    }
    return message;
  };

  /**
   * Handles clicking the confirm button and updates the order.
   */
  handleClickConfirm = async () => {
    const { callback, material, order, type } = this.props;
    const { commodities, packagingOrders } = this.context;
    if (!material) return;
    const { materialSelected } = this.state;
    const { updateDocumentInContext } = this.context;
    this.setState({ saving: true });
    const actions: Array<UpdateAction> = [];
    let timeline: any = {
      id: new BSON.ObjectId(),
      type: type === "commodity" ? T_COMMODITYREPLACED : T_PACKAGINGREPLACED,
      date: new Date(),
      person: userService.getUserId()
    };
    if (type === "commodity") {
      timeline.commodityPre = material._id;
      timeline.commodityPost = materialSelected?.material._id;
      // Action for transaction function - needed since arrayFilters are not supported in frontend
      actions.push({
        collection: ORDERS,
        filter: { _id: order._id },
        update: this.prepareCommodityUpdate(),
        push: { timeline },
        arrayFilters: [{ "r.id": material._id }, { "c._id": material._id }]
      });
      const commodityOrderUpdate = commodityUtils.getCommodityOrderUpdate(material._id.toString(), order, commodities);
      if (commodityOrderUpdate) actions.push(commodityOrderUpdate);
    } else {
      timeline.packagingPre = material._id;
      timeline.packagingPost = materialSelected?.material._id;
      actions.push({
        collection: ORDERS,
        filter: { _id: order._id },
        update: this.preparePackagingUpdate(),
        push: { timeline },
        arrayFilters: [{ "c._id": material._id }]
      });
      const packagingOrderUpdate = packagingUtils.getPackagingOrderUpdate(
        material._id.toString(),
        order,
        packagingOrders
      );
      if (packagingOrderUpdate) actions.push(packagingOrderUpdate);
    }
    const success = await dbService.updatesAsTransaction(actions);
    await toastUtils.databaseOperationToast(
      success,
      `${type === "commodity" ? "Commodity" : "Packaging"} replaced successfully`,
      `Error replacing ${type === "commodity" ? "commodity" : "packaging"}`,
      () => updateDocumentInContext(ORDERS, order._id)
    );

    this.setState({ saving: false });
    if (success) {
      const replacementMessage = this.generateReplacementMessage();
      let destination: string;

      if (process.env.NODE_ENV === "production") {
        // send message to sales employee.
        destination = order.createdFrom._id;
      } else {
        // case dev
        destination = "#novacode-reviews";
      }
      slackService.sendMessage(destination, replacementMessage);
      this.handleHide();
    }
    if (callback) callback();
  };

  /**
   * Handles clicking the back button.
   */
  handleClickBack = () => {
    const { stage } = this.state;
    if (stage === "config") {
      this.setState({ stage: "list", materialSelected: undefined });
    } else if (stage === "confirm") {
      this.setState({ stage: "config" });
    }
  };

  /**
   * Handles changing the new amount.
   * @param e: Event that triggered the change
   */
  handleChangeNumericAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    if (+value >= 0) {
      // @ts-ignore
      this.setState({ [e.target.name]: value });
    }
  };

  /**
   * Handles the blur of the new amount. The order quantity is updated then.
   * @param e: Event that triggered the blur
   */
  handleBlurNewAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { materialSelected } = this.state;
    let value = e.target.value;
    const { needed } = this.resolveNeededAndStockedAmounts(value);
    const orderQuantity =
      "type" in materialSelected!.material &&
      (!materialSelected!.material.type || materialSelected!.material.type === "")
        ? calculationUtils.convertAmount(needed.toString(), "mg", "kg")
        : needed.toString();
    this.updatePriceForOrderQuantity(Number(orderQuantity.split(" ")[0]));
  };

  /**
   * Handles the blur of the new order quantity. Price and supplier are updated.
   * @param e: Event that triggered the blur
   */
  handleBlurNewOrderQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const orderQuantity = +e.target.value;
    this.updatePriceForOrderQuantity(orderQuantity);
  };

  /**
   * Handles changing the unit and updates the new amount.
   * @param e: Event that triggered the change
   */
  handleChangeUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { amountUnit, newAmount } = this.state;
    const newUnit = e.target.value;
    const amount = calculationUtils.convertAmount(newAmount, amountUnit, newUnit);
    this.setState({ amountUnit: newUnit, newAmount: amount });
  };

  /**
   * Handles changing the supplier. Also updates the prices to a matching one of the supplier.
   * @param e: Event that triggered the change
   */
  handleChangeSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, type } = this.props;
    const { materialSelected, newOrderQuantity } = this.state;
    const value = e.target.value;
    let newPrice;
    let supplierId;
    if (type === "commodity") {
      const commodity = materialSelected!.material as CommoditiesDocument;
      const cPrice = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        +newOrderQuantity,
        value
      );
      newPrice = cPrice.price;
      supplierId = cPrice._id;
    } else {
      const packaging = materialSelected!.material as PackagingsDocument;
      const pPrice = orderCalculationUtils.getMatchingPackagingPrice(
        packaging,
        order.settings.manufacturer,
        +newOrderQuantity,
        this.context,
        value
      );
      newPrice = pPrice.price;
      supplierId = pPrice._id;
    }
    if (supplierId.toString() !== value.toString()) {
      toast.error(`No matching price could be found for desired supplier.`);
      return;
    }
    this.setState({ newSupplier: value, newPrice });
  };

  /**
   * Get matching price and supplier for the given quantity and update the state accordingly
   * @param orderQuantity new order quantity
   */
  updatePriceForOrderQuantity = (orderQuantity: number) => {
    const { order, type } = this.props;
    const { materialSelected } = this.state;
    let newSupplier, newPrice;
    // Case commodity
    if (type === "commodity") {
      const commodity = materialSelected?.material as CommoditiesDocument;
      const { _id, price } = orderCalculationUtils.getMatchingCommodityPrice(
        commodity,
        order.settings.manufacturer,
        orderQuantity,
        order.state === ORDERORDERCOMMODITIES ? undefined : "ownstock"
      );
      newSupplier = _id;
      newPrice = price;
      // Case packaging
    } else {
      const packaging = materialSelected?.material as PackagingsDocument;
      const { _id, price } = orderCalculationUtils.getMatchingPackagingPrice(
        packaging,
        order.settings.manufacturer,
        orderQuantity,
        this.context,
        order.state === ORDERORDERCOMMODITIES ? undefined : "ownstock"
      );
      newSupplier = _id;
      newPrice = price;
    }
    this.setState({ newOrderQuantity: orderQuantity.toString(), newSupplier: newSupplier.toString(), newPrice });
  };

  /**
   * Prepare the update in case of a commodity replacement
   * @returns { object } Contains the update for the order
   */
  prepareCommodityUpdate = () => {
    const { material, order } = this.props;
    if (!material) return;
    const { amountUnit, materialSelected, newAmount, newPrice, newSupplier, newOrderQuantity } = this.state;
    const pricePre = order.calculations[0].prices.find(p => p._id.toString() === material._id.toString())!;
    const pricePost = _.cloneDeep(pricePre);
    const np = newPrice as CommodityPrice;
    const newAmountMg = calculationUtils.convertAmount(newAmount, amountUnit, "mg");
    if (!np) return {};
    // Set new values in price post
    // @ts-ignore
    pricePost.supplier = BSON.ObjectId.isValid(newSupplier) ? new BSON.ObjectId(newSupplier) : newSupplier;
    pricePost.amount = +newAmountMg;
    pricePost.totalprice = np.price * +newOrderQuantity;
    pricePost.price = np.price;
    pricePost.orderquantity = +newOrderQuantity;
    pricePost.purchasePrice = np.purchasePrice;
    pricePost.purchaseCurrency = np.purchaseCurrency;
    pricePost.ordered = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    pricePost.userOrdered = ["customer", "ownstock"].includes(newSupplier) ? userService.getUserId() : null;
    pricePost.delivered = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    pricePost.userDelivered = ["customer", "ownstock"].includes(newSupplier) ? userService.getUserId() : null;
    pricePost.eta = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    // Recalculate price information
    const newInfo = orderCalculationUtils.getCalculationInfoAfterPriceChange(order, pricePre, pricePost);
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
    const update: any = {
      state: newState ? newState : order.state,
      "recipe.$[r].id": materialSelected!.material._id,
      "recipe.$[r].amount": +newAmountMg,
      "calculations.0.prices.$[c]._id": materialSelected!.material._id,
      "calculations.0.prices.$[c].amount": +newAmountMg,
      "calculations.0.prices.$[c].supplier": BSON.ObjectId.isValid(newSupplier)
        ? new BSON.ObjectId(newSupplier)
        : newSupplier,
      "calculations.0.prices.$[c].estimatedprice": pricePost.price,
      "calculations.0.prices.$[c].ordered": pricePost.ordered,
      "calculations.0.prices.$[c].userOrdered": pricePost.userOrdered,
      "calculations.0.prices.$[c].delivered": pricePost.delivered,
      "calculations.0.prices.$[c].userDelivered": pricePost.userDelivered,
      "calculations.0.prices.$[c].eta": pricePost.eta,
      "calculations.0.prices.$[c].price": pricePost.price,
      "calculations.0.prices.$[c].purchasePrice": pricePost.purchasePrice,
      "calculations.0.prices.$[c].purchaseCurrency": pricePost.purchaseCurrency,
      "calculations.0.prices.$[c].incoterm": pricePost.incoterm,
      "calculations.0.prices.$[c].orderquantity": +newOrderQuantity,
      "calculations.0.prices.$[c].totalprice": pricePost.totalprice,
      "calculations.0.info": newInfo
    };
    // Update amount per Unit for powder and liquid
    if ([T_POWDER, T_LIQUID].includes(order.settings.type)) {
      // Calculate amountPerUnit to also update already wrong values in settings.perUnit
      const amountPerUnit = order.calculations[0].prices.reduce((a, b) => a + +b.amount, 0);
      update["settings.perUnit"] = amountPerUnit - +pricePre.amount + +pricePost.amount;
    }
    return update;
  };

  /**
   * Prepare the update in case of a packaging replacement
   * @returns { object } Contains the update for the order
   */
  preparePackagingUpdate = () => {
    const { material, order } = this.props;
    if (!material) return;
    const { materialSelected, newAmount, newPrice, newSupplier, newOrderQuantity } = this.state;
    const newUnitPrice = newPrice!.price;
    const np = newPrice as PackagingPrice;
    if (!np) return {};
    const calcPost = _.cloneDeep(order.calculations[0]);
    const pricePost = calcPost.packagings.find(p => p._id.toString() === material._id.toString())!;
    pricePost.price = newUnitPrice;
    pricePost.orderquantity = +newOrderQuantity;
    pricePost.totalprice = newUnitPrice * +newOrderQuantity;
    pricePost.amount = +newAmount;
    pricePost.ordered = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    pricePost.userOrdered = ["customer", "ownstock"].includes(newSupplier) ? userService.getUserId() : null;
    pricePost.delivered = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    pricePost.userDelivered = ["customer", "ownstock"].includes(newSupplier) ? userService.getUserId() : null;
    pricePost.eta = ["customer", "ownstock"].includes(newSupplier) ? new Date() : null;
    const newInfo = orderCalculationUtils.recalculateInfoOnPackagingChanges(order, calcPost);
    const newState = orderUtils.checkOrderStateAfterPriceUpdate(order, pricePost);
    return {
      state: newState ? newState : order.state,
      "calculations.0.packagings.$[c]._id": materialSelected!.material._id,
      "calculations.0.packagings.$[c].amount": +newAmount,
      "calculations.0.packagings.$[c].supplier": BSON.ObjectId.isValid(newSupplier)
        ? new BSON.ObjectId(newSupplier)
        : newSupplier,
      "calculations.0.packagings.$[c].price": pricePost.price,
      "calculations.0.packagings.$[c].orderquantity": pricePost.orderquantity,
      "calculations.0.packagings.$[c].estimatedprice": pricePost.price,
      "calculations.0.packagings.$[c].ordered": pricePost.ordered,
      "calculations.0.packagings.$[c].userOrdered": pricePost.userOrdered,
      "calculations.0.packagings.$[c].delivered": pricePost.delivered,
      "calculations.0.packagings.$[c].userDelivered": pricePost.userDelivered,
      "calculations.0.packagings.$[c].eta": pricePost.eta,
      "calculations.0.packagings.$[c].totalprice": pricePost.totalprice,
      "calculations.0.info": newInfo
    };
  };

  /**
   * Prepares the materials for usage in the replacement dialog.
   * @param show (optional) show modal
   */
  prepareMaterial = (show?: boolean) => {
    const { material, order, type } = this.props;
    if (!material) return;
    const { commodities, packagings, packagingStock } = this.context;
    const materialWithStock = [];
    const usedCommodities = order.recipe.map(r => r.id.toString());
    // Case commodity
    if (type === "commodity" && "type" in material) {
      for (let i = 0; i < commodities.length; i++) {
        const c = commodities[i];
        if (material._id.toString() !== c._id.toString() && usedCommodities.some(c2 => c._id.toString() === c2))
          continue;
        if (!c.disabled && material.type === c.type) {
          materialWithStock.push({
            material: c,
            bestPrice:
              order.state === ORDERORDERCOMMODITIES
                ? orderCalculationUtils.getPriceForMOQ(c, order.calculations[0].units, "", "", 0, true)?.price.price ||
                  0
                : calculationUtils.getAverageStockPrice(c.stock, order.settings.manufacturer),
            stockAmount: this.resolveStockAmount(c)
          });
        }
      }
      // Case packaging
    } else {
      const pack = material as PackagingsDocument;
      const usedPackaging = order.calculations[0].packagings.map(p => p._id.toString());
      for (let i = 0; i < packagings.length; i++) {
        const p = packagings[i];
        const stockForPackaging = packagingStock.filter(pS => pS.packaging.toString() === p._id.toString());
        if (material._id.toString() !== p._id.toString() && usedPackaging.some(p2 => p._id.toString() === p2)) continue;
        if (
          p.packaging_type === pack.packaging_type ||
          ([T_LABEL, T_MULTILAYERLABEL].includes(pack.packaging_type) &&
            [T_LABEL, T_MULTILAYERLABEL].includes(p.packaging_type)) ||
          ([T_PIPETTE, T_SPRAYPUMP].includes(pack.packaging_type) &&
            [T_LID, T_PIPETTE, T_SPRAYPUMP].includes(p.packaging_type))
        ) {
          materialWithStock.push({
            material: p,
            bestPrice:
              order.state === ORDERORDERCOMMODITIES
                ? orderCalculationUtils.getPackagingPriceForMOQ(p, order.calculations[0].units, "", "", 0, true)?.price
                    .price || 0
                : calculationUtils.getAverageStockPrice(stockForPackaging, order.settings.manufacturer),
            stockAmount: this.resolvePackagingStockAmount(stockForPackaging)
          });
        }
      }
    }
    this.setState({ materialWithStock, show: !!show });
  };

  /**
   * Resolves the current stock amount of the given commodity.
   * @param commodity: Commodity whose stock should be calculated
   * @returns { number } Current stock of the commodity
   */
  resolveStockAmount = (commodity: CommoditiesDocument) => {
    const { order } = this.props;
    if (commodity.stock.length === 0) return 0;
    return commodity.stock.reduce(
      (sum, batch) =>
        sum +
        (+batch.amount > 0 &&
        !batch.disabled &&
        batch.location.toString() === order.settings.manufacturer._id.toString() &&
        batch.supplier !== CUSTOMER
          ? +batch.amount
          : 0),
      0
    );
  };

  /**
   * Resolves the current stock amount of the given packaging.
   * @param packagingStock: List of packaging stock documents
   * @returns { number } Current stock for packaging
   */
  resolvePackagingStockAmount = (packagingStock: Array<PackagingStockDocument>) => {
    const { order } = this.props;
    return packagingStock.reduce(
      (sum, batch) =>
        sum +
        (+batch.amount > 0 &&
        !batch.disabled &&
        batch.location.toString() === order.settings.manufacturer._id.toString() &&
        batch.supplier !== CUSTOMER
          ? +batch.amount
          : 0),
      0
    );
  };

  /**
   * Filters the material by the provided query and option.
   * @returns { Array<MaterialWithStock> } Filtered commodities
   */
  filterCommodities = () => {
    const { type } = this.props;
    const { materialWithStock, onlyStocked, query } = this.state;
    const ql = query.toLowerCase();
    const materialFiltered = materialWithStock.filter(cex => {
      let m = cex.material;
      if (type === "commodity") {
        const c = m as CommoditiesDocument;
        return (
          (c.title.de.toLowerCase().includes(ql) ||
            c.title.en.toLowerCase().includes(ql) ||
            c.subtitle.de.toLowerCase().includes(ql) ||
            c.subtitle.en.toLowerCase().includes(ql)) &&
          (!onlyStocked || cex.stockAmount > 0)
        );
      } else {
        const p = m as PackagingsDocument;
        return (
          (packagingUtils.concatPackagingInfo(p).toLowerCase().includes(ql.trim()) ||
            p.note.toLowerCase().includes(ql.trim())) &&
          (!onlyStocked || cex.stockAmount > 0)
        );
      }
    });
    return materialFiltered.sort((c1, c2) =>
      this.resolveTitle(c1.material).localeCompare(this.resolveTitle(c2.material))
    );
  };

  /**
   * Amount of commodity that was used before.
   * @param recipeEntry: Recipe entry of the order
   * @returns { string } Amount with unit
   */
  resolvePreviouslyUsedAmount = (recipeEntry: { amount: number; buffer: number }) => {
    const { material, type } = this.props;
    let amount = "";
    if (!recipeEntry) return amount;
    if (type === "commodity") {
      const c = material as CommoditiesDocument;
      if (!c.type) amount = calculationUtils.formatAmount(recipeEntry.amount, 2);
      else if (c.type !== T_SERVICE) amount = recipeEntry.amount + " pcs.";
      else amount = recipeEntry.amount + " units";
    } else {
      amount = recipeEntry.amount + (recipeEntry.amount > 1 ? " units" : " unit");
    }
    return amount;
  };

  /**
   * Resolves the needed and stocked amount of the commodity.
   * @param amount: Optional, if set this amount is taken for calculation instead of the one from state
   * @returns { needed: number, stocked: string } Needed as raw amount and stock in stock unit format
   */
  resolveNeededAndStockedAmounts = (amount?: string) => {
    const { material, order, type } = this.props;
    if (!material) return { needed: 0, stocked: 0 };
    const { amountUnit, materialSelected, newAmount } = this.state;
    let recipeEntry;
    if (type === "commodity") {
      recipeEntry = order.recipe.find(r => r.id.toString() === material._id.toString())!;
    } else {
      recipeEntry = order.calculations[0].packagings.find(p => p._id.toString() === material._id.toString())!;
    }
    // Needed because after the commodity is replaced there is a small timeframe where we encounter a missing entry
    if (!recipeEntry) return { needed: 0, stocked: "Unknown" };
    let needed = 0;
    let stocked = "0";
    if (type === "commodity") {
      needed = orderUtils.getTotalAmountWithBuffer(
        order.settings.perUnit,
        order.calculations[0].units,
        +calculationUtils.convertAmount(amount ? amount : newAmount, amountUnit, "mg"),
        recipeEntry.buffer,
        order.settings.type
      );
      const c = materialSelected ? (materialSelected!.material as CommoditiesDocument) : null;
      stocked =
        materialSelected && c ? commodityUtils.resolveStockUnit(materialSelected.stockAmount, c.type) : "Unknown";
    } else {
      needed = order.calculations[0].units * (1 + recipeEntry.buffer / 100) * (amount ? +amount : +newAmount);
      stocked = materialSelected?.stockAmount.toString() + " units" || "Unknown";
    }
    return { needed, stocked };
  };

  /**
   * Resolves the title of the material.
   * @param material: Commodity or packaging document
   * @returns { string } Title of the material
   */
  resolveTitle = (material?: CommoditiesDocument | PackagingsDocument) => {
    if (!material) material = this.props.material;
    if (!material) return "";
    if ("title" in material) return material.title.en;
    else return packagingUtils.getPackagingType(material.packaging_type);
  };

  /**
   * Renders the row in case of a commodity replacement
   * @param cex: Commodities with stock
   * @returns { JSX.Element } Row for the commodity
   */
  renderCommodityRow = (cex: MaterialWithStock) => {
    const { order } = this.props;
    const c = cex.material as CommoditiesDocument;
    const stockAmount = cex.stockAmount;
    return (
      <tr
        key={c._id.toString()}
        className={stockAmount > 0 ? "table-hover" : ""}
        style={{ opacity: order.state === ORDERORDERCOMMODITIES || stockAmount > 0 ? 1 : 0.3 }}
      >
        <td className="align-middle">
          <span>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">
                  <Link
                    to={"/commodity/" + c._id.toString()}
                    className="kt-user-card-v2__name pr-2"
                    style={{ display: "inline" }}
                  >
                    {c.title.en}
                  </Link>
                  {c.country && (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip product-popover">{countryList.getName(c.country, "en")}</Tooltip>
                      }
                    >
                      <img
                        style={{
                          width: 14,
                          height: 14,
                          borderRadius: 14,
                          objectFit: "cover"
                        }}
                        alt={c.country}
                        src={toAbsoluteUrl("/media/icons/countries/") + c.country.toLowerCase() + ".png"}
                        className="country-icon"
                      />
                    </OverlayTrigger>
                  )}
                </span>
                <span className="kt-user-card-v2__email">{c.subtitle.en}</span>
              </div>
            </div>
          </span>
        </td>
        <td className="align-middle">{cex.bestPrice === 0 ? "-,-- €" : baseUtils.formatEuro(cex.bestPrice)}</td>
        <td className="align-middle">{commodityUtils.resolveStockUnit(stockAmount, c.type)}</td>
        <td className="align-middle">
          {(order.state === ORDERORDERCOMMODITIES || stockAmount > 0) && (
            <button className="btn btn-sm btn-secondary" onClick={() => this.handleClickSelect(cex)}>
              Select
            </button>
          )}
        </td>
      </tr>
    );
  };

  /**
   * Renders the row in case of a packaging replacement
   * @param cex: Packaging with stock
   * @returns { JSX.Element } Row for the packaging
   */
  renderPackagingRow = (cex: MaterialWithStock) => {
    const { order } = this.props;
    const p = cex.material as PackagingsDocument;
    const stockAmount = cex.stockAmount;
    return (
      <tr
        key={p._id.toString()}
        className={stockAmount > 0 ? "table-hover" : ""}
        style={{ opacity: order.state === ORDERORDERCOMMODITIES || stockAmount > 0 ? 1 : 0.3 }}
      >
        <td className="align-middle">
          <span>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <span className="kt-user-card-v2__name">
                  <Link
                    to={"/packaging/" + p._id.toString()}
                    className="kt-user-card-v2__name pr-2"
                    style={{ display: "inline" }}
                  >
                    {this.resolveTitle(p)}
                  </Link>
                </span>
                <span className="kt-user-card-v2__email">{packagingUtils.resolvePackagingProperties(p)}</span>
              </div>
            </div>
          </span>
        </td>
        <td className="align-middle">{cex.bestPrice === 0 ? "-,-- €" : baseUtils.formatEuro(cex.bestPrice)}</td>
        <td className="align-middle">{stockAmount} units</td>
        <td className="align-middle">
          {(order.state === ORDERORDERCOMMODITIES || stockAmount > 0) && (
            <button className="btn btn-sm btn-secondary" onClick={() => this.handleClickSelect(cex)}>
              Select
            </button>
          )}
        </td>
      </tr>
    );
  };

  /**
   * Renders the modal body for the commodity listing
   * @returns { JSX.Element } Modal body for listing of commodities
   */
  renderBodyList = () => {
    const { order, type } = this.props;
    const { currentPage, onlyStocked, pageSize, query } = this.state;
    const commodities = this.filterCommodities();
    const coms: Array<MaterialWithStock> = paginate(commodities, currentPage, pageSize);

    return (
      <>
        <div className="row">
          <div className="col-6">
            <div className="kt-input-icon kt-input-icon--left">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                onChange={this.handleChangeQuery}
                value={query}
                name="query"
              />
              <span className="kt-input-icon__icon kt-input-icon__icon--left">
                <span>
                  <i className="la la-search" />
                </span>
              </span>
            </div>
          </div>
          <div className="col-6 align-self-center text-right">
            <div className="form-check">
              <label className="form-check-label mr-4 text-dark">Show only stocked</label>
              <input
                className="form-check-input pb-1"
                type="checkbox"
                checked={onlyStocked}
                onChange={() => this.setState({ onlyStocked: !onlyStocked })}
              />
            </div>
          </div>
        </div>
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive">
          <Table>
            <thead>
              <tr>
                <th style={{ width: "50%" }}>{type === "commodity" ? "Commodity" : "Packaging"}</th>
                <th style={{ width: "20%" }}>
                  {order.state === ORDERORDERCOMMODITIES ? "Best Price" : "∅ Stock Price"}
                </th>
                <th>Amount on Stock</th>
                <th style={{ width: "10%" }} />
              </tr>
            </thead>
            <tbody>
              {coms.length > 0 ? (
                coms.map(cex => {
                  if (type === "commodity") {
                    return this.renderCommodityRow(cex);
                  }
                  return this.renderPackagingRow(cex);
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <img
                      src={process.env.PUBLIC_URL + "/media/img/no_results.jpg"}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        height: "500px"
                      }}
                      alt="No results"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="kt-datatable__pager kt-datatable--paging-loaded justify-content-center">
            <Pagination
              itemsCount={commodities.length}
              pageSize={pageSize}
              onPageChange={currentPage => this.setState({ currentPage })}
              currentPage={currentPage}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * Renders the modal body for the commodity configuration.
   * @returns { JSX.Element } Modal body for commodity configuration
   */
  renderBodyConfig = () => {
    const { material, order, type } = this.props;
    if (!material) return null;
    const { amountUnit, materialSelected, newAmount, newOrderQuantity, newPrice, newSupplier } = this.state;
    const { suppliers } = this.context;
    let recipeEntry;
    if (type === "commodity") {
      recipeEntry = order.recipe.find(r => r.id.toString() === material._id.toString())!;
    } else {
      recipeEntry = order.calculations[0].packagings.find(p => p._id.toString() === material._id.toString())!;
    }
    const { needed, stocked } = this.resolveNeededAndStockedAmounts();
    const stockValue =
      type === "commodity"
        ? +calculationUtils.convertAmount(materialSelected!.stockAmount.toString(), "kg", "mg")
        : materialSelected!.stockAmount;
    const inProduction = ![ORDERORDERCOMMODITIES, WAITING, CONTRACT].includes(order.state);
    return (
      <div>
        <div className="row mb-2">
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">Replace</span>
          </div>
          <div className="col-9">
            <input
              type="text"
              value={
                this.resolveTitle() +
                (type === "packaging"
                  ? " (" + packagingUtils.resolvePackagingProperties(material as PackagingsDocument) + ")"
                  : "")
              }
              disabled
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">with</span>
          </div>
          <div className="col-9">
            <input
              type="text"
              value={
                this.resolveTitle(materialSelected!.material) +
                (type === "packaging"
                  ? " (" +
                    packagingUtils.resolvePackagingProperties(materialSelected!.material as PackagingsDocument) +
                    ")"
                  : "")
              }
              disabled
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">Amount / unit before</span>
          </div>
          <div className="col-9">
            <input
              type="text"
              value={this.resolvePreviouslyUsedAmount(recipeEntry)}
              disabled
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">New amount / unit</span>
          </div>
          <div className="col-9">
            <div className="input-group">
              <input
                type="number"
                value={newAmount}
                className="form-control"
                onChange={this.handleChangeNumericAmount}
                onBlur={this.handleBlurNewAmount}
                name="newAmount"
              />
              <div className="input-group-append">
                {"type" in material && !material.type ? (
                  <select
                    className="form-control p-0 pl-2"
                    style={{ borderRadius: "0px 10px 10px 0px" }}
                    value={amountUnit}
                    onChange={this.handleChangeUnit}
                  >
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                    <option value="mg">mg</option>
                    <option value="ug">&#181;g</option>
                  </select>
                ) : (
                  <span className="input-group-text">{type === "commodity" ? "pcs." : "per unit"}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {!inProduction && (
          <>
            <div className="row mb-2">
              <div className="col-3 text-right align-self-center">
                <span className="text-dark">Supplier</span>
              </div>
              <div className="col-9">
                <select className="form-control" onChange={this.handleChangeSupplier} value={newSupplier}>
                  {stockValue > +needed && <option value="ownstock">Ownstock</option>}
                  {materialSelected?.material.suppliers.map(s => {
                    const supplier: SuppliersDocument = baseUtils.getDocFromCollection(suppliers, s._id);
                    return (
                      <option value={supplier._id.toString()} key={supplier._id.toString()}>
                        {supplier.name}
                      </option>
                    );
                  })}
                  <option value="customer">Customer</option>
                </select>
              </div>
            </div>
            {!["stock", "customer"].includes(newSupplier) && newPrice && (
              <div className="row mb-2">
                <div className="col-3 text-right align-self-center">
                  <span className="text-dark">Price</span>
                </div>
                <div className="col-3">
                  <input type="text" value={baseUtils.formatEuro(newPrice.price)} disabled className="form-control" />
                </div>
                {"purchasePrice" in newPrice &&
                  "purchaseCurrency" in newPrice &&
                  !!newPrice.purchasePrice &&
                  !!newPrice.purchaseCurrency && (
                    <>
                      <div className="col-3 text-right align-self-center">
                        <span className="text-dark">Purchase price</span>
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          value={
                            newPrice.purchasePrice
                              ? baseUtils.formatCurrency(newPrice.purchasePrice, newPrice.purchaseCurrency)
                              : "Missing!"
                          }
                          disabled
                          className="form-control"
                        />
                      </div>
                    </>
                  )}
              </div>
            )}
          </>
        )}
        <div className="row mb-2 mt-4">
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">{inProduction ? "Needed amount" : "Order Quantity"}</span>
          </div>
          <div className="col-3">
            <div className="input-group">
              <input
                type="number"
                value={newOrderQuantity}
                className="form-control"
                onChange={this.handleChangeNumericAmount}
                onBlur={this.handleBlurNewOrderQuantity}
                name="newOrderQuantity"
                disabled={inProduction}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  {type === "commodity" ? ("type" in material && !material.type ? "kg" : "tsd.") : "units"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-3 text-right align-self-center">
            <span className="text-dark">Amount stocked</span>
          </div>
          <div className="col-3">
            <input
              type="text"
              value={stocked}
              disabled
              className={
                "form-control" +
                (![ORDERORDERCOMMODITIES, CONTRACT].includes(order.state) && stockValue < needed ? " is-invalid" : "")
              }
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Renders the modal body for replacement confirmation
   * @returns { JSX.Element } Modal body for replacement confirmation
   */
  renderBodyConfirm = () => {
    const { material, order, type } = this.props;
    if (!material) return;
    const { amountUnit, materialSelected, newAmount, newSupplier, newOrderQuantity, newPrice } = this.state;
    const { suppliers } = this.context;
    let recipeEntry, pricePre, amountPre, totalPricePre, neededOld;
    const totalPricePost = newPrice!.price * +newOrderQuantity;
    const mId = material._id.toString();
    if (type === "commodity") {
      const recipeEntryOld = order.calculations[0].prices.find(p => p._id.toString() === mId);
      if (!recipeEntryOld) return;
      neededOld = +recipeEntryOld.orderquantity!;
    } else {
      const packagingEntryOld = order.calculations[0].packagings.find(p => p._id.toString() === mId);
      if (!packagingEntryOld) return;
      neededOld = +packagingEntryOld.orderquantity!;
    }
    if (type === "commodity") {
      recipeEntry = order.recipe.find(r => r.id.toString() === material._id.toString())!;
      pricePre = order.calculations[0].prices.find(p => p._id.toString() === material._id.toString())!;
      if (!recipeEntry || !pricePre) return null;
      amountPre = this.resolvePreviouslyUsedAmount(recipeEntry);
      const totalAmount = orderUtils.getTotalAmountWithBuffer(
        +order.settings.perUnit,
        order.calculations[0].units,
        pricePre.amount,
        pricePre.buffer,
        order.settings.type
      );
      totalPricePre = pricePre!.price * OrderHelper.getOrderQuantityForType(totalAmount, order.settings.type);
    } else {
      pricePre = order.calculations[0].packagings.find(p => p._id.toString() === material._id.toString())!;
      amountPre = pricePre.amount + " unit";
      totalPricePre = pricePre.price * (order.calculations[0].units * pricePre.amount);
    }
    const supPre = baseUtils.getDocFromCollection(suppliers, pricePre!.supplier);
    const supPost = baseUtils.getDocFromCollection(suppliers, newSupplier);
    return (
      <>
        <div className="text-dark">Please check the following information:</div>
        <div className="row mt-4 text-dark">
          <div className="col-3 text-right">{type === "commodity" ? "Commodity" : "Packaging"}</div>
          <div className="col-4">{this.resolveTitle()}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">{this.resolveTitle(materialSelected!.material)}</div>
        </div>
        <div className="row text-dark">
          <div className="col-3 text-right">Amount</div>
          <div className="col-4">{amountPre}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">
            {newAmount +
              ([T_CUSTOM, T_SOFTGEL].includes(order.settings.type) ? " pcs." : amountUnit.replace("ug", "\u00b5g"))}
          </div>
        </div>
        <div className="row text-dark">
          <div className="col-3 text-right">Supplier</div>
          <div className="col-4">{orderUtils.getSupplierName(supPre ? supPre : pricePre.supplier)}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">{orderUtils.getSupplierName(supPost ? supPost : newSupplier)}</div>
        </div>
        <div className="row text-dark">
          <div className="col-3 text-right">Price / kg </div>
          <div className="col-4">{baseUtils.formatEuro(pricePre.price)}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">{baseUtils.formatEuro(newPrice!.price)}</div>
        </div>
        <div className="row text-dark">
          <div className="col-3 text-right">Total price </div>
          <div className="col-4">{baseUtils.formatEuro(pricePre.totalprice ? pricePre.totalprice : totalPricePre)}</div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">{baseUtils.formatEuro(totalPricePost)}</div>
        </div>
        <div className="text-dark row">
          <div className="col-3 text-right">Order quantity </div>
          <div className="col-4">
            {[T_CUSTOM, T_SOFTGEL].includes(order.settings.type) || type === "packaging"
              ? +neededOld / 1000 + " tsd."
              : neededOld + "kg"}
          </div>
          <div className="col-1">
            <i className="fa fa-arrow-right mx-2" />
          </div>
          <div className="col-4">
            {[T_CUSTOM, T_SOFTGEL].includes(order.settings.type) || type === "packaging"
              ? +newOrderQuantity / 1000 + " tsd."
              : calculationUtils.formatAmount(+newOrderQuantity * 1000 * 1000, 4)}
          </div>
        </div>
      </>
    );
  };

  /**
   * Render the configuration buttons.
   * @returns { JSX.Element } Configuration buttons
   */
  renderConfigButtons = () => {
    const { order, type } = this.props;
    const { materialSelected } = this.state;
    const { needed } = this.resolveNeededAndStockedAmounts();
    const stockValue =
      type === "commodity"
        ? +calculationUtils.convertAmount(materialSelected!.stockAmount.toString(), "kg", "mg")
        : materialSelected!.stockAmount;
    return (
      <>
        {![ORDERORDERCOMMODITIES, WAITING, CONTRACT].includes(order.state) && +stockValue < needed ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="notEnoughStock">
                <span className="text-danger">
                  <b>Not enough on stock!</b>
                </span>
              </Tooltip>
            }
            placement="left"
          >
            <button className="btn btn-success disabled">Next</button>
          </OverlayTrigger>
        ) : (
          <button className="btn btn-success" onClick={this.handleClickNext}>
            Next
          </button>
        )}
      </>
    );
  };

  render() {
    const { buttonCSSClasses, order, type, material, externalHandling } = this.props;
    const { saving, stage, show } = this.state;
    const canReplace = accessUtils.canPerformAction(
      type === "commodity" ? ACTIONS.ORDERCOMMODITYREPLACE : ACTIONS.ORDERPACKAGINGREPLACE
    );

    return (
      <>
        {!externalHandling && (
          <button
            className={
              (buttonCSSClasses ? buttonCSSClasses : "btn btn-sm btn-secondary px-2 py-1") +
              (canReplace ? "" : " disabled")
            }
            onClick={canReplace ? this.handleShow : undefined}
            disabled={!canReplace}
          >
            Replace
          </button>
        )}
        <Modal show={(!!material && externalHandling) || show} onHide={this.handleHide} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Replace {this.resolveTitle()} in Order AT-{order.identifier}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {stage === "list" && this.renderBodyList()}
            {stage === "config" && this.renderBodyConfig()}
            {stage === "confirm" && this.renderBodyConfirm()}
          </Modal.Body>
          <Modal.Footer>
            {stage !== "list" && (
              <button className="btn btn-secondary" onClick={this.handleClickBack}>
                Back
              </button>
            )}
            {stage === "list" && (
              <button className="btn btn-secondary" onClick={this.handleHide}>
                Close
              </button>
            )}
            {stage === "config" && this.renderConfigButtons()}
            {stage === "confirm" && (
              <button
                className={"btn btn-success" + (saving ? " disabled" : "")}
                disabled={saving}
                onClick={this.handleClickConfirm}
              >
                {saving ? <>Saving...</> : "Confirm"}
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ReplaceMaterialModal;
