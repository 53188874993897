import { BSON } from "realm-web";
import { Content, CurrencyValue, OrderSnapshot, PersonSnapshot } from "./common.types";
import {
  FileType,
  PackagingUnitDefinition,
  StorageSpace,
  WarehouseTypes
} from "../configuration/warehouseConfiguration.types";
import { LanguageObject, NumValue } from "../common.types";

export enum SenderType {
  SUPPLIER = "supplier",
  CUSTOMER = "customer",
  MANUFACTURER = "manufacturer"
}

export enum BatchTimelineType {
  BATCHCREATED = "batchCreated",
  BATCHADDITIONALBOOKIN = "batchAdditionalBookIn",
  BATCHLOCATIONCHANGE = "batchLocationChange",
  BATCHDATACHANGED = "batchDataChanged",
  BATCHBBDCORRECTED = "batchBBDCorrected",
  BATCHBOOKEDOUT = "batchBookedOut",
  BATCHCUSTOMERORDERREMOVED = "batchCustomerOrderRemoved"
}

export enum BatchTimelineSubType {
  BATCH = "batch",
  DOCUMENT = "document",
  PACKAGINGUNIT = "packagingUnit"
}

export interface Batch {
  _id: BSON.ObjectId;
  sender: SenderInformation; // supplier or manufacturer or customer
  content: Content; // content of batch, e.g. commodity, produced product, capsules, …
  totalAmount: NumValue; // amount in kg that this batch contains
  lot: string;
  comments: Array<BatchComment>;
  unitPrice: CurrencyValue;
  stocked: Date;
  expiry: Date;
  files: Array<BatchFile>;
  blocked?: BlockingInformation;
  locations: Array<BatchLocation>; // where the batch is stored
  timeline: Array<BatchTimelineEntry>;
}

export interface BatchLocation {
  _id: BSON.ObjectId; // unique id. not related to the location, for movement and reservation references
  location: BatchLocationInformation; // (local) warehouse, (physical) warehouse area, storage space
  amountAtLocation: NumValue; // amount in kg that this location contains
  packagingUnits: Array<PackagingUnit>; // which packaging is used
}

export interface BatchLocationInformation {
  warehouseSnapshot: LocationWarehouseSnapshot; // logical storage
  warehouseArea: LocationWarehouseAreaSnapshot; // physical storage
  storageSpace?: StorageSpaceSnapshot; // given if directly managed, exact location
}

export interface LocationWarehouseSnapshot {
  _id: BSON.ObjectId; // logical warehouse id, reference
  shortName: string;
  warehouseName: LanguageObject;
}

export interface LocationWarehouseAreaSnapshot {
  _id: BSON.ObjectId; // physical warehouse id, reference
  type: WarehouseTypes;
  shortName: string;
  warehouseName: LanguageObject;
}

export interface StorageSpaceSnapshot extends Pick<StorageSpace, "_id" | "storageSpaceNo"> {} // storage space id, reference

export interface PackagingUnit {
  _id: BSON.ObjectId; // unique id. not related to the pu, for movement references
  puSnapshot: PackagingUnitDefinition;
  quantity: number | null; // null is specifically if location is a remote warehouse
  weight: NumValue; // how much the content (without packaging) weighs in kg (actual netweight), is a weight per unit
  amountPerPu: NumValue; // how much is actually contained per unit (can be kg, liter, capsules, ...)
  blocked?: BlockingInformation;
}

export interface SenderInformation {
  senderId: string; // supplier/customer/manufacturer id, reference
  type: SenderType; // supplier/customer/manufacturer
  name: string;
  purchaseOrder?: string; // if batch originates from a purchaseOrder
  customerOrders?: Array<OrderSnapshot>; // given if from customer, a list of what orders should be produced with this batch
}

export interface BatchComment {
  _id: BSON.ObjectId; // unique id
  text: string;
  person: PersonSnapshot;
  date: Date;
}

export interface BlockingInformation {
  reason: string;
  files: Array<BatchFile>;
}

export interface BatchFile {
  _id: BSON.ObjectId;
  type: FileType; // snapshot of type defined in configuration
  date: Date;
  person: PersonSnapshot;
  path: string;
  title: string;
  fileExtension: string;
  fileSize: number;
}

export interface BatchTimelineEntry {
  _id: BSON.ObjectId;
  type: BatchTimelineType;
  subtype: BatchTimelineSubType;
  date: Date;
  person: PersonSnapshot;
  payload: object;
  diff: { pre: object; post: object };
}
