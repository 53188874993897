import { Batch, BatchLocation, BatchLocationInformation, SenderType } from "../model/warehouse/batch.types";
import {
  AdditionalProductFilter,
  WarehouseContext,
  WarehouseListingTabNames,
  WarehouseLocation
} from "../context/warehouseContext";
import { BatchContentSpecificType, ContentType } from "../model/warehouse/common.types";
import { WarehouseConfiguration, WarehouseTypes } from "../model/configuration/warehouseConfiguration.types";
import { DataContextType } from "../context/dataContext";
import { compareBatchLocations } from "./batchUtils";
import { Reservation, ReservationState } from "../model/warehouse/reservation.types";
import { CommodityWithBatches } from "../model/warehouse/customTypes.types";
import { CommoditiesDocument } from "../model/commodities.types";
import baseUtils from "./baseUtils";
import { language } from "./translationUtils";

export enum ProductFilterValuesEnum {
  ALL = "allWares",
  ALL_RAW_MATERIALS = "allRawMaterials",
  ALL_PACKAGING = "allPackaging"
}

export type ProductFilterValues = ProductFilterValuesEnum | BatchContentSpecificType;

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param batch a batch
 * @param selectedLocation a selected location
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @param excludeLocation optional, if given, the location will not be included in filtering
 * @returns {boolean} true if batch matches the filter, else false
 */
export const filterBatch = (
  batch: Batch,
  selectedLocation: WarehouseLocation | null,
  productFilter: ProductFilterValues,
  additionalProductFilter: AdditionalProductFilter,
  excludeLocation?: boolean
): boolean => {
  if (excludeLocation) {
    return filterByProductFilters(batch, productFilter, additionalProductFilter);
  }
  return (
    filterBySelectedLocation(batch, selectedLocation) &&
    filterByProductFilters(batch, productFilter, additionalProductFilter)
  );
};

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param batch a batch
 * @param selectedLocation a selected location
 * @returns {boolean} true if batch matches the filter, else false
 */
const filterBySelectedLocation = (batch: Batch, selectedLocation: WarehouseLocation | null): boolean => {
  if (!selectedLocation) return true;
  return batch.locations.some(
    l =>
      selectedLocation?.warehouse === l.location.warehouseSnapshot._id.toString() &&
      (!selectedLocation.warehouseArea || selectedLocation.warehouseArea === l.location.warehouseArea._id.toString())
  );
};

/**
 * A filter function that returns true or false whether the batch should be kept or not
 * @param batch a batch
 * @param productFilter a product type filter
 * @param additionalProductFilter additional product filters, e.g. organic
 * @return {boolean} true if batch matches the filters, else false
 */
const filterByProductFilters = (
  batch: Batch,
  productFilter: ProductFilterValues,
  additionalProductFilter: AdditionalProductFilter
): boolean => {
  const checkProductType = () => {
    switch (productFilter) {
      case ProductFilterValuesEnum.ALL:
        return true;
      case ProductFilterValuesEnum.ALL_RAW_MATERIALS:
        return batch.content.type === ContentType.COMMODITY;
      case BatchContentSpecificType.POWDER:
        return (
          batch.content.type === ContentType.COMMODITY &&
          batch.content.details.specificType === BatchContentSpecificType.POWDER
        );
      case BatchContentSpecificType.SOFTGELS:
        return (
          batch.content.type === ContentType.COMMODITY &&
          batch.content.details.specificType === BatchContentSpecificType.SOFTGELS
        );
      case BatchContentSpecificType.LIQUID:
        return (
          batch.content.type === ContentType.COMMODITY &&
          batch.content.details.specificType === BatchContentSpecificType.LIQUID
        );
      default:
        return false;
    }
  };

  return (
    checkProductType() &&
    (!additionalProductFilter.organic || (additionalProductFilter.organic && !!batch.content.details.organic)) &&
    (!additionalProductFilter.providedByCustomer ||
      (additionalProductFilter.providedByCustomer && batch.sender.type === SenderType.CUSTOMER))
  );
};

/**
 * A filter function that returns truthy or falsy whether the batch should be kept or not
 * @param batch a single batch
 * @param activeTab the currently active tab
 * @param reservations list of reservations
 * @param configuration the warehouse configuration
 * @returns {boolean} true if batch should be displayed for the tab, else false
 */
export function isBatchRelevantForTab(
  batch: Batch,
  activeTab: WarehouseListingTabNames,
  reservations: Array<Reservation>,
  configuration: WarehouseConfiguration | null
): boolean {
  switch (activeTab) {
    case WarehouseListingTabNames.INCOMING:
      return isBatchIncoming(batch, configuration);
    case WarehouseListingTabNames.RESERVED:
      return reservations.some(
        r => r.state === ReservationState.OPEN && r.batches.some(b => b.batch._id.toString() === batch._id.toString())
      );
    case WarehouseListingTabNames.AVAILABLE:
      return (
        !batch.blocked &&
        !reservations.some(
          r => r.state === ReservationState.OPEN && r.batches.some(b => b.batch._id.toString() === batch._id.toString())
        )
      );
  }
  return true;
}

/**
 * A filter function that returns truthy or falsy whether the location should be kept or not
 * @param batchId id of a single batch
 * @param location a specific location object of a batch
 * @param activeTab the currently active tab
 * @param reservations list of reservations
 * @param warehouseContext the warehouse context
 * @returns {boolean} true if batch location should be displayed for the tab, else false
 */
export function filterLocations(
  batchId: string,
  location: BatchLocation,
  activeTab: WarehouseListingTabNames,
  reservations: Array<Reservation>,
  warehouseContext: WarehouseContext
): boolean {
  const { configuration, selectedLocation } = warehouseContext;
  const matchesLocation = selectedLocation
    ? location.location.warehouseSnapshot._id.toString() === selectedLocation.warehouse &&
      (!selectedLocation.warehouseArea ||
        location.location.warehouseArea._id.toString() === selectedLocation.warehouseArea)
    : true;
  switch (activeTab) {
    case WarehouseListingTabNames.INCOMING:
      return matchesLocation && isBatchLocationIncoming(location.location, configuration);
    case WarehouseListingTabNames.RESERVED:
      return (
        matchesLocation &&
        reservations.some(
          r =>
            r.state === ReservationState.OPEN &&
            r.batches.some(
              b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location)
            )
        )
      );
    case WarehouseListingTabNames.AVAILABLE:
      return (
        matchesLocation &&
        !location.packagingUnits.some(p => p.blocked) &&
        !reservations.some(
          r =>
            r.state === ReservationState.OPEN &&
            r.batches.some(
              b => b.batch._id.toString() === batchId && compareBatchLocations(b.location, location.location)
            )
        )
      );
  }
  return matchesLocation;
}

/**
 * Get documents for a specific listing tab
 * @param tab name of the listing tab
 * @param dataContext the data context
 * @param warehouseContext the warehouse context
 * @returns {Array<Batch | CommodityWithBatches>} list of batches or commodities with batches
 */
export function getDocumentsForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<Batch | CommodityWithBatches> {
  switch (tab) {
    case WarehouseListingTabNames.RAW_MATERIAL:
    case WarehouseListingTabNames.AVAILABLE:
    case WarehouseListingTabNames.RESERVED:
      return getBatchesPerCommodityForTab(tab, dataContext, warehouseContext);
    default:
      return getBatchesForTab(tab, dataContext, warehouseContext);
  }
}

/**
 * Filter batches or commodity with batches
 * @param documents list of batches or commodities with batches
 * @param warehouseContext the warehouse context with selected filters
 * @param excludeLocation optional, flag to skip the location check
 * @returns {Array<Batch | CommodityWithBatches>} list of batches or commodities with batches
 */
export function filterListingDocuments(
  documents: Array<Batch | CommodityWithBatches>,
  warehouseContext: WarehouseContext,
  excludeLocation?: boolean
): Array<Batch | CommodityWithBatches> {
  const { selectedLocation, productFilter, additionalProductFilter, query } = warehouseContext;
  if (isBatches(documents)) {
    let filteredDocuments: Array<Batch> = searchBatches(documents, query);
    filteredDocuments = filteredDocuments.filter((b: Batch) =>
      filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
    );
    return filteredDocuments;
  } else if (isCommoditiesWithBatches(documents)) {
    const filteredDocuments: Array<CommodityWithBatches> = [];
    documents.forEach(c => {
      let filteredBatches: Array<Batch> = searchBatches(c.batches, query);
      filteredBatches = filteredBatches.filter((b: Batch) =>
        filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
      );
      if (filteredBatches.length > 0)
        filteredDocuments.push({ ...c, batches: filteredBatches } as CommodityWithBatches);
    });
    return filteredDocuments;
  }
  return [];
}

/**
 * Get batches grouped by commodities
 * @param tab name of the listing tab
 * @param dataContext the data context
 * @param warehouseContext the warehouse context
 * @returns {Array<CommodityWithBatches>} list of commodities with batches
 */
export function getBatchesPerCommodityForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<CommodityWithBatches> {
  const { commodities, batch, reservation } = dataContext;
  const { configuration } = warehouseContext;
  const commodityMap: { [id: string]: { batches: Array<Batch>; commodity: CommoditiesDocument } } = {};
  batch.forEach(b => {
    if (b.content.type === ContentType.COMMODITY && isBatchRelevantForTab(b, tab, reservation, configuration)) {
      const commodityId = b.content.details._id.toString();
      if (commodityId in commodityMap) commodityMap[commodityId].batches.push(b);
      else {
        const commodityDoc = baseUtils.getDocFromCollection(commodities, commodityId);
        if (commodityDoc) commodityMap[commodityId] = { commodity: commodityDoc, batches: [b] };
      }
    }
  });

  return Object.values(commodityMap).map(({ batches, commodity }) => ({
    ...commodity,
    batches
  })) as Array<CommodityWithBatches>;
}

/**
 * Get all batches relevant for a tab
 * @param tab the warehouse listing tab this function is called for
 * @param dataContext the complete data context
 * @param warehouseContext the complete warehouse Context
 * @returns {Array<Batch>} list of batches relevant for the tab
 */
export function getBatchesForTab(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext
): Array<Batch> {
  const { batch, reservation } = dataContext;
  const { configuration } = warehouseContext;
  return batch.filter(b => isBatchRelevantForTab(b, tab, reservation, configuration));
}

/**
 * Get all batches relevant for current tab and applied filters
 * @param tab the warehouse listing tab this function is called for
 * @param dataContext the complete data context
 * @param warehouseContext the complete warehouse Context
 * @param excludeLocation optional, if given, the location will not be included in filtering
 * @returns {Array<Batch>} list of batches matching the tab and filters
 */
export function getBatches(
  tab: WarehouseListingTabNames,
  dataContext: DataContextType,
  warehouseContext: WarehouseContext,
  excludeLocation?: boolean
): Array<Batch> {
  const { batch, reservation } = dataContext;
  const { configuration, selectedLocation, productFilter, additionalProductFilter, query } = warehouseContext;
  const searchedBatches = searchBatches(batch, query);
  return searchedBatches.filter(
    b =>
      isBatchRelevantForTab(b, tab, reservation, configuration) &&
      filterBatch(b, selectedLocation, productFilter, additionalProductFilter, excludeLocation)
  );
}

/**
 * Check if given array contains batches
 * @param documents list of batches or commodities with batches
 * @returns {boolean} true if list contains batches else false
 */
export function isBatches(
  documents: Array<Batch> | Array<CommodityWithBatches> | Array<Batch | CommodityWithBatches>
): documents is Array<Batch> {
  return documents.length === 0 || "content" in documents[0];
}

/**
 * Check if given array contains commodities with batches
 * @param documents list of batches or commodities with batches
 * @returns {boolean} true if list contains commodities with batches else false
 */
export function isCommoditiesWithBatches(
  documents: Array<Batch> | Array<CommodityWithBatches> | Array<Batch | CommodityWithBatches>
): documents is Array<CommodityWithBatches> {
  return documents.length === 0 || "hs_code" in documents[0];
}

/**
 * Check if given document is a batch
 * @param document batch or commodity with batches document
 * @returns {boolean} true if document is a batch else false
 */
export function isBatch(document: Batch | CommodityWithBatches): document is Batch {
  return "content" in document;
}

/**
 * Check if given document is a commodity with batches
 * @param document batch or commodity with batches document
 * @returns {boolean} true if document is a batch else false
 */
export function isCommodityWithBatches(document: Batch | CommodityWithBatches): document is CommodityWithBatches {
  return "hs_code" in document;
}

/**
 * Check is a batch is (partially) "incoming"
 * @param batch any batch document
 * @param configuration the warehouse configuration
 * @returns {boolean} true if batch is (partially) "incoming", else false
 */
export function isBatchIncoming(batch: Batch, configuration: WarehouseConfiguration | null): boolean {
  return batch.locations.some(l => isBatchLocationIncoming(l.location, configuration));
}

/**
 * Check if a batch location is "incoming", i.e. not assigned to a storage space in a directly managed warehouse
 * @param location a batch location
 * @param configuration the warehouse configuration
 * @returns {boolean} true if location is "incoming", else false
 */
export function isBatchLocationIncoming(
  location: BatchLocationInformation,
  configuration: WarehouseConfiguration | null
): boolean {
  if (!configuration || location.storageSpace) return false;
  const { warehouseStructure } = configuration.values;
  return warehouseStructure.some(
    w =>
      w._id.toString() === location.warehouseSnapshot._id.toString() &&
      w.physicalWarehouses.some(
        p => p._id.toString() === location.warehouseArea._id.toString() && p.type === WarehouseTypes.DIRECTLYMANAGED
      )
  );
}

/**
 * Search a list of batches with the given query
 * @param batch list of batches
 * @param query a search query to filter batches with
 * @returns {Array<Batch>} list of batches matching the query
 */
export function searchBatches(batch: Array<Batch>, query: string): Array<Batch> {
  if (!query.trim()) return batch;
  const lang = language();
  return baseUtils.doFuseSearch(batch, query, [
    "lot",
    `content.details.title.${lang}`,
    `content.details.subtitle.${lang}`,
    "content.details.title.de", // DE as fallback if lang is a not yet supported one
    "content.details.subtitle.de",
    "content.details.specificType",
    "sender.name",
    "sender.type"
  ]);
}
