import React, { PureComponent } from "react";
import CommoditiesBaseListing from "./CommoditesBaseListing";
import { DataContext } from "../../context/dataContext";
import commodityUtils from "../../utils/commodityUtils";

interface CommoditiesProps {}
interface CommoditiesState {}

class Commodities extends PureComponent<CommoditiesProps, CommoditiesState> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    const { commodities } = this.context;
    const cFiltered = commodities.filter(c => !c.disabled && commodityUtils.hasCorrectType(c, "commodities"));
    return <CommoditiesBaseListing type={"commodities"} commodities={cFiltered} context={this.context} />;
  }
}

export default Commodities;
