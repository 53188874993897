import _ from "lodash";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { BatchSlim, CustomOrder, PackagingBatchSlim, PackagingStockUpdate, StockUpdate } from "../CustomTypes";
import { DataContext } from "../../../context/dataContext";
import StockUpdateItem from "./common/StockUpdateItem";
import { T_RESERVECOMMODITY, T_RESERVEPACKAGING } from "../../../utils/timelineUtils";
import userService from "../../../services/userService";
import { UsedBatch, UsedPackagingBatch } from "../../../model/orders.types";
import dbService, { UpdateAction, ORDERS } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import orderUtils from "../../../utils/orderUtils";
import accessUtils, { ACTIONS } from "../../../utils/accessUtils";
import AllocatedInfoTooltip from "./common/AllocatedInfoTooltip";
import RemainingInfoTooltip from "./common/RemainingInfoTooltip";

interface ReserveMaterialModalProps {
  type: "commodity" | "packaging";
  order: CustomOrder;
  onHide: () => void;
  show: boolean;
  context: React.ContextType<typeof DataContext>;
}

interface ReserveMaterialModalState {
  commodities: Array<StockUpdate>;
  packaging: Array<PackagingStockUpdate>;
  loading: boolean;
}

class ReserveMaterialModal extends PureComponent<ReserveMaterialModalProps, ReserveMaterialModalState> {
  constructor(props: ReserveMaterialModalProps) {
    super(props);
    this.state = { commodities: [], packaging: [], loading: false };
  }

  componentDidMount() {
    const { context, order } = this.props;
    this.setState({
      commodities: orderUtils.generateCommoditiesStockList(order, context.commodities),
      packaging: orderUtils.generatePackagingStockList(order, context.packagings, context.packagingStock)
    });
  }

  componentDidUpdate(
    prevProps: Readonly<ReserveMaterialModalProps>,
    prevState: Readonly<ReserveMaterialModalState>,
    snapshot?: any
  ) {
    const { context, order } = this.props;
    const usedCommodities = order.recipe.map(c => c.id.toString());
    const usedPackaging = order.calculations[0].packagings.map(p => p._id.toString());
    if (
      !_.isEqual(prevProps.order, order) ||
      !_.isEqual(
        prevProps.context.commodities.filter(c => usedCommodities.includes(c._id.toString())),
        context.commodities.filter(c => usedCommodities.includes(c._id.toString()))
      ) ||
      !_.isEqual(
        prevProps.context.packagingStock.filter(p => usedPackaging.includes(p.packaging.toString())),
        context.packagingStock.filter(p => usedPackaging.includes(p.packaging.toString()))
      )
    ) {
      const newCommodityStock = orderUtils.generateCommoditiesStockList(order, context.commodities);
      const newPackagingStock = orderUtils.generatePackagingStockList(
        order,
        context.packagings,
        context.packagingStock
      );
      this.setState({
        commodities: orderUtils.mergeStockLists(this.state.commodities, newCommodityStock) as Array<StockUpdate>,
        packaging: orderUtils.mergeStockLists(this.state.packaging, newPackagingStock) as Array<PackagingStockUpdate>
      });
    }
  }

  /**
   * Handle updating the stock data.
   * @param commodityID: ID of the commodity
   * @param batchID: ID of the batch
   */
  handleUpdateStock = (commodityID: string, batchID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const commodities = _.cloneDeep(this.state.commodities);
    const batch = commodities.find(c => c.id.toString() === commodityID)?.stock.find(s => s.id === batchID);
    if (!batch) return;
    batch.used = Number(parseFloat(e.target.value) || "0").toString();
    this.setState({ commodities });
  };

  /**
   * Handle updating the stock data.
   * @param packagingId: ID of the packaging
   * @param batchID: ID of the batch
   */
  handleUpdatePackagingStock = (packagingId: string, batchID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const packaging = _.cloneDeep(this.state.packaging);
    const batch = packaging.find(p => p.id.toString() === packagingId)?.stock.find(s => s.id.toString() === batchID);
    if (!batch) return;
    batch.used = Number(parseFloat(e.target.value) || "0").toString();
    this.setState({ packaging });
  };

  /**
   * Handles clicking the reserve button.
   */
  handleClickReserve = async () => {
    const { order, context, type } = this.props;
    const { commodities, packaging } = this.state;
    this.setState({ loading: true });
    const usedBatches: Array<UsedBatch> = [];
    const usedPackagingBatches: Array<UsedPackagingBatch> = [];
    for (let i = 0; i < commodities.length; i++) {
      const commodity = commodities[i];
      for (let j = 0; j < commodity.stock.length; j++) {
        const stock = commodity.stock[j];
        const batch = stock.stock;
        // Only include stocks that were used
        if (+stock.used > 0) {
          usedBatches.push({
            id: batch._id,
            commodityId: commodity.commodity._id,
            supplier: batch.supplier,
            used: +stock.used,
            lot: batch.lot,
            location: batch.location,
            price: batch.price,
            files: batch.files
          });
        }
      }
    }
    for (let i = 0; i < packaging.length; i++) {
      const pack = packaging[i];
      for (let j = 0; j < pack.stock.length; j++) {
        const stock = pack.stock[j];
        const batch = stock.stock;
        // Only include stocks that were used
        if (+stock.used > 0) {
          usedPackagingBatches.push({
            id: batch._id,
            packagingId: pack.packaging._id,
            supplier: batch.supplier,
            used: +stock.used,
            lot: batch.lot,
            location: batch.location,
            price: batch.price,
            files: batch.files
          });
        }
      }
    }

    // Collect some data for a diff inside the timeline entry
    const removed: Array<BatchSlim | PackagingBatchSlim> = [];
    const altered: Array<{ old: BatchSlim | PackagingBatchSlim; new: BatchSlim | PackagingBatchSlim }> = [];
    const added: Array<BatchSlim | PackagingBatchSlim> = [];
    if (type === "commodity") {
      if (order.usedBatches && order.usedBatches.length > 0) {
        for (let i = 0; i < order.usedBatches.length; i++) {
          const uBOld = order.usedBatches[i];
          const uBNew = usedBatches.find(u => u.id === uBOld.id);
          if (!uBNew) removed.push(_.pick(uBOld, ["id", "commodityId", "lot", "supplier", "used"]));
          else {
            altered.push({
              old: _.pick(uBOld, ["id", "commodityId", "lot", "supplier", "used"]),
              new: _.pick(uBNew, ["id", "commodityId", "lot", "supplier", "used"])
            });
          }
        }
      }
      for (let i = 0; i < usedBatches.length; i++) {
        const uBNew = usedBatches[i];
        const uBOld = order.usedBatches?.find(u => u.id === uBNew.id);
        if (!uBOld) added.push(_.pick(uBNew, ["id", "commodityId", "lot", "supplier", "used"]));
      }
    }
    if (type === "packaging") {
      if (order.usedPackagingBatches && order.usedPackagingBatches.length > 0) {
        for (let i = 0; i < order.usedPackagingBatches.length; i++) {
          const uBOld = order.usedPackagingBatches[i];
          const uBNew = usedPackagingBatches.find(u => u.id.toString() === uBOld.id.toString());
          if (!uBNew) removed.push(_.pick(uBOld, ["id", "packagingId", "lot", "supplier", "used"]));
          else {
            altered.push({
              old: _.pick(uBOld, ["id", "packagingId", "lot", "supplier", "used"]),
              new: _.pick(uBNew, ["id", "packagingId", "lot", "supplier", "used"])
            });
          }
        }
      }
      for (let i = 0; i < usedPackagingBatches.length; i++) {
        const uBNew = usedPackagingBatches[i];
        const uBOld = order.usedPackagingBatches?.some(u => u.id.toString() === uBNew.id.toString());
        if (!uBOld) added.push(_.pick(uBNew, ["id", "packagingId", "lot", "supplier", "used"]));
      }
    }
    const timelineEntry = {
      type: type === "commodity" ? T_RESERVECOMMODITY : T_RESERVEPACKAGING,
      reservations: { removed, altered, added },
      date: new Date(),
      person: userService.getUserId()
    };
    const action: UpdateAction = {
      collection: ORDERS,
      filter: { _id: order._id },
      push: { timeline: timelineEntry },
      update: type === "commodity" ? { usedBatches } : { usedPackagingBatches }
    };
    const res = await dbService.updatesAsTransaction([action]);
    await toastUtils.databaseOperationToast(res, "Materials reserved successfully", "Error reserving materials", () =>
      context.updateDocumentInContext(ORDERS, order._id)
    );
    this.setState({ loading: false });
    if (res) this.handleHide();
  };

  handleHide = () => {
    const { onHide } = this.props;
    this.setState({ loading: false });
    onHide();
  };

  /**
   * Check the input for invalidity.
   * @returns { boolean } True if input is invalid, else false
   */
  isInvalidInput = () => {
    const { commodities } = this.state;
    for (let i = 0; i < commodities.length; i++) {
      const c = commodities[i];
      if (c.stock.some(s => +s.used < 0 || +s.used > s.stock.amount)) return true;
    }
    return false;
  };

  render() {
    const { context, order, show, type } = this.props;
    const { commodities, packaging, loading } = this.state;
    const disabled = loading || this.isInvalidInput() || !accessUtils.canPerformAction(ACTIONS.RESERVEMATERIAL);
    return (
      <Modal show={show} onHide={this.handleHide} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Allocate {type} for AT-{order.identifier}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "commodity" && (
            <div className="form-group form-group-last">
              <div className="row">
                <div className="col-4">
                  <span className="form-text kt-font-dark">Used Batch</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">Used Amount</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">
                    Remaining
                    <RemainingInfoTooltip />
                  </span>
                </div>
                <div className="col-2">
                  <span className="form-text kt-font-dark">
                    Other Allocated
                    <AllocatedInfoTooltip />
                  </span>
                </div>
              </div>
              {commodities.map(c => (
                <StockUpdateItem
                  key={c.id.toString()}
                  location={order.settings.manufacturer._id}
                  stockUpdate={c}
                  onUpdateStock={this.handleUpdateStock}
                  context={context}
                  order={order}
                  showEditedBatches={false}
                />
              ))}
            </div>
          )}
          {type === "packaging" && (
            <div className="form-group form-group-last">
              <div className="row">
                <div className="col-4">
                  <span className="form-text kt-font-dark">Used Batch</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">Used Amount</span>
                </div>
                <div className="col-3">
                  <span className="form-text kt-font-dark">
                    Remaining
                    <RemainingInfoTooltip />
                  </span>
                </div>
                <div className="col-2">
                  <span className="form-text kt-font-dark">
                    Other Allocated
                    <AllocatedInfoTooltip />
                  </span>
                </div>
              </div>
              {packaging.map(p => (
                <StockUpdateItem
                  key={p.id.toString()}
                  location={order.settings.manufacturer._id}
                  stockUpdate={p}
                  onUpdateStock={this.handleUpdatePackagingStock}
                  context={context}
                  order={order}
                  showEditedBatches={false}
                />
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={this.handleHide}>
            Close
          </button>
          <button
            className={"btn btn-success" + (disabled ? " disabled" : "")}
            onClick={disabled ? undefined : this.handleClickReserve}
            disabled={disabled}
          >
            {loading ? "Loading..." : "Allocate"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReserveMaterialModal;
