import _ from "lodash";
import React, { Component, PureComponent } from "react";
import { BSON } from "realm-web";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PackagingPrice, PackagingsDocument } from "../../../../model/packagings.types";
import { CustomOrder, ExtendedPackagingSupplier } from "../../CustomTypes";
import { SuppliersDocument } from "../../../../model/suppliers.types";
import { calculation, pricing, pricingCommodities } from "../../../../model/orders.types";
import packagingUtils from "../../../../utils/packagingUtils";
import orderCalculationUtils from "../../../../utils/orderCalculationUtils";
import UpdatePricesModal from "../../modals/UpdatePricesModal";
import accessUtils, { ACTIONS } from "../../../../utils/accessUtils";
import { DataContext } from "../../../../context/dataContext";

interface OrderPackagingCheckProps {
  order: CustomOrder;
  packaging: Array<PackagingsDocument>;
  suppliers: Array<SuppliersDocument>;
  calculations: Array<calculation>;
  onFlagChange: (type: "requested" | "updated", commodityId: BSON.ObjectId | string) => void;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderPackagingCheckState {
  packagingToUpdate?: PackagingsDocument;
}

class OrderPackagingCheck extends PureComponent<OrderPackagingCheckProps, OrderPackagingCheckState> {
  constructor(props: OrderPackagingCheckProps) {
    super(props);
    this.state = {};
  }

  handleUpdatePrices = (packaging: PackagingsDocument) => this.setState({ packagingToUpdate: packaging });
  handleCloseUpdatePrices = () => this.setState({ packagingToUpdate: undefined });

  render() {
    const { order, packaging, suppliers, calculations, context, onFlagChange, onCalculationChange } = this.props;
    const { packagingToUpdate } = this.state;
    const canCheck = accessUtils.canPerformAction(ACTIONS.ORDERCOMMODITYCHECK);

    return (
      <>
        {packagingToUpdate && (
          <UpdatePricesModal type={"packaging"} document={packagingToUpdate} onClose={this.handleCloseUpdatePrices} />
        )}
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--subtable kt-datatable--loaded table-responsive">
          <table className="kt-datatable__table d-table ">
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Amount</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "52%" }}>
                  <span>Packaging</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Requested</span>
                </th>
                <th className="kt-datatable__cell d-table-cell" style={{ width: "10%" }}>
                  <span>Updated</span>
                </th>
                <th className="kt-datatable__cell d-table-cell text-right" style={{ width: "18%" }}>
                  <span>Prices</span>
                </th>
              </tr>
            </thead>
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {order.calculations[0].packagings.map(price => {
                const pack = packaging.find(p => p._id.toString() === price._id.toString());
                if (!pack) return null;
                return (
                  <OrderPackagingCheckItem
                    key={price._id.toString()}
                    order={order}
                    price={price}
                    packaging={pack}
                    suppliers={suppliers}
                    calculations={calculations}
                    canCheck={canCheck}
                    context={context}
                    onFlagChange={onFlagChange}
                    onCalculationChange={onCalculationChange}
                    onUpdatePrices={() => this.handleUpdatePrices(pack)}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

interface OrderPackagingCheckItemProps {
  order: CustomOrder;
  price: pricing;
  packaging: PackagingsDocument;
  suppliers: Array<SuppliersDocument>;
  calculations: Array<calculation>;
  canCheck: boolean;
  context: React.ContextType<typeof DataContext>;
  onFlagChange: (type: "requested" | "updated", commodityId: BSON.ObjectId | string) => void;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  onUpdatePrices: () => void;
}

interface OrderPackagingCheckItemState {
  showDetails: boolean;
}

class OrderPackagingCheckItem extends React.Component<OrderPackagingCheckItemProps, OrderPackagingCheckItemState> {
  constructor(props: OrderPackagingCheckItemProps) {
    super(props);
    this.state = {
      showDetails: true
    };
  }

  shouldComponentUpdate = (
    nextProps: Readonly<OrderPackagingCheckItemProps>,
    nextState: Readonly<OrderPackagingCheckItemState>
  ): boolean => {
    const { order, price, packaging, suppliers, calculations, context } = this.props;
    const { showDetails } = this.state;

    return (
      !_.isEqual(showDetails, nextState.showDetails) ||
      !_.isEqual(order, nextProps.order) ||
      !_.isEqual(price, nextProps.price) ||
      !_.isEqual(packaging, nextProps.packaging) ||
      !_.isEqual(suppliers, nextProps.suppliers) ||
      calculations.some(c => {
        const nextCalc = nextProps.calculations.find(c2 => c2.id === c.id);
        if (!nextCalc) return true;
        return !_.isEqual(
          c.packagings.find(p => p._id.toString() === packaging._id.toString()),
          nextCalc.packagings.find(p => p._id.toString() === packaging._id.toString())
        );
      }) ||
      !_.isEqual(context.orders, nextProps.context.orders) ||
      !_.isEqual(context.packagingOrders, nextProps.context.packagingOrders) ||
      !_.isEqual(context.packagingStock, nextProps.context.packagingStock)
    );
  };

  handleToggleDetails = () => this.setState({ showDetails: !this.state.showDetails });

  render() {
    const {
      order,
      price,
      packaging,
      suppliers,
      calculations,
      canCheck,
      context,
      onFlagChange,
      onCalculationChange,
      onUpdatePrices
    } = this.props;
    const { showDetails } = this.state;
    const { packagingOrders, packagingStock, orders } = context;

    const requested = calculations.every(c => {
      const price = c.packagings.find(p => p._id.toString() === packaging._id.toString());
      return !!price && !!price.requested;
    });
    const updated = calculations.every(c => {
      const price = c.packagings.find(p => p._id.toString() === packaging._id.toString());
      return !!price && !!price.updated;
    });
    const availableStock = packagingUtils.getAvailableStock(
      packaging,
      packagingStock,
      packagingOrders,
      orders,
      order.settings.manufacturer._id
    );

    return (
      <>
        <tr className="kt-datatable__row d-table-row material-row">
          <td className=" kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>{price.amount + (price.amount === 1 ? " item" : " items")}</span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <div className="kt-user-card-v2">
              <div className="kt-user-card-v2__details">
                <Link className="kt-user-card-v2__name kt-link" to={"/packaging/" + packaging._id.toString()}>
                  {packagingUtils.getPackagingType(packaging.packaging_type)}
                  {packaging.article_number && <span className="text-success"> {packaging.article_number}</span>}
                </Link>
                <span className="kt-user-card-v2__email">{packagingUtils.resolvePackagingProperties(packaging)}</span>
              </div>
            </div>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>
              <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
                <input
                  type="checkbox"
                  onChange={canCheck ? () => onFlagChange("requested", packaging._id) : undefined}
                  checked={requested}
                  disabled={!canCheck}
                />
                <span />
              </label>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell" onClick={this.handleToggleDetails}>
            <span>
              <label className="kt-checkbox kt-checkbox--single checkbox-dark my-auto">
                <input
                  type="checkbox"
                  onChange={canCheck ? () => onFlagChange("updated", packaging._id) : undefined}
                  checked={updated}
                  disabled={!canCheck}
                />
                <span />
              </label>
            </span>
          </td>
          <td className="kt-datatable__cell d-table-cell text-right">
            {canCheck && (
              <span className="pointer text-hover-black" onClick={onUpdatePrices}>
                <u>Update Prices</u>
              </span>
            )}
          </td>
        </tr>
        <tr className={showDetails ? "kt-datatable__row d-table-row " : "d-none"}>
          <td colSpan={5}>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
              style={{ boxShadow: "none" }}
            >
              <table className="kt-datatable__table d-table">
                <tbody
                  className="kt-datatable__body "
                  style={{ backgroundColor: "#fafafa", display: "table-row-group" }}
                >
                  {calculations.map(c => (
                    <OrderPackagingCheckItemDetails
                      key={c.id.toString()}
                      calculation={c}
                      order={order}
                      price={price}
                      packaging={packaging}
                      suppliers={suppliers}
                      availableStock={availableStock}
                      canCheck={canCheck}
                      context={context}
                      onCalculationChange={onCalculationChange}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

interface OrderPackagingCheckItemDetailsProps {
  calculation: calculation;
  order: CustomOrder;
  price: pricing;
  packaging: PackagingsDocument;
  suppliers: Array<SuppliersDocument>;
  availableStock: number;
  canCheck: boolean;
  onCalculationChange: (calculation: calculation, priceId: string | BSON.ObjectId) => void;
  context: React.ContextType<typeof DataContext>;
}

interface OrderPackagingCheckItemDetailsState {
  calculation: calculation;
  extendedSuppliers: Array<ExtendedPackagingSupplier>;
}

class OrderPackagingCheckItemDetails extends Component<
  OrderPackagingCheckItemDetailsProps,
  OrderPackagingCheckItemDetailsState
> {
  constructor(props: OrderPackagingCheckItemDetailsProps) {
    super(props);
    this.state = {
      calculation: _.cloneDeep(props.calculation),
      extendedSuppliers: this.getExtendedSuppliers(props)
    };
  }

  componentDidUpdate(
    prevProps: Readonly<OrderPackagingCheckItemDetailsProps>,
    prevState: Readonly<OrderPackagingCheckItemDetailsState>,
    snapshot?: any
  ) {
    if (prevProps.calculation !== this.props.calculation || !_.isEqual(prevProps.calculation, this.props.calculation)) {
      this.setState({ calculation: _.cloneDeep(this.props.calculation) });
    }
    if (
      !_.isEqual(prevProps.packaging, this.props.packaging) ||
      !_.isEqual(prevProps.suppliers, this.props.suppliers)
    ) {
      this.setState({ extendedSuppliers: this.getExtendedSuppliers(this.props) });
    }
  }

  shouldComponentUpdate = (
    nextProps: Readonly<OrderPackagingCheckItemDetailsProps>,
    nextState: Readonly<OrderPackagingCheckItemDetailsState>
  ): boolean => {
    const { order, price, packaging, suppliers, calculation, availableStock } = this.props;
    const { calculation: cState, extendedSuppliers } = this.state;
    return (
      availableStock !== nextProps.availableStock ||
      !_.isEqual(price, nextProps.price) ||
      !_.isEqual(cState, nextState.calculation) ||
      !_.isEqual(calculation, nextProps.calculation) ||
      !_.isEqual(order, nextProps.order) ||
      !_.isEqual(packaging, nextProps.packaging) ||
      !_.isEqual(suppliers, nextProps.suppliers) ||
      !_.isEqual(extendedSuppliers, nextState.extendedSuppliers)
    );
  };

  /**
   * Get extended suppliers
   * @param props the components properties
   * @returns {ExtendedCommoditySupplier} supplier document extended with price information for the commodity
   */
  getExtendedSuppliers = (props: OrderPackagingCheckItemDetailsProps) => {
    const { packaging, suppliers } = props;
    return packaging.suppliers.map(s => {
      const supplier = suppliers.find(s2 => s2._id.toString() === s._id.toString());
      return {
        _id: s._id,
        prices: s.prices,
        ...supplier
      } as ExtendedPackagingSupplier;
    });
  };

  handleCalculationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { price, onCalculationChange } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const tmpPrice = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const key = e.target.name as keyof pricingCommodities;
    let value = e.target.value;
    if (["orderquantity"].includes(key)) {
      // handle in-component state updates that are propagated only on blur, no recalculation
      // @ts-ignore
      tmpPrice[key] = Number(parseInt(value) || "0");
      this.setState({ calculation });
    } else {
      // this path is currently only for deliverytime in case of supplier === customer! no recalculation required here
      // @ts-ignore
      tmpPrice[key] = +value;
      onCalculationChange(calculation, price._id);
    }
  };

  handleSupplierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, packaging, price, onCalculationChange, context } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const id = e.currentTarget.value;
    const supplierId = BSON.ObjectId.isValid(id) ? new BSON.ObjectId(id) : id;
    const packagingPrice = orderCalculationUtils.getMatchingPackagingPrice(
      packaging,
      order.settings.manufacturer,
      quantity,
      context,
      supplierId
    )!;
    this.updatePriceObject(priceObject, packagingPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleDeliveryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, packaging, price, onCalculationChange, context } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const targetDelivery = e.currentTarget.value;
    const packagingPrice = orderCalculationUtils.getMatchingPackagingPrice(
      packaging,
      order.settings.manufacturer,
      quantity,
      context,
      priceObject.supplier,
      targetDelivery
    );
    if (packagingPrice.price.delivery !== targetDelivery) {
      toast.error("No matching price could be found for delivery option: " + targetDelivery);
      return;
    }
    this.updatePriceObject(priceObject, packagingPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleDeliveryTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { order, packaging, price, onCalculationChange, context } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const targetDeliveryTime = +e.currentTarget.value;
    const packagingPrice = orderCalculationUtils.getMatchingPackagingPrice(
      packaging,
      order.settings.manufacturer,
      quantity,
      context,
      priceObject.supplier,
      priceObject.delivery,
      targetDeliveryTime
    );
    if (packagingPrice.price.deliverytime !== targetDeliveryTime) {
      toast.error("No matching price could be found for the selected delivery time: " + targetDeliveryTime);
      return;
    }
    this.updatePriceObject(priceObject, packagingPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  handleOrderQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { order, packaging, price, onCalculationChange, context } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const valueNum = parseInt(e.target.value);
    const packagingPrice = orderCalculationUtils.getMatchingPackagingPrice(
      packaging,
      order.settings.manufacturer,
      valueNum,
      context,
      price.supplier
    )!;
    this.updatePriceObject(priceObject, packagingPrice, valueNum);
    onCalculationChange(calculation, price._id);
  };

  /**
   * Handle selection of delivery time. The action depends on the selected supplier
   * @param e The react change event
   * @param priceObject the price object containing supplier information
   */
  handleDeliveryTimeSelect = (e: React.ChangeEvent<HTMLSelectElement>, priceObject: pricing) => {
    if (typeof priceObject.supplier === "string") {
      if (priceObject.supplier === "customer") this.handleCalculationChange(e);
    } else {
      this.handleDeliveryTimeChange(e);
    }
  };

  handleAutoSelect = () => {
    const { order, packaging, price, onCalculationChange, context } = this.props;
    const calculation = _.cloneDeep(this.state.calculation);
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const quantity = priceObject.orderquantity!;
    const packagingPrice = orderCalculationUtils.getMatchingPackagingPrice(
      packaging,
      order.settings.manufacturer,
      quantity,
      context
    )!;
    this.updatePriceObject(priceObject, packagingPrice, quantity);
    onCalculationChange(calculation, price._id);
  };

  /**
   * Updates the passed price object with new price data
   * @param priceObject object of a calculation price
   * @param price object with new commodity price
   * @param orderQuantity the total order quantity
   * @param auto optional auto flag
   */
  updatePriceObject = (
    priceObject: pricing,
    price: { _id: BSON.ObjectID | "customer" | "ownstock"; price: PackagingPrice },
    orderQuantity: number,
    auto?: boolean
  ) => {
    const newPrice = price.price;
    const newTotalPrice = orderQuantity * newPrice.price;
    priceObject.supplier = price._id;
    priceObject.price = newPrice.price;
    priceObject.totalprice = newTotalPrice;
    priceObject.orderquantity = orderQuantity;
    priceObject.delivery = newPrice.delivery;
    priceObject.deliverytime = newPrice.deliverytime;
    priceObject.auto = !!auto;
  };

  render() {
    const { price, packaging, suppliers, availableStock, canCheck } = this.props;
    const { calculation, extendedSuppliers } = this.state;
    const priceObject = calculation.packagings.find(p => p._id.toString() === price._id.toString())!;
    const priceDifference = (+priceObject.price / +priceObject.estimatedprice - 1) * 100;
    const selectedSupplier = suppliers.find(s => s._id.toString() === priceObject.supplier.toString());
    const totalAmount = +calculation.units * price.amount * (1 + price.buffer / 100);
    let availableDeliveries = ["parcel", "air"];
    let deliveryList: Array<string> = [];
    const deliveryTimesByDelivery: any = {};
    if (selectedSupplier && packaging.suppliers) {
      const moq = orderCalculationUtils.getMatchingMOQ(packaging, selectedSupplier._id, +priceObject.orderquantity!);
      const selectedSupplierPrices = packaging.suppliers.find(
        s => s._id.toString() === selectedSupplier._id.toString()
      );
      if (selectedSupplierPrices) {
        selectedSupplierPrices.prices.forEach(p => {
          if (moq === p.moq) {
            if (!deliveryList.includes(p.delivery)) {
              deliveryTimesByDelivery[p.delivery] = [p.deliverytime];
              if (p.delivery.trim() !== "") deliveryList.push(p.delivery);
            } else {
              deliveryTimesByDelivery[p.delivery].push(p.deliverytime);
            }
          }
          availableDeliveries = deliveryList;
        });
      }
    }
    const deliveryTimesForDelivery =
      !_.isEmpty(deliveryTimesByDelivery) && deliveryTimesByDelivery[priceObject.delivery]
        ? deliveryTimesByDelivery[priceObject.delivery]
        : [];
    const currentSupplierNotExists = extendedSuppliers.some(s => s._id.toString() === priceObject.supplier.toString());
    const invalidMOQ =
      selectedSupplier &&
      orderCalculationUtils.getMatchingMOQ(packaging, selectedSupplier._id, +priceObject.orderquantity!) >
        priceObject.orderquantity!;

    return (
      <tr className="kt-datatable__row d-table-row">
        <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
          <span>{calculation.units + " units"}</span>
        </td>
        <td className=" kt-datatable__cell d-table-cell" style={{ width: "8%", paddingTop: 5, paddingBottom: 5 }}>
          <span className="kt-font-bold ">{totalAmount + (totalAmount === 1 ? " item" : " items")}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "14%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <div className="form-group " style={{ marginBottom: "0" }}>
              <div className="input-group input-group-sm">
                <input
                  type="number"
                  className="form-control"
                  name="orderquantity"
                  min={1}
                  disabled={!canCheck}
                  value={priceObject.orderquantity!.toString()}
                  onChange={canCheck ? this.handleCalculationChange : undefined}
                  onBlur={canCheck ? this.handleOrderQuantityBlur : undefined}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{priceObject.orderquantity! === 1 ? "item" : "items"}</span>
                </div>
              </div>
            </div>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "22%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              className="form-control"
              style={invalidMOQ ? { border: "1px solid red" } : {}}
              value={priceObject.supplier.toString()}
              disabled={!canCheck}
              onChange={canCheck ? this.handleSupplierChange : undefined}
            >
              <option key={"ownstock"} value={"ownstock"} disabled={priceObject.orderquantity! > availableStock}>
                Stock {availableStock + (availableStock === 1 ? " item" : " items")}
              </option>
              <option key={"customer"} value={"customer"}>
                Customer
              </option>
              {!currentSupplierNotExists && selectedSupplier && (
                <option key={selectedSupplier._id.toString()} value={selectedSupplier._id.toString()}>
                  {selectedSupplier.name}
                </option>
              )}
              {extendedSuppliers.map(supp => {
                const matchingMOQ = orderCalculationUtils.getMatchingMOQ(
                  packaging,
                  supp._id,
                  +priceObject.orderquantity!
                );
                return (
                  <option
                    key={supp._id.toString()}
                    value={supp._id.toString()}
                    //disabled if we dont have a matching moq
                    disabled={priceObject.orderquantity! < matchingMOQ}
                  >
                    {supp.name + ` MOQ ${matchingMOQ} ${matchingMOQ === 1 ? " item" : " items"}`}
                  </option>
                );
              })}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              disabled={
                availableDeliveries.length === 0 ||
                (typeof priceObject.supplier === "string" && ["ownstock", "customer"].includes(priceObject.supplier)) ||
                !currentSupplierNotExists ||
                invalidMOQ ||
                !canCheck
              }
              className="form-control"
              value={priceObject.delivery}
              onChange={canCheck ? this.handleDeliveryChange : undefined}
            >
              <option disabled key={""} value={""}>
                not set
              </option>
              {availableDeliveries.map(delivery => (
                <option key={delivery} value={delivery}>
                  {delivery}
                </option>
              ))}
              {!availableDeliveries.includes(priceObject.delivery) &&
                priceObject.delivery.trim() !== "" &&
                priceObject.supplier !== "customer" &&
                priceObject.supplier !== "ownstock" && (
                  // Add current delivery if it isnt included anymore
                  <option key={priceObject.delivery} value={priceObject.delivery}>
                    {priceObject.delivery}
                  </option>
                )}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <select
              className="form-control input-number-arrows-low-pr"
              disabled={
                (deliveryTimesForDelivery.length < 2 && priceObject.supplier !== "customer") ||
                !currentSupplierNotExists ||
                invalidMOQ ||
                undefined
              }
              value={priceObject.deliverytime}
              name={"deliverytime"}
              onChange={e => (canCheck ? this.handleDeliveryTimeSelect(e, priceObject) : undefined)}
            >
              {deliveryTimesForDelivery.map((d: string) => (
                <option key={d} value={d}>
                  {d} days
                </option>
              ))}
              {!deliveryTimesForDelivery.includes(priceObject.deliverytime) && priceObject.supplier !== "customer" && (
                // Add current deliverytime if it isnt included anymore
                <option key={priceObject.deliverytime} value={priceObject.deliverytime}>
                  {priceObject.deliverytime} days
                </option>
              )}
              {priceObject.supplier === "ownstock" && (
                <option key={"stock"} value={"stock"}>
                  On Stock
                </option>
              )}
              {priceObject.supplier === "customer" && (
                <>
                  <option value={"0"}>Immediately</option>
                  <option value={"7"}>In 7 days</option>
                  <option value={"10"}>In 10 days</option>
                  <option value={"14"}>In 14 days</option>
                  <option value={"28"}>In 28 days</option>
                  <option value={"30"}>In 30 days</option>
                  <option value={"60"}>In 60 days</option>
                </>
              )}
            </select>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "10%", paddingTop: 5, paddingBottom: 5 }}>
          <span>
            <span className="mr-1">
              {priceObject.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}/item
            </span>
            {canCheck && (
              <span className="kt-portlet__head-icon ml-3 pointer " onClick={this.handleAutoSelect}>
                <i className="kt-font-brand fa fa-magic" />
              </span>
            )}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell" style={{ width: "8%" }}>
          <span className={priceDifference <= 0 ? "text-success" : priceDifference > 0 ? "text-danger" : ""}>
            <b>{priceObject.supplier === "customer" ? "-" : priceDifference.toFixed(2) + "%"}</b>
          </span>
        </td>
      </tr>
    );
  }
}

export default OrderPackagingCheck;
