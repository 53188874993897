import i18n from "i18next";
import { CustomerData, CustomOrder, InvoicePosition } from "../../components/order/CustomTypes";
import pdfUtils from "./pdfUtils";
import baseUtils from "../baseUtils";
import { Invoice } from "../../model/orders.types";
import { I_CANCELATION, I_FINALINVOICE, I_PARTIALINVOICE, I_PAYMENTINADVANCE, I_REMINDER } from "../invoiceUtils";
import { OposCollection } from "../../components/finance/opos/CompanyOposTab";

/**
 * Create an invoice pdf
 * @param order an order document
 * @param customer data about the customer
 * @param invoice invoice data
 * @param positions list of positions
 * @param reverseCharge flag if it is a reverse charge or not
 * @param subTotal the subtotal value, i.e. total amount without VAT
 * @param vatList list with all vat values
 * @param total the total amount with VAT
 * @param type Type of invoice
 * @param lot optional lot number for product
 * @param draft optional flag for draft
 * @param payments optional, contains already made payments
 * @param specificLanguage optional, overwrite the used language
 * @returns { string } html representation for invoice pdf
 */
function createInvoice(
  order: CustomOrder,
  customer: CustomerData,
  invoice: Invoice,
  positions: Array<InvoicePosition>,
  reverseCharge: boolean,
  subTotal: number,
  vatList: Array<{ vat: string; value: number; total: number }>,
  total: number,
  type: string,
  lot?: string,
  draft?: boolean,
  payments?: Array<{ value: number; date: Date; note: string }>,
  specificLanguage?: { lng: string }
) {
  const language = specificLanguage ? specificLanguage : { lng: i18n.language };

  const { invoiceDate, cancelation, deliveryDate, dueIn, invoiceNumber, pdfData, reminder, vatID } = invoice;
  const { content } = pdfData;
  let title, header, paymentInfo, footer;
  if (type === I_CANCELATION) {
    ({ title, header, paymentInfo, footer } = cancelation!.content);
  } else if (type === I_REMINDER) {
    ({ title, header, paymentInfo, footer } = reminder[reminder.length - 1].content);
  } else {
    ({ title, header, paymentInfo, footer } = content);
  }
  let currentUser = order.createdFrom;
  let invoiceNr = draft
    ? "TBD"
    : type === I_CANCELATION
    ? cancelation!.cancelationNumber.toString()
    : invoiceNumber.toString();
  let html =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"><br><table cellpadding="5" cellspacing="0" style="width: 100%; "<tr><td></br><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCustomerHTML(customer, vatID);
  html +=
    `</span></td><td style="text-align: right; font-size:16px; width: 30%"> ${i18n.t(
      "invoice:invoiceNumber",
      language
    )} ` +
    invoiceNr +
    `<br>${i18n.t("invoice:orderNumber", language)}  ` +
    order.identifier;
  if (invoice.bioNumber) {
    html += `<br/>${i18n.t("invoice:organicControlNumber", language)} ` + invoice.bioNumber;
  }
  html +=
    `<br>${i18n.t("invoice:invoiceDate", language)}` +
    baseUtils.formatDate(invoiceDate) +
    `<br>${i18n.t("invoice:deliveryDate", language)}` +
    baseUtils.formatDate(deliveryDate) +
    "</br>";
  html += "</br>";
  html += pdfUtils.getResponsibleHTML(currentUser);
  html += '</td></tr><tr><td style="font-size:25px; font-weight: bold;"><br>';

  if (type === I_PARTIALINVOICE) html += `${i18n.t("invoice:partialInvoiceNo", language)}` + invoiceNr;
  else if (type === I_FINALINVOICE) html += `${i18n.t("invoice:invoiceNo", language)}` + invoiceNr;
  else if (type === I_PAYMENTINADVANCE) html += `${i18n.t("invoice:prepaymentInvoiceNo", language)}` + invoiceNr + "-A";
  else if (type === I_CANCELATION) html += `${i18n.t("invoice:cancellationInvoiceNo", language)}` + invoiceNr;
  else if (type === I_REMINDER && invoice.reminder[invoice.reminder.length - 1].position) {
    const dunningAmount = invoice.reminder.filter(r => r.position).length;
    html += dunningAmount + `${i18n.t("invoice:dunningForInvoiceNo", language)}` + invoiceNr;
  } else if (type === I_REMINDER) html += `${i18n.t("invoice:reminderForInvoiceNo", language)}` + invoiceNr;

  if (title) {
    html += '<br><span style="font-size:18px;">' + title + "</span>";
  }
  if (content.subtitle) {
    html += '<br/><span style="font-size:16px; font-weight: normal">' + content.subtitle + "</span>";
  }
  html += "<br></td></tr></table></body>";
  if (type === I_REMINDER) html += '<table style="width:100%"><tr><td style="width:90%">';
  else html += '<table style="width:100%"><tr><td style="width:50%">';

  if (header) {
    html += '<span style="font-size:16px; position: relative; top:0px; left:0px">' + header + "</span>";
  }
  html += "</td><td></td></tr>";

  html += "</tbody></table></td></tr></table>";
  html += `<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px" ><head><tr style="background-color:#cccccc"><td><b>#</b></td><td style="min-width: 250px"><b style="white-space:nowrap"> ${i18n.t(
    "invoice:name",
    language
  )}</b></td><td><b>${i18n.t("invoice:amount", language)}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "invoice:unit",
    language
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "invoice:single",
    language
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "invoice:vat",
    language
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t(
    "invoice:discount",
    language
  )}</b></td><td><b style="white-space:nowrap;">${i18n.t("invoice:total", language)}</b></td></tr></head>`;
  html += "<tbody>";

  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    html += pdfUtils.getPositionHTML(position, reverseCharge, i, lot, language);
  }
  html += "</tbody>";
  html += "</table>";

  html += pdfUtils.getSubtotalHTML(subTotal, language);
  html += pdfUtils.getVatHTML(vatList, reverseCharge, language);
  html += pdfUtils.getTotalHTML(total, payments, type === I_CANCELATION, language);
  html += pdfUtils.getReverseChargeHTML(reverseCharge, customer.name, vatID, language);
  html += "</br>";
  html += `${i18n.t("template:additionalInvoiceText", language)}`;
  if (type !== I_PAYMENTINADVANCE && type !== I_CANCELATION) html += pdfUtils.getPaymentHTML(type, dueIn, language);
  if (type === I_PAYMENTINADVANCE) {
    html += `</br><span style="font-size:16px"><b>${i18n.t("invoice:paymentInAdvance", language)}</b></span>`;
  }
  if ([I_PAYMENTINADVANCE, I_CANCELATION].includes(type)) html += "</br>";
  if (type !== I_CANCELATION) {
    html += '<span style="font-size:17px">' + paymentInfo + "</span>";
  }
  html += "<br>";
  html += '<span style="font-size:17px">' + footer + "</span><br>";
  html += i18n.t("template:additionalText1", language);
  return html;
}

/**
 * Get the html representation of an opos list.
 * @param timeRelatedOposEntities the invoices entities relevant for the opos listing.
 * @param customer the corresponding customer.
 * @param specificLanguage optional specific language.
 * @returns {string} html representation of a opos list.
 */
function createOposListing(
  timeRelatedOposEntities: OposCollection,
  customer: CustomerData,
  specificLanguage?: { lng: string }
) {
  const language = specificLanguage ? specificLanguage : { lng: i18n.language };

  // plf icon + address section
  let html: string =
    '<head><link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"><style>tr:nth-child(even) {background-color: white;}</style><meta http-equiv="content-type" content="text/html; charset=utf-8"></head>';
  html += '<body style="font-family: Helvetica ">';
  html +=
    '</img><hr><img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="200"></br></br><table cellpadding="5" cellspacing="0" style="width: 100%; "<tr><td></br><span style="font-size:12px; float:left;"><u>Private Label Factory Deutschland GmbH, Philipp-Reis-Str. 7, 45659 Recklinghausen</u></span><br>';
  html += '<span style="font-size:16px; float:left;">';
  html += pdfUtils.getCustomerHTML(customer);
  html += `</table></br></br>`;

  // creation date
  html += `<div style="width: 100%; text-align: right;"><span style="font-size:16px;"><b>${i18n.t(
    "opos:date",
    language
  )}${new Date().toISOString().split("T")[0]}</b></span></div></br>`;

  // title
  const title =
    timeRelatedOposEntities.monthlyCollectionDate.toLocaleString(language.lng === "en" ? "en-US" : "de-DE", {
      month: "long"
    }) +
    " - " +
    timeRelatedOposEntities.monthlyCollectionDate.getFullYear();
  html += `<span style="font-size:18px; margin-left: 0.5%"><b>${i18n.t(
    "opos:oposTitle",
    language
  )} ${title}</b></span></br>`;

  // opos table
  html += `<table cellpadding="5" cellspacing="0"  style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px; text-align: left" >`;
  // table header
  html += `<tr style="background-color:#cccccc">`;
  html += `<th><b>${i18n.t("opos:invoiceDate", language)}</b></th>`; // invoice date header
  html += `<th><b>${i18n.t("opos:invoiceNumber", language)}</b></th>`; // invoice number header
  html += `<th><b>${i18n.t("opos:relatedProduct", language)}</b></th>`; // product header
  html += `<th><b>${i18n.t("opos:paymentReceived", language)}</b></th>`; // paid header
  html += `<th><b>${i18n.t("opos:restAmount", language)}</b></th>`; // rest amount header
  html += `</tr>`;

  // table content
  html += `<tbody>`;
  timeRelatedOposEntities.relatedOposEntities.forEach(entry => {
    html += `<tr>`;
    html += `<td>${entry.invoiceDate.toISOString().split("T")[0]}</td>`;
    html += `<td>${entry.invoiceId}</td>`;
    html += `<td>${entry.orderProduct}</td>`;
    html += `<td>`;
    if (entry.paymentStatus === "paid") {
      html += `${i18n.t("opos:paymentStatusPaid", language)}`;
    } else if (entry.paymentStatus === "partlyPaid") {
      html += `${i18n.t("opos:paymentStatusPartlyPaid", language)}`;
    } else {
      html += `${i18n.t("opos:paymentStatusNotPaid", language)}`;
    }
    html += `</td>`;
    html += `<td>${baseUtils.formatEuro(entry.remainingAmount)}</td>`;
    html += `</tr>`;
  });
  html += `<tr style="height: 20px;"/>`;
  html += `<tr style="background-color:#cccccc"><th><b>${i18n.t(
    "opos:totalRemaining",
    language
  )}</b><th colspan="3"/><th><b>${baseUtils.formatEuro(timeRelatedOposEntities.totalRestAmount)}</b></th></tr>`;
  html += `</tbody>`;

  // end of table
  html += `</table>`;

  // closing formula
  html += `</br></br></br>`;
  html += `${i18n.t("opos:closingFormula", language)}`;

  // document footer
  html += `<div style="font-size:15px; width:100%; bottom: 0; position: absolute">`;
  html += `${i18n.t("opos:banking", language)}`;
  html += `</br></br>`;
  html += `${i18n.t("opos:footer", language)}`;
  html += `</div>`;

  return html;
}

export default { createInvoice, createOposListing };
