import { BSON } from "realm-web";
import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import CommodityHeader from "./CommodityHeader";
import CommodityNote from "./CommodityNote";
import CommodityPriceChart from "./CommodityPriceChart";
import CommodityTabPanel from "./CommodityTabPanel";
import { DataContext } from "../../context/dataContext";
import CommodityCommodityOrders from "./tabPanels/CommodityCommodityOrders";
import { CommoditiesDocument } from "../../model/commodities.types";
import { OrdersDocument } from "../../model/orders.types";
import { ORDERS } from "../../services/dbService";

interface CommodityParams {
  id: string;
  order: string;
}

interface CommodityProps extends RouteComponentProps<CommodityParams, {}, {}> {
  context: React.ContextType<typeof DataContext>;
}

interface CommodityState {
  commodity: CommoditiesDocument;
  saving: boolean;
  order?: OrdersDocument;
}

class Commodity extends PureComponent<CommodityProps, CommodityState> {
  tabPanelRef: any;

  constructor(props: CommodityProps) {
    super(props);
    let order;
    if (props.match.params.order) {
      order = props.context.orders.find(o => o._id.toString() === props.match.params.order);
    }
    this.state = {
      commodity: props.context.commodities.find(c => c._id.toString() === props.match.params.id)!,
      saving: false,
      order
    };
  }

  componentDidUpdate(prevProps: Readonly<CommodityProps>, prevState: Readonly<CommodityState>, snapshot?: any) {
    const { context, match } = this.props;
    const commodity = context.commodities.find(c => c._id.toString() === match.params.id)!;
    if (!_.isEqual(commodity, this.state.commodity)) {
      this.setState({ commodity });
    }
    // If an order was provided in the params but we have not loaded an order - retry to find it
    if (!prevState.order && match.params.order) {
      this.setState({ order: context.orders.find(o => o._id.toString() === match.params.order) });
    }
  }

  async componentDidMount() {
    const { match, context } = this.props;
    const { order } = this.state;
    // If there was an ID provided but no order loaded we might still load the orders in background. To be able to show
    // the modal we can bypass the loading and query the document directly
    if (match.params.id && !order) {
      // If an invalid ObjectID was passed we can just ignore it - thus the try catch
      try {
        await context.updateDocumentInContext(ORDERS, new BSON.ObjectId(match.params.order));
      } catch (e) {
        console.error("Invalid object id provided in query: ", match.params.order, e);
      }
    }
  }

  handleChangeTab = (tab: string) => this.tabPanelRef.handleTab(tab);

  setRef = (ref: any) => {
    this.tabPanelRef = ref;
  };

  render() {
    const { context } = this.props;
    const { commodity, saving, order } = this.state;
    return (
      <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <CommodityHeader commodity={commodity} context={context} />
        <div className="row">
          <div className="col-xl-4">
            {commodity.type !== "service" && (
              <div className="kt-portlet kt-portlet--head-noborder">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Open Commodity Orders</h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <CommodityCommodityOrders
                    commodity={commodity}
                    context={context}
                    size="small"
                    onTabChange={this.handleChangeTab}
                    order={order}
                  />
                </div>
              </div>
            )}
            <CommodityNote commodity={commodity} onTabChange={this.handleChangeTab} />
            {commodity.suppliers.length > 0 && <CommodityPriceChart commodity={commodity} context={context} />}
          </div>
          <div className="col-xl-8">
            <CommodityTabPanel commodity={commodity} context={context} saving={saving} ref={this.setRef} />
          </div>
        </div>
      </div>
    );
  }
}

export default Commodity;
