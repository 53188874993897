import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { CommoditiesDocument } from "../../model/commodities.types";
import baseUtils from "../../utils/baseUtils";
import fileUtils from "../../utils/fileUtils";
import HistoryBackButton from "../listings/common/HistoryBackButton";
import dateUtils from "../../utils/dateUtils";

interface CommodityConsumptionProps extends RouteComponentProps {
  commodities: Array<CommoditiesDocument>;
}

interface CommodityConsumptionState {
  excludeNotUsed: boolean;
  generating: boolean;
  periodValues: Array<{ value: string; label: string }>;
  showDataLast12Months: boolean;
  withPrices: boolean;
}

class CommodityConsumption extends PureComponent<CommodityConsumptionProps, CommodityConsumptionState> {
  constructor(props: CommodityConsumptionProps) {
    super(props);
    this.state = {
      excludeNotUsed: false,
      generating: false,
      periodValues: [],
      showDataLast12Months: false,
      withPrices: true
    };
  }

  handleToggleOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  handlePeriodChange = (e: Array<{ value: string; label: string }> | null) => {
    this.setState({ periodValues: e ? e : [] });
  };

  handleClickGenerateCSV = () => {
    this.setState({ generating: true });
    const { periodValues, showDataLast12Months, withPrices } = this.state;
    const comData = this.generateCommodityData();
    const headers = ["OID", "Title", "Subtitle", "Identifier"];
    periodValues
      .sort((a, b) => Number(a.value) - Number(b.value))
      .forEach(periodValue => {
        headers.push("Volume " + periodValue.value + " (KG)");
      });
    headers.push(showDataLast12Months ? "Volume last 12 Months (KG)" : "Volume Total (KG)");
    if (withPrices) headers.push(showDataLast12Months ? "Average price last 12 Months (€/KG)" : "Average price (€/KG)");
    const content = [];
    try {
      for (let i = 0; i < comData.length; i++) {
        const c = comData[i];
        const contentData = [c.id, c.title, c.subtitle, c.identifier];
        periodValues
          .sort((a, b) => Number(a.value) - Number(b.value))
          .forEach(periodValue => {
            if (c["volume" + periodValue.value] !== undefined) contentData.push(c["volume" + periodValue.value]);
          });
        contentData.push(c.volumeTotal.toString());
        if (withPrices && c.averagePrice !== undefined) contentData.push(c.averagePrice.toString());
        content.push(contentData);
      }
      const csv = fileUtils.exportAsCSV(
        headers,
        _.sortBy(content, e => e[1])
      );
      fileUtils.downloadFile(csv, "CommodityConsumption_" + baseUtils.formatDate(new Date()) + ".csv", "text/plain");
    } catch (e) {
      toast.error("Error generating csv");
      console.error(e);
    } finally {
      this.setState({ generating: false });
    }
  };

  generateCommodityData = () => {
    const { commodities } = this.props;
    const { excludeNotUsed, periodValues, showDataLast12Months, withPrices } = this.state;
    const commodityData: Array<any> = [];
    for (let i = 0; i < commodities.length; i++) {
      const c = commodities[i];
      const entry: any = {
        id: c._id.toString(),
        title: baseUtils.formatStringForCSV(c.title.en),
        subtitle: baseUtils.formatStringForCSV(c.subtitle.en),
        identifier: c.identifier,
        volumeTotal:
          c.orders && c.orders.length > 0
            ? showDataLast12Months
              ? c.orders.reduce((sum, o) => (this.isOlderThan12Months(o.delivered) ? sum : sum + o.orderquantity), 0)
              : c.orders.reduce((sum, o) => sum + o.orderquantity, 0)
            : 0
      };
      periodValues.forEach(periodValue => {
        entry["volume" + periodValue.value] =
          c.orders && c.orders.length > 0
            ? c.orders.reduce(
                (sum, o) => (o.delivered?.getFullYear() === Number(periodValue.value) ? sum + o.orderquantity : sum),
                0
              )
            : 0;
      });
      if (withPrices) {
        entry.averagePrice =
          (c.orders && c.orders.length > 0
            ? showDataLast12Months
              ? c.orders.reduce((price, o) => (this.isOlderThan12Months(o.delivered) ? price : price + o.totalPrice), 0)
              : c.orders.reduce((price, o) => price + o.totalPrice, 0)
            : 0) / (entry.volumeTotal !== 0 ? entry.volumeTotal : 1);
      }
      if (excludeNotUsed && entry.volumeTotal <= 0) continue;
      commodityData.push(entry);
    }
    return commodityData;
  };

  isOlderThan12Months = (date: Date | null) => {
    if (!date) return true;
    const dateLimit = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 12); // 12 Months ago;
    return date < dateLimit;
  };

  render() {
    const { history } = this.props;
    const { excludeNotUsed, generating, periodValues, showDataLast12Months, withPrices } = this.state;
    const defaultDate = dateUtils.getDefaultPeriod();
    const volumeOptions = dateUtils.generateYearsBetween(defaultDate.start, defaultDate.end).map(date => {
      return { value: date.toString(), label: date.toString() };
    });

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Commodity Consumption</h3>
          </div>
          <HistoryBackButton history={history} />
        </div>
        <div className="kt-portlet__body">
          <div className="row mt-2">
            <div className="col text-dark font-weight-bolder mb-5">
              Exports the consumption data of all commodities in the system.
            </div>
          </div>
          <div className="row mt-2">
            <label className="col col-4 col-md-3 col-lg-2 align-self-center">Timespan (Show volume for)</label>
            <div className="col col-4 col-md-3 col-lg-2">
              <Select
                className="select-default"
                isMulti={true}
                options={volumeOptions}
                value={periodValues}
                onChange={(e: any) => this.handlePeriodChange(e)}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={this.handleToggleOption}
                  checked={showDataLast12Months}
                  name="showDataLast12Months"
                />
                <label className="form-check-label kt-font-dark ml-1">Show volume for last 12 months</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={this.handleToggleOption}
                  checked={excludeNotUsed}
                  name="excludeNotUsed"
                />
                <label className="form-check-label kt-font-dark ml-1">Exclude unused commodities</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={this.handleToggleOption}
                  checked={withPrices}
                  name="withPrices"
                />
                <label className="form-check-label kt-font-dark ml-1">Show average price</label>
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="float-right">
              <button
                className="btn btn-success"
                disabled={generating}
                onClick={generating ? undefined : this.handleClickGenerateCSV}
              >
                {generating ? "Generating..." : "Generate CSV"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CommodityConsumption);
