import React, { PureComponent } from "react";
import Select from "react-select";
import { BSON } from "realm-web";
import _ from "lodash";
import baseUtils, { round } from "../../../utils/baseUtils";
import { ProductTypes } from "../../configurator/configuratorConstants";
import { CustomCalculationForModal } from "../../configurator/CustomTypes";
import { ManufacturersDocument } from "../../../model/manufacturers.types";
import { CustomCalculationPreviewStats } from "../../configurator/modals/CustomCalculationModal";
import { PRODUCTION_TYPES } from "../../configurator/calculationDetails/calculationHelper";
import userService from "../../../services/userService";

interface CustomCalculationViewProps {
  type: string;
  edit: boolean;
  oldUnitPriceNaked?: number;
  reconstructedUnitPriceNaked?: number;
  buffer: string;
  optionalCost?: string;
  note?: string;
  customCalculationInfo: Array<CustomCalculationForModal>;
  stats: CustomCalculationPreviewStats;
  filteredManufacturers: Array<ManufacturersDocument>;
  onChangeManufacturer: (
    e: {
      value: {
        manufacturerId: BSON.ObjectId;
        type: PRODUCTION_TYPES;
      };
      label: string;
    },
    index: number
  ) => void;
  onChangeCost: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeOptionalCost: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeBuffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

class CustomCalculationView extends PureComponent<CustomCalculationViewProps> {
  render() {
    const {
      type,
      edit,
      oldUnitPriceNaked,
      reconstructedUnitPriceNaked,
      buffer,
      optionalCost,
      note,
      customCalculationInfo,
      stats,
      filteredManufacturers,
      onChangeManufacturer,
      onChangeCost,
      onChangeOptionalCost,
      onChangeBuffer,
      onChangeNote
    } = this.props;
    // consider only 4 digits behind comma
    const difference =
      Math.round((oldUnitPriceNaked || 0) * 10000) / 10000 -
      Math.round((reconstructedUnitPriceNaked || 0) * 10000) / 10000;
    const isUserAdmin = userService.isAdmin();

    return (
      <>
        <div
          className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded"
          style={{ maxHeight: "50vh" }}
        >
          {edit && oldUnitPriceNaked && reconstructedUnitPriceNaked && difference !== 0 && (
            <div className="mb-3">
              <span className="kt-badge kt-badge--inline py-1 kt-badge--warning text-left font-weight-bold w-100">
                Cost per unit could not be reconstructed correctly!
                <br />
                Cost per unit in order: {round(oldUnitPriceNaked)}€, reconstructed cost per unit:{" "}
                {round(reconstructedUnitPriceNaked)}€
              </span>
            </div>
          )}
          <div className="kt-widget kt-widget--user-profile-2">
            <div className="kt-widget__body">
              <div className="kt-widget__content">
                <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                  <div className="kt-widget__icon">
                    <i className="flaticon-piggy-bank" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Unit Price Naked</span>
                    <span className="kt-widget__value">{baseUtils.formatEuro(stats.unitPriceNaked)}</span>
                  </div>
                </div>
                <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                  <div className="kt-widget__icon">
                    <i className="flaticon-pie-chart" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Total Margin</span>
                    <span className="kt-widget__value">{stats.totalMarginPercent.toFixed(2)}%</span>
                  </div>
                </div>
                <div className="kt-widget__stats " style={{ paddingBottom: "0.3rem" }}>
                  <div className="kt-widget__icon">
                    <i className="flaticon-pie-chart" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Unit Margin</span>
                    <span className="kt-widget__value">{baseUtils.formatEuro(stats.unitMargin)}</span>
                  </div>
                </div>
                <div className="kt-widget__stats" style={{ paddingBottom: "0.3rem" }}>
                  <div className="kt-widget__icon">
                    <i className="flaticon-pie-chart" />
                  </div>
                  <div className="kt-widget__details">
                    <span className="kt-widget__title">Total Margin</span>
                    <span className="kt-widget__value">{baseUtils.formatEuro(stats.totalMargin)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="kt-datatable__table d-table" style={{ overflow: "visible" }}>
            <thead className="kt-datatable__head" style={{ display: "table-header-group" }}>
              <tr className="kt-datatable__row d-table-row">
                <th className="kt-datatable__cell d-table-cell pr-1" style={{ width: "35%", paddingLeft: "0px" }}>
                  <span>Manufacturer</span>
                </th>
                <th className="kt-datatable__cell d-table-cell px-1" style={{ width: "30%" }}>
                  <span>Type</span>
                </th>
                <th className="kt-datatable__cell d-table-cell px-1" style={{ width: "35%" }}>
                  <span>Cost</span>
                </th>
              </tr>
            </thead>
            <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
              {customCalculationInfo.map((cci, index) => {
                return (
                  <tr key={cci.type} className="kt-datatable__row table-hover d-table-row border-0">
                    <td className="kt-datatable__cell d-table-cell py-1 pl-0 pr-1">
                      {![ProductTypes.SERVICE].includes(type) && (
                        <div className="w-100">
                          <Select
                            className="select-default w-100"
                            onChange={(e: any) => onChangeManufacturer(e, index)}
                            value={
                              cci.manufacturerId
                                ? {
                                    value: {
                                      manufacturerId: cci.manufacturerId,
                                      type: cci.type
                                    },
                                    label:
                                      filteredManufacturers.find(
                                        m => m._id.toString() === cci.manufacturerId.toString()
                                      )?.name || ""
                                  }
                                : { value: undefined, label: "" }
                            }
                            options={filteredManufacturers.map(manufacturer => {
                              return {
                                value: {
                                  manufacturerId: manufacturer._id,
                                  type: cci.type
                                },
                                label: manufacturer.name
                              };
                            })}
                          />
                        </div>
                      )}
                    </td>
                    <td className="kt-datatable__cell d-table-cell p-1">
                      <input className="form-control" disabled={true} value={_.capitalize(cci.type)} />
                    </td>
                    <td className="kt-datatable__cell d-table-cell py-1 pl-1 pr-0">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ width: "60px" }}>
                            Total
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          placeholder="Total price"
                          value={cci.cost}
                          onChange={e => onChangeCost(e, index)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr className="kt-datatable__row d-table-row border-0">
                <td className="kt-datatable__cell d-table-cell p-1"></td>
                <td className="kt-datatable__cell d-table-cell p-1">
                  <input className="form-control" disabled={true} value={"Additional Costs"} />
                </td>
                <td className="kt-datatable__cell d-table-cell py-1 pl-1 pr-0">
                  <div className="form-group form-group-last">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" style={{ width: "60px" }}>
                          Total
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="number"
                        value={optionalCost}
                        placeholder="Additional Costs"
                        onChange={e => onChangeOptionalCost(e)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              {isUserAdmin && (
                <tr className="kt-datatable__row d-table-row border-0">
                  <td className="kt-datatable__cell d-table-cell p-1"></td>
                  <td className="kt-datatable__cell d-table-cell p-1">
                    <input className="form-control mt-3" disabled={true} value={"Margin Buffer"} />
                  </td>
                  <td className="kt-datatable__cell d-table-cell py-1 pl-1 pr-0">
                    <div className="form-group form-group-last mt-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ width: "60px" }}>
                            Unit
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="number"
                          value={buffer}
                          min={0}
                          placeholder="Margin Buffer per Unit"
                          onChange={e => onChangeBuffer(e)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <textarea
          className="form-control m-0"
          placeholder="Notes"
          rows={2}
          onChange={e => onChangeNote(e)}
          value={note}
        />
      </>
    );
  }
}

export default CustomCalculationView;
