import React, { Component } from "react";
import { DataContext } from "../../context/dataContext";
import orderUtils, {
  ARCHIVE,
  CONTRACT,
  CREATEINVOICE,
  DECLINED,
  FULFILLMENT,
  OFFER,
  ORDERORDERCOMMODITIES,
  PRODUCTION,
  PRODUCTIONQUEUE,
  REQUESTAPPROVED,
  REQUESTPENDING,
  WAITING
} from "../../utils/orderUtils";
import { CustomOrder } from "./CustomTypes";
import OrderHelper from "./OrderHelper";
import { FulfillmentPriceInfo } from "../../model/orders.types";
import { T_OFFER, T_ORDERED } from "../../utils/timelineUtils";
import dbGeneralService from "../../services/dbServices/dbGeneralService";
import dbService, { ORDERS } from "../../services/dbService";
import toastUtils from "../../utils/toastUtils";
import accessUtils from "../../utils/accessUtils";
import SetShelfLifeModal from "./modals/SetShelfLifeModal";
import ChangeManufacturerModal, { ManufacturerType } from "./modals/ChangeManufacturerModal";

interface OrderOverviewProps {
  order: CustomOrder;
  context: React.ContextType<typeof DataContext>;
}

interface OrderOverviewState {
  generatingLot: boolean;
}

class OrderOverview extends Component<OrderOverviewProps, OrderOverviewState> {
  constructor(props: OrderOverviewProps) {
    super(props);
    this.state = {
      generatingLot: false
    };
  }

  handleGenerateLOT = async () => {
    const { order } = this.props;
    if (order.fulfillment && order.fulfillment.lot) return;
    this.setState({ generatingLot: true });
    const lot = await dbGeneralService.callGenerateLotNumberFunction();
    let result;
    if (order.fulfillment && !order.fulfillment.lot) {
      result = await dbService.updateDocument(ORDERS, order._id, { "fulfillment.lot": lot });
    } else if (!order.fulfillment) {
      const fulfillment = {
        lot,
        exp: new Date(0), // set an obviously invalid date
        shippingNote: "",
        shippingGroups: []
      };
      result = await dbService.updateDocument(ORDERS, order._id, { fulfillment });
    }
    await toastUtils.databaseOperationToast(
      !!result && result.modifiedCount > 0,
      "LOT successfully generated: " + lot,
      "LOT could not be generated"
    );
    this.setState({ generatingLot: false });
  };

  getStateDescription = () => {
    const { order } = this.props;
    switch (order.state) {
      case OFFER:
        return "Offer";
      case REQUESTPENDING:
        return "Pending Request";
      case REQUESTAPPROVED:
        return "Approved Request";
      case ORDERORDERCOMMODITIES:
        return "Order Commodities";
      case CONTRACT:
        return "Contract";
      case WAITING:
        return "On Hold";
      case PRODUCTIONQUEUE:
        return "Ready For Production";
      case PRODUCTION:
        return "In Production";
      case FULFILLMENT:
        return "QM / Fulfillment";
      case CREATEINVOICE:
        return "Invoice Required";
      case ARCHIVE:
        return "Archive";
      case DECLINED:
        return "Declined";
    }
  };

  /**
   * Get values from the fulfillment price info object
   * @param property key of the fulfillment info object to get the data for
   * @returns {[string, number, any]} triple of prefix, the value and null
   */
  getFulfillmentValue = (property: keyof Omit<FulfillmentPriceInfo, "marginBuffer">): [string, number, null] => {
    const { order } = this.props;
    const priceInfo = order.fulfillment?.priceInfo!;
    return ["", priceInfo[property], null];
  };

  render() {
    const { order, context } = this.props;
    const { userdata } = context;
    const { generatingLot } = this.state;
    const trackingId =
      order.identifier +
      "-" +
      order._id
        .toString()
        .slice(order._id.toString().length - 6)
        .toUpperCase();
    const hasFfPriceInfo =
      [FULFILLMENT, CREATEINVOICE, ARCHIVE, DECLINED].includes(order.state) &&
      order.fulfillment &&
      order.fulfillment.priceInfo;
    const totalMargin = hasFfPriceInfo
      ? this.getFulfillmentValue("totalPrice")
      : orderUtils.getAverageValue(order, "totalprice");
    const percentMargin = hasFfPriceInfo
      ? this.getFulfillmentValue("percentMargin")
      : orderUtils.getAverageValue(order, "percentmargin");
    const contactPerson = userdata.find(
      user => user.company_id && user.company_id.toString() === order.createdFor._id.toString()
    );
    const canSeeFinanceData = accessUtils.canSeeFinanceData();
    const offerTimelineEntry = order.timeline.find(t => t.type === T_OFFER);
    const orderedTimelineEntry = order.timeline
      .filter(t => t.type === T_ORDERED)
      .sort((t1, t2) => t2.date.getTime() - t1.date.getTime());
    const isCustom = order.calculations.some(c => c.info.customCalculation);

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Overview</h3>
          </div>
          <div className="kt-portlet__head-toolbar" />
        </div>
        <div className="kt-form kt-form--label-right">
          <div className="kt-portlet__body">
            {offerTimelineEntry && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">Offer created:</label>
                <div className="col-8">
                  <span className="form-control-plaintext kt-font-bolder">
                    {offerTimelineEntry.date.toLocaleDateString("de-DE", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })}
                  </span>
                </div>
              </div>
            )}
            {orderUtils.isOrderState(order.state) && orderedTimelineEntry.length > 0 && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">Ordered:</label>
                <div className="col-8">
                  <span className="form-control-plaintext kt-font-bolder">
                    {orderedTimelineEntry[0].date.toLocaleDateString("de-DE", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })}
                  </span>
                </div>
              </div>
            )}
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">State:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">{this.getStateDescription()}</span>
              </div>
            </div>
            <ManufacturerInformation order={order} />
            {canSeeFinanceData && (
              <>
                <div className="form-group form-group-xs row">
                  <label className="col-4 col-form-label">Volume:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext kt-font-bolder">
                      {totalMargin[0] +
                        totalMargin[1].toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR"
                        })}
                      {OrderHelper.renderDetailsTooltip(totalMargin[2])}
                    </span>
                  </div>
                </div>
                <div className="form-group form-group-xs row">
                  <label className="col-4 col-form-label">Margin:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext kt-font-bolder">
                      {percentMargin[0] + (Math.round(percentMargin[1] * 100) / 100).toString() + " %"}
                      {OrderHelper.renderDetailsTooltip(percentMargin[2], true)}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="form-group form-group-xs row">
              <label className="col-4 col-form-label">Contact Person:</label>
              <div className="col-8">
                <span className="form-control-plaintext kt-font-bolder">
                  {contactPerson ? contactPerson.prename + " " + contactPerson.surname : "-"}
                </span>
              </div>
            </div>
            {orderUtils.isOrderState(order.state) && order.state !== DECLINED && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">Shelf Life:</label>
                <div className="col-8 my-auto">
                  <span className="form-control-plaintext kt-font-bolder ">
                    {order.fulfillment && order.fulfillment.shelfLife && (
                      <span className="mr-2">{order.fulfillment.shelfLife} months</span>
                    )}
                    <SetShelfLifeModal order={order} />
                  </span>
                </div>
              </div>
            )}
            {orderUtils.isOrderState(order.state) && order.state !== DECLINED && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">LOT Number:</label>
                <div className="col-8 my-auto">
                  {order.fulfillment && order.fulfillment.lot ? (
                    <span className="form-control-plaintext kt-font-bolder">{order.fulfillment.lot}</span>
                  ) : (
                    <button
                      className={"btn btn-sm btn-secondary py-1 px-2 " + (generatingLot ? "disabled" : "")}
                      disabled={generatingLot}
                      onClick={generatingLot ? undefined : this.handleGenerateLOT}
                    >
                      Generate LOT
                    </button>
                  )}
                </div>
              </div>
            )}
            {orderUtils.isOrderState(order.state) && order.state !== DECLINED && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">Trackingnummer:</label>
                <div className="col-8">
                  <span className="form-control-plaintext kt-font-bolder">
                    <a target="_blank" href={"https://private-label-factory.com/tracking?trackingId=" + trackingId}>
                      {trackingId}
                    </a>
                  </span>
                </div>
              </div>
            )}
            {isCustom && (
              <div className="form-group form-group-xs row">
                <label className="col-4 col-form-label">Calculation:</label>
                <div className="col-8">
                  <span className="form-control-plaintext kt-font-bolder">
                    <div className="form-group form-group-xs ">
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill kt-badge--warning"
                        onClick={() => document.getElementById("detailsTab")?.click()}
                      >
                        Custom
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

interface ManufacturerInformationProps {
  order: CustomOrder;
}

const ManufacturerInformation: React.FC<ManufacturerInformationProps> = ({ order }) => {
  const { manufacturer, filler } = order.settings;
  return (
    <>
      <div className="form-group form-group-xs row">
        <label className="col-4 col-form-label">Manufacturer:</label>
        <div className="col-8">
          <span className="form-control-plaintext kt-font-bolder ">
            <span className="mr-2">
              {manufacturer.name.trim() !== "" ? manufacturer.name : "<Empty Name - Please fill in>"}
            </span>
            {[ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, CONTRACT].includes(order.state) && (
              <ChangeManufacturerModal order={order} type={ManufacturerType.MANUFACTURER} />
            )}
          </span>
        </div>
      </div>
      <div className="form-group form-group-xs row">
        <label className="col-4 col-form-label">Filler:</label>
        <div className="col-8">
          <span className="form-control-plaintext kt-font-bolder">
            {filler && (
              <span className="mr-2">{filler.name.trim() !== "" ? filler.name : "<Empty Name - Please fill in>"}</span>
            )}
            {[ORDERORDERCOMMODITIES, WAITING, PRODUCTIONQUEUE, PRODUCTION, CONTRACT].includes(order.state) && (
              <ChangeManufacturerModal order={order} type={ManufacturerType.FILLER} />
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default OrderOverview;
